import React from "react";
import styled from "styled-components";
import CheckmarkIcon from "../assets/icons/JSX/CheckmarkIcon";
import Colors from "../assets/styles/Colors";

const Checkbox: React.FC<{
  id: string | number;
  label: string;
  className?: string;
  onChange: (_: boolean) => void;
  onClick?: () => void;
  style?: React.CSSProperties;
  defaultChecked?: boolean;
  disabled?: boolean;
  checked?: boolean;
  notChecke?: boolean;
  type?: "checkbox " | "radio " | string;
}> = ({
  id,
  label,
  className,
  onChange,
  onClick,
  style,
  defaultChecked = undefined,
  disabled = false,
  checked,
  notChecke = false,
  type = "checkbox",
}) => {
  //
  //
  return (
    <Wrapper style={style} className={className}>
      <Input
        onClick={onClick}
        style={{ opacity: disabled ? "0.6" : "unset" }}
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        type={type}
        id={`field-${id}`}
        name={`field-${id}`}
        onChange={(event) =>
          notChecke ? console.log(null) : [onChange(event.target.checked)]
        }
      />
      {checked === true || defaultChecked === true ? (
        <Marker>
          <CheckmarkIcon />
        </Marker>
      ) : null}
      <Label htmlFor={`field-${id}`}>{label}</Label>
    </Wrapper>
  );
};
//
//
// styles
const Marker = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: ${Colors.primaryRed};
  position: absolute;
  /* top: 0; */
  left: 0;
  user-select: none;
  pointer-events: none;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* inner icon svg */
  svg {
    width: 13px;
    color: ${Colors.white};
  }
`;
const Label = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.rollingStone};
  padding-left: 16px;
  cursor: pointer;
`;
const Input = styled.input`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border-color: transparent;
  outline: none;
  padding: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  /* after */
  &::after {
    content: "";
    display: block;
    border: 1px solid ${Colors.rollingStone};
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: ${Colors.white};
  }

  /* checked status styles */
  &:checked + label {
    color: ${Colors.primaryBlack};
  }
`;
const Wrapper = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  /* margin-left: 50px; */
`;

export default Checkbox;
