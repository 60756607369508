import {
  Button,
  Checkbox,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Table,
  Tooltip,
} from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineClear } from "react-icons/ai";
import { MdDone } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Colors from "../../../assets/styles/Colors";
import { useAppSelector } from "../../../store";
import AdminWebUserDetails from "./AdminWebUserDetails";

type FilterFieldsType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  personalNumber: string;
  activated: boolean;
  franiUser: boolean;
};

type TableFilterType = {
  pageIndex: number;
  pageSize: number;
};

const AdminWebUserList = () => {
  const { lang } = useAppSelector((root) => root.app);
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openEditUserDrawer, setOpenEditUserDrawer] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  const [filterFields, setFilterFields] =
    useState<Partial<FilterFieldsType> | null>();

  const [tableFilterFields, setTableFilterFields] =
    useState<Partial<TableFilterType> | null>({
      pageIndex: 1,
      pageSize: 10,
    });

  const _setFilterFields = (field: keyof FilterFieldsType, value: any) => {
    setFilterFields({
      ...filterFields,
      [field]: value,
    });
  };

  const [debouncedFirstName] = useDebounce(filterFields?.firstName, 1000);

  const [debouncedLastName] = useDebounce(filterFields?.lastName, 1000);

  const [debouncedEmail] = useDebounce(filterFields?.email, 1000);

  const [debouncedPhone] = useDebounce(filterFields?.phone, 1000);

  const [debouncedPersonalNumber] = useDebounce(
    filterFields?.personalNumber,
    1000
  );

  const {
    data: webUsersList,
    isLoading: isWebUsersListLoading,
    refetch: webUserListRefetch,
  } = api.useGetWebUsersListQuery({
    query: `pageIndex=${tableFilterFields?.pageIndex}&pageSize=${
      tableFilterFields?.pageSize
    }${
      debouncedFirstName !== undefined && debouncedFirstName !== ""
        ? `&firstName=${debouncedFirstName}`
        : ""
    }${
      debouncedLastName !== undefined && debouncedLastName !== ""
        ? `&lastName=${debouncedLastName}`
        : ""
    }
    ${
      debouncedEmail !== undefined && debouncedEmail !== ""
        ? `&email=${debouncedEmail}`
        : ""
    }${
      debouncedPhone !== undefined && debouncedPhone !== ""
        ? `&phone=${debouncedPhone}`
        : ""
    }${
      debouncedPersonalNumber !== undefined && debouncedPersonalNumber !== ""
        ? `&personalNumber=${debouncedPersonalNumber}`
        : ""
    }${
      filterFields?.activated !== undefined && filterFields?.activated !== null
        ? `&activated=${filterFields?.activated}`
        : ""
    }${
      filterFields?.franiUser !== undefined && filterFields?.franiUser !== null
        ? `&franiUser=${filterFields?.franiUser}`
        : ""
    }`,
  });

  const [deleteWebUser, { isLoading: isDeleteWebUserLoading }] =
    api.useDeleteWebUserMutation();

  // countries list
  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);

  const columns = [
    {
      translations: {
        en: {
          title: "Name",
        },
        ge: {
          title: "სახელი",
        },
      },
      dataIndex: "firstName",
    },
    {
      translations: {
        en: {
          title: "Surname",
        },
        ge: {
          title: "გვარი",
        },
      },
      dataIndex: "lastName",
    },
    {
      translations: {
        en: {
          title: "E-mail",
        },
        ge: {
          title: "ელ.ფოსტა",
        },
      },
      dataIndex: "email",
    },
    {
      translations: {
        en: {
          title: "Phone",
        },
        ge: {
          title: "მობ. ნომერი",
        },
      },
      dataIndex: "phone",
    },
    {
      translations: {
        en: {
          title: "ID Number",
        },
        ge: {
          title: "პირადი ნომერი",
        },
      },
      dataIndex: "personalNumber",
    },
    {
      translations: {
        en: {
          title: "Passport Number",
        },
        ge: {
          title: "პასპორტის ნომერი",
        },
      },
      dataIndex: "passportNumber",
    },
    {
      translations: {
        en: {
          title: "Cityzenship",
        },
        ge: {
          title: "მოქალაქეობა",
        },
      },
      dataIndex: "citizenship",
    },
    {
      translations: {
        en: {
          title: "Name Ge",
        },
        ge: {
          title: "სახელი ქართულად",
        },
      },
      dataIndex: "firstNameGe",
    },
    {
      translations: {
        en: {
          title: "Surname Ge",
        },
        ge: {
          title: "გვარი ქართულად",
        },
      },
      dataIndex: "lastNameGe",
    },
    {
      translations: {
        en: {
          title: "Date of birth",
        },
        ge: {
          title: "დაბადების თარიღი",
        },
      },
      dataIndex: "birthday",
    },
    {
      translations: {
        en: {
          title: "Age",
        },
        ge: {
          title: "ასაკი",
        },
      },
      dataIndex: "age",
    },
    {
      translations: {
        en: {
          title: "Gender",
        },
        ge: {
          title: "სქესი",
        },
      },
      dataIndex: "gender",
    },
    {
      translations: {
        en: {
          title: "SSGS Verification",
        },
        ge: {
          title: "სსგს ვერიფიკაცია",
        },
      },
      dataIndex: "sgsVerification",
    },
    {
      translations: {
        en: {
          title: "E-mail Verification",
        },
        ge: {
          title: "ელ.ფოსტის ვერიფიკაცია",
        },
      },
      dataIndex: "emailVerification",
    },
    {
      translations: {
        en: {
          title: "Phone Verification",
        },
        ge: {
          title: "მობ.ნომერის ვერიფიკაცია",
        },
      },
      dataIndex: "phoneVerification",
    },
    {
      translations: {
        en: {
          title: "Identificated",
        },
        ge: {
          title: "იდენტიფიცირებული",
        },
      },
      dataIndex: "identificated",
    },
    {
      translations: {
        en: {
          title: "Agree To Terms",
        },
        ge: {
          title: "თანხმობა წესებზე და პირობებზე",
        },
      },
      dataIndex: "agreeToTerms",
    },
    {
      translations: {
        en: {
          title: "Mobile Notifications",
        },
        ge: {
          title: "მობილურის შეტყობინებები",
        },
      },
      dataIndex: "mobileNotifications",
    },
    {
      translations: {
        en: {
          title: "Web Notifications",
        },
        ge: {
          title: "ვების შეტყობინებები",
        },
      },
      dataIndex: "webNotifications",
    },
    {
      translations: {
        en: {
          title: "E-mail Notifications",
        },
        ge: {
          title: "ელ.ფოსტის შეტყობინებები",
        },
      },
      dataIndex: "emilNotifications",
    },
    {
      translations: {
        en: {
          title: "Activated",
        },
        ge: {
          title: "აქტიური",
        },
      },
      dataIndex: "activated",
    },
    {
      translations: {
        en: {
          title: "Language",
        },
        ge: {
          title: "ენა",
        },
      },
      dataIndex: "lang",
    },
    {
      translations: {
        en: {
          title: "User Type",
        },
        ge: {
          title: "მომხმარებლის ტიპი",
        },
      },
      dataIndex: "userType",
    },
    {
      translations: {
        en: {
          title: "Company Users",
        },
        ge: {
          title: "კომპანიის მომხმარებლები",
        },
      },
      dataIndex: "companyUsers",
    },
    {
      translations: {
        en: {
          title: "Avatar",
        },
        ge: {
          title: "პროფილი",
        },
      },
      dataIndex: "avatar",
    },
    {
      translations: {
        en: {
          title: "Creation date",
        },
        ge: {
          title: "შექმნის თარიღი",
        },
      },
      dataIndex: "createdAt",
    },
    {
      translations: {
        en: {
          title: "Update date",
        },
        ge: {
          title: "განახლების თარიღი",
        },
      },
      dataIndex: "updatedAt",
    },
  ];

  const _deleteWebUserById = async (id: number) => {
    if (selectedRowKeys && selectedRowKeys.length === 1 && id) {
      try {
        await deleteWebUser(id).unwrap();
        //
        webUserListRefetch();
        //
        toast.success(`web user removed`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const _closeEditWebUserDrawer = () => {
    setOpenEditUserDrawer(false);
    setUserId(null);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    hideSelectAll: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const MainLoading =
    isDeleteWebUserLoading || isWebUsersListLoading || isCountriesLoading;

  return (
    <Wrapper>
      <Flex justify={"space-between"} align={"center"}>
        <Flex
          justify={"space-between"}
          align={"center"}
          style={{ width: "100%" }}
        >
          <Title
            style={{
              margin: 0,
            }}
            level={4}
          >
            {`${t("webUsers")}`}
          </Title>

          <Tooltip placement="bottom" title={"clean up"}>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                minWidth: "40px",
              }}
              type="dashed"
              danger
              size="large"
              icon={<AiOutlineClear />}
              onClick={() => [setFilterFields({})]}
            />
          </Tooltip>
        </Flex>

        <Flex>
          {selectedRowKeys &&
            selectedRowKeys.length === 1 &&
            webUsersList &&
            webUsersList?.data?.find((x) => x.id === selectedRowKeys[0]) && (
              <Popconfirm
                title="Are you sure to delete the user?"
                description="If you delete the user, you cannot restore it !"
                onConfirm={() =>
                  _deleteWebUserById(selectedRowKeys[0] as number)
                }
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: Colors.primaryRed,
                    borderColor: Colors.primaryRed,
                  }}
                  size="large"
                  icon={<RiDeleteBinLine />}
                />
              </Popconfirm>
            )}
        </Flex>
      </Flex>

      <InputBlock>
        <Input
          size="large"
          placeholder={`${t("firstName")}`}
          style={{
            width: "100%",
            minWidth: "200px",
          }}
          value={filterFields?.firstName || ""}
          onChange={(e) => [
            _setFilterFields("firstName", e.target.value),
            setTableFilterFields({
              pageIndex: 1,
              pageSize: tableFilterFields?.pageSize,
            }),
          ]}
        />

        <Input
          size="large"
          placeholder={`${t("lastName")}`}
          style={{
            width: "100%",
            minWidth: "200px",
          }}
          value={filterFields?.lastName || ""}
          onChange={(e) => [
            _setFilterFields("lastName", e.target.value),
            setTableFilterFields({
              pageIndex: 1,
              pageSize: tableFilterFields?.pageSize,
            }),
          ]}
        />

        <Input
          size="large"
          placeholder={`${t("email")}`}
          style={{
            width: "100%",
            minWidth: "200px",
          }}
          value={filterFields?.email || ""}
          onChange={(e) => [
            _setFilterFields("email", e.target.value),
            setTableFilterFields({
              pageIndex: 1,
              pageSize: tableFilterFields?.pageSize,
            }),
          ]}
        />

        <Input
          size="large"
          placeholder={`${t("phone")}`}
          style={{
            width: "100%",
            minWidth: "200px",
          }}
          value={filterFields?.phone || ""}
          onChange={(e) => [
            _setFilterFields("phone", e.target.value),
            setTableFilterFields({
              pageIndex: 1,
              pageSize: tableFilterFields?.pageSize,
            }),
          ]}
        />

        <Input
          size="large"
          placeholder={`${t("personalNumber")}`}
          style={{
            width: "100%",
            minWidth: "200px",
          }}
          value={filterFields?.personalNumber || ""}
          onChange={(e) => [
            _setFilterFields("personalNumber", e.target.value),
            setTableFilterFields({
              pageIndex: 1,
              pageSize: tableFilterFields?.pageSize,
            }),
          ]}
        />

        <CheckboxGrop>
          {[true, false]?.map((status, statusIndex) => (
            <Checkbox
              key={statusIndex}
              type="radio"
              id={`id-${status}`}
              checked={status === filterFields?.activated}
              onChange={() => [
                _setFilterFields("activated", status),
                setTableFilterFields({
                  pageIndex: 1,
                  pageSize: tableFilterFields?.pageSize,
                }),
              ]}
            >
              {status === true ? `${t("active")}` : `${t("inActive")}`}
            </Checkbox>
          ))}

          <Checkbox
            checked={filterFields?.franiUser}
            onChange={(e) => [
              _setFilterFields("franiUser", e.target.checked),
              setTableFilterFields({
                pageIndex: 1,
                pageSize: tableFilterFields?.pageSize,
              }),
            ]}
          >
            {t("franiUser")}
          </Checkbox>
        </CheckboxGrop>
      </InputBlock>

      <ListDiv>
        <Table
          loading={MainLoading}
          onRow={(record) => {
            return {
              onClick: () => {
                //
                const _webUserId = record.key;

                if (_webUserId) {
                  return [setUserId(_webUserId), setOpenEditUserDrawer(true)];
                }
              },
            };
          }}
          rowSelection={rowSelection}
          bordered
          scroll={{ x: "max-content" }}
          pagination={{
            position: ["bottomCenter"],
            current: tableFilterFields?.pageIndex,
            pageSize: tableFilterFields?.pageSize,
            total: webUsersList?.totalCount,
            showSizeChanger: false,
            onChange: (page, size) => {
              setTableFilterFields({
                pageIndex: page,
                pageSize: size,
              });
            },
          }}
          dataSource={
            webUsersList && webUsersList?.data?.length >= 1
              ? [
                  ...webUsersList?.data?.map((item) => ({
                    key: item.id,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    email: item.email,
                    phone: item.phone,
                    personalNumber: item.personalNumber,
                    passportNumber: item.passportNumber,
                    citizenship: countries?.find(
                      (x) => x.isoCode === item.citizenship
                    )?.translations?.[lang]?.name,
                    firstNameGe: item.firstNameGe,
                    lastNameGe: item.lastNameGe,
                    birthday: item.birthday
                      ? dayjs(item?.birthday).format("D MMM YYYY")
                      : "",
                    age: item.age,
                    gender:
                      item.gender === "male"
                        ? `${t("male")}`
                        : item.gender === "female"
                        ? `${t("female")}`
                        : "",
                    sgsVerification: item.sgsVerification ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.sgsVerification === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    emailVerification: item.emailVerification ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.emailVerification === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    phoneVerification: item.phoneVerification ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.phoneVerification === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    identificated: item.identificated ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.identificated === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    agreeToTerms: item.agreeToTerms ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.agreeToTerms === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    activated: item.activated
                      ? `${t("active")}`
                      : `${t("inActive")}`,
                    mobileNotifications: item.enableMobileNotifications ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.enableMobileNotifications === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    webNotifications: item.enableWebNotifications ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.enableWebNotifications === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    emilNotifications: item.enableEmailNotifications ? (
                      <MdDone color={Colors.primaryGreen} />
                    ) : item.enableEmailNotifications === false ? (
                      <RxCross1 color={Colors.primaryRed} />
                    ) : (
                      ""
                    ),
                    lang: item.lang,
                    userType: item.userType,
                    companyUsers: item.companyUsers
                      ?.map((x) => x.role)
                      .join(" / "),
                    avatar: item.avatar,
                    createdAt: dayjs(item?.createdAt).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
                    updatedAt: dayjs(item?.updatedAt).format(
                      "DD MMMM YYYY HH:mm:ss"
                    ),
                  })),
                ]
              : []
          }
          columns={[
            ...columns.map((item) => ({
              title: item?.translations?.[lang]?.title,
              dataIndex: item.dataIndex,
            })),
          ]}
        />
      </ListDiv>

      {/* edit web user drawer */}
      <Drawer
        width={500}
        title={t("update")}
        onClose={_closeEditWebUserDrawer}
        open={openEditUserDrawer}
      >
        <AdminWebUserDetails userId={userId} />
      </Drawer>
      {/*! edit web user drawer */}
    </Wrapper>
  );
};

//

const CheckboxGrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const ListDiv = styled.div`
  margin-top: 40px;
  overflow-x: scroll;
  white-space: nowrap;

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-wrapper .ant-table {
    scrollbar-color: auto;
  }
`;
const Wrapper = styled.div`
  /*  */
`;
const InputBlock = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 10px 20px;

  ${Responsive.mobile} {
    grid-template-columns: 1fr;
  }
`;
export default AdminWebUserList;
