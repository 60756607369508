import { PlusOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import { AdminPaymentScheduleType } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const AdminPaymentScheduleTypes = () => {
  const { t } = useTranslation();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

  const [paymentID, setPaymentID] = useState<number | undefined>(undefined);

  const [existingPaymentType, setExistingPaymentScheduleType] = useState<
    Partial<AdminPaymentScheduleType>
  >({});

  const {
    data: paymentScheduleTypeDetail,
    isLoading: isDeaactivePaymentScheduleTypeDetailLoading,
  } = api.useGetAdminPaymentScheduleTypeDetailQuery(paymentID as number, {
    skip: !paymentID,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
  });

  const [storePaymentScheduleType, { isLoading: isStorePaymentScheduleType }] =
    api.useStorePaymentScheduleTypeMutation();

  const [
    removePaymentScheduleType,
    { isLoading: isRemovePaymentScheduleTypeLoading },
  ] = api.useRemovePaymentScheduleTypeMutation();

  // get policy deactive reason
  const {
    data: paymentScheduleTypeArr,
    isLoading: isPaymentScheduleTypeArrLoading,
    refetch: refetchPaymentScheduleTypeArr,
  } = api.useGetPaymentScheduleTypesQuery(2);

  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setPaymentID(undefined);
    setExistingPaymentScheduleType({});
  };

  const storePaymentScheduleTypeFun = async (isActive?: boolean) => {
    try {
      if (paymentID === 0) {
        await storePaymentScheduleType({
          translations: existingPaymentType.translations,
          number: existingPaymentType.number,
          slug: existingPaymentType.slug,
        }).unwrap();

        onCloseDrawer();
        refetchPaymentScheduleTypeArr();
      } else {
        await storePaymentScheduleType({
          ...existingPaymentType,
          isActive: isActive,
        }).unwrap();

        onCloseDrawer();
        refetchPaymentScheduleTypeArr();
      }

      toast.success(
        paymentID === 0
          ? `${t("createdSuccessfully")}`
          : `${t("updatedSuccessfully")}`
      );
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        console.log(error);
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const RemovePaymentScheduleTypeHandler = async () => {
    try {
      if (paymentID !== 0 && paymentID) {
        await removePaymentScheduleType(paymentID).unwrap();
        onCloseDrawer();
        refetchPaymentScheduleTypeArr();
      }
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        const message =
          error.data.message ===
          "PAYMENT_SCHEDULE_TYPE_REFERENCED_BY_TPL_PRICES"
            ? "გადახდის ჯერადობა გამოიყენება ფასის ლიმიტის მიერ. წაშლა ვერ მოხერხდა."
            : error;
        toast.error(message);
      }
    }
  };

  const paymentTitleChangeHandler = (language: string, value: string) => {
    if (language && value.length < 35) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        translations: {
          ...existingPaymentType.translations,
          [language]: {
            ...existingPaymentType?.translations?.[language],
            title: value,
          },
        },
      });
    }
  };

  const paymentNumberChangeHandler = (language: string, value: number) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        number: value,
      });
    }
  };

  const paymentSlugChangeHandler = (language: string, value: string) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        slug: value,
      });
    }
  };

  const paymentDescriptionChangeHandler = (language: string, value: string) => {
    if (language) {
      setExistingPaymentScheduleType({
        ...existingPaymentType,
        translations: {
          ...existingPaymentType.translations,
          [language]: {
            ...existingPaymentType?.translations?.[language],
            description: value,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (paymentID && paymentScheduleTypeDetail) {
      setExistingPaymentScheduleType(paymentScheduleTypeDetail);
    }
  }, [paymentScheduleTypeDetail, paymentID]);

  const MainLoader = isPaymentScheduleTypeArrLoading;
  const DrawerLoader = isDeaactivePaymentScheduleTypeDetailLoading;

  return MainLoader ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setDrawerStatus(!drawerStatus)}
        >
          {t("create")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <Flex gap={30} wrap="wrap" justify="space-evenly">
        {paymentScheduleTypeArr &&
          paymentScheduleTypeArr?.map((item, index) => (
            <Badge.Ribbon
              key={index}
              style={{
                marginTop: "-20px",
              }}
              text={item.isActive ? t("active") : t("inActive")}
              color={item.isActive ? "green" : "red"}
            >
              <Card
                key={index}
                hoverable
                style={{
                  maxWidth: "300px",
                  width: "100vw",
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                }}
                onClick={() => [
                  setPaymentID(item.id),
                  setDrawerStatus(!drawerStatus),
                ]}
              >
                {item.translations?.[field_Language]?.title ||
                  t("textNotFound")}
              </Card>
            </Badge.Ribbon>
          ))}
      </Flex>

      {/* EDIT TOP SIDE */}
      <Drawer
        extra={<AdminLangSwicher />}
        title={paymentID ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        {DrawerLoader ? (
          <Loader />
        ) : (
          <Flex gap={20} vertical>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("title")}</Text>
              <Input
                size="large"
                placeholder={t("title")}
                value={
                  existingPaymentType.translations?.[field_Language]?.title ||
                  ""
                }
                onChange={(e) =>
                  paymentTitleChangeHandler(field_Language, e?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("paymentsNumber")}</Text>
              <Input
                size="large"
                type="number"
                placeholder={t("paymentsNumber")}
                value={existingPaymentType.number || ""}
                onChange={(e) =>
                  paymentNumberChangeHandler(
                    field_Language,
                    parseInt(e.target.value)
                  )
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("paymentSlug")}</Text>
              <Input
                size="large"
                placeholder={t("paymentSlug")}
                value={existingPaymentType.slug || ""}
                onChange={(e) =>
                  paymentSlugChangeHandler(field_Language, e.target.value)
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("comment")}</Text>
              <TextArea
                size="large"
                placeholder={t("comment")}
                autoSize={{ minRows: 2 }}
                value={
                  existingPaymentType.translations?.[field_Language]
                    ?.description || ""
                }
                onChange={(e) =>
                  paymentDescriptionChangeHandler(
                    field_Language,
                    e.target.value
                  )
                }
              />
            </Space>

            <Divider />

            <Button
              size="large"
              type="primary"
              loading={isStorePaymentScheduleType}
              onClick={() => storePaymentScheduleTypeFun()}
            >
              {paymentID ? t("update") : t("create")}
            </Button>

            {paymentID && (
              <>
                <Button
                  size="large"
                  type="default"
                  onClick={() => {
                    if (existingPaymentType.isActive === true) {
                      storePaymentScheduleTypeFun(false);
                    } else {
                      storePaymentScheduleTypeFun(true);
                    }
                  }}
                >
                  {existingPaymentType.isActive === true
                    ? t("deactivate")
                    : t("activate")}
                </Button>

                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={RemovePaymentScheduleTypeHandler}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    loading={isRemovePaymentScheduleTypeLoading}
                    danger
                    size="large"
                    type="default"
                  >
                    {t("delete")}
                  </Button>
                </Popconfirm>
              </>
            )}
          </Flex>
        )}
      </Drawer>
      {/* EDIT BOTTOM SIDE */}
    </>
  );
};

export default AdminPaymentScheduleTypes;
