import { Card } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";

const Admin_manage_pages = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const pages = [
    {
      title: t("mainPage"),
      url: "/landing-manage-texts",
    },
    {
      title: t("FAQpage"),
      url: "/manage-faq",
    },
    {
      title: t("aboutUsPage"),
      url: "/about-us-edit",
    },
    {
      title: t("productDetailPage"),
      url: "/edit-product-detail",
    },
    {
      title: t("partnersPage"),
      url: "/frani-partners",
    },
    {
      title: t("landingSlider"),
      url: "/landing-slider",
    },
  ];

  return (
    <GridMinMax>
      {pages.map((item, index) => (
        <Card key={index} hoverable onClick={() => navigate(item.url)}>
          <Title level={5}>{item.title}</Title>
        </Card>
      ))}
    </GridMinMax>
  );
};

export default Admin_manage_pages;
