import { ClearOutlined, FileSearchOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { RootState } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
const { Text } = Typography;

type FilterFieldStateTypes = {
  companyName: string;
  idNumber: string;
  productType: string;
  pageIndex: number;
  pageSize: number;
};

type InputFieldTypes = {
  companyName: string;
  idNumber: string;
  productType: string;
};

const AdminBusinessModule = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";

  const [filterFieldState, setFilterFieldState] =
    useState<FilterFieldStateTypes>({
      companyName: "",
      idNumber: "",
      productType: "",
      pageIndex: 1,
      pageSize: 20,
    });

  const [inputFields, setInputFields] = useState<InputFieldTypes>({
    companyName: "",
    idNumber: "",
    productType: "",
  });

  const query = `pageIndex=${filterFieldState.pageIndex}&pageSize=${filterFieldState.pageSize}&name=${filterFieldState.companyName}&idNumber=${filterFieldState.idNumber}&type=${filterFieldState.productType}`;

  const { data: corporateModuleData, isLoading: corporateModuleLoading } =
    api.useGetAllBusinessModulesQuery(query);

  const corporateModule = corporateModuleData?.data;
  const pageCount =
    corporateModuleData && Math.ceil(corporateModuleData.totalCount / 20);

  const setInputField = (fieldName: keyof InputFieldTypes, fieldValue: any) => {
    setInputFields({
      ...inputFields,
      [fieldName]: fieldValue,
    });
  };

  const setFilterField = (
    fieldName: keyof FilterFieldStateTypes,
    fieldValue: any
  ) => {
    setFilterFieldState({
      ...filterFieldState,
      [fieldName]: fieldValue,
    });
  };

  const handlePageClick = (page: number) => {
    setFilterField("pageIndex", page);
  };

  const handleFilterButton = () => {
    setFilterFieldState({
      companyName: inputFields.companyName || "",
      idNumber: inputFields.idNumber || "",
      productType: inputFields.productType || "",
      pageIndex: 1,
      pageSize: 20,
    });
  };

  const productTypeArr = [
    { id: 1, label: `${t("healthInsurance")}`, value: "health" },
    { id: 2, label: `${t("carInsurance")}`, value: "car" },
    { id: 3, label: `${t("propertyInsurance")}`, value: "property" },
    { id: 4, label: `${t("other")}`, value: "other" },
  ];

  return (
    <>
      <GridMinMax>
        <Space direction="vertical">
          <Text>{t("companyName")}</Text>
          <Input
            placeholder={t("companyName")}
            size="large"
            value={inputFields?.companyName || ""}
            onChange={(event) =>
              setInputField("companyName", event?.target?.value)
            }
          />
        </Space>

        <Space direction="vertical">
          <Text>{t("companyID")}</Text>
          <Input
            placeholder={t("companyID")}
            size="large"
            value={inputFields?.idNumber || ""}
            onChange={(event) =>
              setInputField("idNumber", event?.target?.value)
            }
          />
        </Space>

        <Space direction="vertical">
          <Text>{t("productType")}</Text>
          <Select
            style={{ width: "100%" }}
            allowClear
            placeholder={t("productType")}
            size="large"
            value={inputFields?.productType || ""}
            onChange={(value) => setInputField("productType", value)}
            options={[
              ...productTypeArr.map((item) => ({
                value: item.value,
                label: item.label,
              })),
            ]}
          />
        </Space>

        <Button
          size="large"
          type="default"
          icon={<FileSearchOutlined />}
          onClick={handleFilterButton}
        >
          {t("filter")}
        </Button>

        <Button
          danger
          size="large"
          type="dashed"
          icon={<ClearOutlined />}
          onClick={() =>
            setInputFields({
              companyName: "",
              idNumber: "",
              productType: "",
            })
          }
        >
          {t("clear")}
        </Button>
      </GridMinMax>

      {corporateModuleLoading ? (
        <Loader />
      ) : (
        <>
          <Divider>
            {t("searched")} <b>{corporateModule?.length || 0}</b>{" "}
            {t("statement")}
          </Divider>

          <Flex vertical gap={20}>
            {isFraniAdmin &&
              corporateModule?.map((item, index) => (
                <Flex gap={20}>
                  <Card
                    style={{
                      width: "100%",
                    }}
                    hoverable
                    onClick={() => navigate(`/business-module/${item.id}`)}
                  >
                    <Row gutter={[16, 16]} justify="space-between">
                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Flex
                          vertical
                          style={{ width: "20%", whiteSpace: "nowrap" }}
                        >
                          <Text>
                            {item.type === "health"
                              ? t("healthInsurance")
                              : item.type === "car"
                              ? t("carInsurance")
                              : item.type === "property"
                              ? t("propertyInsurance")
                              : item.type === "other"
                              ? t("other")
                              : ""}
                          </Text>
                          <Text>{item.email}</Text>
                        </Flex>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Flex
                          vertical
                          style={{ width: "20%", whiteSpace: "nowrap" }}
                        >
                          <Text>{item?.companyName}</Text>
                          <Text>{item?.idNumber}</Text>
                        </Flex>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Flex
                          vertical
                          style={{ width: "20%", whiteSpace: "nowrap" }}
                        >
                          <Text>{item?.firstname + " " + item?.lastname}</Text>
                          <Text>{item?.mobileNumber}</Text>
                        </Flex>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Flex
                          vertical
                          style={{ width: "20%", whiteSpace: "nowrap" }}
                        >
                          <Text>{t("createdDate")}</Text>
                          <Text>
                            {dayjs(item?.createdAt).format(
                              "DD MMM YYYY | HH:mm"
                            )}
                          </Text>
                        </Flex>
                      </Col>
                    </Row>
                  </Card>
                </Flex>
              ))}
          </Flex>

          {corporateModule && corporateModule.length >= 1 ? (
            <Flex justify="center" style={{ marginTop: "20px" }}>
              <Pagination
                responsive={true}
                current={filterFieldState.pageIndex}
                total={pageCount || 0}
                showSizeChanger={false}
                onChange={handlePageClick}
              />
            </Flex>
          ) : null}
        </>
      )}
    </>
  );
};

export default AdminBusinessModule;
