import { ArrowLeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Input, Tooltip } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import NotFound404 from "../../../components/NotFound404";
import UploadFile from "../../../components/UploadFile";
import { AdminCompany, EnabledLanguage } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";

type CompanyTranslationFields = {
  title: string;
  excerpt: string;
  body: string;
  logo: string;
};

const Admin_company_detail = () => {
  const { companyId: companyidStr = "0" } = useParams();
  const companyId = parseInt(companyidStr);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // add travel addon state
  const [addCompanyState, setAddCompanyState] = useState<Partial<AdminCompany>>(
    {}
  );
  // existing company
  const { data: existingCompany, isLoading: isExistingCompanyLoading } =
    api.useGetCurrentCompanyByIdQuery(companyId);

  const [saveCompany, { isLoading: isSaveCompanyLoading }] =
    api.useSaveCompanyMutation();
  // add company function
  const saveCompanyFunction = async () => {
    try {
      await saveCompany(addCompanyState).unwrap();
      toast.success(
        companyId
          ? `${t("company")} ${t("updatedSuccessfully")}`
          : `${t("company")} ${t("addedSuccessfully")}`
      );
      navigate("/authorized-companies");
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  // add company fields
  const setCompany = (field: keyof AdminCompany, value: any) => {
    setAddCompanyState({
      ...addCompanyState,
      [field]: value,
    });
  };
  // add company translation fields
  const setCompanyTranslatedField = (
    field: keyof CompanyTranslationFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddCompanyState({
      ...addCompanyState,
      translations: {
        ...addCompanyState.translations,
        [language]: {
          ...addCompanyState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  // set company brand name translation field
  const setBrandNameTranslatedField = (
    field: keyof { title: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setAddCompanyState({
      ...addCompanyState,
      brandName: {
        ...addCompanyState.brandName,
        [language]: {
          ...addCompanyState?.brandName?.[language],
          [field]: value,
        },
      },
    });
  };

  useEffect(() => {
    if (existingCompany) {
      setAddCompanyState(existingCompany);
    }
  }, [existingCompany]);

  // main loading
  const MainLoading = isSaveCompanyLoading || isExistingCompanyLoading;

  return (
    <>
      {MainLoading ? (
        <Loader />
      ) : (
        <>
          {companyId > 0 && !existingCompany ? (
            <NotFound404 />
          ) : (
            <>
              <Flex justify="space-between" align="center">
                <Button
                  size="large"
                  type="link"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}
                >
                  {t("back")}
                </Button>

                <Title level={3}>
                  {companyId ? `${t("update")}` : `${t("create")}`}
                </Title>

                <AdminLangSwicher />
              </Flex>

              <Divider />

              <Flex gap={30} vertical>
                <Input
                  size="large"
                  placeholder={t("companySlug")}
                  value={addCompanyState.slug || ""}
                  onChange={(e) => setCompany("slug", e?.target?.value)}
                  suffix={
                    <Tooltip title={t("companySlug")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  placeholder={t("companyID")}
                  value={addCompanyState.identificationNumber || ""}
                  onChange={(e) =>
                    setCompany("identificationNumber", e?.target?.value)
                  }
                  suffix={
                    <Tooltip title={t("companyID")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  placeholder={t("brandTitle")}
                  value={
                    addCompanyState?.brandName?.[field_Language]?.title || ""
                  }
                  onChange={(e) =>
                    setBrandNameTranslatedField(
                      "title",
                      field_Language,
                      e?.target?.value
                    )
                  }
                  suffix={
                    <Tooltip title={t("brandTitle")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  placeholder={t("nameOfCompany")}
                  value={
                    addCompanyState?.translations?.[field_Language]?.title || ""
                  }
                  onChange={(e) =>
                    setCompanyTranslatedField(
                      "title",
                      field_Language,
                      e?.target?.value
                    )
                  }
                  suffix={
                    <Tooltip title={t("nameOfCompany")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  type="email"
                  placeholder={t("companyEmail")}
                  value={addCompanyState?.emailAddress || ""}
                  onChange={(e) => setCompany("emailAddress", e?.target?.value)}
                  suffix={
                    <Tooltip title={t("companyEmail")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  placeholder={t("companyBriefDescription")}
                  value={
                    addCompanyState?.translations?.[field_Language]?.excerpt ||
                    ""
                  }
                  onChange={(e) =>
                    setCompanyTranslatedField(
                      "excerpt",
                      field_Language,
                      e?.target?.value
                    )
                  }
                  suffix={
                    <Tooltip
                      title={t("companyBriefDescription")}
                      placement="left"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <Input
                  size="large"
                  placeholder={t("companyDescription")}
                  value={
                    addCompanyState?.translations?.[field_Language]?.body || ""
                  }
                  onChange={(e) =>
                    setCompanyTranslatedField(
                      "body",
                      field_Language,
                      e?.target?.value
                    )
                  }
                  suffix={
                    <Tooltip title={t("companyDescription")} placement="left">
                      <InfoCircleOutlined />
                    </Tooltip>
                  }
                />

                <UploadFile
                  label={t("companyCover")}
                  onChange={(props) => setCompany("cover", props.url)}
                  defaultFileName={addCompanyState?.cover || ""}
                />

                <UploadFile
                  label={`${t("companyLogo")} ${field_Language?.toUpperCase()}`}
                  onChange={(props) =>
                    setCompanyTranslatedField("logo", field_Language, props.url)
                  }
                  defaultFileName={
                    addCompanyState?.translations?.[field_Language]?.logo || ""
                  }
                />

                <Button
                  style={{ width: "80%", marginInline: "auto" }}
                  size="large"
                  type="primary"
                  onClick={saveCompanyFunction}
                >
                  {companyId ? `${t("update")}` : `${t("create")}`}
                </Button>
              </Flex>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Admin_company_detail;
