import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../../../assets/styles/GlobalStyle";
import Button from "../../../../../components/Button";
import Loader from "../../../../../components/Loader";
import NotFound404 from "../../../../../components/NotFound404";
import {
  EnabledLanguage,
  Product,
  TravelProduct,
  WordingFileType,
} from "../../../../../domain/types";
import DocumentFieldUploader from "../../../../admin_components/DocumentFieldUploader";
import { useTranslation } from "react-i18next";
//
//
//
//
const EditAbroadDocumentsTab: React.FC<{
  travelProduct?: TravelProduct;
  language: EnabledLanguage;
  isLoading: boolean;
  // setTranslatedFieldOnProduct: any
  setTravelProductField: (
    fieldName: keyof TravelProduct,
    fieldValue: any
  ) => void;
  setMainProductField: (fieldName: keyof Product, fieldValue: any) => void;
  saveTab: any;
}> = ({ travelProduct, language, isLoading, setMainProductField, saveTab }) => {
  //
  const { t } = useTranslation();
  //
  const [wordingFileTypes, setWordingFileTypes] = useState<any[]>([]);
  //
  //
  useEffect(() => {
    if (travelProduct?.mainProduct?.wordingFileUrl?.includes("translations")) {
      setWordingFileTypes([
        {
          language: "GE",
          url: (
            JSON.parse(
              travelProduct?.mainProduct?.wordingFileUrl
            ) as WordingFileType
          )?.translations?.ge?.url,
        },
        {
          language: "EN",
          url: (
            JSON.parse(
              travelProduct?.mainProduct?.wordingFileUrl
            ) as WordingFileType
          )?.translations?.en?.url,
        },
      ]);
    } else {
      setWordingFileTypes([
        {
          language: "GE",
          url: "",
        },
        {
          language: "EN",
          url: "",
        },
      ]);
    }

    return () => {};
  }, [travelProduct?.mainProduct?.wordingFileUrl]);

  //
  //
  const setWordingFileTypesHandler = (newUrl, language) => {
    const newTypes = wordingFileTypes.map((f) => {
      return {
        ...f,
        url: language === f.language ? newUrl : f.url,
      } as any;
    });

    setWordingFileTypes(newTypes);
  };
  //
  const saveHandler = () => {
    const wordingFiles: WordingFileType = {
      translations: {
        ge: {
          url: wordingFileTypes.find((w) => w.language === "GE")?.url,
        },
        en: {
          url: wordingFileTypes.find((w) => w.language === "EN")?.url,
        },
      },
    };

    saveTab(wordingFiles);
  };

  return isLoading ? (
    <Loader />
  ) : !travelProduct ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <MediumScreenTitle>{t("manageDocuments")}</MediumScreenTitle>
      <DocumentBlockTitle>{t("policyWording")}</DocumentBlockTitle>
      <Row>
        {wordingFileTypes.map((wordingFile) => (
          <Column $widthPercent={50} key={wordingFile.language}>
            <Block>
              <DocumentBlockTitle>{wordingFile.language}</DocumentBlockTitle>
              <DocumentFieldUploader
                url={wordingFile.url}
                onChange={(newUrl) =>
                  setWordingFileTypesHandler(newUrl, wordingFile.language)
                }
                width={300}
              />
            </Block>
          </Column>
        ))}
      </Row>

      <DocumentBlockTitle>{t("policyFormTemplate")}</DocumentBlockTitle>
      <Row>
        <Column $widthPercent={50}>
          <Block>
            <DocumentFieldUploader
              url={travelProduct?.mainProduct.policyFileUrl || ""}
              onChange={(newUrl) =>
                setMainProductField("policyFileUrl", newUrl)
              }
              width={300}
            />
          </Block>
        </Column>
      </Row>

      <Button
        btnStyleType="primary"
        icon={false}
        fullWidth={true}
        disabled={false}
        onClick={saveHandler}
        text={t("save")}
      />
    </Wrapper>
  );
};
//
//
// styles
// const Text = styled.div``
const Block = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.primaryGrey};
  margin-bottom: 20px;
  /*  */
  > div {
    flex-basis: 50%;
  }
`;
const Wrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;
const Column = styled.div<{ $widthPercent?: number }>`
  padding: 0 15px 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;
const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;

export default EditAbroadDocumentsTab;
