import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { EnabledLanguage, PackageService } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
const { Text } = Typography;

type AddServiceTranslatedFields = {
  name: string;
  comment: string;
};

const AdminTplServices = () => {
  const { t } = useTranslation();
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // selected service id
  const [packageServiceId, setPackageServiceId] = useState<number | null>(null);
  // states
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  // add service state
  const [addPackageServiceState, setAddPackageServiceState] = useState<
    Partial<PackageService>
  >({});
  // get package services
  const {
    data: packageServices,
    isLoading: isGetPackageServicesLoading,
    refetch: refetchPackageServices,
  } = api.useGetPackageServicesQuery({ category: "tpl" });
  // get package service by id
  const {
    data: existingPackageService,
    isLoading: isExistingPackageServiceLoading,
  } = api.useGetPackageServiceByIdQuery(
    { category: "tpl", id: packageServiceId as number },
    {
      skip: true && !packageServiceId,
    }
  );
  // add travel service
  const [addPackageService, { isLoading: isAddPackageServiceLoading }] =
    api.useAddPackageServiceMutation();
  // update travel service
  const [updatePackageService, { isLoading: isUpdatePackageServiceLoading }] =
    api.useUpdatePackageServiceMutation();

  const updatePackageServiceFun = async () => {
    try {
      await updatePackageService({
        category: "tpl",
        service: addPackageServiceState,
      }).unwrap();
      // refetch travel service
      refetchPackageServices();
      // close drawer
      onCloseDrawer();
      toast.success(`${t("service")} ${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // remvoe travel service
  const [
    removePackageService,
    { isLoading: isRemoveServiceLoading, isSuccess: removeServiceSucces },
  ] = api.useRemovePackageServiceMutation();

  //close add service drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setPackageServiceId(null);
  };

  // add service fields
  const setAddPackageService = (field: keyof PackageService, value: any) => {
    setAddPackageServiceState({
      ...addPackageServiceState,
      [field]: value,
    });
  };

  // add service translation fields
  const setAddServiceTranslatedField = (
    field: keyof AddServiceTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddPackageServiceState({
      ...addPackageServiceState,
      translations: {
        ...addPackageServiceState.translations,
        [language]: {
          ...addPackageServiceState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add service function
  const AddServiceFunction = async () => {
    try {
      await addPackageService({
        category: "tpl",
        service: addPackageServiceState,
      }).unwrap();
      // refetch travel services
      refetchPackageServices();
      // close drawer
      onCloseDrawer();
      toast.success(`${t("service")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // auto refetch services when user remove service
  useEffect(() => {
    if (removeServiceSucces) {
      refetchPackageServices();
      onCloseDrawer();
    }
  }, [removePackageService, removeServiceSucces, refetchPackageServices]);

  useEffect(() => {
    if (existingPackageService) {
      setAddPackageServiceState(existingPackageService);
    }
  }, [existingPackageService]);

  const MainLoading =
    isGetPackageServicesLoading ||
    isAddPackageServiceLoading ||
    isUpdatePackageServiceLoading ||
    isRemoveServiceLoading ||
    isExistingPackageServiceLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => [
          setDrawerStatus(!drawerStatus),
          setAddPackageServiceState({}),
        ]}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax align="stretch">
        {packageServices &&
          packageServices.length >= 1 &&
          packageServices.map((item, index) => (
            <Card key={index} hoverable>
              <Flex
                vertical
                gap={20}
                onClick={() => [
                  setPackageServiceId(item.id),
                  setDrawerStatus(!drawerStatus),
                ]}
              >
                <Text>
                  {t("position")}: <b>{item.sort}</b>
                </Text>
                <Text>
                  {t("comment")}: <b>{item?.comment}</b>
                </Text>
                <Text>
                  {t("service")}: <b>{item.translations?.[lang]?.name}</b>
                </Text>
              </Flex>
            </Card>
          ))}
      </GridMinMax>

      <Drawer
        extra={<AdminLangSwicher />}
        title={packageServiceId ? t("updateService") : t("createService")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("positionExample")}</Text>
            <Input
              size="large"
              placeholder={t("positionExample")}
              value={addPackageServiceState.sort || ""}
              onChange={(event) =>
                setAddPackageService("sort", event?.target?.value)
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("serviceTitle")}</Text>
            <Input
              size="large"
              placeholder={t("serviceTitle")}
              value={
                addPackageServiceState?.translations?.[field_Language]?.name ||
                ""
              }
              onChange={(event) =>
                setAddServiceTranslatedField(
                  "name",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("comment")}</Text>
            <Input
              size="large"
              placeholder={t("comment")}
              value={
                addPackageServiceState?.translations?.[field_Language]
                  ?.comment || ""
              }
              onChange={(event) =>
                setAddServiceTranslatedField(
                  "comment",
                  field_Language,
                  event?.target.value
                )
              }
            />
          </Space>

          <UploadFile
            label={t("serviceIcon")}
            onChange={(props) => setAddPackageService("iconPath", props.url)}
            defaultFileName={addPackageServiceState?.iconPath || ""}
          />

          <Divider />

          <Flex gap={10}>
            <Button
              size="large"
              onClick={
                packageServiceId ? updatePackageServiceFun : AddServiceFunction
              }
              style={{
                width: "100%",
              }}
            >
              {packageServiceId ? t("update") : t("create")}
            </Button>

            {packageServiceId && (
              <Popconfirm
                title={t("certainToDeleteIt")}
                onConfirm={() =>
                  removePackageService({
                    category: "tpl",
                    id: packageServiceId as number,
                  })
                }
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                placement="bottom"
              >
                <Button size="large" danger>
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminTplServices;
