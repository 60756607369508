import { Card, Divider, Flex, Select, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { TranslationsField } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

const { Text } = Typography;

const AdminWelcomerManagePages = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "welcomer-choose-date",
      text: "სადაზღვევო პერიოდი",
      translations: {
        en: {
          text: "Insurance period",
        },
        ge: {
          text: "სადაზღვევო პერიოდი",
        },
      },
    },
    {
      id: 2,
      slug: "welcomer-currency-and-limit",
      text: "ვალუტა / ლიმიტი",
      translations: {
        en: {
          text: "Currency / Limit",
        },
        ge: {
          text: "ვალუტა / ლიმიტი",
        },
      },
    },
    {
      id: 3,
      slug: "welcomer-age",
      text: "მოგზაურის ასაკი",
      translations: {
        en: {
          text: "How old is traveler",
        },
        ge: {
          text: "მოგზაურის ასაკი",
        },
      },
    },
    {
      id: 4,
      slug: "welcomer-frani-or-myself",
      text: "შეთავაზების ტიპი",
      translations: {
        en: {
          text: "Offer type",
        },
        ge: {
          text: "შეთავაზების ტიპი",
        },
      },
    },
    {
      id: 5,
      slug: "welcomer-choose-offer",
      text: "შეთავაზებები",
      translations: {
        en: {
          text: "Offers",
        },
        ge: {
          text: "შეთავაზებები",
        },
      },
    },
    {
      id: 6,
      slug: "welcomer-best-offer",
      text: "საუკეთესო შეთავაზება",
      translations: {
        en: {
          text: "Best offer",
        },
        ge: {
          text: "საუკეთესო შეთავაზება",
        },
      },
    },
    {
      id: 7,
      slug: "welcomer-who-insure",
      text: "ვისთვის ყიდულობ დაზღვევას",
      translations: {
        en: {
          text: "Who do you buy insurance for",
        },
        ge: {
          text: "ვისთვის ყიდულობ დაზღვევას",
        },
      },
    },
    {
      id: 8,
      slug: "welcomer-personal-info",
      text: "დამზღვევის ინფორმაცია",
      translations: {
        en: {
          text: "Insurer's information",
        },
        ge: {
          text: "დამზღვევის ინფორმაცია",
        },
      },
    },
    {
      id: 9,
      slug: "welcomer-insured-person-info",
      text: "დაზღვეულის ინფორმაცია",
      translations: {
        en: {
          text: "Insured's information",
        },
        ge: {
          text: "დაზღვეულის ინფორმაცია",
        },
      },
    },
    {
      id: 10,
      slug: "welcomer-check-info",
      text: "ინფორმაციის გადამოწმება",
      translations: {
        en: {
          text: "Check information",
        },
        ge: {
          text: "ინფორმაციის გადამოწმებ",
        },
      },
    },
    {
      id: 11,
      slug: "welcomer-register-policy",
      text: "საკონტაქტო ინფორმაცია",
      translations: {
        en: {
          text: "Contact info",
        },
        ge: {
          text: "საკონტაქტო ინფორმაცია",
        },
      },
    },
  ];

  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  // get welcomer flow
  const { data: pageTextArray, isLoading: isPageTextArrayLoading } =
    api.useGetWelcomerFlowPageTextsBySlugQuery(selectedSlug as string, {
      skip: !selectedSlug && true,
    });

  return isPageTextArrayLoading ? (
    <Loader />
  ) : (
    <>
      <Flex gap={20} justify="space-between" align="flex-end">
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text>{t("choosePage")}</Text>
          <Select
            size="large"
            style={{
              width: "100%",
            }}
            placeholder={t("choosePage")}
            value={selectedSlug || undefined}
            onChange={(value) => setSelectedSlug(value)}
            options={[
              ...pageSlugs.map((item) => ({
                value: item.slug,
                label: item?.translations?.[lang]?.text,
              })),
            ]}
          />
        </Space>

        <AdminLangSwicher />
      </Flex>

      {pageTextArray && (
        <>
          <Divider />

          <GridMinMax align="stretch">
            {pageTextArray?.translations && (
              <Card
                hoverable
                onClick={() =>
                  navigate(`/manage-welcomer-pages-edit/${selectedSlug}`)
                }
                style={{ cursor: "pointer" }}
              >
                {pageTextArray?.translations?.[field_Language]?.title && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("title")}</Text>
                    <Text>
                      {pageTextArray?.translations?.[field_Language]?.title}
                    </Text>
                  </Space>
                )}

                <Divider variant="dashed" />

                {pageTextArray?.translations?.[field_Language]?.stepName && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("stepName")}</Text>
                    <Text>
                      {pageTextArray?.translations?.[field_Language]?.stepName}
                    </Text>
                  </Space>
                )}
              </Card>
            )}

            {pageTextArray?.listItems?.map((item, index) => (
              <Card
                hoverable
                key={index}
                onClick={() =>
                  navigate(
                    `/manage-welcomer-pages-edit/${selectedSlug}/${item?.id}`
                  )
                }
              >
                {item?.translations?.[field_Language]?.title && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("title")}</Text>
                    <Text>{item?.translations?.[field_Language]?.title}</Text>
                  </Space>
                )}

                <Divider variant="dashed" />

                {item?.translations?.[field_Language]?.body && (
                  <>
                    <Space direction="vertical">
                      <Text type="secondary">{t("body")}</Text>
                      <Text>{item?.translations?.[field_Language]?.body}</Text>
                    </Space>
                  </>
                )}
              </Card>
            ))}
          </GridMinMax>
        </>
      )}
    </>
  );
};

export default AdminWelcomerManagePages;
