import { ArrowLeftOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Flex, Input, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import { AdminTravelMultiDay, EnabledLanguage } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";

type EditedDayType = {
  title: string;
  excerpt: string;
  body: string;
};

const Admin_travel_multi_day_edit = () => {
  //
  const { dayId: dayIdStr = "0" } = useParams();
  const dayID = parseInt(dayIdStr);
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const [editedDayInfo, setEditedDayInfo] = useState<
    Partial<AdminTravelMultiDay>
  >({});
  //
  const {
    data: multiDayById,
    isLoading: isMultiDayByIdLoading,
    refetch: isMultiDayRefetch,
  } = api.useGetAdminTravelMultiDayByIdQuery(dayID, {
    skip: !dayID && true,
  });
  // set edited day info
  useEffect(() => {
    if (multiDayById) {
      setEditedDayInfo(multiDayById);
    }
  }, [multiDayById]);
  //
  const setEditedDayInfoTranstationField = (
    field: keyof EditedDayType,
    language: EnabledLanguage,
    value: any
  ) => {
    setEditedDayInfo({
      ...editedDayInfo,
      translations: {
        ...editedDayInfo.translations,
        [language]: {
          ...editedDayInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  // add travel addon fields
  const setEditedDayFields = (field: keyof AdminTravelMultiDay, value: any) => {
    setEditedDayInfo({
      ...editedDayInfo,
      [field]: value,
    });
  };
  //  remove travel multi day
  const [removeTravelMultiDay, { isLoading: isRemoveTravelMultiDayLoading }] =
    api.useRemoveTravelMultiDayMutation();
  //  store frani user  & update frani user
  const [storeTravelDay, { isLoading: isStoreTravelDayLoading }] =
    api.useStoreTravelMultiDayMutation();
  //
  const storeTravelMultiDaysFunction = async () => {
    //
    try {
      await storeTravelDay(editedDayInfo).unwrap();
      //
      toast.success(
        dayID === 0
          ? `${t("addedSuccessfully")}`
          : `${t("updatedSuccessfully")}`
      );
      //
      if (dayID === 0) {
        navigate("/travel-multi-days");
      } else {
        isMultiDayRefetch();
      }
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const RemoveTravelDayFunction = () => {
    if (dayID !== 0 && dayID) {
      removeTravelMultiDay(dayID);
      //
      navigate("/travel-multi-days");
    }
  };
  //
  const mainLoading =
    isMultiDayByIdLoading ||
    isStoreTravelDayLoading ||
    isRemoveTravelMultiDayLoading;
  //
  return (
    <>
      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <Button
              size="large"
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>

            <Title level={3}>{dayID === 0 ? t("create") : t("update")}</Title>

            <AdminLangSwicher />
          </Flex>
        </>
      )}

      <Divider />

      <Flex gap={20} vertical>
        <Badge.Ribbon
          text={t("title")}
          color="blue"
          style={{
            marginTop: "-20px",
          }}
        >
          <TextArea
            size="large"
            placeholder={t("title")}
            autoSize={{ minRows: 1 }}
            value={editedDayInfo?.translations?.[field_Language]?.title || ""}
            onChange={(event) =>
              setEditedDayInfoTranstationField(
                "title",
                field_Language,
                event?.target?.value
              )
            }
          />
        </Badge.Ribbon>

        <Badge.Ribbon
          text={t("description")}
          color="blue"
          style={{
            marginTop: "-20px",
          }}
        >
          <TextArea
            size="large"
            placeholder={t("description")}
            autoSize={{ minRows: 1 }}
            value={editedDayInfo?.translations?.[field_Language]?.body || ""}
            onChange={(event) =>
              setEditedDayInfoTranstationField(
                "body",
                field_Language,
                event?.target?.value
              )
            }
          />
        </Badge.Ribbon>

        <Badge.Ribbon
          text={t("additionalInformation")}
          color="blue"
          style={{
            marginTop: "-20px",
          }}
        >
          <TextArea
            size="large"
            placeholder={t("additionalInformation")}
            autoSize={{ minRows: 1 }}
            value={editedDayInfo?.translations?.[field_Language]?.excerpt || ""}
            onChange={(event) =>
              setEditedDayInfoTranstationField(
                "excerpt",
                field_Language,
                event?.target?.value
              )
            }
          />
        </Badge.Ribbon>

        <Badge.Ribbon
          text={t("day")}
          color="blue"
          style={{
            marginTop: "-20px",
          }}
        >
          <Input
            type="number"
            size="large"
            placeholder={t("day")}
            value={editedDayInfo.days || ""}
            onChange={(event) =>
              setEditedDayFields("days", event?.target?.value)
            }
          />
        </Badge.Ribbon>

        <Badge.Ribbon
          text={t("insuredDaysNumber")}
          color="blue"
          style={{
            marginTop: "-20px",
          }}
        >
          <Input
            type="number"
            size="large"
            placeholder={t("insuredDaysNumber")}
            value={editedDayInfo.insuredDays || ""}
            onChange={(event) =>
              setEditedDayFields("insuredDays", Number(event?.target?.value))
            }
          />
        </Badge.Ribbon>
      </Flex>

      <Divider />

      <Flex gap={20}>
        <Button
          size="large"
          onClick={storeTravelMultiDaysFunction}
          style={{
            width: "100%",
          }}
        >
          {dayID === 0 ? t("create") : t("update")}
        </Button>

        {dayID !== 0 && (
          <Popconfirm
            disabled={isRemoveTravelMultiDayLoading}
            title={t("certainToDeleteIt")}
            onConfirm={RemoveTravelDayFunction}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
            placement="bottom"
          >
            <Button
              danger
              size="large"
              style={{
                width: "100%",
              }}
            >
              {t("delete")}
            </Button>
          </Popconfirm>
        )}
      </Flex>
    </>
  );
};

export default Admin_travel_multi_day_edit;
