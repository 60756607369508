import {
  CloudUploadOutlined,
  LinkOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Badge, Empty, Flex, Popconfirm, Spin, Card, Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../api";
import Colors from "../../assets/styles/Colors";

const DocumentFieldUploader = ({
  url,
  onChange,
  width = 300,
  language,
  removable = true,
}: {
  url: string;
  onChange: (newUrl: string) => void;
  width?: number;
  language?: string;
  removable?: boolean;
}) => {
  const { t } = useTranslation();

  const [uploadFileMutation, { isLoading: isUploadFileLoading }] =
    api.useUploadFileMutation();

  const uniqueId = `file-${Math.random().toString(36).substring(2, 9)}`;

  const fileNotUploaded = url && url !== "file-goes-here.pdf" ? true : false;

  return (
    <Flex>
      <Badge.Ribbon
        text={language || t("lang")}
        style={{
          width: "fit-content",
        }}
      >
        <Card size="small" style={{ width: "fit-content" }}>
          {isUploadFileLoading ? (
            <Flex
              justify="center"
              align="center"
              style={{ height: "450px", width: "300px" }}
            >
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
          ) : (
            <Block>
              {fileNotUploaded ? (
                <Iframe loading="lazy" src={url} title={url} />
              ) : (
                <Flex
                  style={{ width: "300px", height: "100%" }}
                  justify="center"
                  align="center"
                >
                  <Empty
                    style={{ marginTop: "-20px" }}
                    description={t("noFileChosen")}
                  />
                </Flex>
              )}

              <BottomSheet>
                <Button
                  disabled={!fileNotUploaded}
                  type="link"
                  onClick={() => window.open(url, "_blank")}
                >
                  <IconSpan>
                    <LinkOutlined style={{ color: "white" }} />
                  </IconSpan>
                </Button>

                <Button type="link" disabled={isUploadFileLoading}>
                  <InputFileLabel htmlFor={uniqueId}>
                    <IconSpan>
                      <CloudUploadOutlined style={{ color: "white" }} />
                    </IconSpan>
                  </InputFileLabel>
                  <InputFile
                    id={uniqueId}
                    type="file"
                    onChange={async (event) => {
                      const file = event?.target?.files?.[0];
                      if (!file) {
                        toast.error(`${t("pleaseChooseFile")}`);
                        return;
                      }
                      const result = await uploadFileMutation({
                        file,
                      }).unwrap();
                      onChange(result.url);
                    }}
                  />
                </Button>

                {removable && (
                  <Popconfirm
                    title={t("certainToDeleteIt")}
                    onConfirm={() => onChange("")}
                    onCancel={() => {}}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      type="link"
                      disabled={!fileNotUploaded || isUploadFileLoading}
                    >
                      <IconSpan>
                        <DeleteOutlined style={{ color: Colors.primaryRed }} />
                      </IconSpan>
                    </Button>
                  </Popconfirm>
                )}
              </BottomSheet>
            </Block>
          )}
        </Card>
      </Badge.Ribbon>
    </Flex>
  );
};
//
const IconSpan = styled.span`
  padding-block: 10px;
  display: grid;
  place-items: center;
  cursor: pointer;

  svg {
    width: 50px;
    font-size: 20px;
    transition: 100ms ease;
  }

  &:hover {
    transition: 100ms ease;
    transform: scale(1.08);
  }
`;
const InputFileLabel = styled.label``;
const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const BottomSheet = styled.div`
  background-color: #323639;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 55px;
  transition: bottom 0.3s ease-in-out;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
`;

const Iframe = styled.iframe`
  width: inherit;
  height: inherit;
`;

const Block = styled.div`
  max-width: 300px;
  width: 100%;
  height: 450px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
`;

export default DocumentFieldUploader;
