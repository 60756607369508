import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AboutUsType,
  AddUser,
  AdminAuthorizedCompany,
  AdminCompany,
  AdminCompanyUser,
  AdminCurrentUserCompany,
  AdminDeactivePolicyReason,
  AdminInformationSheet,
  AdminPaymentListFilterFields,
  AdminPaymentScheduleType,
  AdminProductCategoriesList,
  AdminProductDetail,
  AdminSoldPolicyFilterFields,
  AdminTravelMultiDay,
  AdminTravelPolicyDetail,
  AdminWebDocument,
  CompaniesWithProduct,
  Company,
  CompanyPaymentsListResponse,
  CompanyProductsWithSubModal,
  CorporateModule,
  CorporateModuleDetails,
  Country,
  CreateNotification,
  DeactivatedPolicy,
  DepartureAndArrival,
  ExtraCoverages,
  FaqTypes,
  Footer,
  Franchise,
  FraniPartnerType,
  GeneratePolicyFilesProps,
  GeneratePolicyFilesType,
  InsuranceKinds,
  LandingSlide,
  LandingSlideRoot,
  MainPageText,
  NotificationList,
  OurTeam,
  Package,
  PackageService,
  PetBreed,
  PetCategory,
  PetPackage,
  PetPackagePrices,
  PetService,
  PolicyFileType,
  PolicyRegistrationFirstStepList,
  Product,
  ProductCategory,
  ProductCategoryEmail,
  ProfileEditableFields,
  PromoCode,
  PromoCodePackage,
  SoldPolicies,
  StoreFraniUser,
  SubNotification,
  TplAndLocalTplList,
  TplCurrencies,
  TplCurrencyList,
  TplInsurancePeriod,
  TplLimitList,
  TplOfferRequest,
  TplOfferType,
  TplPackage,
  TplPackagePrices,
  TplPagesWording,
  TplPaymentSchedule,
  TplPolicyRegisrationFirstStep,
  TplPolicyRegistration,
  TplPolicyRegistrationResponse,
  TplProduct,
  TplStartDateText,
  TplTransactions,
  Transation,
  TranslationsField,
  TravelAboardBestOfferPost,
  TravelAboardBestOfferResponse,
  TravelAbroadPriceCombination,
  TravelAddon,
  TravelCurrencies,
  TravelDateText,
  TravelFranchise,
  TravelLimits,
  TravelMultiDayOption,
  TravelPackage,
  TravelPagesWording,
  TravelPolicyRegistration,
  TravelPolicyRegistrationResponse,
  TravelProduct,
  TravelProductsMinPrice,
  TravelRegions,
  TravelRisk,
  TravelSingleOrMultiPrice,
  User,
  UserCurrentPolicies,
  UserTravelPolicyType,
  WebMenu,
  WebPage,
  WebUser,
  WebUserList,
  WelcomerAddon,
  WelcomerAgeGroup,
  WelcomerCurrencies,
  WelcomerDayGroup,
  WelcomerLimitsList,
  WelcomerPackage,
  WelcomerPackagePrices,
  WelcomerPackagePricesList,
  WelcomerPagesWording,
  WelcomerPolicyRegistrationResponse,
  WelcomerProduct,
  WelcomerRisk,
  WhoWillChooseCompany,
  WordingFileType,
} from "../domain/types";
import { RootState } from "../store";
// import { detectUserLanguage } from '../store/langStorage'

export const devMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";
/** after deployment, the api is `${same hostname}/api` */
// http://localhost:3333
// https://admin.frani.com
// https://admin-dev.frani.com
export const serverUrl = devMode
  ? "https://admin-dev.frani.com"
  : `${document.location.origin}`;
export const apiUrl = `${serverUrl}/api/v1/`;

export const uploadUrl = (uploadedFile: string) => {
  if (uploadedFile.startsWith("http")) {
    return uploadedFile;
  } else {
    return `${serverUrl}${uploadedFile}`;
  }
};

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      // const lang = detectUserLanguage()
      // headers.set('locale', lang)
      headers.set("locale", "ge");
      //
      headers.set("Cache-Control", "no-store, no-cache");
      //
      return headers;
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<
      { user: User; token: string },
      { email: string; password: string }
    >({
      query: (credentials) => ({
        url: "signin",
        method: "POST",
        body: credentials,
      }),
    }),
    //logout
    logout: builder.mutation({
      query: (args) => ({
        url: "signout",
        method: "POST",
        body: {},
      }),
    }),
    // reset password step one & enter email
    resetPasswordSeendEmail: builder.mutation<
      { success: boolean },
      { email: string }
    >({
      query: ({ email }) => ({
        url: "forgot-password",
        method: "POST",
        body: {
          email,
        },
      }),
    }),
    // reset password step two & send new password and token
    resetPasswordCreateNewPassword: builder.mutation<
      { success: boolean },
      { token: string; password: string; passwordConfirm: string }
    >({
      query: ({ token, password, passwordConfirm }) => ({
        url: `reset-password/${token}`,
        method: "POST",
        body: {
          password,
          passwordConfirm,
        },
      }),
    }),
    // sign up
    signUp: builder.mutation<
      { user: User; token: string },
      {
        firstName: string;
        lastName: string;
        firstNameGe?: string;
        lastNameGe?: string;
        phone: string;
        personalNumber: string;
        email: string;
        password: string;
        passwordConfirm: string;
        birthday: string;
        citizenship: string;
        gender: string;
        consent: boolean;
      }
    >({
      query: (userFields) => ({
        url: "signup",
        method: "POST",
        body: userFields,
      }),
    }),
    // profile
    profile: builder.query<User, { token: string }>({
      // token will be added by `prepareHeaders`
      query: ({ token }) => ({
        url: "profile",
        method: "GET",
      }),
    }),
    // updateProfile: builder.mutation<User, ProfileEditableFields | { avatar?: File }>({
    //   query: (profileFields) => {
    //     const formData = Object.entries(profileFields).reduce((d, [name, value]) => {
    //       name && value && d.append(name, value)
    //       return d
    //     }, new FormData())
    //     return {
    //       url: 'profile/personal-info/update',
    //       method: 'POST',
    //       body: formData, //ProfileEditableFields,
    //     }
    //   },
    // }),
    // update profile v2
    updateProfile: builder.mutation<User, ProfileEditableFields>({
      query: (userFields) => ({
        url: "profile/personal-info/update",
        method: "POST",
        body: userFields,
      }),
    }),
    // update password
    updatePassword: builder.mutation<
      { message: string },
      {
        oldPassword: string;
        password: string;
        passwordConfirm: string;
        smsCode: number;
      }
    >({
      query: (updatePasswordFields) => ({
        url: "profile/password/update",
        method: "POST",
        body: updatePasswordFields,
      }),
    }),
    // company list
    companyList: builder.query<Company[], undefined>({
      query: () => ({
        url: "admin/companies/list",
        method: "GET",
      }),
    }),
    // subscribe notification
    subNotification: builder.mutation<{ success: boolean }, SubNotification>({
      query: (notificationFields) => ({
        url: "notification-subscription",
        method: "POST",
        body: notificationFields,
      }),
    }),
    // create notification
    createNotification: builder.mutation<
      { success: boolean },
      CreateNotification
    >({
      query: (createNotificationFields) => ({
        url: "admin/send-notifications",
        method: "POST",
        body: createNotificationFields,
      }),
    }),
    // update notifications
    updateNotifications: builder.mutation<
      User,
      {
        enableWebNotifications: boolean;
        enableMobileNotifications: boolean;
        enableEmailNotifications: boolean;
        lang: string;
      }
    >({
      query: (updateNotificationsFields) => ({
        url: "profile/notifications/update",
        method: "POST",
        body: updateNotificationsFields,
      }),
    }),
    // get notification list
    getNotificationList: builder.query<NotificationList[], undefined>({
      query: () => ({
        url: "profile/notifications",
        method: "GET",
      }),
    }),
    // GET product category
    getProductCategory: builder.query<ProductCategory[], undefined>({
      query: () => ({
        url: "categories",
        method: "GET",
      }),
    }),
    // web menu
    getWebMenu: builder.query<WebMenu[], undefined>({
      query: () => ({
        url: "admin/menu/list/1",
        method: "GET",
      }),
    }),
    // web footer
    getAdminFooter: builder.query<[], undefined>({
      query: () => ({
        url: "admin/menu/list/2",
        method: "GET",
      }),
    }),
    // admin menu
    getAdminMenu: builder.query<[], undefined>({
      query: () => ({
        url: "admin/menu/list/3",
        method: "GET",
      }),
    }),
    // admin current user company
    getAdminCurrentUserCompany: builder.query<
      AdminCurrentUserCompany,
      undefined
    >({
      query: () => ({
        url: "admin/current-user-company",
        method: "GET",
      }),
    }),
    // get departure and arrival & travel steo 1
    getDepatureAndArrival: builder.query<DepartureAndArrival[], undefined>({
      query: () => ({
        url: "travel-abroad-or-georgia",
        method: "GET",
      }),
    }),
    // get who will choose company & travel steo 2
    getWhoWillChooseCompany: builder.query<WhoWillChooseCompany[], undefined>({
      query: () => ({
        url: "who-will-choose-company",
        method: "GET",
      }),
    }),
    // get companies with product & travel steo 2
    getCompaniesWithProduct: builder.query<CompaniesWithProduct[], string>({
      query: (flyType) => ({
        url: `companies-with-product/${flyType}`,
        method: "GET",
      }),
    }),
    // get insurance kinds & travel steo 3
    getInsuranceKinds: builder.query<InsuranceKinds[], undefined>({
      query: () => ({
        url: `travel-single-or-multi`,
        method: "GET",
      }),
    }),
    // get travel prodcut min price
    getTravelProductMinPrices: builder.query<TravelProductsMinPrice[], string>({
      query: (productType) => ({
        url: `travel-products-min-prices/${productType}`,
        method: "GET",
      }),
    }),
    // get travel multi days
    getTravelMultiDays: builder.query<TravelMultiDayOption[], undefined>({
      query: () => ({
        url: `travel-multi-days`,
        method: "GET",
      }),
    }),
    // get travel regions
    getTravelRegions: builder.query<TravelRegions[], undefined>({
      query: () => ({
        url: `travel-areas`,
        method: "GET",
      }),
    }),
    // get travel currencies
    getTravelCurrencies: builder.query<TravelCurrencies[], string>({
      query: (currency) => ({
        url: `travel-currencies/${currency}`,
        method: "GET",
      }),
    }),
    // get travel limits
    getTravelLimits: builder.query<TravelLimits[], undefined>({
      query: (_noArg) => ({
        url: `travel-abroad-limits`,
        method: "GET",
      }),
    }),
    // get travel franchises
    getTravelFranchises: builder.query<TravelFranchise[], string>({
      query: (travelType) => ({
        url: `package-franchises/${travelType}`,
        method: "GET",
      }),
    }),
    // get extra coverages
    getExtraCoverages: builder.query<
      ExtraCoverages[],
      { categorySlug: string; secondParam: string }
    >({
      query: ({ categorySlug, secondParam }) => ({
        url: `travel-attached-addons-with-min-prices/${categorySlug}/${secondParam}`,
        method: "GET",
      }),
    }),
    // get product package min price single & multi
    getTravelSingleOrMultiPrice: builder.query<
      TravelSingleOrMultiPrice,
      undefined
    >({
      query: () => ({
        url: `travel-products-min-prices/travel-outside-georgia-package-prices`,
        method: "GET",
      }),
    }),
    // get countries
    getCountries: builder.query<Country[], undefined>({
      query: () => ({
        url: `countries`,
        method: "GET",
      }),
    }),
    // travel aboard best offer
    travelAboardbestOffer: builder.mutation<
      TravelAboardBestOfferResponse[],
      TravelAboardBestOfferPost
    >({
      query: (offerParams) => ({
        url: "travel-abroad-best-offer",
        method: "POST",
        body: offerParams,
      }),
    }),
    // #################################
    // ADMIN
    // #################################
    // upload single file
    uploadFile: builder.mutation<
      { url: string; key: string; id: number; contentType: string },
      { file: File }
    >({
      query: ({ file }) => {
        const uploadFormData = new FormData();
        uploadFormData.append(
          // if we were submitting a single multipart/form-data request with all the fields, we'd use .append(`${fileType}[]`, ..), as in 'form100[]'
          "file",
          file
        );
        return {
          url: "file/store",
          method: "POST",
          body: uploadFormData,
        };
      },
    }),

    createTravelProduct: builder.mutation<
      { message: string },
      { companyId: number; productCategoryId: number }
    >({
      query: (travelProduct) => ({
        url: "admin/travel-products/store",
        method: "POST",
        body: travelProduct,
      }),
    }),

    getTravelPackageById: builder.query<TravelPackage, number>({
      query: (travelPackageId) => ({
        url: `admin/travel-packages/${travelPackageId}/details`,
        method: "GET",
      }),
    }),
    // create travel package
    createTravelPackage: builder.mutation<
      TravelPackage,
      { productId: number; translations: TranslationsField<{ title: string }> }
    >({
      query: (travelPackage) => ({
        url: "admin/travel-packages/store",
        method: "POST",
        body: travelPackage,
      }),
    }),
    // update travel package
    updateTravelPackage: builder.mutation<TravelPackage, TravelPackage>({
      query: (travelPackage) => ({
        url: `admin/travel-packages/update/${travelPackage.id}`,
        method: "POST",
        body: travelPackage,
      }),
    }),
    // update travel package
    deleteTravelPackage: builder.mutation<
      { message?: string },
      TravelPackage["id"]
    >({
      query: (travelPackageId) => ({
        url: `admin/travel-packages/delete/${travelPackageId}`,
        method: "DELETE",
      }),
    }),
    // admin product categories list
    getAdminProductCategoriesList: builder.query<
      AdminProductCategoriesList[],
      undefined
    >({
      query: () => ({
        url: "admin/product-categories/list",
        method: "GET",
      }),
    }),
    // admin company products with sub modals
    getCompanyProductswithSubModals: builder.query<
      CompanyProductsWithSubModal[],
      number
    >({
      query: (companyId) => ({
        url: `admin/company/${companyId}/travel-products-with-sub-models`,
        method: "GET",
      }),
    }),
    // admin company products with sub modals
    getProductListByCategoryId: builder.query<Product[], number>({
      query: (companyId) => ({
        url: `admin/company/${companyId}/product/list`,
        method: "GET",
      }),
    }),
    // get product by id
    getProductById: builder.query<Product, number>({
      query: (productId) => ({
        url: `admin/products/current/${productId}`,
        method: "GET",
      }),
    }),
    // get travel product by id
    getTravelProductById: builder.query<TravelProduct, number>({
      query: (productId) => ({
        url: `admin/travel-products/${productId}/details`,
        method: "GET",
      }),
    }),
    // travel product tab one product fields store = save
    storeTravelProductTabOneProductFields: builder.mutation<
      TravelPolicyRegistrationResponse,
      TravelProduct
    >({
      query: (travelProduct) => ({
        url: `admin/travel-products/${travelProduct.id}/update`,
        method: "POST",
        body: travelProduct,
      }),
    }),
    updateProductDocuments: builder.mutation<
      Product,
      {
        id: number;
        conditionFileUrl?: string;
        wordingFileUrl?: string;
        policyFileUrl?: string;
        policyFiles?: PolicyFileType[];
        wordingFiles?: WordingFileType;
      }
    >({
      query: (newFileUrls) => ({
        url: `admin/products/update-documents/${newFileUrls.id}`,
        method: "POST",
        body: newFileUrls,
      }),
    }),
    updateTravelProductPriceCombinations: builder.mutation<
      TravelAbroadPriceCombination[],
      Pick<
        TravelProduct,
        | "id"
        | "priceCombinations"
        | "priceCoefficients"
        | "minTotalPremiumInfos"
        | "dayGroupInfos"
      >
    >({
      query: (travelProductCoefficientFields) => ({
        url: `admin/travel-products/${travelProductCoefficientFields.id}/update-price-combinations`,
        method: "POST",
        body: travelProductCoefficientFields,
      }),
    }),
    // admin: approve or reject product data entered by insurance companies
    adminActivateProduct: builder.mutation<Product, Product["id"]>({
      query: (productId) => ({
        url: `admin/product/${productId}/activate`,
        method: "POST",
        body: {},
      }),
    }),
    // admin: deactivate (reject changes on) product data entered by insurance companies
    adminDeactivateProduct: builder.mutation<Product, Product["id"]>({
      query: (productId) => ({
        url: `admin/product/${productId}/deactivate`,
        method: "POST",
        body: {},
      }),
    }),
    //
    // admin: copy product
    adminCopyProduct: builder.mutation<Product, Product["id"]>({
      query: (productId) => ({
        url: `admin/tpl-packages/${productId}/copy`,
        method: "POST",
        body: {},
      }),
    }),
    //
    // admin: approve or reject package data entered by insurance companies
    adminActivatePackage: builder.mutation<Package, Package["id"]>({
      query: (packageId) => ({
        url: `admin/package/${packageId}/activate`,
        method: "POST",
        body: {},
      }),
    }),
    // admin: deactivate (reject changes on) package data entered by insurance companies
    adminDeactivatePackage: builder.mutation<Package, Package["id"]>({
      query: (packageId) => ({
        url: `admin/package/${packageId}/deactivate`,
        method: "POST",
        body: {},
      }),
    }),
    //
    //
    // travel policy registration
    travelPolicyRegistration: builder.mutation<
      TravelPolicyRegistrationResponse,
      TravelPolicyRegistration
    >({
      query: (travelPolicy) => ({
        url: `travel-policy-registration`,
        method: "POST",
        body: travelPolicy,
      }),
    }),
    // travel policy payment init
    getTravelPolicyPaymentUrl: builder.query<{ url: string }, number>({
      query: (orderId) => ({
        url: `pay/init/order/${orderId}`,
        method: "GET",
      }),
    }),
    // get policies
    getPoliciesList: builder.query<UserTravelPolicyType, number>({
      query: (pageNumber) => ({
        url: `profile/policies?${pageNumber}`,
        method: "GET",
      }),
    }),
    // get user current policies
    getUserCurrentPolicies: builder.query<UserCurrentPolicies, number>({
      query: (mainPolicyId) => ({
        url: `profile/policy/${mainPolicyId}`,
        method: "GET",
      }),
    }),
    // send travel verification sms
    sendVerificationSms: builder.mutation<
      { response: boolean },
      { phone: string }
    >({
      query: (verificationSms) => ({
        url: `send-verification-sms`,
        method: "POST",
        body: verificationSms,
      }),
    }),
    // authorization after mobile verification
    authorizationAfterMobileVerification: builder.mutation<
      User,
      { phone: string; email: string; smsCode: number; userId: number }
    >({
      query: (verificationInfo) => ({
        url: `authorization-after-mobile-verification`,
        method: "POST",
        body: verificationInfo,
      }),
    }),
    // get policies
    getPolicyRegistrationFirstStep: builder.query<
      PolicyRegistrationFirstStepList[],
      undefined
    >({
      query: () => ({
        url: "travel-abroad-policy-registration-first-step-list",
        method: "GET",
      }),
    }),
    // get admin policy detail
    getAdminPolicyById: builder.query<AdminTravelPolicyDetail, number>({
      query: (policyId) => ({
        url: `admin/company-policy/${policyId}`,
        method: "GET",
      }),
    }),
    // get all company payment list
    getAllCompanyPaymentList: builder.query<
      CompanyPaymentsListResponse,
      Partial<AdminPaymentListFilterFields>
    >({
      query: ({
        page,
        limit,
        paymentDateFrom,
        paymentDateTo,
        privateNumber,
        orderId,
        companyId,
        productCategoryId,
      }) => ({
        url: `admin/payments/report?${
          paymentDateFrom === null ? "" : `paymentDateFrom=${paymentDateFrom}&`
        }${privateNumber === null ? "" : `privateNumber=${privateNumber}&`}${
          companyId === undefined ? "" : `companyId=${companyId}&`
        }${orderId === null ? "" : `orderId=${orderId}&`}${
          productCategoryId === null
            ? ""
            : `productCategoryId=${productCategoryId}&`
        }${
          paymentDateTo === null ? "" : `paymentDateTo=${paymentDateTo}&`
        }page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    // download all payments list
    getDownloadAllCompanyPaymentList: builder.mutation<
      SoldPolicies,
      Partial<AdminPaymentListFilterFields>
    >({
      query: (filterFields) => ({
        url: `admin/payments/report/excel`,
        method: "POST",
        body: filterFields,
      }),
    }),
    // download company payments list
    getDownloadCompanyPaymentList: builder.mutation<
      SoldPolicies,
      Partial<AdminPaymentListFilterFields>
    >({
      query: (filterFields) => ({
        url: `admin/company/${filterFields.companyId}/payment/generate-report`,
        method: "POST",
        body: filterFields,
      }),
    }),
    // get company payment list
    getAuthorizedCompanies: builder.query<Company[], undefined>({
      query: () => ({
        url: `admin/user/authorized-companies`,
        method: "GET",
      }),
    }),
    // get tpl and local tpl kist
    getTplAndlocalTplList: builder.query<TplAndLocalTplList[], undefined>({
      query: () => ({
        url: `tpl-and-local-tpl-list`,
        method: "GET",
      }),
    }),
    // get tpl date text
    getTplStartDateText: builder.query<TplStartDateText, undefined>({
      query: () => ({
        url: `tpl-start-date-text`,
        method: "GET",
      }),
    }),
    // get tpl insurance period
    getTplInsurancePeriod: builder.query<TplInsurancePeriod[], undefined>({
      query: () => ({
        url: `tpl-insurance-period-list`,
        method: "GET",
      }),
    }),
    // get tpl currency list
    getTplCurrencyList: builder.query<TplCurrencyList[], undefined>({
      query: () => ({
        url: `tpl-currency-list`,
        method: "GET",
      }),
    }),
    // get tpl limit list
    getTplLimitList: builder.query<{ value: number }[], undefined>({
      query: () => ({
        url: `tpl-limit-list`,
        method: "GET",
      }),
    }),
    // travel policy registration
    tplOffer: builder.mutation<TplOfferType[], TplOfferRequest>({
      query: (tplOffer) => ({
        url: `tpl-best-offer`,
        method: "POST",
        body: tplOffer,
      }),
    }),
    // get admin travel risks
    getAdminTravelRiskList: builder.query<TravelRisk[], undefined>({
      query: () => ({
        url: `admin/travel/risks`,
        method: "GET",
      }),
    }),
    // get admin travel risk
    getAdminTravelRiskById: builder.query<TravelRisk, number>({
      query: (riskId) => ({
        url: `admin/risk/current/${riskId}`,
        method: "GET",
      }),
    }),
    // save admin travel risk
    saveTravelRisk: builder.mutation<TravelRisk, Partial<TravelRisk>>({
      query: (travelRisk) => ({
        url:
          travelRisk && "id" in travelRisk && travelRisk.id && travelRisk.id > 0
            ? `admin/risk/update/${travelRisk.id}`
            : "admin/risk/store",
        method: "POST",
        body: travelRisk,
      }),
    }),
    // remove admin travel risk
    removeTravelRisk: builder.mutation<{ message: string }, number>({
      query: (riskId) => ({
        url: `admin/risk/delete/${riskId}`,
        method: "DELETE",
      }),
    }),
    // get admin travel risks
    getAdminTravelAbroadAddonList: builder.query<TravelAddon[], undefined>({
      query: () => ({
        url: `admin/travel-abroad/addons`,
        method: "GET",
      }),
    }),
    // get admin travel addon
    getAdminTravelAddonById: builder.query<TravelAddon, number>({
      query: (addonId) => ({
        url: `admin/addon/current/${addonId}`,
        method: "GET",
      }),
    }),
    // save admin travel addon
    saveTravelAddon: builder.mutation<TravelAddon, Partial<TravelAddon>>({
      query: (travelAddon) => ({
        url:
          travelAddon &&
          "id" in travelAddon &&
          travelAddon.id &&
          travelAddon.id > 0
            ? `admin/addon/update/${travelAddon.id}`
            : "admin/addon/store",
        method: "POST",
        body: travelAddon,
      }),
    }),
    // remove admin travel addon
    removeTravelAddon: builder.mutation<{ message: string }, number>({
      query: (addonId) => ({
        url: `admin/addon/delete/${addonId}`,
        method: "DELETE",
      }),
    }),
    // get admin franchise list
    getAdminTravelFranchiseList: builder.query<Franchise[], string>({
      query: (slug) => ({
        url: `admin/franchise/${slug}/list`,
        method: "GET",
      }),
    }),
    // add admin travel franchise
    addAdminTravelFranchise: builder.mutation<
      { message: string },
      { franchise: number }
    >({
      query: (franchise) => ({
        url: `admin/franchise/store/travel-abroad-franchise`,
        method: "POST",
        body: franchise,
      }),
    }),
    // get admin franchise list
    getAdminAuthorizedCompanies: builder.query<
      AdminAuthorizedCompany[],
      undefined
    >({
      query: () => ({
        url: `admin/user/authorized-companies`,
        method: "GET",
      }),
    }),
    // verify email with emal and token
    verifyEmail: builder.query<
      { message: string; stack: string },
      { email: string; token: string }
    >({
      query: ({ email, token }) => ({
        url: `verify-email/${email}/${token}`,
        method: "GET",
      }),
    }),
    // send email verification link in email
    sendEmailVerificationLink: builder.query<{ message: string }, undefined>({
      query: () => ({
        url: `send-email-verification`,
        method: "GET",
      }),
    }),
    // admin company users
    getAdminCompanyUsers: builder.query<User[], number>({
      query: (companyId) => ({
        url: `admin/company/${companyId}/user/list`,
        method: "GET",
      }),
    }),
    // create user in Admin
    storeUserInAdmin: builder.mutation<AdminCompanyUser, AddUser>({
      query: (user) => ({
        url: `admin/company-users/store`,
        method: "POST",
        body: user,
      }),
    }),
    // update user in Admin
    updateUserInAdmin: builder.mutation<
      User,
      { company: Partial<AdminCompanyUser>; userId: number }
    >({
      query: ({ company, userId }) => ({
        url: `admin/company-users/update/${userId}`,
        method: "POST",
        body: company,
      }),
    }),
    // remove user in Admin
    removeUserInAdmin: builder.mutation<{ message: string }, number>({
      query: (userId) => ({
        url: `admin/company-users/delete/${userId}`,
        method: "DELETE",
      }),
    }),
    // save company in admin
    saveCompany: builder.mutation<
      Partial<AdminAuthorizedCompany>,
      Partial<AdminCompany>
    >({
      query: (company) => ({
        url:
          company && "id" in company && company.id && company.id > 0
            ? `admin/companies/update/${company.id}`
            : "admin/companies/store",
        method: "POST",
        body: company,
      }),
    }),
    // get current company by id
    getCurrentCompanyById: builder.query<AdminCompany, number>({
      query: (companyId) => ({
        url: `admin/companies/current/${companyId}`,
        method: "GET",
      }),
    }),
    // remove company
    removeCompany: builder.mutation<{ message: string }, number>({
      query: (companyId) => ({
        url: `admin/companies/delete/${companyId}`,
        method: "DELETE",
      }),
    }),
    // travel flow page texts by slug
    getTravelFlowPageTextsBySlug: builder.query<
      Partial<TravelPagesWording[]>,
      string
    >({
      query: (slug) => ({
        url: `admin/travel-flow/${slug}/list`,
        method: "GET",
      }),
    }),
    // travel flow current item by list id
    getTravelFlowPageTextItemByListItemId: builder.query<
      Partial<TravelPagesWording>,
      number
    >({
      query: (listID) => ({
        url: `admin/travel-flow/${listID}/current`,
        method: "GET",
      }),
    }),
    // travel flow update current item by id
    updateTravelFlowPageItemByListItemId: builder.mutation<
      TravelPagesWording[],
      Partial<TravelPagesWording>
    >({
      query: (item) => ({
        url: `admin/travel-flow/${item.id}/update`,
        method: "POST",
        body: item,
      }),
    }),
    // admin travel report list
    getAdminTravelReportList: builder.query<Object[], undefined>({
      query: (_noArg) => ({
        url: `admin/travel-abroad/download/policies-report`,
        method: "GET",
      }),
    }),
    // admin travel report pdf
    getAdminTravelReportPdf: builder.query<{ url: string }, undefined>({
      query: (_noArg) => ({
        url: `admin/travel-abroad/pdf-reports-file/policies-report`,
        method: "GET",
      }),
    }),
    // admin travel insurance company report list
    getAdminTravelIncuranceCompanyReportList: builder.query<Object[], number>({
      query: (companyID) => ({
        url: `admin/company/${companyID}/travel-abroad/pdf-reports-file/policies-report`,
        method: "GET",
      }),
    }),
    // admin travel insurance company report pdf
    getAdminTravelInsuranceCompanyReportPdf: builder.query<
      { url: string },
      number
    >({
      query: (companyID) => ({
        url: `admin/company/${companyID}/travel-abroad/download/policies-report`,
        method: "GET",
      }),
    }),
    // admin web documents
    getAdminWebDocuments: builder.query<AdminWebDocument, string>({
      query: (slug) => ({
        url: `admin/frani-condition/${slug}/detailed`,
        method: "GET",
      }),
    }),
    // admin web documents store
    storeWebDocument: builder.mutation<
      AdminWebDocument[],
      Partial<AdminWebDocument>
    >({
      query: (item) => ({
        url: `admin/frani-condition/${item.slug}/update`,
        method: "POST",
        body: item,
      }),
    }),
    // get web documenents for web
    getWebPage: builder.query<Partial<WebPage>, string>({
      query: (slug) => ({
        url: `page/${slug}`,
        method: "GET",
      }),
    }),
    // get frani pdf file
    getFraniPdf: builder.query<{ url: string }, undefined>({
      query: (_noArg) => ({
        url: `frani-pdf/travel`,
        method: "GET",
      }),
    }),
    // get travel single select date texts
    getTravelDateTexts: builder.query<TravelDateText, string>({
      query: (slug) => ({
        url: `travel-dates/${slug}`,
        method: "GET",
      }),
    }),
    // get user orders
    getUserTransactions: builder.query<Transation[], undefined>({
      query: (_noArg) => ({
        url: `profile/orders`,
        method: "GET",
      }),
    }),
    // get tpl personal info & first step
    getTplPolicyRegisrationFirstStep: builder.query<
      TplPolicyRegisrationFirstStep[],
      undefined
    >({
      query: (_noArg) => ({
        url: `tpl-policy-registration-first-step`,
        method: "GET",
      }),
    }),
    // get frani users
    getFraniUsersList: builder.query<User[], undefined>({
      query: (_noArg) => ({
        url: `admin/frani-user-list`,
        method: "GET",
      }),
    }),
    // get frani user by id
    getFraniUserById: builder.query<User, number>({
      query: (userID) => ({
        url: `admin/current-frani-user/${userID}`,
        method: "GET",
      }),
    }),
    // save frani user
    storeFraniUser: builder.mutation<User, Partial<StoreFraniUser>>({
      query: (user) => ({
        url:
          user && "id" in user && user.id && user.id > 0
            ? `admin/update-frani-user/${user.id}`
            : "admin/store-frani-user",
        method: "POST",
        body: user,
      }),
    }),
    // frani user deactivation
    franiUserDeactivation: builder.mutation<User, number>({
      query: (userID) => ({
        url: `admin/frani-user-deactivation/${userID}`,
        method: "POST",
        body: userID,
      }),
    }),
    // frani user activation
    franiUserActivation: builder.mutation<User, number>({
      query: (userID) => ({
        url: `admin/frani-user-activation/${userID}`,
        method: "POST",
        body: userID,
      }),
    }),
    // get admin travel multi days
    getAdminTravelMultiDayById: builder.query<AdminTravelMultiDay, number>({
      query: (dayID) => ({
        url: `admin/travel-multi-day/${dayID}/current`,
        method: "GET",
      }),
    }),
    // admin travel multi day store & update
    storeTravelMultiDay: builder.mutation<
      AdminTravelMultiDay,
      Partial<AdminTravelMultiDay>
    >({
      query: (day) => ({
        url:
          day && "id" in day && day.id && day.id > 0
            ? `admin/travel-multi-day/${day.id}/update`
            : "admin/travel-multi-day/store",
        method: "POST",
        body: day,
      }),
    }),
    // remove travel multi day
    removeTravelMultiDay: builder.mutation<AdminTravelMultiDay, number>({
      query: (dayID) => ({
        url: `admin/travel-multi-day/${dayID}/delete`,
        method: "DELETE",
        body: dayID,
      }),
    }),
    // get main page text list
    getMainPageTextList: builder.query<MainPageText[], undefined>({
      query: (_noArg) => ({
        url: `page/main-page/list`,
        method: "GET",
      }),
    }),
    // get main page by id
    getMainPageTextById: builder.query<MainPageText, number>({
      query: (pageID) => ({
        url: `admin/page/main-page/${pageID}/current`,
        method: "GET",
      }),
    }),
    // update main page text
    updateMainPageText: builder.mutation<MainPageText, Partial<MainPageText>>({
      query: (data) => ({
        url: `admin/page/main-page/${data.id}/update`,
        method: "POST",
        body: data,
      }),
    }),
    // get main page by id
    getFaqList: builder.query<Partial<FaqTypes[]>, undefined>({
      query: (_noArg) => ({
        url: `admin/page/faq/list`,
        method: "GET",
      }),
    }),
    // FAQ MAIN TEXT UPDATE
    updateFaqMainText: builder.mutation<FaqTypes, Partial<FaqTypes>>({
      query: (data) => ({
        url: `admin/page/main-faq/update`,
        method: "POST",
        body: data,
      }),
    }),
    // admin FAQ store & update
    storeFAQ: builder.mutation<Partial<FaqTypes>, Partial<FaqTypes>>({
      query: (FAQ) => ({
        url:
          FAQ && "id" in FAQ && FAQ.id && FAQ.id > 0
            ? `admin/page/faq/${FAQ.id}/update`
            : "admin/page/faq/store",
        method: "POST",
        body: FAQ,
      }),
    }),
    // get FAQ by id
    getFaqById: builder.query<Partial<FaqTypes>, number>({
      query: (FaqID) => ({
        url: `admin/page/faq/${FaqID}`,
        method: "GET",
      }),
    }),
    // remove FAQ by id
    removeFaqById: builder.mutation<FaqTypes, number>({
      query: (faqID) => ({
        url: `admin/page/${faqID}/delete`,
        method: "DELETE",
        body: faqID,
      }),
    }),
    // web footer
    getWebFooter: builder.query<Footer[], undefined>({
      query: () => ({
        url: "menu/footer/list",
        method: "GET",
      }),
    }),
    // web feedback section
    getFeedback: builder.query<
      {
        registerdUsers: number;
        paidPolicies: number;
        registerdCompanies: number;
      },
      undefined
    >({
      query: () => ({
        url: "feedback",
        method: "GET",
      }),
    }),
    // tpl policy registration
    tplPolicyRegistration: builder.mutation<undefined, TplPolicyRegistration>({
      query: (tplPolicy) => ({
        url: `tpl-policy-registration`,
        method: "POST",
        body: tplPolicy,
      }),
    }),
    // get all sold policies with page
    getAllSoldPolicies: builder.query<SoldPolicies, number>({
      query: (pageNumber) => ({
        url: `admin/policies/?page=${pageNumber}`,
        method: "GET",
      }),
    }),
    // sold policy filter request
    getSoldSearchedPolicies: builder.mutation<
      SoldPolicies,
      Partial<AdminSoldPolicyFilterFields>
    >({
      query: (filterFields) => ({
        url: `admin/policies`,
        method: "POST",
        body: filterFields,
      }),
    }),
    // sold policy filter request
    getSoldSearchedPolicyReports: builder.mutation<
      { url: string },
      Partial<AdminSoldPolicyFilterFields>
    >({
      query: (filterFields) => ({
        url: `admin/policies`,
        method: "POST",
        body: filterFields,
      }),
    }),
    // store frani team member
    storeTeamMember: builder.mutation<Partial<OurTeam>, Partial<OurTeam>>({
      query: (member) => ({
        url:
          member && "id" in member && member.id && member.id > 0
            ? `admin/page/about-us/${member.id}/update`
            : "admin/page/about-us/store",
        method: "POST",
        body: member,
      }),
    }),
    // get current team member
    getCurrentTeamMember: builder.query<Partial<OurTeam>, number>({
      query: (memberID) => ({
        url: `admin/page/about-us/${memberID}`,
        method: "GET",
      }),
    }),
    // get admin about us texts
    getAdminAboutUsTexts: builder.query<Partial<AboutUsType>, undefined>({
      query: (_noArg) => ({
        url: `admin/page/about-us-main-text`,
        method: "GET",
      }),
    }),
    // update about us texts
    updateAboutUsTexts: builder.mutation<
      Partial<AboutUsType>,
      Partial<AboutUsType>
    >({
      query: (aboutUsTexts) => ({
        url: `admin/page/update-about-us-main-text`,
        method: "POST",
        body: aboutUsTexts,
      }),
    }),
    // delete franchise
    removeFranchise: builder.mutation<{ message?: string }, number>({
      query: (franchiseID) => ({
        url: `admin/franchise/travel-abroad-franchise/${franchiseID}/delete`,
        method: "DELETE",
      }),
    }),
    // get admin policy detail
    getAdminPolicyDetail: builder.query<AdminTravelPolicyDetail, number>({
      query: (policyID) => ({
        url: `admin/policies/${policyID}/detailed`,
        method: "GET",
      }),
    }),
    // get travel limits by currency
    getTravelLimitsByCurrency: builder.query<{ value: number }[], string>({
      query: (currency) => ({
        url: `travel-abroad/get-package-limits-by-currency/${currency}`,
        method: "GET",
      }),
    }),
    // get travel franchise by limits
    getTravelFranchiseByLimits: builder.query<{ value: number }[], number>({
      query: (limit) => ({
        url: `travel-abroad/get-package-franchises-by-limits?limits=${limit}`,
        method: "GET",
      }),
    }),
    // Generate Policy Files By Policy Ids
    generatePolicyFilesByPolicyIds: builder.mutation<
      GeneratePolicyFilesType[],
      GeneratePolicyFilesProps
    >({
      query: (policyProps) => ({
        url: `admin/generate-pdf-file-and-store-it-to-bucket-manually`,
        method: "POST",
        body: policyProps,
      }),
    }),
    // Send Email/Sms To Policy Holder
    sendEmailAndSmsToPolicyHolder: builder.mutation<
      GeneratePolicyFilesType[],
      GeneratePolicyFilesProps
    >({
      query: (policyProps) => ({
        url: `admin/send-sms-and-email-manually`,
        method: "POST",
        body: policyProps,
      }),
    }),
    // After Payment Steps On DEV
    afterPaymentStepsOnDev: builder.mutation<
      GeneratePolicyFilesType[],
      { amount: number; orderId: number }
    >({
      query: (payload) => ({
        url: `after-payment-steps-on-dev`,
        method: "POST",
        body: payload,
      }),
    }),
    // get frani product current emails
    getFraniProductCurrentEmail: builder.query<ProductCategoryEmail, number>({
      query: (productCategoruId) => ({
        url: `admin/frani-emails/${productCategoruId}/current`,
        method: "GET",
      }),
    }),
    // store  frani product emails
    storeFraniProductEmails: builder.mutation<
      ProductCategoryEmail,
      Partial<ProductCategoryEmail>
    >({
      query: (productEmails) => ({
        url: "admin/frani-emails/store",
        method: "POST",
        body: productEmails,
      }),
    }),

    //  update frani product emails
    updateFraniProductEmails: builder.mutation<
      ProductCategoryEmail,
      Partial<ProductCategoryEmail>
    >({
      query: (productEmails) => ({
        url: `admin/frani-emails/${productEmails.productCategoryId}/update`,
        method: "POST",
        body: productEmails,
      }),
    }),

    // delete frani product mails
    removeFraniProductMails: builder.mutation<
      { message?: string },
      TravelPackage["id"]
    >({
      query: (emailItemId) => ({
        url: `admin/frani-emails/${emailItemId}/delete`,
        method: "DELETE",
      }),
    }),

    // get admin travel policy deactive reason
    getAdminPolicyDeactiveReason: builder.query<
      AdminDeactivePolicyReason[],
      { categoryId: number; isActive?: boolean }
    >({
      query: ({ categoryId, isActive }) => ({
        url: `admin/policy-deactivation-reason/list?productCategoryId=${categoryId}${
          isActive ? `&isActive=${isActive}` : ""
        }`,
        method: "GET",
      }),
    }),

    // get admin travel policy deactive reason detail
    getAdminPolicyDeactiveReasonDetail: builder.query<
      AdminDeactivePolicyReason,
      number
    >({
      query: (id) => ({
        url: `admin/policy-deactivation-reason/${id}/current`,
        method: "GET",
      }),
    }),
    // get admin information sheet list
    getAdminInformationSheet: builder.query<
      AdminInformationSheet[],
      { categoryId: number; attachmentSlug: string }
    >({
      query: ({ categoryId, attachmentSlug }) => ({
        url: `admin/product-category-attachments/list?productCategoryId=${categoryId}&attachmentSlug=${attachmentSlug}`,
        method: "GET",
      }),
    }),
    // get admin current information sheet
    getAdminInformationSheetDetail: builder.query<
      AdminInformationSheet,
      number
    >({
      query: (id) => ({
        url: `admin/product-category-attachments/${id}/current`,
        method: "GET",
      }),
    }),
    // store  or update information sheet
    storeInformationSheet: builder.mutation<
      Partial<AdminInformationSheet>,
      Partial<AdminInformationSheet>
    >({
      query: (attachment) => ({
        url:
          attachment && "id" in attachment && attachment.id && attachment.id > 0
            ? `admin/product-category-attachments/${attachment.id}/update`
            : "admin/product-category-attachments/store",
        method: "POST",
        body: attachment,
      }),
    }),
    // get admin payment shcedule types
    getPaymentScheduleTypes: builder.query<AdminPaymentScheduleType[], number>({
      query: () => ({
        url: `admin/payment-schedule-type/list`,
        method: "GET",
      }),
    }),

    // get admin travel policy deactive reason detail
    getAdminPaymentScheduleTypeDetail: builder.query<
      AdminDeactivePolicyReason,
      number
    >({
      query: (id) => ({
        url: `admin/payment-schedule-type/${id}/current`,
        method: "GET",
      }),
    }),

    storePaymentScheduleType: builder.mutation<
      Partial<AdminPaymentScheduleType>,
      Partial<AdminPaymentScheduleType>
    >({
      query: (paymentScheduleType) => ({
        url:
          paymentScheduleType &&
          "id" in paymentScheduleType &&
          paymentScheduleType.id &&
          paymentScheduleType.id > 0
            ? `admin/payment-schedule-type/${paymentScheduleType.id}/update`
            : "admin/payment-schedule-type/store",
        method: "POST",
        body: paymentScheduleType,
      }),
    }),

    // store frani team member
    storePolicyReason: builder.mutation<
      Partial<AdminDeactivePolicyReason>,
      Partial<AdminDeactivePolicyReason>
    >({
      query: (reason) => ({
        url:
          reason && "id" in reason && reason.id && reason.id > 0
            ? `admin/policy-deactivation-reason/${reason.id}/update`
            : "admin/policy-deactivation-reason/store",
        method: "POST",
        body: reason,
      }),
    }),

    removePaymentScheduleType: builder.mutation<{ message: string }, number>({
      query: (reasonId) => ({
        url: `admin/payment-schedule-type/${reasonId}/delete`,
        method: "DELETE",
      }),
    }),

    // remove reason
    removeReason: builder.mutation<{ message: string }, number>({
      query: (reasonId) => ({
        url: `admin/policy-deactivation-reason/${reasonId}/delete`,
        method: "DELETE",
      }),
    }),

    // active policy
    activePolicy: builder.mutation<
      { message: string },
      { policyId: number; policyDeactivationId: number; policyStatus: string }
    >({
      query: (activeInfo) => ({
        url: `admin/policy-activation`,
        method: "POST",
        body: activeInfo,
      }),
    }),

    // deactive policy
    deactivePolicy: builder.mutation<
      DeactivatedPolicy,
      Partial<DeactivatedPolicy>
    >({
      query: (deactiveInfo) => ({
        url: `admin/policy-deactivation`,
        method: "POST",
        body: deactiveInfo,
      }),
    }),

    // tpl flow page texts by slug
    getTplFlowPageTextsBySlug: builder.query<Partial<TplPagesWording>, string>({
      query: (slug) => ({
        url: `admin/flows/${slug}/list`,
        method: "GET",
      }),
    }),

    // tpl flow current item by list id
    getTplFlowPageTextItemByListItemId: builder.query<
      Partial<TplPagesWording>,
      { itemID: number; slug: string }
    >({
      query: ({ itemID, slug }) => ({
        url: `admin/flows/${slug}/list/${itemID}/current`,
        method: "GET",
      }),
    }),

    // tpl flow update current item by id
    updateTplFlowPageItemByListItemId: builder.mutation<
      TplPagesWording[],
      { item: Partial<TplPagesWording>; slug: string }
    >({
      query: ({ item, slug }) => ({
        url: `admin/flows/${slug}/list/${item.id}/update`,
        method: "POST",
        body: item,
      }),
    }),

    // tpl flow update
    updateTplFlow: builder.mutation<
      TplPagesWording[],
      { item: Partial<TplPagesWording>; slug: string }
    >({
      query: ({ item, slug }) => ({
        url: `admin/flows/${slug}/update`,
        method: "POST",
        body: item,
      }),
    }),

    // get tpl limits
    getTplLimits: builder.query<TplLimitList[], { isActive?: string }>({
      query: (params) => ({
        url: params.isActive
          ? `admin/tpl-limits?isActive=${params.isActive}`
          : `admin/tpl-limits`,
        method: "GET",
      }),
    }),

    // add tpl limit
    addTplLimit: builder.mutation<TplLimitList, { value: number }>({
      query: (value) => ({
        url: `admin/tpl-limit`,
        method: "POST",
        body: value,
      }),
    }),

    // update tpl limit status
    updateTplLimitStatus: builder.mutation<TplLimitList, Partial<TplLimitList>>(
      {
        query: (updatedInfo) => ({
          url: `admin/tpl-limit`,
          method: "PUT",
          body: updatedInfo,
        }),
      }
    ),

    // remove reason
    removeLimit: builder.mutation<TplLimitList, number>({
      query: (id) => ({
        url: `admin/tpl-limit/${id}`,
        method: "DELETE",
      }),
    }),

    // get tpl
    getTplCurrencies: builder.query<TplCurrencies[], undefined>({
      query: () => ({
        url: `admin/tpl-currencies`,
        method: "GET",
      }),
    }),

    // add tpl Currency
    addTplCurrency: builder.mutation<TplCurrencies, Partial<TplCurrencies>>({
      query: (currency) => ({
        url: `admin/tpl-currency`,
        method: "POST",
        body: currency,
      }),
    }),

    // update tpl currency status
    updateTplCurrencyStatus: builder.mutation<
      TplCurrencies,
      Partial<TplCurrencies>
    >({
      query: (updatedInfo) => ({
        url: `admin/tpl-currency`,
        method: "PUT",
        body: updatedInfo,
      }),
    }),

    // remove currency
    removeTplCurrency: builder.mutation<TplCurrencies, number>({
      query: (id) => ({
        url: `admin/tpl-currency/${id}`,
        method: "DELETE",
      }),
    }),

    // get services
    getPackageServices: builder.query<
      PackageService[],
      { category: "tpl" | "travel" }
    >({
      query: (props) => ({
        url: `admin/products/${props.category}/package-services`,
        method: "GET",
      }),
    }),

    // get services by id
    getPackageServiceById: builder.query<
      PackageService,
      { category: "tpl" | "travel"; id: number }
    >({
      query: (props) => ({
        url: `admin/products/${props.category}/package-services/${props.id}`,
        method: "GET",
      }),
    }),

    // add package service
    addPackageService: builder.mutation<
      PackageService,
      { category: "tpl" | "travel"; service: Partial<PackageService> }
    >({
      query: (props) => ({
        url: `admin/products/${props.category}/package-services`,
        method: "POST",
        body: props.service,
      }),
    }),

    // add package service
    updatePackageService: builder.mutation<
      PackageService,
      { category: "tpl" | "travel"; service: Partial<PackageService> }
    >({
      query: (props) => ({
        url: `admin/products/${props.category}/package-services/${props.service.id}`,
        method: "PUT",
        body: props.service,
      }),
    }),

    // remove package service
    removePackageService: builder.mutation<
      PackageService,
      { category: "tpl" | "travel"; id: number }
    >({
      query: (props) => ({
        url: `admin/products/${props.category}/package-services/${props.id}`,
        method: "DELETE",
      }),
    }),
    //
    // get tpl product by id
    getTplProductById: builder.query<TplProduct, number>({
      query: (productId) => ({
        url: `admin/tpl-products/${productId}/details`,
        method: "GET",
      }),
    }),
    // get pet product by id
    getPetProductById: builder.query<TplProduct, number>({
      query: (productId) => ({
        url: `admin/pet-products/${productId}/details`,
        method: "GET",
      }),
    }),
    // tpl product tab one product fields store = save
    storeTplProductTabOneProductFields: builder.mutation<
      TplPolicyRegistrationResponse,
      TplProduct
    >({
      query: (tplProduct) => ({
        url: `admin/tpl-product/${tplProduct.id}/update`,
        method: "POST",
        body: tplProduct,
      }),
    }),
    // pet product tab one product fields store = save
    storePetProductTabOneProductFields: builder.mutation<
      TplPolicyRegistrationResponse,
      TplProduct
    >({
      query: (petProduct) => ({
        url: `admin/pet-product/${petProduct.id}/update`,
        method: "POST",
        body: petProduct,
      }),
    }),
    //tplPackages
    createTplPackage: builder.mutation<
      TplPackage,
      {
        productId: number;
        translations: TranslationsField<{ title: string }>;
        limitId: number;
        currencyId: number;
        paymentScheduleTypeIds: number[];
      }
    >({
      query: (tplPackage) => ({
        url: "admin/tpl-packages/store",
        method: "POST",
        body: tplPackage,
      }),
    }),
    //pet Packages
    createPetPackage: builder.mutation<
      PetPackage,
      {
        productId: number;
        translations: TranslationsField<{ title: string }>;
        // limitId: number;
        // currencyId: number;
        paymentScheduleTypeIds: number[];
      }
    >({
      query: (petPackage) => ({
        url: "admin/pet-packages/store",
        method: "POST",
        body: petPackage,
      }),
    }),
    // update tpl package
    updateTplPackage: builder.mutation<TplPackage, TplPackage>({
      query: (tplPackage) => ({
        url: `admin/tpl-packages/${tplPackage.id}/update`,
        method: "POST",
        body: tplPackage,
      }),
    }),
    //
    updatePetPackage: builder.mutation<PetPackage, TplPackage>({
      query: (petPackage) => ({
        url: `admin/pet-packages/${petPackage.id}/update`,
        method: "POST",
        body: petPackage,
      }),
    }),
    // delete tpl package
    deleteTplPackage: builder.mutation<{ message?: string }, TplPackage["id"]>({
      query: (tplPackageId) => ({
        url: `admin/tpl-packages/${tplPackageId}/delete`,
        method: "DELETE",
      }),
    }),
    // delete pet package
    deletePetPackage: builder.mutation<{ message?: string }, PetPackage["id"]>({
      query: (petPackageId) => ({
        url: `admin/pet-packages/${petPackageId}/delete`,
        method: "DELETE",
      }),
    }),
    //
    createTplProduct: builder.mutation<
      { message: string },
      { companyId: number; productCategoryId: number }
    >({
      query: (tplProduct) => ({
        url: "admin/tpl-product/store",
        method: "POST",
        body: tplProduct,
      }),
    }),
    //
    createPetProduct: builder.mutation<
      { message: string },
      { companyId: number; productCategoryId: number }
    >({
      query: (petProduct) => ({
        url: "admin/pet-product/store",
        method: "POST",
        body: petProduct,
      }),
    }),
    //
    getTplPackageById: builder.query<TplPackage, number>({
      query: (tplPackageId) => ({
        url: `admin/tpl-packages/${tplPackageId}/details`,
        method: "GET",
      }),
    }),
    //
    getPetPackageById: builder.query<PetPackage, number>({
      query: (petPackageId) => ({
        url: `admin/pet-packages/${petPackageId}/details`,
        method: "GET",
      }),
    }),
    //
    getTplPackagePrices: builder.query<TplPackagePrices[], number>({
      query: (productId) => ({
        url: `admin/tpl-packages/prices/${productId}`,
        method: "GET",
      }),
    }),
    //
    getPetPackagePrices: builder.query<PetPackagePrices[], number>({
      query: (productId) => ({
        url: `admin/pet-packages/prices/${productId}`,
        method: "GET",
      }),
    }),
    //
    updateTplPackagePriceById: builder.mutation<
      { message: string },
      { priceId: number; premium: number }
    >({
      query: (props) => ({
        url: `admin/tpl-packages/prices/${props.priceId}`,
        method: "PUT",
        body: {
          premium: props.premium,
        },
      }),
    }),
    //
    updatePetPackagePriceById: builder.mutation<
      { message: string },
      { priceId: number; premium: number }
    >({
      query: (props) => ({
        url: `admin/pet-packages/prices/${props.priceId}`,
        method: "PUT",
        body: {
          premium: props.premium,
        },
      }),
    }),
    //
    getAdminProductsList: builder.query<AdminProductDetail[], undefined>({
      query: (_noArg) => ({
        url: `admin/product-detail-information/list`,
        method: "GET",
      }),
    }),
    //
    getAdminProductDetailByType: builder.query<AdminProductDetail, string>({
      query: (productType) => ({
        url: `product-detail-information/${productType}`,
        method: "GET",
      }),
    }),
    //
    storeAdminProductDetail: builder.mutation<
      Partial<AdminProductDetail>,
      Partial<AdminProductDetail>
    >({
      query: (productDetal) => ({
        url: `admin/product-detail-information`,
        method: "POST",
        body: productDetal,
      }),
    }),
    //
    updateAdminProductDetail: builder.mutation<
      Partial<AdminProductDetail>,
      Partial<AdminProductDetail>
    >({
      query: (productDetal) => ({
        url: `admin/product-detail-information/${productDetal.id}`,
        method: "PUT",
        body: productDetal,
      }),
    }),
    //
    removeAdminProductDetail: builder.mutation<
      { message?: string },
      AdminProductDetail["id"]
    >({
      query: (id) => ({
        url: `admin/product-detail-information/${id}`,
        method: "DELETE",
      }),
    }),
    // upload single file
    tpluploadFile: builder.mutation<
      { fileUrl: string; fileKey: string }, // the uploaded file url
      { file: File }
    >({
      query: ({ file }) => {
        const uploadFormData = new FormData();
        uploadFormData.append(
          // if we were submitting a single multipart/form-data request with all the fields, we'd use .append(`${fileType}[]`, ..), as in 'form100[]'
          "file",
          file
        );
        return {
          url: "admin/product-detail-information/tpl/storeFile",
          method: "POST",
          body: uploadFormData,
        };
      },
    }),
    // get welcomer risks list
    getAdminWelcomerRiskList: builder.query<WelcomerRisk[], undefined>({
      query: () => ({
        url: `admin/welcomer/risks`,
        method: "GET",
      }),
    }),
    // get welcomer risk by id
    getAdminWelcomerRiskById: builder.query<WelcomerRisk, number>({
      query: (riskId) => ({
        url: `admin/welcomer/risk/current/${riskId}`,
        method: "GET",
      }),
    }),
    // save welcomer risk
    saveWelcomerRisk: builder.mutation<WelcomerRisk, Partial<WelcomerRisk>>({
      query: (welcomerRisk) => ({
        url:
          welcomerRisk &&
          "id" in welcomerRisk &&
          welcomerRisk.id &&
          welcomerRisk.id > 0
            ? `admin/welcomer/risk/update/${welcomerRisk.id}`
            : `admin/welcomer/risk/store`,
        method:
          welcomerRisk &&
          "id" in welcomerRisk &&
          welcomerRisk.id &&
          welcomerRisk.id > 0
            ? "PUT"
            : "POST",
        body: welcomerRisk,
      }),
    }),
    // remove welcomer risk
    removeWelcomerRisk: builder.mutation<{ message: string }, number>({
      query: (riskId) => ({
        url: `admin/welcomer/risk/delete/${riskId}`,
        method: "DELETE",
      }),
    }),
    // get admin welcomer addons
    getAdminWelcomerAddonList: builder.query<WelcomerAddon[], undefined>({
      query: () => ({
        url: `admin/welcomer/addons`,
        method: "GET",
      }),
    }),
    // get admin welcomer addon by id
    getAdminWelcomerAddonById: builder.query<WelcomerAddon, number>({
      query: (addonId) => ({
        url: `admin/welcomer/addon/current/${addonId}`,
        method: "GET",
      }),
    }),
    // save admin welcomer addon
    saveWelcomerAddon: builder.mutation<WelcomerAddon, Partial<WelcomerAddon>>({
      query: (welcomerAddon) => ({
        url:
          welcomerAddon &&
          "id" in welcomerAddon &&
          welcomerAddon.id &&
          welcomerAddon.id > 0
            ? `admin/welcomer/addon/update/${welcomerAddon.id}`
            : `admin/welcomer/addon/store`,
        method:
          welcomerAddon &&
          "id" in welcomerAddon &&
          welcomerAddon.id &&
          welcomerAddon.id > 0
            ? "PUT"
            : "POST",
        body: welcomerAddon,
      }),
    }),
    // remove admin welcomer addon
    removeWelcomerAddon: builder.mutation<{ message: string }, number>({
      query: (addonId) => ({
        url: `admin/welcomer/addon/delete/${addonId}`,
        method: "DELETE",
      }),
    }),
    // get admin welcomer franchise list
    getAdminWelcomerFranchiseList: builder.query<Franchise[], string>({
      query: (slug) => ({
        url: `admin/franchise/${slug}/list`,
        method: "GET",
      }),
    }),
    // add admin welcomer franchise list
    addAdminWelcomerFranchise: builder.mutation<
      { message: string },
      { franchise: number }
    >({
      query: (franchise) => ({
        url: `admin/franchise/store/welcomer-franchise`,
        method: "POST",
        body: franchise,
      }),
    }),
    // remove admin welcomer franchise
    removeWelcomerFranchise: builder.mutation<{ message?: string }, number>({
      query: (franchiseId) => ({
        url: `admin/franchise/welcomer-franchise/${franchiseId}/delete`,
        method: "DELETE",
      }),
    }),
    // get welcomer flow texts current page by slug
    getWelcomerFlowPageTextsBySlug: builder.query<
      Partial<WelcomerPagesWording>,
      string
    >({
      query: (slug) => ({
        url: `admin/welcomer-flow/${slug}/list`,
        method: "GET",
      }),
    }),
    // get welcomer flow current item texts by list id
    getWelcomerFlowPageItemTextsByListItemId: builder.query<
      Partial<WelcomerPagesWording>,
      { itemID: number; slug: string }
    >({
      query: ({ itemID, slug }) => ({
        url: `admin/flows/${slug}/list/${itemID}/current`,
        method: "GET",
      }),
    }),
    // update welcomer flow current item texts by list id
    updateWelcomerFlowPageItemTextsByListItemId: builder.mutation<
      WelcomerPagesWording[],
      Partial<WelcomerPagesWording>
    >({
      query: (item) => ({
        url: `admin/welcomer-flow/${item.id}/update`,
        method: "POST",
        body: item,
      }),
    }),
    // create welcomer product
    createWelcomerProduct: builder.mutation<
      { message: string },
      { companyId: number; productCategoryId: number }
    >({
      query: (welcomerProduct) => ({
        url: "admin/welcomer-products/store",
        method: "POST",
        body: welcomerProduct,
      }),
    }),
    // get welcomer product by id
    getWelcomerProductById: builder.query<WelcomerProduct, number>({
      query: (productId) => ({
        url: `admin/welcomer-products/${productId}/details`,
        method: "GET",
      }),
    }),
    // welcomer product tab one product fields store = save
    storeWelcomerProductTabOneProductFields: builder.mutation<
      WelcomerPolicyRegistrationResponse,
      WelcomerProduct
    >({
      query: (welcomerProduct) => ({
        url: `admin/welcomer-products/${welcomerProduct.id}/update`,
        method: "PUT",
        body: welcomerProduct,
      }),
    }),
    // store and update welcomer age groups
    storeWelcomerAgeGroups: builder.mutation<
      WelcomerAgeGroup,
      { welcomerProductId: number; data: WelcomerAgeGroup[] }
    >({
      query: (value) => ({
        url: `admin/welcomer-age-groups`,
        method: "POST",
        body: value,
      }),
    }),
    // delete welcomer age group by id
    deleteWelcomerAgegroupById: builder.mutation<WelcomerAgeGroup, number>({
      query: (id) => ({
        url: `admin/welcomer-age-group/${id}`,
        method: "DELETE",
      }),
    }),
    // store and update welcomer day groups
    storeWelcomerDayGroups: builder.mutation<
      WelcomerDayGroup,
      { welcomerProductId: number; data: WelcomerDayGroup[] }
    >({
      query: (value) => ({
        url: `admin/welcomer-day-groups`,
        method: "POST",
        body: value,
      }),
    }),
    // delete welcomer day group by id
    deleteWelcomerDaygroupById: builder.mutation<WelcomerDayGroup, number>({
      query: (id) => ({
        url: `admin/welcomer-day-group/${id}`,
        method: "DELETE",
      }),
    }),
    // create welcomer package
    createWelcomerPackage: builder.mutation<
      WelcomerPackage,
      {
        productId: number;
        translations: TranslationsField<{ title: string }>;
        limitId: number;
        currencyId: number;
      }
    >({
      query: (welcomerPackage) => ({
        url: "admin/welcomer-packages/store",
        method: "POST",
        body: welcomerPackage,
      }),
    }),
    // get welcomer package by id
    getWelcomerPackageById: builder.query<WelcomerPackage, number>({
      query: (welcomerPackageId) => ({
        url: `admin/welcomer-packages/${welcomerPackageId}/details`,
        method: "GET",
      }),
    }),
    // update welcomer package
    updateWelcomerPackage: builder.mutation<WelcomerPackage, WelcomerPackage>({
      query: (welcomerPackage) => ({
        url: `admin/welcomer-packages/update/${welcomerPackage.id}`,
        method: "POST",
        body: welcomerPackage,
      }),
    }),
    // delete welcomer package
    deleteWelcomerPackage: builder.mutation<
      { message?: string },
      WelcomerPackage["id"]
    >({
      query: (welcomerPackageId) => ({
        url: `admin/welcomer-packages/delete/${welcomerPackageId}`,
        method: "DELETE",
      }),
    }),
    // get welcomer package prices
    getWelcomerPackagePrices: builder.query<WelcomerPackagePrices[], number>({
      query: (productId) => ({
        url: `admin/welcomer-packages/prices/${productId}`,
        method: "GET",
      }),
    }),
    // update welcomer prices
    updateWelcomerPackagePrices: builder.mutation<
      WelcomerPackagePricesList,
      { list: WelcomerPackagePricesList[] }
    >({
      query: (value) => ({
        url: `admin/welcomer-packages/prices`,
        method: "PUT",
        body: value,
      }),
    }),
    // get welcomer limits
    getWelcomerLimits: builder.query<
      WelcomerLimitsList[],
      { isActive?: string }
    >({
      query: (params) => ({
        url: params.isActive
          ? `admin/welcomer-limits?isActive=${params.isActive}`
          : `admin/welcomer-limits`,
        method: "GET",
      }),
    }),
    // add welcomer limit
    addWelcomerLimit: builder.mutation<WelcomerLimitsList, { value: number }>({
      query: (value) => ({
        url: `admin/welcomer-limit`,
        method: "POST",
        body: value,
      }),
    }),
    // update tpl limit status
    updateWelcomerLimitStatus: builder.mutation<
      WelcomerLimitsList,
      Partial<WelcomerLimitsList>
    >({
      query: (updatedInfo) => ({
        url: `admin/welcomer-limit`,
        method: "PUT",
        body: updatedInfo,
      }),
    }),
    // remove reason
    removeWelcomerLimit: builder.mutation<WelcomerLimitsList, number>({
      query: (id) => ({
        url: `admin/welcomer-limit/${id}`,
        method: "DELETE",
      }),
    }),
    // get welcomer currencies
    getWelcomerCurrencies: builder.query<WelcomerCurrencies[], undefined>({
      query: () => ({
        url: `admin/welcomer-currencies`,
        method: "GET",
      }),
    }),
    // add welcomer Currency
    addWelcomerCurrency: builder.mutation<
      WelcomerCurrencies,
      Partial<WelcomerCurrencies>
    >({
      query: (currency) => ({
        url: `admin/welcomer-currency`,
        method: "POST",
        body: currency,
      }),
    }),
    // update tpl currency status
    updateWelcomerCurrencyStatus: builder.mutation<
      WelcomerCurrencies,
      Partial<WelcomerCurrencies>
    >({
      query: (updatedInfo) => ({
        url: `admin/welcomer-currency`,
        method: "PUT",
        body: updatedInfo,
      }),
    }),
    // remove currency
    removeWelcomerCurrency: builder.mutation<WelcomerCurrencies, number>({
      query: (id) => ({
        url: `admin/welcomer-currency/${id}`,
        method: "DELETE",
      }),
    }),
    // get business modules
    getAllBusinessModules: builder.query<CorporateModule, string>({
      query: (query) => ({
        url: `admin/corporate-insurance-offer/fetch-all?${query}`,
        method: "GET",
      }),
    }),
    // get business module by id
    getBusinessModuleById: builder.query<CorporateModuleDetails, number>({
      query: (id) => ({
        url: `admin/corporate-insurance-offer/fetch/${id}`,
        method: "GET",
      }),
    }),
    //
    getAdminTplTransactions: builder.query<
      TplTransactions[],
      { orderId: number }
    >({
      query: (props) => ({
        url: `admin/tpl/orders/${props.orderId}/payments`,
        method: "GET",
      }),
    }),
    //
    getAdminTplPaymentSchedule: builder.query<
      TplPaymentSchedule,
      { orderId: number }
    >({
      query: (props) => ({
        url: `admin/tpl/orders/${props.orderId}/scheduled-payments`,
        method: "GET",
      }),
    }),
    //
    getAdminPetPaymentSchedule: builder.query<
      TplPaymentSchedule,
      { orderId: number }
    >({
      query: (props) => ({
        url: `admin/pet/orders/${props.orderId}/scheduled-payments`,
        method: "GET",
      }),
    }),
    //
    getAdminPetTransactions: builder.query<
      TplTransactions[],
      { orderId: number }
    >({
      query: (props) => ({
        url: `admin/pet/orders/${props.orderId}/payments`,
        method: "GET",
      }),
    }),
    //
    getPetCategories: builder.query<PetCategory[], undefined>({
      query: (_noArg) => ({
        url: `admin/pet/categories`,
        method: "GET",
      }),
    }),
    //
    getPetBreeds: builder.query<PetBreed[], { query: string }>({
      query: ({ query }) => ({
        url: `admin/pet/breeds?${query}`,
        method: "GET",
      }),
    }),
    //
    updatePetBreeds: builder.mutation<Partial<PetBreed>, PetBreed>({
      query: (petBreeds) => ({
        url: `admin/pet/breeds`,
        method: "PUT",
        body: petBreeds,
      }),
    }),
    //
    getPetServices: builder.query<PetService[], undefined>({
      query: (_noArg) => ({
        url: `admin/pet/resources/services`,
        method: "GET",
      }),
    }),
    //
    createPetService: builder.mutation<PetService, Partial<PetService>>({
      query: (service) => ({
        url: `admin/pet/resources/services`,
        method: "POST",
        body: service,
      }),
    }),
    //
    updatePetService: builder.mutation<PetService, Partial<PetService>>({
      query: (service) => ({
        url: `admin/pet/resources/services/${service.id}`,
        method: "PUT",
        body: service,
      }),
    }),
    //
    deletePetService: builder.mutation<PetService, number>({
      query: (id) => ({
        url: `admin/pet/resources/services/${id}`,
        method: "DELETE",
      }),
    }),
    // ##################
    getPetServicesSecond: builder.query<PetService[], undefined>({
      query: (_noArg) => ({
        url: `admin/pet/resources/package-services`,
        method: "GET",
      }),
    }),
    //
    createPetServiceSecond: builder.mutation<PetService, Partial<PetService>>({
      query: (service) => ({
        url: `admin/pet/resources/package-services`,
        method: "POST",
        body: service,
      }),
    }),
    //
    updatePetServiceSecond: builder.mutation<PetService, Partial<PetService>>({
      query: (service) => ({
        url: `admin/pet/resources/package-services/${service.id}`,
        method: "PUT",
        body: service,
      }),
    }),
    //
    deletePetServiceSecond: builder.mutation<PetService, number>({
      query: (id) => ({
        url: `admin/pet/resources/package-services/${id}`,
        method: "DELETE",
      }),
    }),
    //
    getFraniPartners: builder.query<FraniPartnerType[], undefined>({
      query: (_noArg) => ({
        url: `admin/partners`,
        method: "GET",
      }),
    }),
    //
    // get critical limits
    getCriticalLimits: builder.query<TplLimitList[], { isActive?: string }>({
      query: (params) => ({
        url: params.isActive
          ? `admin/critical-limits?isActive=${params.isActive}`
          : `admin/critical-limits`,
        method: "GET",
      }),
    }),
    // add critical limit
    addCriticalLimits: builder.mutation<TplLimitList, { value: number }>({
      query: (value) => ({
        url: `admin/critical-limits`,
        method: "POST",
        body: value,
      }),
    }),
    // update critical limit status
    updateCriticalLimitStatus: builder.mutation<
      TplLimitList,
      Partial<TplLimitList>
    >({
      query: (updatedInfo) => ({
        url: `admin/critical-limits`,
        method: "PUT",
        body: updatedInfo,
      }),
    }),
    // remove limit
    removeCriticalLimit: builder.mutation<TplLimitList, number>({
      query: (id) => ({
        url: `admin/critical-limits/${id}`,
        method: "DELETE",
      }),
    }),
    // get critical currencies
    getCriticalCurrencies: builder.query<TplCurrencies[], undefined>({
      query: () => ({
        url: `admin/critical-currencies`,
        method: "GET",
      }),
    }),
    // add critical Currency
    addCriticalCurrency: builder.mutation<
      TplCurrencies,
      Partial<TplCurrencies>
    >({
      query: (currency) => ({
        url: `admin/critical-currencies`,
        method: "POST",
        body: currency,
      }),
    }),
    // update critical currency status
    updateCriticalCurrencyStatus: builder.mutation<
      TplCurrencies,
      Partial<TplCurrencies>
    >({
      query: (updatedInfo) => ({
        url: `admin/critical-currencies`,
        method: "PUT",
        body: updatedInfo,
      }),
    }),
    // remove currency
    removeCriticalCurrency: builder.mutation<TplCurrencies, number>({
      query: (id) => ({
        url: `admin/critical-currencies/${id}`,
        method: "DELETE",
      }),
    }),
    //
    getCriticalRisks: builder.query<PetService[], undefined>({
      query: (_noArg) => ({
        url: `admin/critical/resources/risks`,
        method: "GET",
      }),
    }),
    //
    createCriticalRisk: builder.mutation<PetService, Partial<PetService>>({
      query: (risk) => ({
        url: `admin/critical/resources/risks`,
        method: "POST",
        body: risk,
      }),
    }),
    //
    updateCriticalRisk: builder.mutation<PetService, Partial<PetService>>({
      query: (risk) => ({
        url: `admin/critical/resources/risks/${risk.id}`,
        method: "PUT",
        body: risk,
      }),
    }),
    //
    deleteCrtiticalRisk: builder.mutation<PetService, number>({
      query: (id) => ({
        url: `admin/critical/resources/risks/${id}`,
        method: "DELETE",
      }),
    }),
    getFraniPartnersByid: builder.query<FraniPartnerType, number>({
      query: (partnerId) => ({
        url: `admin/partners/${partnerId}`,
        method: "GET",
      }),
    }),
    //
    createPartner: builder.mutation<
      FraniPartnerType,
      Partial<FraniPartnerType>
    >({
      query: (partner) => ({
        url: `admin/partners`,
        method: "POST",
        body: partner,
      }),
    }),
    //
    updatePartner: builder.mutation<
      FraniPartnerType,
      Partial<FraniPartnerType>
    >({
      query: (partner) => ({
        url: `admin/partners/${partner.id}`,
        method: "PUT",
        body: partner,
      }),
    }),
    //
    deletePartner: builder.mutation<FraniPartnerType, number>({
      query: (partnerId) => ({
        url: `admin/partners/${partnerId}`,
        method: "DELETE",
      }),
    }),
    //
    getPromoCodesList: builder.query<PromoCode[], { query: string }>({
      query: ({ query }) => ({
        url: `admin/promo-codes?${query}`,
        method: "GET",
      }),
    }),
    //
    getPromoCodeById: builder.query<PromoCode, number | null>({
      query: (id) => ({
        url: `admin/promo-codes/${id}/detailed`,
        method: "GET",
      }),
    }),
    //
    deletePromoCode: builder.mutation<{ message?: string }, number>({
      query: (id) => ({
        url: `admin/promo-codes/${id}`,
        method: "DELETE",
      }),
    }),
    //
    createPromoCode: builder.mutation<PromoCode, Partial<PromoCode>>({
      query: (promo_code) => ({
        url: `admin/promo-codes`,
        method: "POST",
        body: promo_code,
      }),
    }),
    //
    updatePromoCode: builder.mutation<PromoCode, Partial<PromoCode>>({
      query: (promo_code) => ({
        url: `admin/promo-codes/${promo_code.id}`,
        method: "PUT",
        body: promo_code,
      }),
    }),
    //
    getWebUsersList: builder.query<WebUserList, { query: string }>({
      query: ({ query }) => ({
        url: `admin/users?${query}`,
        method: "GET",
      }),
    }),
    //
    getWebUserById: builder.query<WebUser[], number | null>({
      query: (id) => ({
        url: `admin/users/${id}`,
        method: "GET",
      }),
    }),
    //
    updateWebUser: builder.mutation<WebUser, Partial<WebUser>>({
      query: (web_user) => ({
        url: `admin/users/${web_user.id}`,
        method: "PUT",
        body: web_user,
      }),
    }),
    //
    updateWebUserPassword: builder.mutation<
      WebUser,
      { password: string; id: string }
    >({
      query: ({ password, id }) => ({
        url: `admin/users/change-password/${id}`,
        method: "PATCH",
        body: password,
      }),
    }),
    //
    deleteWebUser: builder.mutation<{ message?: string }, number>({
      query: (id) => ({
        url: `admin/users/${id}`,
        method: "DELETE",
      }),
    }),
    //
    getLandingSlides: builder.query<LandingSlideRoot, undefined>({
      query: (_noArg) => ({
        url: `admin/slides`,
        method: "GET",
      }),
    }),
    //
    createLandingSlide: builder.mutation<LandingSlide, Partial<LandingSlide>>({
      query: (slide) => ({
        url: `admin/slides`,
        method: "POST",
        body: slide,
      }),
    }),
    //
    updateLandingSlide: builder.mutation<LandingSlide, Partial<LandingSlide>>({
      query: (slide) => ({
        url: `admin/slides/${slide.id}`,
        method: "PUT",
        body: slide,
      }),
    }),
    //
    getLandingSlideById: builder.query<LandingSlide, { id: number }>({
      query: (props) => ({
        url: `admin/slides/${props.id}`,
        method: "GET",
      }),
    }),
    //
    deleteSlideById: builder.mutation<{ message?: string }, number>({
      query: (id) => ({
        url: `admin/slides/delete/${id}`,
        method: "DELETE",
      }),
    }),
    //
    getProductPackagesForPromoCode: builder.query<
      PromoCodePackage[],
      string | undefined
    >({
      query: (props) => ({
        url: `admin/product-categories/packages?${props}`,
        method: "GET",
      }),
    }),
    //
  }),
});

export default api;
