import { LoadingOutlined } from "@ant-design/icons";
import { Button, Flex, Result, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { AdminWebDocument, TranslationsField } from "../../../domain/types";
import { RootState, useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import { AuthState } from "../../../store/authSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
import Editor from "../../admin_components/admin_editor/Editor";
//
const Admin_web_documents = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // edited state
  const [editedDocument, setEditedDocument] =
    useState<Partial<AdminWebDocument> | null>(null);
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "terms-and-conditions",
      text: "წესები და პირობები",
      translations: {
        en: {
          text: "Terms and conditions",
        },
        ge: {
          text: "წესები და პირობები",
        },
      },
    },
    {
      id: 2,
      slug: "privacy",
      text: "კონფიდენციალურობა",
      translations: {
        en: {
          text: "Confidentiality",
        },
        ge: {
          text: "კონფიდენციალურობა",
        },
      },
    },
    {
      id: 3,
      slug: "consent",
      text: "თანხმობა",
      translations: {
        en: {
          text: "Consent",
        },
        ge: {
          text: "თანხმობა",
        },
      },
    },
  ];

  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  const {
    data: webDocuments,
    isLoading: isWebDocumentsLoading,
    refetch: refetchWebDocuments,
  } = api.useGetAdminWebDocumentsQuery(selectedSlug as string, {
    skip: !selectedSlug && true,
  });

  // add travel addon
  const [storeDocument, { isLoading: isStoreDocumentLoading }] =
    api.useStoreWebDocumentMutation();

  // store web document function
  const StoreWebDocumentFun = async () => {
    try {
      await storeDocument(editedDocument as Partial<AdminWebDocument>).unwrap();
      // refetch travel Addons
      refetchWebDocuments();
      //
      toast.success(`${t("document")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // existing document
  useEffect(() => {
    if (webDocuments && selectedSlug && webDocuments.slug === selectedSlug) {
      setEditedDocument(webDocuments);
    }
  }, [webDocuments, selectedSlug]);
  //
  const mainLoading = isWebDocumentsLoading || isStoreDocumentLoading;
  //
  return (
    <>
      {auth && auth.user?.userType !== "Frani-Admin" ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Result
            title="თქვენ არ გაქვთ წვდომა მიმდინარე გვერდზე !!!"
            extra={
              <Button type="primary" size="large" onClick={() => navigate("/")}>
                {t("homePage")}
              </Button>
            }
          />
        </Flex>
      ) : (
        <>
          <Flex justify="space-between" align="center" gap={20}>
            <Select
              size="large"
              style={{ width: "100%" }}
              placeholder={t("chooseDocument")}
              defaultValue={
                pageSlugs.find((x) => x.slug === selectedSlug)?.text || null
              }
              onChange={(value) => [
                setEditedDocument(null),
                setSelectedSlug(value),
              ]}
              options={[
                ...pageSlugs?.map((item) => ({
                  value: item.slug,
                  label: item.translations?.[lang]?.text,
                })),
              ]}
            />

            <AdminLangSwicher />
          </Flex>

          {mainLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
          ) : (
            <Flex vertical gap={20} style={{ marginTop: "20px" }}>
              {webDocuments && (
                <>
                  <Editor
                    value={editedDocument?.translations?.[field_Language]?.body}
                    onChange={(value) => {
                      if (webDocuments && editedDocument) {
                        setEditedDocument({
                          ...webDocuments,
                          translations: {
                            ...webDocuments.translations,
                            [field_Language]: {
                              ...webDocuments.translations?.[field_Language],
                              body: value,
                            },
                          },
                        });
                      }
                    }}
                  />

                  <Button
                    type="primary"
                    size="large"
                    block
                    onClick={StoreWebDocumentFun}
                  >
                    {t("submit")}
                  </Button>
                </>
              )}
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default Admin_web_documents;
