import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import { EnabledLanguage, FraniPartnerType } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import Editor from "../../admin_components/admin_editor/Editor";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
const { Text } = Typography;

type TranslationFields = {
  title: string;
  body: string;
  secondBody: string;
  thirdBody: string;
};

const AdminPartners = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [partnerId, setPartnerId] = useState<number | undefined>(undefined);
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [existingPartner, setExistingPartner] = useState<
    Partial<FraniPartnerType>
  >({});

  const { data: franiPartner, isLoading: isFraniPartnerLoading } =
    api.useGetFraniPartnersByidQuery(partnerId as number, {
      skip: !partnerId,
    });

  const [createPartner, { isLoading: isCreatePartnerLoading }] =
    api.useCreatePartnerMutation();

  const [updatePartner, { isLoading: isUpdatePartnerLoading }] =
    api.useUpdatePartnerMutation();

  const [deletePartner, { isLoading: isDeletePartnerLoading }] =
    api.useDeletePartnerMutation();

  const setPartnerField = (
    field: keyof Partial<FraniPartnerType>,
    value: any
  ) => {
    setExistingPartner({
      ...existingPartner,
      [field]: value,
    });
  };

  const setFraniTranslationField = (
    field: keyof TranslationFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingPartner({
      ...existingPartner,
      translations: {
        ...existingPartner.translations,
        [language]: {
          ...existingPartner?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const _createPartner = async () => {
    try {
      await createPartner(existingPartner).unwrap();
      toast.success(`${t("partnerCompany")} ${t("addedSuccessfully")}`);
      isFraniPartnersRefetch();
      onCloseDrawer();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const _updatePartner = async () => {
    try {
      await updatePartner(existingPartner).unwrap();
      toast.success(`${t("partnerCompany")} ${t("updatedSuccessfully")}`);
      isFraniPartnersRefetch();
      onCloseDrawer();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const _deletePartner = async () => {
    try {
      await deletePartner(partnerId as number).unwrap();
      toast.success(`${t("partnerCompany")} ${t("deletedSuccessfully")}`);
      isFraniPartnersRefetch();
      onCloseDrawer();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  //close base drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setExistingPartner({});
    setPartnerId(undefined);
  };

  useEffect(() => {
    // set existing partner
    if (partnerId && franiPartner) {
      setExistingPartner(franiPartner);
    }
    return () => {};
  }, [partnerId, franiPartner]);

  const {
    data: franiPartners,
    isLoading: isFraniPartnersLoading,
    refetch: isFraniPartnersRefetch,
  } = api.useGetFraniPartnersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const MainLoader = isFraniPartnersLoading || isDeletePartnerLoading;

  const drawerLoading =
    isCreatePartnerLoading ||
    isFraniPartnerLoading ||
    isUpdatePartnerLoading ||
    isDeletePartnerLoading;

  return MainLoader ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Button
          size="large"
          type="primary"
          onClick={() => [
            setDrawerStatus(!drawerStatus),
            setExistingPartner({}),
          ]}
        >
          {t("create")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider></Divider>

      <GridMinMax align="stretch">
        {franiPartners?.map((item, index) => (
          <Card
            style={{ maxWidth: "400px" }}
            hoverable
            key={index}
            onClick={() => [
              setDrawerStatus(!drawerStatus),
              setPartnerId(item?.id),
            ]}
            cover={
              <img
                style={{ height: "200px", objectFit: "cover" }}
                alt={item?.imageUrl}
                src={item?.imageUrl}
              />
            }
          >
            <Meta
              title={item?.translations?.[lang]?.title}
              description={
                <Flex vertical gap={5}>
                  <Text>{item?.translations?.[field_Language]?.title}</Text>
                </Flex>
              }
            />
          </Card>
        ))}
      </GridMinMax>

      <Drawer
        extra={<AdminLangSwicher />}
        title={partnerId ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
        width={600}
        loading={drawerLoading}
      >
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <Input
              size="large"
              placeholder={t("title")}
              value={
                existingPartner?.translations?.[field_Language]?.title || ""
              }
              onChange={(event) =>
                setFraniTranslationField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("siteAddress")}</Text>
            <Input
              size="large"
              placeholder={t("siteAddress")}
              value={existingPartner?.webAddress || ""}
              onChange={(event) =>
                setPartnerField("webAddress", event?.target?.value)
              }
            />
          </Space>

          <UploadFile
            label={t("imageIcon")}
            onChange={(props) => setPartnerField("imageUrl", props.url)}
            defaultFileName={existingPartner?.imageUrl || ""}
          />

          <Editor
            label={t("companyBriefDescription")}
            value={existingPartner.translations?.[field_Language]?.body}
            onChange={(value) => {
              if (existingPartner) {
                setFraniTranslationField("body", field_Language, value);
              }
            }}
          />

          <Editor
            label={t("companyDescription")}
            value={existingPartner.translations?.[field_Language]?.secondBody}
            onChange={(value) => {
              if (existingPartner) {
                setFraniTranslationField("secondBody", field_Language, value);
              }
            }}
          />

          <Divider />

          <Button
            size="large"
            type="primary"
            onClick={() => {
              if (partnerId) {
                _updatePartner();
              } else {
                _createPartner();
              }
            }}
          >
            {partnerId ? t("update") : t("create")}
          </Button>

          {partnerId && (
            <Popconfirm
              title={t("certainToDeleteIt")}
              onConfirm={() => _deletePartner()}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              placement="bottom"
            >
              <Button
                danger
                size="large"
                style={{
                  width: "100%",
                }}
              >
                {t("delete")}
              </Button>
            </Popconfirm>
          )}
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminPartners;
