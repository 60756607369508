import { Card, Divider, Flex, Select, Space, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { TranslationsField } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const Admin_tpl_manage_pages = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useAppSelector((root) => root.app);
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // page slugs
  const pageSlugs: {
    id: number;
    slug: string;
    text: string;
    translations: TranslationsField<{ text: string }>;
  }[] = [
    {
      id: 1,
      slug: "tpl-or-local-tpl",
      text: "დაზღვევის ტიპი",
      translations: {
        en: {
          text: "Insurance type",
        },
        ge: {
          text: "დაზღვევის ტიპი",
        },
      },
    },
    {
      id: 2,
      slug: "tpl-insurance-period",
      text: "სადაზღვევო პერიოდი",
      translations: {
        en: {
          text: "Insurance period",
        },
        ge: {
          text: "სადაზღვევო პერიოდი",
        },
      },
    },
    {
      id: 3,
      slug: "tpl-currencies",
      text: "ვალუტა/ლიმიტი",
      translations: {
        en: {
          text: "Currency / Limit",
        },
        ge: {
          text: "ვალუტა/ლიმიტი",
        },
      },
    },
    {
      id: 4,
      slug: "tpl-extra-service",
      text: "სერვისები",
      translations: {
        en: {
          text: "Services",
        },
        ge: {
          text: "სერვისები",
        },
      },
    },
    {
      id: 5,
      slug: "tpl-choise",
      text: "შეთავაზების ტიპი",
      translations: {
        en: {
          text: "Offer type",
        },
        ge: {
          text: "შეთავაზების ტიპი",
        },
      },
    },
    {
      id: 6,
      slug: "tpl-offer",
      text: "შეთავაზებები",
      translations: {
        en: {
          text: "Offers",
        },
        ge: {
          text: "შეთავაზებები",
        },
      },
    },
    {
      id: 7,
      slug: "tpl-best-offer",
      text: "საუკეთესო შეთავაზება",
      translations: {
        en: {
          text: "Best offer",
        },
        ge: {
          text: "საუკეთესო შეთავაზება",
        },
      },
    },
    {
      id: 8,
      slug: "tpl-insure-choice",
      text: "ვისთვის ყიდულობ დაზღვევას",
      translations: {
        en: {
          text: "Who do you buy insurance for",
        },
        ge: {
          text: "ვისთვის ყიდულობ დაზღვევას",
        },
      },
    },
    {
      id: 9,
      slug: "tpl-personal-info",
      text: "დამზღვევის ინფორმაცია",
      translations: {
        en: {
          text: "Insurer's information",
        },
        ge: {
          text: "დამზღვევის ინფორმაცია",
        },
      },
    },
    {
      id: 10,
      slug: "tpl-insured-persons-info",
      text: "დაზღვეულის ინფორმაცია",
      translations: {
        en: {
          text: "Insured's information",
        },
        ge: {
          text: "დაზღვეულის ინფორმაცია",
        },
      },
    },
    {
      id: 11,
      slug: "tpl-car-info",
      text: "ავტოტრანსპორტის ინფორმაცია",
      translations: {
        en: {
          text: "Vehicle information",
        },
        ge: {
          text: "ავტოტრანსპორტის ინფორმაცია",
        },
      },
    },
    {
      id: 12,
      slug: "tpl-check-info",
      text: "ინფორმაციის გადამოწმება",
      translations: {
        en: {
          text: "Check information",
        },
        ge: {
          text: "ინფორმაციის გადამოწმება",
        },
      },
    },
    {
      id: 13,
      slug: "tpl-contact-info",
      text: "საკონტაქტო ინფორმაცია",
      translations: {
        en: {
          text: "Contact info",
        },
        ge: {
          text: "საკონტაქტო ინფორმაცია",
        },
      },
    },
  ];

  // selected slug
  const [selectedSlug, setSelectedSlug] = useState<string | null>(null);

  // get tpl flow
  const { data: pageTextArry, isLoading: isPageTextArrayLoading } =
    api.useGetTplFlowPageTextsBySlugQuery(selectedSlug as string, {
      skip: !selectedSlug && true,
    });

  const mainLoading = isPageTextArrayLoading;

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex gap={20} justify="space-between" align="flex-end">
        <Space direction="vertical" style={{ width: "100%" }}>
          <Text>{t("choosePage")}</Text>
          <Select
            size="large"
            style={{
              width: "100%",
            }}
            placeholder={t("choosePage")}
            value={selectedSlug || undefined}
            onChange={(value) => setSelectedSlug(value)}
            options={[
              ...pageSlugs.map((item) => ({
                value: item.slug,
                label: item?.translations?.[lang]?.text,
              })),
            ]}
          />
        </Space>

        <AdminLangSwicher />
      </Flex>

      {pageTextArry && (
        <>
          <Divider />

          <GridMinMax align="stretch">
            {pageTextArry?.translations && (
              <Card
                hoverable
                onClick={() =>
                  navigate(`/manage-tpl-pages-edit/${selectedSlug}`)
                }
                style={{ cursor: "pointer" }}
              >
                {pageTextArry?.translations?.[field_Language]?.title && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("title")}</Text>
                    <Text>
                      {pageTextArry?.translations?.[field_Language]?.title}
                    </Text>
                  </Space>
                )}

                <Divider variant="dashed" />

                {pageTextArry?.translations?.[field_Language]?.stepName && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("stepName")}</Text>
                    <Text>
                      {pageTextArry?.translations?.[field_Language]?.stepName}
                    </Text>
                  </Space>
                )}
              </Card>
            )}

            {pageTextArry?.listItems?.map((item, index) => (
              <Card
                hoverable
                key={index}
                onClick={() =>
                  navigate(`/manage-tpl-pages-edit/${selectedSlug}/${item?.id}`)
                }
              >
                {item?.translations?.[field_Language]?.title && (
                  <Space direction="vertical">
                    <Text type="secondary">{t("title")}</Text>
                    <Text>{item?.translations?.[field_Language]?.title}</Text>
                  </Space>
                )}

                <Divider variant="dashed" />

                {item?.translations?.[field_Language]?.body && (
                  <>
                    <Space direction="vertical">
                      <Text type="secondary">{t("body")}</Text>
                      <Text>{item?.translations?.[field_Language]?.body}</Text>
                    </Space>
                  </>
                )}
              </Card>
            ))}
          </GridMinMax>
        </>
      )}
    </>
  );
};

export default Admin_tpl_manage_pages;
