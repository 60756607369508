import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../api";
import Responsive from "../../assets/config/Responsive";
import Colors from "../../assets/styles/Colors";
import Loader from "../../components/Loader";
import { AdminSubMenu } from "../../domain/types";
import { RootState, useAppSelector } from "../../store";
import { AuthState } from "../../store/authSlice";
import { MenuList } from "./nav/AdminMenu";

const Admin_dashboard: React.FC = () => {
  //
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  // user current company
  const {
    data: AdminCurrentUserCompany,
    isLoading: isAdminCurrentUserCompanyLoading,
  } = api.useGetAdminCurrentUserCompanyQuery(undefined);
  //
  const navigate = useNavigate();
  // account innner menu
  const [openInnerMenu, setOpenInnerMenu] = useState<boolean>(false);
  // active menu item id
  const [activeMenuId, setActiveMenuId] = useState<number | null>(null);
  //
  const currentUerType = auth?.user?.userType;
  //
  const currentUerRoles = auth?.user?.companyUsers?.map((x) => x.role);
  //
  const allRols = currentUerRoles
    ? [...currentUerRoles, currentUerType]
    : [currentUerType];
  //
  const { lang } = useAppSelector((root) => root.app);
  //
  return (
    <>
      {isAdminCurrentUserCompanyLoading ? (
        <Loader />
      ) : (
        <>
          {AdminCurrentUserCompany && AdminCurrentUserCompany.id && (
            <CompanyBanner
            // onClick={() => navigate(`/company-detail/${AdminCurrentUserCompany.id}`)}
            >
              <div>
                <img
                  src={AdminCurrentUserCompany?.cover}
                  alt={AdminCurrentUserCompany.title + " - ქოვერი"}
                />
              </div>
              <div>
                <h1>{AdminCurrentUserCompany?.title} </h1>
                <p>{AdminCurrentUserCompany?.excerpt}</p>
              </div>
              <span
                style={{
                  backgroundColor:
                    AdminCurrentUserCompany?.status === "active" ||
                    AdminCurrentUserCompany?.status === "published"
                      ? Colors.java
                      : Colors.primaryRed,
                }}
              >
                <small>{AdminCurrentUserCompany?.status}</small>
              </span>
            </CompanyBanner>
          )}
        </>
      )}

      {/*  */}
      <br />
      {/*  */}
      <MenuBlock>
        {MenuList &&
          MenuList.map((item, index) => (
            <div key={index}>
              {item.hide === true &&
              (auth.user?.companyUsers[0]?.role || auth.user?.userType) !==
                "Frani-Admin" ? null : (
                <MenuItem
                  onClick={() => {
                    if (item.sub_menu.length >= 1) {
                      setActiveMenuId(item.id);
                      setOpenInnerMenu(!openInnerMenu);
                    }
                    //
                    if (item.sub_menu.length === 0) {
                      navigate(`${item.url}`);
                    }
                    //
                    if (item.sub_menu.length === 0) {
                      setActiveMenuId(null);
                      setOpenInnerMenu(false);
                    }
                  }}
                  //
                >
                  <span>{item.translations[lang]?.title}</span>

                  {openInnerMenu && item.id === activeMenuId && (
                    <InnerMenuContent>
                      <SideBarUl>
                        {MenuList.find(
                          (x) => x.id === activeMenuId
                        )?.sub_menu?.map(
                          (sub_item: AdminSubMenu, sub_index: number) => {
                            //
                            const filteredRols = sub_item?.permissions?.filter(
                              function (accepted_role) {
                                return (
                                  allRols?.filter(function (
                                    current_user_roles
                                  ) {
                                    return current_user_roles === accepted_role;
                                  }).length !== 0
                                );
                              }
                            );
                            //
                            return (
                              <div key={sub_index}>
                                {filteredRols?.length === 0 ? null : (
                                  <div>
                                    <SideBarLi
                                      onClick={() => [
                                        navigate(`${sub_item.url}`),
                                        setOpenInnerMenu(!openInnerMenu),
                                      ]}
                                    >
                                      <span>
                                        {sub_item.translations[lang].title}
                                      </span>
                                    </SideBarLi>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        )}
                      </SideBarUl>
                    </InnerMenuContent>
                  )}
                </MenuItem>
              )}
            </div>
          ))}
      </MenuBlock>
    </>
  );
};
//
//
// styles
const SideBarUl = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  width: 100%;
  text-align: center;
`;
const SideBarLi = styled.li`
  margin-block: 5px;
  padding-block: 5px;
  cursor: pointer;
  color: ${Colors.primaryGrey};

  /* side bar li text */
  span {
    font-size: 14px;
    line-height: 16px;
  }

  /* hover */
  &:hover {
    color: ${Colors.primaryBlack};
  }
`;
const InnerMenuContent = styled.div`
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: ${Colors.backgroundGrey};
  padding-block: 10px;
  border-radius: 6px;
  min-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
`;
const MenuItem = styled.div`
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: all 200ms ease;
  display: grid;
  place-items: center;
  min-height: 100px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;
  position: relative;
`;
const MenuBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;

  ${Responsive.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;
const CompanyBanner = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
  padding: 10px;
  margin-block: 10px;
  /*  */
  span {
    transition: all 100ms ease;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 7px;
    width: 7px;
    border-radius: 50%;
    user-select: none;
    pointer-events: none;
    display: inline-block;
    /*  */
    small {
      display: none;
    }
  }
  /*  */
  /*  */
  div {
    flex-basis: 50%;
    /*  */
    &:first-child {
      display: grid;
      place-items: center;
    }
    /*  */
  }
  /*  */
  img {
    width: 50%;
    user-select: none;
    pointer-events: none;
  }
  /*  */
  h1 {
    font-size: 20px;
  }
  /*  */
  p {
    font-size: 16px;
    line-height: 18px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*  */
  &:hover {
    span {
      transition: all 100ms ease;
      height: fit-content;
      width: fit-content;
      border-radius: 4px;
      padding: 3px 6px;
      font-size: 14px;
      text-transform: capitalize;
      display: grid;
      place-items: center;
      color: ${Colors.white};
      /*  */
      small {
        display: inline-block;
      }
    }
  }
  /* responsive */
  ${Responsive.tablet} {
    padding-inline: 10px;
  }
  ${Responsive.mobile} {
    padding-inline: 10px;
    /*  */
    h1 {
      font-size: 16px;
    }
    /*  */
    p {
      font-size: 13px;
    }
  }
`;

export default Admin_dashboard;
