import { Flex } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const AdminTplExceptionCars = () => {
  return (
    <Flex justify="center" align="center">
      <Title type="danger" level={3}>
        Temporarily unavailable
      </Title>
    </Flex>
  );
};

export default AdminTplExceptionCars;
