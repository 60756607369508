import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Currency,
  EnabledLanguage,
  Product,
  WelcomerProduct,
  WordingFileType,
} from "../../../../../domain/types";
import ScreenHeader from "../../../../../components/ScreenHeader";
import AdminLangSwicher from "../../../../admin_components/AdminLangSwicher";
import { Box } from "@mui/material";
import styled from "styled-components";
import Colors from "../../../../../assets/styles/Colors";
import Responsive from "../../../../../assets/config/Responsive";
import EditWelcomerProductFieldsTab from "./EditWelcomerProductFieldsTab";
import EditWelcomerPackagesTab from "./EditWelcomerPackagesTab";
import api from "../../../../../api";
import EditWelcomerDocumentsTab from "./EditWelcomerDocumentsTab";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { AppState, setEditedlanguage } from "../../../../../store/appSlice";
import lodash from "lodash";
import { toast } from "react-toastify";
import EditWelcomerPricesTab from "./EditWelcomerPricesTab";
import { Container } from "../../../../../assets/styles/GlobalStyle";

//
type ProductTabData = {
  welcomerProduct: WelcomerProduct;
};
//
const AdminWelcomerProductEdit = () => {
  //
  const { welcomerProductId: str = "0" } = useParams();
  const welcomerProductId = parseInt(str);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { t } = useTranslation();
  //
  const dispatch = useAppDispatch();
  //
  const [activeTab, setActiveTab] = useState("1");
  //
  //
  const [productFieldsState, setProductFieldsState] = useState<
    ProductTabData | undefined
  >();
  //
  // product list
  const {
    data: existingWelcomerProduct,
    isLoading: isExistingWelcomerProductLoading,
    refetch: refetchExistingWelcomerProduct,
  } = api.useGetWelcomerProductByIdQuery(welcomerProductId);
  // welcomer product tab one fields store or save
  const [
    m_welcomerProductTabOneProductFields,
    { isLoading: isSaveWelcomerProductFieldsLoading },
  ] = api.useStoreWelcomerProductTabOneProductFieldsMutation();
  // save welcomer age groups
  const [saveWelcomerAgeGroups, { isLoading: isSaveWelcomerAgeGroupsLoading }] =
    api.useStoreWelcomerAgeGroupsMutation();
  // save welcomer day group
  const [saveWelcomerDayGroups, { isLoading: isSaveWelcomerDayGroupsLoading }] =
    api.useStoreWelcomerDayGroupsMutation();
  //
  // get welcomer package prices
  const {
    data: welcomerPackagePrices,
    isLoading: isWelcomerPackagePricesLoading,
    refetch: RefetchWelcomerPackagePrice,
  } = api.useGetWelcomerPackagePricesQuery(
    existingWelcomerProduct?.mainProduct?.id as number,
    {
      skip: existingWelcomerProduct?.mainProduct?.id ? false : true,
    }
  );

  // save products document
  const [
    m_saveProductDocumentFields,
    { isLoading: isSaveProductDocumentFieldsLoading },
  ] = api.useUpdateProductDocumentsMutation();
  // [admin]: approve product changes
  const [m_activateProduct, { isLoading: isActivateProductLoading }] =
    api.useAdminActivateProductMutation();
  // [admin]: reject product changes
  const [m_deactivateProduct, { isLoading: isDeactivateProductLoading }] =
    api.useAdminDeactivateProductMutation();
  // delete welcomer age group by id
  const [
    deleteWelcomerAgeGroups,
    {
      isLoading: isDeleteWelcomerAgeGroupsLoading,
      isSuccess: deleteWelcomerAgeGroupSuccess,
    },
  ] = api.useDeleteWelcomerAgegroupByIdMutation();
  //
  // delete welcomer day group by id
  const [
    deleteWelcomerDayGroups,
    {
      isLoading: isDeleteWelcomerDayGroupsLoading,
      isSuccess: deleteWelcomerDayGroupSuccess,
    },
  ] = api.useDeleteWelcomerDaygroupByIdMutation();
  //
  // welcomer franchise
  const { data: welcomerFranchise = [], isLoading: isFranchiseListLoading } =
    api.useGetAdminWelcomerFranchiseListQuery("welcomer-franchise");
  //
  // welcomer risks
  const { data: welcomerRisks = [], isLoading: isWelcomerRisksLoading } =
    api.useGetAdminWelcomerRiskListQuery(undefined);
  //
  // welcomer addons
  const { data: welcomerAddons = [], isLoading: isWelcomerAddonsLoading } =
    api.useGetAdminWelcomerAddonListQuery(undefined);
  // welcomer limits
  const { data: welcomerLimits, isLoading: isWelcomerLimitsLoading } =
    api.useGetWelcomerLimitsQuery({ isActive: "true" });
  // welcomer currencies
  const { data: welcomerCurrencies, isLoading: isWelcomerCurrenciesLoading } =
    api.useGetWelcomerCurrenciesQuery(undefined);

  const [perWelcomerPackage_usedCurrencies, only_setUsedCurrencies] = useState<
    Array<{ welcomerPackageId: number; values: Currency[] }>
  >([]);

  const [perWelcomerPackage_usedFranchises, only_setUsedFranchises] = useState<
    Array<{ welcomerPackageId: number; values: number[] }>
  >([]);
  const setUsedCurrencies = (welcomerPackageId: number, values: Currency[]) => {
    const listWithout = perWelcomerPackage_usedCurrencies.filter(
      (x) => x.welcomerPackageId !== welcomerPackageId
    );
    const listWith = [...listWithout, { welcomerPackageId, values: values }];
    only_setUsedCurrencies(listWith);
  };
  const setUsedFranchises = (welcomerPackageId: number, values: number[]) => {
    const listWithout = perWelcomerPackage_usedFranchises.filter(
      (x) => x.welcomerPackageId !== welcomerPackageId
    );
    const listWith = [...listWithout, { welcomerPackageId, values: values }];
    only_setUsedFranchises(listWith);
  };
  //
  //
  const saveWelcomerAgeGroupsFun = async () => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing Product Data");
      }

      const AgeGroupsToSave =
        productFieldsState.welcomerProduct.welcomerAgeGroup;

      await saveWelcomerAgeGroups({
        welcomerProductId: welcomerProductId,
        data: AgeGroupsToSave,
      });
      toast.success(`${t("ageGroups")} ${t("savedSuccessfully")}`);
      //
      //
      refetchExistingWelcomerProduct();
      RefetchWelcomerPackagePrice();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  const saveWelcomerDayGroupsFun = async () => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing Product Data");
      }

      const DayGroupsToSave =
        productFieldsState.welcomerProduct.welcomerDayGroup;

      await saveWelcomerDayGroups({
        welcomerProductId: welcomerProductId,
        data: DayGroupsToSave,
      });
      toast.success(`${t("daysRange")} ${t("savedSuccessfully")}`);
      //
      //
      refetchExistingWelcomerProduct();
      RefetchWelcomerPackagePrice();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  //
  const setMainProductField = (
    fieldName: keyof ProductTabData["welcomerProduct"]["mainProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setProductFieldsState({
      ...productFieldsState,
      welcomerProduct: {
        ...productFieldsState.welcomerProduct,
        mainProduct: {
          ...productFieldsState.welcomerProduct.mainProduct,
          [fieldName]: fieldValue,
        },
      },
    });
  };
  //
  const setWelcomerProductField = (
    fieldName: keyof ProductTabData["welcomerProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    const updatedProductFields: WelcomerProduct = {
      ...productFieldsState.welcomerProduct,
      [fieldName]: fieldValue,
    };
    let productWithRegeneratedPriceCombinations: WelcomerProduct;

    //change
    try {
      productWithRegeneratedPriceCombinations = updatedProductFields;
    } catch (e) {
      productWithRegeneratedPriceCombinations = updatedProductFields;
    }
    setProductFieldsState({
      ...productFieldsState,
      welcomerProduct: productWithRegeneratedPriceCombinations,
    });
  };

  //aa
  //
  const setTranslatedFieldOnProduct = (
    field: keyof Product["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setWelcomerProductField("mainProduct", {
      ...productFieldsState.welcomerProduct.mainProduct,
      translations: {
        ...productFieldsState.welcomerProduct.mainProduct.translations,
        [language]: {
          ...productFieldsState.welcomerProduct.mainProduct?.translations?.[
            language
          ],
          [field]: value,
        },
      },
    });
  };
  //
  //
  //
  const allCurrenciesOfWelcomerProduct = (
    welcomerProduct: WelcomerProduct
  ): Currency[] => {
    return lodash.uniq(
      welcomerProduct?.mainProduct?.packages
        ?.map((pkg) =>
          pkg?.compensationCurrencies.map(
            (currencyHolder) => currencyHolder.currency
          )
        )
        .reduce((carry, next) => (carry ?? []).concat(next ?? []), [])
    ) as Currency[];
  };
  //
  //
  const allFranchisesOfWelcomerProduct = (
    welcomerProduct: WelcomerProduct
  ): number[] => {
    return lodash.uniq(
      welcomerProduct?.mainProduct?.packages
        ?.map((pkg) => pkg?.franchises)
        .reduce((carry, next) => (carry ?? []).concat(next ?? []), [])
    );
  };
  //
  const storeWelcomerProductGeneralFields = async () => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing product data");
      }

      let policyRanges =
        productFieldsState?.welcomerProduct.mainProduct.policyNumberRanges ||
        [];

      if (
        policyRanges.some(
          (p) =>
            hasNonNumericCharacters(p.minPolicyNumber) ||
            hasNonNumericCharacters(p.maxPolicyNumber)
        )
      ) {
        toast.error(`${t("incorrectPolicyFormat")}`);
        return;
      }

      const welcomerProductToSave = productFieldsState.welcomerProduct;

      await m_welcomerProductTabOneProductFields(
        welcomerProductToSave
      ).unwrap();
      toast.success(`${t("product")} ${t("savedSuccessfully")}`);

      refetchExistingWelcomerProduct();
      RefetchWelcomerPackagePrice();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  const hasNonNumericCharacters = (input: string) => /[^0-9]/.test(input);

  //
  const approveProductChanges = async () => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing product data");
      }
      if (
        productFieldsState.welcomerProduct.mainProduct.status === "published"
      ) {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save main fields
      await storeWelcomerProductGeneralFields();
      // third, activate the product
      await m_activateProduct(
        productFieldsState.welcomerProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t("product")} ${t("publishedSuccessfully")}`);
      //
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      refetchExistingWelcomerProduct();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  const rejectProductChanges = async () => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing product data");
      }
      if (
        productFieldsState.welcomerProduct.mainProduct.status === "published"
      ) {
        // in this case, admin is converting a published product to draft (probably just auditing existing published products)
      }
      await m_deactivateProduct(
        productFieldsState.welcomerProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t("product")} ${t("deactivated")}`);
      //
      // refetch travel products
      // WARNING: this resets product fields. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      refetchExistingWelcomerProduct();
    } catch (error) {
      // console.log("reject product changes error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  // save uploaded documents
  const storeProductDocumentFields = async (wordingFiles: WordingFileType) => {
    try {
      if (!productFieldsState?.welcomerProduct?.id) {
        throw new Error("Missing product data");
      }
      await m_saveProductDocumentFields({
        ...productFieldsState.welcomerProduct.mainProduct,
        wordingFiles: wordingFiles,
      }).unwrap();
      toast.success(`${t("uploadedSuccessfully")}`);
      //
      // refetch travel products
      refetchExistingWelcomerProduct();
    } catch (error) {
      // console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  const isFullPageLoading =
    isExistingWelcomerProductLoading || !productFieldsState;
  //
  const isProductFieldsTabLoading =
    isFullPageLoading ||
    isActivateProductLoading ||
    isDeactivateProductLoading ||
    isSaveWelcomerAgeGroupsLoading ||
    isDeleteWelcomerAgeGroupsLoading ||
    isSaveWelcomerDayGroupsLoading ||
    isDeleteWelcomerDayGroupsLoading ||
    isSaveWelcomerProductFieldsLoading;
  //
  const isPackagesTabLoading =
    isFullPageLoading ||
    isFranchiseListLoading ||
    isWelcomerRisksLoading ||
    isWelcomerLimitsLoading ||
    isWelcomerCurrenciesLoading ||
    isWelcomerAddonsLoading;
  //
  const isPDFTabLoading =
    isFullPageLoading || isSaveProductDocumentFieldsLoading;
  //
  //
  // onload existing travel product
  useEffect(() => {
    if (existingWelcomerProduct) {
      setProductFieldsState({
        welcomerProduct: existingWelcomerProduct,
      });
      //
      for (let welcomerPackageId of existingWelcomerProduct.mainProduct.packages.map(
        (pkg) => pkg.targetId
      )) {
        setUsedCurrencies(
          welcomerPackageId,
          allCurrenciesOfWelcomerProduct(existingWelcomerProduct)
        );
        setUsedFranchises(
          welcomerPackageId,
          allFranchisesOfWelcomerProduct(existingWelcomerProduct)
        );
      }
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingWelcomerProduct]);
  //
  //
  useEffect(() => {
    if (deleteWelcomerAgeGroupSuccess) {
      refetchExistingWelcomerProduct();
      RefetchWelcomerPackagePrice();
    }
  }, [
    deleteWelcomerAgeGroupSuccess,
    refetchExistingWelcomerProduct,
    RefetchWelcomerPackagePrice,
  ]);
  //
  useEffect(() => {
    if (deleteWelcomerDayGroupSuccess) {
      refetchExistingWelcomerProduct();
      RefetchWelcomerPackagePrice();
    }
  }, [
    deleteWelcomerDayGroupSuccess,
    refetchExistingWelcomerProduct,
    RefetchWelcomerPackagePrice,
  ]);
  //
  return (
    <div style={{ paddingBottom: "50px" }}>
      <ScreenHeader
        text={t("products")}
        url="/products"
        onClick={() => (document.location.href = "/products")}
        toolbar={
          <AdminLangSwicher
            onChange={(value) => dispatch(setEditedlanguage(value))}
          />
        }
      />
      <Container>
        <MuiBox>
          <Box>
            <MuiTabList>
              <MuiTab
                onClick={() => setActiveTab("1")}
                $active={activeTab === "1"}
              >
                {t("productFeatures")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("2")}
                $active={activeTab === "2"}
              >
                {t("packages")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("3")}
                $active={activeTab === "3"}
              >
                {t("documents")}
              </MuiTab>
              {productFieldsState &&
              productFieldsState.welcomerProduct &&
              productFieldsState.welcomerProduct.mainProduct &&
              productFieldsState.welcomerProduct.mainProduct.integrated &&
              productFieldsState.welcomerProduct.mainProduct.integrated ===
                true ? null : (
                <MuiTab
                  onClick={() => setActiveTab("4")}
                  $active={activeTab === "4"}
                >
                  {t("prices")}
                </MuiTab>
              )}
            </MuiTabList>
          </Box>
          {/*  */}
          {/*  */}
          {/* value = 1 */}
          <MuiTabPanel style={activeTab === "1" ? {} : { display: "none" }}>
            {/*პროდუქტის მახასიათებლები */}
            <EditWelcomerProductFieldsTab
              welcomerProduct={productFieldsState?.welcomerProduct}
              language={field_Language}
              isLoading={isProductFieldsTabLoading}
              setWelcomerProductField={setWelcomerProductField}
              setTranslatedFieldOnProduct={setTranslatedFieldOnProduct}
              setMainProductField={setMainProductField}
              saveTab={storeWelcomerProductGeneralFields}
              approveProductChanges={approveProductChanges}
              rejectProductChanges={rejectProductChanges}
              saveWelcomerAgeGroupsFun={saveWelcomerAgeGroupsFun}
              deleteWelcomerAgeGroups={deleteWelcomerAgeGroups}
              saveWelcomerDayGroupsFun={saveWelcomerDayGroupsFun}
              deleteWelcomerDayGroups={deleteWelcomerDayGroups}
            />
          </MuiTabPanel>

          {/* value="2" */}
          <MuiTabPanel style={activeTab === "2" ? {} : { display: "none" }}>
            {/* პაკეტები */}
            <EditWelcomerPackagesTab
              welcomerProduct={productFieldsState?.welcomerProduct}
              welcomerFranchise={welcomerFranchise}
              welcomerRisks={welcomerRisks}
              welcomerAddon={welcomerAddons}
              isLoading={isPackagesTabLoading}
              welcomerLimits={welcomerLimits}
              welcomerCurrencies={welcomerCurrencies}
              refetchExistingWelcomerProduct={refetchExistingWelcomerProduct}
              /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
              setWelcomerProductField={setWelcomerProductField}
              setUsedCurrencies={setUsedCurrencies}
              setUsedFranchises={setUsedFranchises}
              refetchWelcomerPackagePrices={RefetchWelcomerPackagePrice}
            />
          </MuiTabPanel>

          {/* value="3" */}
          <MuiTabPanel style={activeTab === "3" ? {} : { display: "none" }}>
            {/* დოკუმენტები */}
            <EditWelcomerDocumentsTab
              language={field_Language}
              welcomerProduct={productFieldsState?.welcomerProduct}
              isLoading={isPDFTabLoading}
              setWelcomerProductField={setWelcomerProductField}
              setMainProductField={setMainProductField}
              saveTab={storeProductDocumentFields}
            />
          </MuiTabPanel>
          <MuiTabPanel style={activeTab === "4" ? {} : { display: "none" }}>
            {/* ფასები */}
            <EditWelcomerPricesTab
              welcomerPackagePrices={welcomerPackagePrices}
              refetchWelcomerPackagePrice={RefetchWelcomerPackagePrice}
              isWelcomerPackagePricesLoading={isWelcomerPackagePricesLoading}
              language={field_Language}
            />
          </MuiTabPanel>
        </MuiBox>
      </Container>
    </div>
  );
};
//
//
// styles
// const MuiTabList = styled(TabList)`
const MuiTabList = styled.div`
  /* MUI tab bottom line */
  .MuiTabs-indicator {
    background-color: ${Colors.primaryRed} !important;
  }
  ${Responsive.mobile} {
    /* mui button */
    button {
      margin-right: auto;
      font-size: 17px;
    }
  }
`;
// const MuiTab = styled(Tab)`
const MuiTab = styled.button<{ $active?: boolean }>`
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px none;
  margin: 0px;
  margin-right: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
  color: ${Colors.primaryBlack} !important;
  padding: 0px;
  margin-right: 30px;
  font-size: 18px;
  text-transform: none;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  ${({ $active = false }) =>
    $active ? `border-bottom-color: rgb(254, 95, 85);` : ""}

  &:last-child {
    margin-right: 0px;
  }
`;
// const MuiTabPanel = styled(TabPanel)`
const MuiTabPanel = styled.div`
  background-color: ${Colors.white};
  padding: 0px;
  padding-block: 20px;
  border-radius: 8px;
  box-shadow: ${Colors.white} 0px 30px 60px -12px inset,
    ${Colors.white} 0px 18px 36px -18px inset;
  ${Responsive.mobile} {
    margin-top: 20px;
  }
`;
// const MuiBox = styled(Box)`
const MuiBox = styled.div`
  margin-top: 30px;
  button {
    font-family: inherit;
  }
`;
//

export default AdminWelcomerProductEdit;
