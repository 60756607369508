import { Space } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Colors from "../../../assets/styles/Colors";
import Loader from "../../../components/Loader";

const AdminExpiredPromoCodesDetails: React.FC<{
  promoCodeId: number | null;
}> = (promoCodeId) => {
  //
  const { t } = useTranslation();
  //
  const { data: promoCodeDetail, isLoading: isPromoCodeDetailLoading } =
    api.useGetPromoCodeByIdQuery(promoCodeId.promoCodeId, {
      skip: promoCodeId.promoCodeId === null ? true : false,
    });
  //
  const {
    data: AdminProductCategoriesList,
    isLoading: isAdminProductCategoriesListLoading,
  } = api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const getDayDifference = (startDate, endDate) => {
    const start = dayjs(startDate, "YYYY-MM-DD"); // Replace 'YYYY-MM-DD' with your date format
    const end = dayjs(endDate, "YYYY-MM-DD"); // Replace 'YYYY-MM-DD' with your date format
    const diffInDays = end.diff(start, "day");
    return diffInDays + 1;
  };
  //
  const MainLoading =
    isPromoCodeDetailLoading || isAdminProductCategoriesListLoading;
  //
  return (
    <>
      {MainLoading ? (
        <Loader />
      ) : (
        <Space
          direction="vertical"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              {`${t("expiredAt")} `}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {dayjs(promoCodeDetail?.endDate).format("D MMMM YYYY")}
              </span>
            </div>
          </div>

          <HR />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "14px",
            }}
          >
            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("promoCode")}`}</OffetTextLeftSide>
              <OffetTextRightSide>{promoCodeDetail?.code}</OffetTextRightSide>
            </CheckInfoTextBox>
            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("promoCodeName")}`}</OffetTextLeftSide>
              <OffetTextRightSide>{promoCodeDetail?.name}</OffetTextRightSide>
            </CheckInfoTextBox>
            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("product")}`}</OffetTextLeftSide>
              <OffetTextRightSide>
                {AdminProductCategoriesList?.filter((x) =>
                  promoCodeDetail?.productPromoCodes
                    ?.map((x) => x.productId)
                    ?.includes(x.id)
                )?.map((x) => x.slug + " ")}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <HR />

            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("startDate")}`}</OffetTextLeftSide>
              <OffetTextRightSide>
                {dayjs(promoCodeDetail?.startDate).format("D MMMM YYYY")}
              </OffetTextRightSide>
            </CheckInfoTextBox>
            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("expireDate")}`}</OffetTextLeftSide>
              <OffetTextRightSide>
                {dayjs(promoCodeDetail?.endDate).format("D MMMM YYYY")}
              </OffetTextRightSide>
            </CheckInfoTextBox>
            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("numberOfDays")}`}</OffetTextLeftSide>
              <OffetTextRightSide>{`${getDayDifference(
                promoCodeDetail?.startDate,
                promoCodeDetail?.endDate
              )}  ${t("day")}`}</OffetTextRightSide>
            </CheckInfoTextBox>

            <HR />

            {promoCodeDetail?.applyingDiscount?.days && (
              <CheckInfoTextBox>
                <OffetTextLeftSide>{`${t(
                  "discountByDays"
                )}`}</OffetTextLeftSide>
                <OffetTextRightSide>{`${
                  promoCodeDetail?.applyingDiscount?.days
                } ${t("day")}`}</OffetTextRightSide>
              </CheckInfoTextBox>
            )}

            {promoCodeDetail?.applyingDiscount?.amount && (
              <CheckInfoTextBox>
                <OffetTextLeftSide>{`${t(
                  "discountByAmount"
                )}`}</OffetTextLeftSide>
                <OffetTextRightSide>
                  {promoCodeDetail?.applyingDiscount?.amount}
                </OffetTextRightSide>
              </CheckInfoTextBox>
            )}

            {promoCodeDetail?.applyingDiscount?.percentageDiscount
              ?.percentage && (
              <CheckInfoTextBox>
                <OffetTextLeftSide>{`${t(
                  "discountByPercent"
                )}`}</OffetTextLeftSide>
                <OffetTextRightSide>{`${promoCodeDetail?.applyingDiscount?.percentageDiscount?.percentage} %`}</OffetTextRightSide>
              </CheckInfoTextBox>
            )}

            <HR />

            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t("numberOfCoupon")}`}</OffetTextLeftSide>
              <OffetTextRightSide>{`${promoCodeDetail?.remainedCapacity} / ${promoCodeDetail?.capacity}`}</OffetTextRightSide>
            </CheckInfoTextBox>

            <HR />

            <CheckInfoTextBox>
              <OffetTextLeftSide>{`${t(
                "amountCouponUsageForUser"
              )}`}</OffetTextLeftSide>
              <OffetTextRightSide>
                {promoCodeDetail?.usageAmount}
              </OffetTextRightSide>
            </CheckInfoTextBox>

            <HR />
          </div>
        </Space>
      )}
    </>
  );
};

const HR = styled.hr`
  background-color: #d3dade;
  height: 2px;
`;

const CheckInfoTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const OffetTextLeftSide = styled.span`
  color: ${Colors.primaryGrey};
  font-size: 14px;
  /* font-weight: 600; */
`;
const OffetTextRightSide = styled.span`
  color: ${Colors.primaryBlack};
  font-size: 14px;
  /* font-weight: 600; */
`;
export default AdminExpiredPromoCodesDetails;
