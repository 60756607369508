import { Select } from "antd";
import React from "react";
import { EnabledLanguage, supportedLocales } from "../../domain/types";
import { useAppDispatch, useAppSelector } from "../../store";
import { AppState, setEditedlanguage } from "../../store/appSlice";
//
//
const AdminLangSwicher: React.FC<{
  onChange?: (value: any) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}> = ({ onChange, style, disabled }) => {
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const dispatch = useAppDispatch();

  return (
    <Select
      disabled={disabled}
      size="large"
      defaultValue={field_Language}
      style={{
        ...style,
        minWidth: "70px",
      }}
      onChange={(value) => {
        onChange?.(value as EnabledLanguage);
        dispatch(setEditedlanguage(value as EnabledLanguage));
      }}
      options={supportedLocales?.map((l) => ({
        value: l,
        label: l?.toUpperCase(),
      }))}
    />
  );
};
//
export default AdminLangSwicher;
