import { ArrowLeftOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Flex } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { EnabledLanguage, TravelPagesWording } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

type TravelPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};
//
const Admin_manage_travel_pages_edit = () => {
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);
  //
  const navigate = useNavigate();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<TravelPagesWording>
  >({});
  //
  const {
    data: existingTravelPageItem,
    isLoading: isExistringTravelPageItemLoading,
    refetch: RefetchExistingItem,
  } = api.useGetTravelFlowPageTextItemByListItemIdQuery(itemID as number, {
    skip: !itemID && true,
  });
  // add travel addon fields
  const setTravelFlowTexts = (field: keyof TravelPagesWording, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };
  // add travel translation fields
  const setAddTravelAddonTranslatedField = (
    field: keyof TravelPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  const [updatePageByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateTravelFlowPageItemByListItemIdMutation();
  //
  const UpdateTravelPageTexts = async () => {
    //
    try {
      await updatePageByListItemId(updatedPageTextsState).unwrap();
      //
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      //
      RefetchExistingItem();
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  useEffect(() => {
    if (existingTravelPageItem) {
      setUpdatedPageTextsState(existingTravelPageItem);
    }
  }, [existingTravelPageItem]);
  //
  const mainLoading = isExistringTravelPageItemLoading || isUpdatePageLoading;
  //
  const { t } = useTranslation();
  //
  return (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {updatedPageTextsState?.translations?.[field_Language]?.title}
        </Title>

        <AdminLangSwicher />
      </Flex>

      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <Divider />

          <Flex vertical gap={30}>
            <Badge.Ribbon
              text={t("title")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("title")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.title || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "title",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("body")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("body")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]?.body ||
                  ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "body",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("additionalInformation")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("additionalInformation")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.excerpt || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "excerpt",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("secondTitle")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("secondTitle")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.secondTitle || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "secondTitle",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("secondAdditionalInformation")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("secondAdditionalInformation")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.secondExcerpt || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "secondExcerpt",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("thirdTitle")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("thirdTitle")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.thirdTitle || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "thirdTitle",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <Badge.Ribbon
              text={t("thirdAdditionalInformation")}
              color="blue"
              style={{
                marginTop: "-20px",
              }}
            >
              <TextArea
                size="large"
                placeholder={t("thirdAdditionalInformation")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextsState?.translations?.[field_Language]
                    ?.thirdExcerpt || ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "thirdExcerpt",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Badge.Ribbon>

            <UploadFile
              label={t("uploadImage")}
              onChange={(props) => setTravelFlowTexts("image", props.url)}
              defaultFileName={updatedPageTextsState?.image || ""}
            />

            <Button
              style={{
                marginInline: "20%",
              }}
              size="large"
              type="primary"
              onClick={UpdateTravelPageTexts}
            >
              {t("update")}
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default Admin_manage_travel_pages_edit;
