import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import Accordion from "../../../../../components/Accordion";
import Button from "../../../../admin_components/AdminButton";
import Input from "../../../../admin_components/AdminInput";
// import Loader from '../../../../../components/Loader'
// import NotFound404 from '../../../../../components/NotFound404'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-toastify";
import Logo from "../../../../../assets/icons/JSX/Logo";
import {
  AdminPaymentScheduleType,
  Country,
  Currency,
  EnabledLanguage,
  Package,
  PackageService,
  Product,
  TplCoverageTranslations,
  TplCurrencies,
  TplImportantTermTranslations,
  TplLimitList,
  TplPackage,
  TplProduct,
  WordingFileType,
} from "../../../../../domain/types";
// import BaseModal from '../../../../../components/modals/BaseModal'
import dayjs from "dayjs";
import api from "../../../../../api";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import { useAppSelector } from "../../../../../store";
import DocumentFieldUploader from "../../../../admin_components/DocumentFieldUploader";
import { AppState } from "../../../../../store/appSlice";
import { useTranslation } from "react-i18next";
dayjs.locale("ka");

type Props = {
  tplPackageId: TplPackage["id"];
  countries: Country[];
  refetchTplProduct: () => void;
  tplProduct: TplProduct;
  /** setTplProductField is not used for saving packages, but only to receive price combination-related updates */
  setTplProductField: (fieldName: keyof TplProduct, fieldValue: any) => void;
  copyProduct: any;
  setUsedCurrencies: (tplPackageId: number, _: Currency[]) => void;
  setUsedServices: (tplPackageId: number, values: number[]) => void;
  packageServices: PackageService[] | undefined;
  setUsedPaymentSchedules: (tplPackageId: number, values: number[]) => void;
  paymentSchedules: AdminPaymentScheduleType[] | undefined;
  tplLimits: TplLimitList[] | undefined;
  tplCurrencies: TplCurrencies[] | undefined;
};

const TplPackageEditor = ({
  tplPackageId: pkgId,
  countries,
  refetchTplProduct,
  tplProduct,
  setTplProductField,
  copyProduct,
  setUsedCurrencies,
  setUsedServices,
  packageServices,
  setUsedPaymentSchedules,
  paymentSchedules,
  tplLimits,
  tplCurrencies,
}: Props) => {
  //
  const { t } = useTranslation();
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  /** small context:
   * when the package fields change here, we have to propagate them back to the parent.
   * this means that we shouldn't receive `tplPackage` from the parent, or it will
   * become a two-way binding and we'll receive a "Maximum update depth exceeded" error.
   */
  const {
    //
    data: initialTplPackage,
    isLoading: isInitialTplPackageLoading,
    // refetch: refetchInitialTplPackage,
  } = api.useGetTplPackageByIdQuery(pkgId);
  //
  const [m_updateTplPackage, { isLoading: isUpdateTplPackageLoading }] =
    api.useUpdateTplPackageMutation();
  //
  const [_deleteTplPackage, { isLoading: isDeleteTplPackageLoading }] =
    api.useDeleteTplPackageMutation();
  // [admin]: approve package changes
  const [m_activatePackage, { isLoading: isActivatePackageLoading }] =
    api.useAdminActivatePackageMutation();
  // [admin]: reject package changes
  const [m_deactivatePackage, { isLoading: isDeactivatePackageLoading }] =
    api.useAdminDeactivatePackageMutation();
  //
  //
  const [editedPackage, only_setEditedPackage] = useState<
    TplPackage | undefined
  >(undefined);
  //
  //
  //
  //
  //
  /** also update parent TplProduct state so that price combinations are updated */
  const setEditedPackage = useCallback(
    (newState: TplPackage) => {
      only_setEditedPackage(newState);
      if (newState) {
        const mainProductWithUpdatedPackage: Product = {
          ...tplProduct.mainProduct,
          packages: tplProduct.mainProduct.packages.map((mainPkg) => {
            if (mainPkg.id === newState.mainPackage.id) {
              return {
                ...newState.mainPackage,
                target: { ...newState },
              };
            } else {
              return mainPkg;
            }
          }),
        };
        /** this will cause price combinations to regenerate with updated data */
        setTplProductField("mainProduct", mainProductWithUpdatedPackage);
      }
    },
    [only_setEditedPackage, setTplProductField, tplProduct]
  );
  //
  const [editedCoverageTranslations, setEditedCoverageTranslations] =
    useState<TplCoverageTranslations[]>();
  //
  console.log("editedCoverageTranslations", editedCoverageTranslations);
  //
  const [
    editedImportantTermsTranslations,
    setEditedImportantTermsTranslations,
  ] = useState<TplImportantTermTranslations[]>();
  //
  const [pkgServiceIds, setPkgServiceIds] = useState<number[]>();
  //
  //
  useEffect(() => {
    if (initialTplPackage) {
      setEditedPackage(initialTplPackage);
      setEditedCoverageTranslations(
        initialTplPackage?.tplCoverages.map((coverage) => coverage.translations)
      );
      setEditedImportantTermsTranslations(
        initialTplPackage?.tplImportantTerms.map((term) => term.translations)
      );
      setPkgServiceIds(
        initialTplPackage?.packageServiceRelations.map(
          (service) => service.packageService.id
        )
      );
    }
  }, [initialTplPackage, setEditedPackage]);
  //
  //
  const setTplPackageField = (fieldName: keyof TplPackage, fieldValue: any) => {
    if (!editedPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      [fieldName]: fieldValue,
    });
  };
  //
  const setMainPackageField = (
    fieldName: keyof TplPackage["mainPackage"],
    fieldValue: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      mainPackage: {
        ...editedPackage.mainPackage,
        [fieldName]: fieldValue,
      },
    });
  };
  //
  //
  const setTranslatedFieldOnMainPackage = (
    field: keyof Package["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setMainPackageField("translations", {
      ...editedPackage.mainPackage.translations,
      [language]: {
        ...editedPackage.mainPackage.translations?.[language],
        [field]: value,
      },
    });
  };
  //
  //
  const saveTplPackage = async () => {
    if (editedPackage) {
      try {
        let fixedUpEditedPackage: TplPackage = { ...editedPackage };

        const parsedScheduleArray =
          typeof editedPackage?.mainPackage.paymentScheduleTypeIds === "string"
            ? JSON.parse(editedPackage?.mainPackage.paymentScheduleTypeIds)
            : editedPackage?.mainPackage.paymentScheduleTypeIds;

        fixedUpEditedPackage = {
          ...fixedUpEditedPackage,
          mainPackage: {
            ...fixedUpEditedPackage.mainPackage,
            paymentScheduleTypeIds: parsedScheduleArray,
          },
        };

        //in case the checkboxes are unchanged and editedPackage?.packageServiceIds is an empty array
        fixedUpEditedPackage = {
          ...fixedUpEditedPackage,
          packageServiceIds: pkgServiceIds,
        };

        const result = await m_updateTplPackage(fixedUpEditedPackage).unwrap();
        // refetchTplProduct()
        setEditedPackage(result);
        toast.success(`${t("package")} ${t("savedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToSave")}`;
        toast.error(message);
      }
    }
  };
  const deleteTplPackage = async () => {
    if (editedPackage) {
      if (!window.confirm(`${t("certainToDeleteIt")}`)) {
        return;
      }
      try {
        const result = await _deleteTplPackage(editedPackage.id).unwrap();
        refetchTplProduct();
        console.log("delete tpl package", result);
        toast.success(`${t("package")} ${t("deletedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToDelete")}`;
        toast.error(message);
      }
    }
  };
  // approve package changes [admin does this]
  const approvePackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save package fields
      await saveTplPackage();
      // second, activate the package
      const result = await m_activatePackage(
        editedPackage.mainPackage.id
      ).unwrap();
      toast.success(`${t("package")} ${t("publishedSuccessfully")}`);
      //
      // refetch tpl products
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      // refetchExistingTplProduct()
      setEditedPackage({
        ...editedPackage,
        mainPackage: result,
      });
    } catch (error) {
      // console.log("approve package tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  // reject package changes [admin does this]
  const rejectPackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        // in this case, admin is converting a published package to draft (probably just auditing existing published packages)
      }
      //const result =
      await m_deactivatePackage(editedPackage.mainPackage.id).unwrap();
      toast.success(`${t("package")} ${t("deactivated")}`);
      //
      // refetch tpl products
      // WARNING: this resets edited package inputs. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      //  refetchExistingTplPackage()
      // WARNING: this also resets edited package inputs, so only update the status field
      // setEditedPackage({
      //   ...editedPackage,
      //   mainPackage: result,
      // })
      setMainPackageField("status", "draft");
    } catch (error) {
      // console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //

  // const [serviceIdsToHandle, setServiceIdsToHandle] = useState<number[]>(
  //   editedPackage?.packageServiceRelations || []
  // )

  // const handleServicesCheckboxChange = (serviceId) => {
  //   setServiceIdsToHandle((prevIds: any) => {
  //     if (prevIds.includes(serviceId)) {
  //       return prevIds.filter((id) => id !== serviceId)
  //     } else {
  //       return [...prevIds, serviceId]
  //     }
  //   })
  //   setTplPackageField('packageServiceRelations', serviceIdsToHandle)
  // }
  //
  //
  const isLoading =
    isInitialTplPackageLoading ||
    isUpdateTplPackageLoading ||
    isActivatePackageLoading ||
    isDeactivatePackageLoading ||
    isDeleteTplPackageLoading;
  //
  if (!editedPackage) {
    return null;
  }
  //
  //
  const FranoHorizontalLine: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <CustomHorizontalLine>
        <div></div>
        {title ? <span>{title}</span> : <Logo />}
        <div></div>
      </CustomHorizontalLine>
    );
  };
  //
  //
  //
  //
  const parsedScheduleArray =
    typeof editedPackage?.mainPackage.paymentScheduleTypeIds === "string"
      ? JSON.parse(editedPackage?.mainPackage.paymentScheduleTypeIds)
      : editedPackage?.mainPackage.paymentScheduleTypeIds;

  //
  //
  return (
    <Wrapper>
      <Accordion title={editedPackage.mainPackage.title} key={editedPackage.id}>
        <Row>
          <Column $widthPercent={70} style={{ paddingLeft: "0px !important" }}>
            <FranoHorizontalLine title={t("packageTitle")} />
            <CustomBlock>
              <Input
                id=""
                label={t("packageTitle")}
                type="text"
                placeholder=""
                value={
                  editedPackage.mainPackage.translations[field_Language]
                    ?.title || ""
                }
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setTranslatedFieldOnMainPackage(
                    // @ts-ignore
                    "title",
                    field_Language,
                    value
                  )
                }
              />
              <Input
                id=""
                label={t("productPriority")}
                type="text"
                placeholder=""
                value={editedPackage?.priorityText}
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) => setTplPackageField("priorityText", value)}
              />
            </CustomBlock>
            <FranoHorizontalLine title={t("compensationCurrency")} />
            <Block>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {tplCurrencies
                  ?.filter((currency) => currency.isActive)
                  .map((currency) => (
                    <Block key={currency.currencyCode}>
                      <InputLabel
                        htmlFor={`package-${editedPackage.id}-currency-${currency.currencyCode}`}
                      >
                        {currency.currencyCode}
                      </InputLabel>
                      <Checkbox
                        id={`package-${editedPackage.id}-currency-${currency.currencyCode}`}
                        checked={editedPackage?.mainPackage?.compensationCurrencies?.some(
                          (a) => a.currency === currency.currencyCode
                        )}
                        onChange={(event) => {
                          const checked = event?.target.checked;
                          const newCurrencyList = [
                            ...(
                              editedPackage?.mainPackage
                                .compensationCurrencies || []
                            ).filter(
                              (a) => a.currency !== currency.currencyCode
                            ),
                            ...(checked
                              ? [{ currency: currency.currencyCode }]
                              : []),
                          ];
                          setMainPackageField(
                            "compensationCurrencies",
                            newCurrencyList
                          );
                          setUsedCurrencies(
                            editedPackage.id,
                            newCurrencyList.map((v) => v.currency as Currency)
                          );
                        }}
                      />
                    </Block>
                  ))}
              </div>
            </Block>
            <FranoHorizontalLine title={t("compensationLimit")} />
            {tplLimits
              ?.filter((limit) => limit.isActive)
              .map((limit, index) => (
                <RadioGroup
                  key={index}
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={editedPackage.mainPackage.compensationLimit}
                  onChange={(event) => {
                    return [
                      setMainPackageField(
                        "compensationLimit",
                        event.target.value
                      ),
                    ];
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "unset",
                  }}
                >
                  <FormControlLabel
                    value={limit.value}
                    control={<Radio />}
                    label={limit.value}
                  />
                </RadioGroup>
              ))}
            <FranoHorizontalLine title={t("vehicleAndDriverInfo")} />
            <Block>
              <InputLabel htmlFor="input-1">
                {t("carManufactoreYearMinimum")}
              </InputLabel>
              <Input
                id="input-1"
                label=""
                type="text"
                placeholder=""
                value={editedPackage?.minAgeOfCarRelease}
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setTplPackageField("minAgeOfCarRelease", value)
                }
              />
            </Block>
            <br />
            <Block>
              <InputLabel htmlFor="input-1">{t("driverMinAge")}</InputLabel>
              <Input
                id="input-1"
                label=""
                type="text"
                placeholder=""
                value={editedPackage?.minAgeOfTheDriver}
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setTplPackageField("minAgeOfTheDriver", value)
                }
              />
            </Block>
            <br />
            <Block>
              <InputLabel htmlFor="input-1">{t("driverMaxAge")}</InputLabel>
              <Input
                id="input-1"
                label=""
                type="text"
                placeholder=""
                value={editedPackage?.maxAgeOfTheDriver}
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setTplPackageField("maxAgeOfTheDriver", value)
                }
              />
            </Block>
            <br />
            <Block>
              <InputLabel htmlFor="input-1">{t("purpose")}</InputLabel>
              <Input
                id="input-1"
                label=""
                type="text"
                placeholder=""
                value={
                  editedPackage?.destination === null
                    ? ""
                    : editedPackage?.destination[field_Language]?.name
                }
                onChange={(value) => {
                  const destination = editedPackage?.destination ?? {
                    [field_Language]: { name: value },
                  };

                  setTplPackageField("destination", {
                    ...destination,
                    [field_Language]: {
                      ...destination[field_Language],
                      name: value,
                    },
                  });
                }}
              />
            </Block>
            <br />
            <FranoHorizontalLine title={t("manageServicesInfo")} />
            <FormGroup>
              <div style={{ display: "flex", marginBottom: "20px" }}>
                <InputLabel
                  style={{ width: "400px", color: `${Colors.primaryBlack}` }}
                >
                  {t("servicesList")}
                </InputLabel>
                <InputLabel
                  style={{
                    marginRight: "40px",
                    color: `${Colors.primaryBlack}`,
                  }}
                >
                  {`${t("yes")}/${t("no")}`}
                </InputLabel>
                <InputLabel
                  style={{ width: "400px", color: `${Colors.primaryBlack}` }}
                >
                  {t("comment")}
                </InputLabel>
              </div>

              {packageServices?.map((service) => (
                <div key={service.id} style={{ display: "flex" }}>
                  <InputLabel
                    htmlFor={`service-${service.id}`}
                    style={{ width: "400px", marginTop: "10px" }}
                  >
                    {service.translations[field_Language]?.name || ""}
                  </InputLabel>
                  <Checkbox
                    style={{ marginRight: "50px" }}
                    checked={pkgServiceIds?.includes(service.id)}
                    onChange={(event) => {
                      const checked = event?.target.checked;
                      const newServicesList = [
                        ...(pkgServiceIds || []).filter(
                          (a) => a !== service.id
                        ),
                        ...(checked ? [service.id] : []),
                      ] as number[];
                      setPkgServiceIds(newServicesList);
                      setTplPackageField(
                        "packageServiceIds",
                        newServicesList as number[]
                      );
                      // setUsedServices(
                      //   editedPackage.id,
                      //   newServicesList as number[]
                      // )
                    }}
                  />
                  <InputLabel
                    htmlFor={`service-${service.id}`}
                    style={{ maxWidth: "400px", marginTop: "10px" }}
                  >
                    {service.translations?.[field_Language]?.comment || ""}
                  </InputLabel>
                </div>
              ))}
            </FormGroup>
            {/*  */}

            {/*  */}
            <FranoHorizontalLine title={t("coveragesAndImportant")} />
            <h5>{t("coverages")}</h5>
            <CoverageBlock>
              {editedCoverageTranslations?.map((item, index) => (
                <CoverageItem key={index}>
                  <Input
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    id={`coverage-title-${index}`}
                    name="coverage-title"
                    label={`#${index + 1} ${t("coverageName")}`}
                    type="text"
                    placeholder=""
                    value={item?.[field_Language]?.title}
                    onChange={(value) => {
                      const oldBody = item?.[field_Language]?.body;
                      const listWithUpdatedTitle = (
                        editedCoverageTranslations ?? []
                      ).map((g, i) =>
                        i === index
                          ? {
                              ...g,
                              ...g.translations,
                              [field_Language]: {
                                ...g.translations?.[field_Language],
                                title: value,
                                body: oldBody,
                              },
                            }
                          : g
                      );
                      setEditedCoverageTranslations(listWithUpdatedTitle);
                      setTplPackageField(
                        "coveragesTranslationsArray",
                        listWithUpdatedTitle
                      );
                    }}
                    width={"fullWidth"}
                  />
                  <Input
                    style={{
                      marginTop: "20px",
                    }}
                    id={`coverage-body-${index}`}
                    name="coverage-name"
                    label={t("description")}
                    type="text"
                    placeholder=""
                    value={item?.[field_Language]?.body}
                    onChange={(value) => {
                      const oldTitle = item?.[field_Language]?.title;
                      const listWithUpdatedBody = (
                        editedCoverageTranslations ?? []
                      ).map((g, i) =>
                        i === index
                          ? {
                              ...g,
                              ...g.translations,
                              [field_Language]: {
                                ...g.translations?.[field_Language],
                                body: value,
                                title: oldTitle,
                              },
                            }
                          : g
                      );
                      // console.log(listWithUpdatedBody);

                      setEditedCoverageTranslations(listWithUpdatedBody);
                      setTplPackageField(
                        "coveragesTranslationsArray",
                        listWithUpdatedBody
                      );
                    }}
                    width={"fullWidth"}
                  />

                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    btnStyleType="secondary"
                    size="medium"
                    icon={false}
                    fullWidth={false}
                    disabled={false}
                    onClick={() => {
                      setEditedCoverageTranslations(
                        editedCoverageTranslations!.filter(
                          (i, coverageIndex) => coverageIndex !== index
                        )
                      );
                      setTplPackageField(
                        "coveragesTranslationsArray",
                        editedCoverageTranslations!.filter(
                          (i, coverageIndex) => coverageIndex !== index
                        )
                      );
                    }}
                    text={t("delete")}
                  />
                </CoverageItem>
              ))}
            </CoverageBlock>
            <Button
              btnStyleType="primary"
              icon={false}
              disabled={false}
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                const newItem = {
                  translations: {
                    ge: {
                      title: " ",
                      body: " ",
                    },
                    en: {
                      title: " ",
                      body: " ",
                    },
                    ru: {
                      title: " ",
                      body: " ",
                    },
                  },
                } as TplCoverageTranslations;
                const listWithAddedItem: TplCoverageTranslations[] = [
                  ...(editedCoverageTranslations || []),
                  newItem.translations as TplCoverageTranslations,
                ];
                setEditedCoverageTranslations(listWithAddedItem);
                setTplPackageField(
                  "coveragesTranslationsArray",
                  listWithAddedItem
                );
              }}
              text={t("add")}
            />
            <br />
            <br />
            <h5>{t("importantTerms")}</h5>
            <CoverageBlock>
              {editedImportantTermsTranslations?.map((item, index) => (
                <CoverageItem key={index}>
                  <Input
                    style={{
                      marginTop: "20px",
                      marginRight: "10px",
                    }}
                    id={`term-title-${index}`}
                    name="term-title"
                    label={`#${index + 1} ${t("conditionTitle")}`}
                    type="text"
                    placeholder=""
                    value={item?.[field_Language]?.title}
                    onChange={(value) => {
                      const oldBody = item?.[field_Language]?.body;
                      const listWithUpdatedTitle = (
                        editedImportantTermsTranslations ?? []
                      ).map((g, i) =>
                        i === index
                          ? {
                              ...g,
                              ...g.translations,
                              [field_Language]: {
                                ...g.translations?.[field_Language],
                                title: value,
                                body: oldBody,
                              },
                            }
                          : g
                      );
                      setEditedImportantTermsTranslations(listWithUpdatedTitle);
                      setTplPackageField(
                        "importantTermsTranslationsArray",
                        listWithUpdatedTitle
                      );
                    }}
                    width={"fullWidth"}
                  />
                  <Input
                    style={{
                      marginTop: "20px",
                    }}
                    id={`term-body-${index}`}
                    name="term-name"
                    label={t("description")}
                    type="text"
                    placeholder=""
                    value={item?.[field_Language]?.body}
                    onChange={(value) => {
                      const oldTitle = item?.[field_Language]?.title;
                      const listWithUpdatedBody = (
                        editedImportantTermsTranslations ?? []
                      ).map((g, i) =>
                        i === index
                          ? {
                              ...g,
                              ...g.translations,
                              [field_Language]: {
                                ...g.translations?.[field_Language],
                                body: value,
                                title: oldTitle,
                              },
                            }
                          : g
                      );

                      setEditedImportantTermsTranslations(listWithUpdatedBody);
                      setTplPackageField(
                        "importantTermsTranslationsArray",
                        listWithUpdatedBody
                      );
                    }}
                    width={"fullWidth"}
                  />

                  <Button
                    style={{
                      marginLeft: "auto",
                    }}
                    btnStyleType="secondary"
                    size="medium"
                    icon={false}
                    fullWidth={false}
                    disabled={false}
                    onClick={() => {
                      setEditedImportantTermsTranslations(
                        editedImportantTermsTranslations!.filter(
                          (i, termIndex) => termIndex !== index
                        )
                      );
                      setTplPackageField(
                        "importantTermsTranslationsArray",
                        editedImportantTermsTranslations!.filter(
                          (i, termIndex) => termIndex !== index
                        )
                      );
                    }}
                    text={t("delete")}
                  />
                </CoverageItem>
              ))}
            </CoverageBlock>
            <Button
              btnStyleType="primary"
              icon={false}
              disabled={false}
              style={{
                marginTop: "20px",
              }}
              onClick={() => {
                const newItem = {
                  translations: {
                    ge: {
                      title: " ",
                      body: " ",
                    },
                    en: {
                      title: " ",
                      body: " ",
                    },
                    ru: {
                      title: " ",
                      body: " ",
                    },
                  },
                } as TplImportantTermTranslations;
                const listWithAddedItem: TplImportantTermTranslations[] = [
                  ...(editedImportantTermsTranslations || []),
                  newItem.translations as TplImportantTermTranslations,
                ];
                setEditedImportantTermsTranslations(listWithAddedItem);
                setTplPackageField(
                  "importantTermsTranslationsArray",
                  listWithAddedItem
                );
              }}
              text={t("add")}
            />
            {/*  */}
            <FranoHorizontalLine title={t("paymentTerms")} />
            <FormGroup>
              <span style={{ fontWeight: "700" }}>{`${t(
                "paymentType"
              )}:`}</span>
              {paymentSchedules
                ?.filter((paymentSchedule) => paymentSchedule.isActive)
                .map((paymentSchedule) => (
                  <FormControlLabel
                    key={paymentSchedule.id}
                    control={
                      <Checkbox
                        id={`package-${editedPackage.id}-currency-${paymentSchedule}`}
                        checked={
                          parsedScheduleArray?.includes(paymentSchedule.id) ??
                          false
                        }
                        onChange={(event) => {
                          const checked = event?.target.checked;
                          const newPaymentScheduleList = [
                            ...(parsedScheduleArray || []).filter(
                              (a) => a !== paymentSchedule.id
                            ),
                            ...(checked ? [paymentSchedule.id] : []),
                          ];

                          setMainPackageField(
                            "paymentScheduleTypeIds",
                            newPaymentScheduleList as number[]
                          );
                          setUsedPaymentSchedules(
                            editedPackage.id,
                            newPaymentScheduleList
                          );
                        }}
                      />
                    }
                    label={
                      paymentSchedule?.translations?.[field_Language]?.title ||
                      ""
                    }
                  />
                ))}
            </FormGroup>
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            <FranoHorizontalLine title={t("packageDocuments")} />
            <DocumentsWrapper
              style={{
                paddingTop: "0px",
              }}
            >
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Column $widthPercent={50}>
                  <div>
                    <DocumentBlockTitle>
                      {t("termsAndConditions")}
                    </DocumentBlockTitle>
                    <DocumentFieldUploader
                      url={editedPackage.mainPackage.conditionFileUrl}
                      onChange={(newUrl) =>
                        setMainPackageField("conditionFileUrl", newUrl)
                      }
                      width={275}
                    />
                  </div>
                </Column>
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (GE)
                  </DocumentBlockTitle>
                  <DocumentFieldUploader
                    url={
                      editedPackage?.mainPackage?.wordingFileUrl
                        ? (
                            JSON.parse(
                              editedPackage.mainPackage.wordingFileUrl
                            ) as WordingFileType
                          )?.translations?.ge?.url ?? ""
                        : ""
                    }
                    onChange={(newUrl) =>
                      setMainPackageField(
                        "wordingFileUrl",
                        JSON.stringify({
                          translations: {
                            ge: {
                              url: newUrl,
                            },
                            en: {
                              url: editedPackage?.mainPackage?.wordingFileUrl
                                ? (
                                    JSON.parse(
                                      editedPackage.mainPackage.wordingFileUrl
                                    ) as WordingFileType
                                  )?.translations?.en?.url ?? ""
                                : "",
                            },
                          },
                        })
                      )
                    }
                    width={275}
                  />
                </Column>
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (EN)
                  </DocumentBlockTitle>
                  <DocumentFieldUploader
                    url={
                      editedPackage?.mainPackage?.wordingFileUrl
                        ? (
                            JSON.parse(
                              editedPackage.mainPackage.wordingFileUrl
                            ) as WordingFileType
                          )?.translations?.en?.url ?? ""
                        : ""
                    }
                    onChange={(newUrl) =>
                      setMainPackageField(
                        "wordingFileUrl",
                        JSON.stringify({
                          translations: {
                            ge: {
                              url: editedPackage?.mainPackage?.wordingFileUrl
                                ? (
                                    JSON.parse(
                                      editedPackage.mainPackage.wordingFileUrl
                                    ) as WordingFileType
                                  )?.translations?.ge?.url ?? ""
                                : "",
                            },
                            en: {
                              url: newUrl,
                            },
                          },
                        })
                      )
                    }
                    width={275}
                  />
                </Column>
              </Row>
              {/* <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              disabled={false}
              onClick={saveTab}
              text={'შენახვა'}
            /> */}
            </DocumentsWrapper>
          </Column>

          <Column $widthPercent={30}>
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="primary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => saveTplPackage()}
                  text={t("save")}
                />
              )}
            </Block>
            <br />
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="secondary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => deleteTplPackage()}
                  text={t("deletePackage")}
                />
              )}
            </Block>
            <br />
            {editedPackage.mainPackage.createdAt ? (
              <Block>
                <Text>{t("createdDate")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.createdAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {editedPackage.mainPackage.updatedAt ? (
              <Block>
                <Text>{t("lastUpdatedAt")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.updatedAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {/* if approved */}
            {/* if approved, activationDate MUST be present */}
            {editedPackage.mainPackage.approvedByAdmin ? (
              <Block>
                <Text>{t("lastPublishedAt")}:</Text>
                <Text>
                  {editedPackage.mainPackage.activatedDate
                    ? dayjs(editedPackage.mainPackage.activatedDate).format(
                        "DD MMMM YYYY HH:mm"
                      )
                    : `${t("notFound")}`}
                </Text>
              </Block>
            ) : null}
            {/* if NOT approved */}
            {/* if NOT approved, two cases: 1) never rejected before, we have no deactivation date */}{" "}
            {/* 2) rejected recently, and we have deactiation date */}
            {/* that's why we don't */}
            {!editedPackage.mainPackage.approvedByAdmin ? (
              editedPackage.mainPackage.deactivatedDate ? (
                <Block>
                  <Text>{t("lastRejectedAt")}:</Text>
                  <Text>
                    {dayjs(editedPackage.mainPackage.deactivatedDate).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </Text>
                </Block>
              ) : (
                <Block>
                  <Text style={{ flexBasis: "100%" }}>
                    {t("waitingAdminReview")}
                  </Text>
                </Block>
              )
            ) : null}
            <br />
            <Block>
              <Text>{t("status")}:</Text>
              <Text>{editedPackage.mainPackage.status}</Text>
            </Block>
            {isAdmin && editedPackage.mainPackage.status === "draft" ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={approvePackageChanges}
                text={t("publish")}
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={rejectPackageChanges}
                text={
                  editedPackage.mainPackage.status === "draft"
                    ? `${t("rejectChanges")}`
                    : `${t("moveToDraft")}`
                }
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={copyProduct}
                text={t("copy")}
              />
            ) : null}
            {/* {editedPackage.mainPackage.activatedDate ? (
            <Block>
              <Text>გააქტიურდა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.activatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null}
          {editedPackage.mainPackage.deactivatedDate ? (
            <Block>
              <Text>დასადასტურებლად გაიგზავნა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.deactivatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null} */}
          </Column>
        </Row>
      </Accordion>
    </Wrapper>
  );
};
//
//
//
// styles
const CustomHorizontalLine = styled.div`
  margin-block: 20px;
  padding-block: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: all 200ms ease;
    height: 25px;
    width: 55px;
    min-width: 55px;
  }
  span {
    white-space: nowrap;
    font-weight: bold;
    color: ${Colors.primaryBlack};
  }
  div {
    height: 2px;
    width: 100%;
    background-color: ${Colors.primaryRed};
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  /* hover */
  &:hover {
    svg {
      transition: all 200ms ease;
      transform: scale(1.05);
    }
  }
`;
const Wrapper = styled.div`
  .MuiSvgIcon-fontSizeMedium {
    color: ${Colors.primaryRed};
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
  }
  .MuiFormLabel-root {
    font-family: inherit;
    font-weight: 600;
  }
  .admin-addons-accoridon {
    .accordion-head-title {
      color: ${Colors.primaryRed};
      font-weight: bold;
    }
    .accordion-head-title ~ svg {
      color: ${Colors.primaryRed};
    }
  }
`;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid ${Colors.primaryGrey};
  padding-bottom: 10px;
  margin-bottom: 10px; */
  /*  */
  /* > div {
    flex-basis: 50%;
  } */
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
  color: ${Colors.primaryBlack};
`;
const DocumentsWrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;
const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;
const CoverageItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const CoverageBlock = styled.div`
  max-height: 450px;
  overflow-y: scroll;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 2px;
  margin-top: 20px;
`;
const CustomBlock = styled(Block)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  div {
    width: 475px;
  }
  ${Responsive.laptop} {
    div {
      width: 330px;
    }
  }
  ${Responsive.tablet} {
    flex-direction: row;
    div {
      width: 300px;
    }
  }
`;
const areEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps.tplPackageId === nextProps.tplPackageId) {
    return true; // do NOT re-render
  } else {
    return false; // will re-render
  }
};

export default React.memo(TplPackageEditor, areEqual);
