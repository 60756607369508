import { Card, Flex, Typography } from "antd";
import React from "react";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import { useAppSelector } from "../../../../store";

const { Text } = Typography;

const AdminPetCategories = () => {
  const { lang } = useAppSelector((root) => root.app);

  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);

  return isPetCategoriesLoading ? (
    <Loader />
  ) : (
    <Flex justify="space-evenly" gap={20} align="center">
      {petCategories?.map((item, index) => (
        <Card key={index} style={{ width: "50%" }}>
          <Text>{item?.translations?.[lang]?.name}</Text>
        </Card>
      ))}
    </Flex>
  );
};

export default AdminPetCategories;
