import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import styled from "styled-components";
import { useAppSelector } from "../store";
import { AppState } from "../store/appSlice";

const CustomToast = () => {
  const { appTheme } = useAppSelector((data: { app: AppState }) => data.app);

  return (
    <Wrapper>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        draggable={true}
        newestOnTop={true}
        theme={appTheme}
        transition={Zoom}
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  user-select: none;

  .Toastify__toast {
    font-family: inherit;
    font-size: 14px;
  }
`;

export default CustomToast;
