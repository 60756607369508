import { LoadingOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Flex, Form, Input, Spin } from "antd";
import Title from "antd/es/typography/Title";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import { Container } from "../../../assets/styles/GlobalStyle";
import { UserRole } from "../../../domain/types";
import { useAppSelector } from "../../../store";

const InnerLoader = () => {
  return (
    <Flex
      justify="center"
      align="center"
      style={{
        height: "100svh",
        width: "100vw",
        backgroundColor: Colors.primaryGrey,
        overflow: "hidden",
      }}
    >
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </Flex>
  );
};

// If there is a token, then log in automatically
const AutoLogin = ({ children }: any) => {
  const auth = useAppSelector((data) => data.auth);
  if (auth.userloading) {
    return <InnerLoader />;
  }
  return auth.token && auth.user && auth.user.userType !== "User" ? (
    <Navigate
      replace
      to={"dashboard"}
      state={{ from: "routerProps.location" }}
    />
  ) : (
    children
  );
};

const Admin_login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [login, { isLoading, data: isLoginData }] = api.useLoginMutation();
  const [logout, { isLoading: logOutLoading }] = api.useLogoutMutation();
  const [form] = Form.useForm();

  const LogoutFunction = useCallback(async () => {
    try {
      await logout({}).unwrap();
    } catch (error) {
      toast("logout error | " + error);
    }
  }, [logout]);

  // if user don't have access in admin
  useEffect(() => {
    if (
      (isLoginData && isLoginData.user.userType === UserRole["User"]) ||
      (isLoginData && isLoginData.user.activated === false)
    ) {
      LogoutFunction();

      if (isLoginData.user.userType === UserRole["User"]) {
        toast.info(`${t("notAccessAdmin")}`);
      }

      if (isLoginData.user.activated === false) {
        toast.info(`${t("accountDeactivated")}`);
      }
    }
  }, [isLoginData, LogoutFunction, t]);

  const onFinish = async (values: any) => {
    try {
      await login({ email: values.email, password: values.password }).unwrap();
      navigate(`dashboard`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const MainLoading = isLoading || logOutLoading;

  return MainLoading ? (
    <InnerLoader />
  ) : (
    <AutoLogin>
      <Wrapper>
        <Container>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            onFinish={onFinish}
            autoFocus={true}
          >
            <Flex
              vertical
              align="center"
              justify="center"
              style={{
                maxWidth: "450px",
                marginInline: "auto",
                background: Colors.white,
                padding: "20px",
                borderRadius: "20px",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(10px)",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Title level={4} style={{ marginBottom: "20px" }}>
                {t("administrator")}
              </Title>

              <Form.Item
                style={{
                  width: "100%",
                }}
                name="email"
                rules={[{ required: true }]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={t("email")}
                />
              </Form.Item>

              <Form.Item
                style={{
                  width: "100%",
                }}
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password
                  autoComplete="on"
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={t("password")}
                />
              </Form.Item>

              <Form.Item
                shouldUpdate
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {() => (
                  <Button
                    style={{
                      width: "100%",
                      paddingInline: "100px",
                    }}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {t("signIn")}
                  </Button>
                )}
              </Form.Item>
            </Flex>
          </Form>
        </Container>
      </Wrapper>
    </AutoLogin>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
  background: linear-gradient(
    -26.3deg,
    ${Colors.grey} 50%,
    ${Colors.primaryRed} 50%
  );
  display: grid;
  align-items: center;
`;

export default Admin_login;
