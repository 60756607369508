import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Input, Select, Space, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import { StoreFraniUser } from "../../../domain/types";
const { Text } = Typography;

const Admin_frani_user_edit = () => {
  const { userId: userIdStr = "0" } = useParams();
  const userID = parseInt(userIdStr);
  const { t } = useTranslation();
  const [franiUserInfo, setFraniUserInfo] = useState<Partial<StoreFraniUser>>(
    {}
  );
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const rolesArray = ["Frani-Product-Owner", "Frani-Admin"];
  const navigate = useNavigate();

  // frani user by id
  const {
    data: user,
    isLoading: isUserLoading,
    refetch: isUserRefetch,
  } = api.useGetFraniUserByIdQuery(userID, { skip: !userID && true });

  //  store frani user  & update frani user
  const [storeFraniUser, { isLoading: isStoreFraniUserLoading }] =
    api.useStoreFraniUserMutation();

  // stroe frani user function
  const StoreFraniUserunction = async () => {
    // set user id for store & update
    setFraniUser("id", userID);
    try {
      await storeFraniUser(franiUserInfo).unwrap();
      isUserRefetch();
      toast.success(
        userID === 0
          ? `${t("user")} ${t("createdSuccessfully")}`
          : `${t("user")} ${t("updatedSuccessfully")}`
      );
      navigate("/frani-users");
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      }
    }
  };

  // add travel addon fields
  const setFraniUser = (field: keyof StoreFraniUser, value: any) => {
    setFraniUserInfo({
      ...franiUserInfo,
      [field]: value,
    });
  };

  // set updated user in state
  useEffect(() => {
    if (user) {
      const customUserVariable = {
        adminRole: user?.roles?.[0].role,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        id: user?.id,
      };
      if (customUserVariable) {
        setFraniUserInfo(customUserVariable);
      }
    }
  }, [user]);

  const mainLoading = isUserLoading || isStoreFraniUserLoading;

  return (
    <>
      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <Button
              size="large"
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>

            <Title level={3}>
              {userID === 0 ? `${t("createUser")}` : `${t("updateUser")}`}
            </Title>
          </Flex>

          <Divider variant="dashed" />

          <Flex gap={20} wrap="wrap" style={{ marginTop: "20px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("firstName")}</Text>
              <Input
                size="large"
                placeholder=""
                value={franiUserInfo.firstName || ""}
                onChange={(e) => setFraniUser("firstName", e?.target?.value)}
                width={"fullWidth"}
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("lastName")}</Text>
              <Input
                size="large"
                placeholder={t("lastName")}
                value={franiUserInfo.lastName || ""}
                onChange={(e) => setFraniUser("lastName", e?.target?.value)}
                width={"fullWidth"}
              />
            </Space>

            {userID === 0 ? (
              <Space direction="vertical" style={{ width: "100%" }}>
                <Text>{t("userRole")}</Text>
                <Select
                  style={{
                    width: "100%",
                  }}
                  placeholder={t("userRole")}
                  size="large"
                  value={franiUserInfo.adminRole || ""}
                  onChange={(value) => setFraniUser("adminRole", value)}
                  options={[
                    ...rolesArray.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                />
              </Space>
            ) : (
              <>
                {rolesArray && franiUserInfo && franiUserInfo.adminRole && (
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("userRole")}</Text>
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder={t("userRole")}
                      size="large"
                      value={franiUserInfo.adminRole || ""}
                      onChange={(value) => setFraniUser("adminRole", value)}
                      options={[
                        ...rolesArray.map((item) => ({
                          value: item,
                          label: item,
                        })),
                      ]}
                    />
                  </Space>
                )}
              </>
            )}

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("email")}</Text>
              <Input
                type="email"
                size="large"
                placeholder={t("email")}
                value={franiUserInfo.email || ""}
                onChange={(e) => setFraniUser("email", e?.target?.value)}
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("password")}</Text>
              <Input.Password
                type="password"
                size="large"
                placeholder={t("password")}
                value={franiUserInfo.password || ""}
                onChange={(e) => setFraniUser("password", e?.target?.value)}
                visibilityToggle={{
                  visible: passwordVisible,
                  onVisibleChange: setPasswordVisible,
                }}
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("repeatPassword")}</Text>
              <Input.Password
                type="password"
                size="large"
                placeholder={t("repeatPassword")}
                value={franiUserInfo.passwordConfirm || ""}
                onChange={(e) =>
                  setFraniUser("passwordConfirm", e?.target?.value)
                }
              />
            </Space>
          </Flex>

          <Divider variant="dashed" />

          <Flex justify="center" style={{ width: "100%" }}>
            <Button
              style={{ width: "80%" }}
              type="primary"
              size="large"
              onClick={StoreFraniUserunction}
            >
              {userID === 0 ? `${t("create")}` : `${t("update")}`}
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};
export default Admin_frani_user_edit;
