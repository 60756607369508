import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import {
  EnabledLanguage,
  TplListItems,
  TplPagesWording,
} from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
const { Text } = Typography;

type TplPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};

type TplPageTranslation = {
  title: string;
  stepName: string;
};

const Admin_tpl_manage_pages_edit = () => {
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);
  const { slug } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<TplListItems>
  >({});

  // updated main info
  const [updatedPageTextArry, setUpdatedPageTextArry] = useState<
    Partial<TplPagesWording>
  >({});

  const {
    data: existingTplPageItem,
    isLoading: isExistringTplPageItemLoading,
    refetch: RefetchExistingItem,
  } = api.useGetTplFlowPageTextItemByListItemIdQuery(
    { itemID: itemID as number, slug: slug as string },
    {
      skip: !itemID && !slug && true,
    }
  );

  // get tpl flow for page title and stepName
  const {
    data: pageTextArry,
    isLoading: isPageTextArrayLoading,
    refetch: RefetchPageTextArry,
  } = api.useGetTplFlowPageTextsBySlugQuery(slug as string, {
    skip: !slug && true,
  });

  // add tpl addon fields
  const setTplFlowTexts = (field: keyof TplListItems, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };

  // add tpl translation fields
  const setAddTplAddonTranslatedField = (
    field: keyof TplPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };

  // add tpl main translation fields
  const setAddTplMainAddonTranslatedField = (
    field: keyof TplPageTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextArry({
      ...updatedPageTextArry,
      translations: {
        ...updatedPageTextArry.translations,
        [language]: {
          ...updatedPageTextArry?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };

  const [updatePageByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateTplFlowPageItemByListItemIdMutation();

  const [updateTplFlow, { isLoading: isUpdateTplFlowLoading }] =
    api.useUpdateTplFlowMutation();

  const UpdateTplPageTexts = async () => {
    try {
      await updatePageByListItemId({
        item: updatedPageTextsState,
        slug: slug as string,
      }).unwrap();
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      RefetchExistingItem();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const UpdateMainTexts = async () => {
    try {
      await updateTplFlow({
        item: updatedPageTextArry,
        slug: slug as string,
      }).unwrap();
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      RefetchPageTextArry();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  useEffect(() => {
    if (existingTplPageItem) {
      setUpdatedPageTextsState(existingTplPageItem);
    }
  }, [existingTplPageItem]);

  useEffect(() => {
    if (pageTextArry) {
      setUpdatedPageTextArry(pageTextArry);
    }
  }, [pageTextArry]);

  const mainLoading =
    isExistringTplPageItemLoading ||
    isUpdatePageLoading ||
    isPageTextArrayLoading ||
    isUpdateTplFlowLoading;

  return mainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {updatedPageTextsState?.translations?.[field_Language]?.title}
        </Title>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <Flex vertical gap={20}>
        {updatedPageTextArry?.translations?.[field_Language]?.title &&
          itemID === 0 && (
            <Space direction="vertical">
              <Text>{t("pageTitle")}</Text>
              <TextArea
                size="large"
                placeholder={t("pageTitle")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextArry?.translations?.[field_Language]?.title ||
                  ""
                }
                onChange={(event) =>
                  setAddTplMainAddonTranslatedField(
                    "title",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>
          )}

        {updatedPageTextArry?.translations?.[field_Language]?.stepName &&
          itemID === 0 && (
            <Space direction="vertical">
              <Text>{t("stepName")}</Text>
              <TextArea
                size="large"
                placeholder={t("stepName")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextArry?.translations?.[field_Language]
                    ?.stepName || ""
                }
                onChange={(event) =>
                  setAddTplMainAddonTranslatedField(
                    "stepName",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>
          )}

        {updatedPageTextsState?.translations?.[field_Language]?.title && (
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <TextArea
              size="large"
              placeholder={t("title")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]?.title ||
                ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.body && (
          <Space direction="vertical">
            <Text>{t("body")}</Text>
            <TextArea
              size="large"
              placeholder={t("body")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]?.body ||
                ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "body",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.excerpt && (
          <Space direction="vertical">
            <Text>{t("additionalInformation")}</Text>
            <TextArea
              size="large"
              placeholder={t("additionalInformation")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.excerpt || ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "excerpt",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.secondTitle && (
          <Space direction="vertical">
            <Text>{t("secondTitle")}</Text>
            <TextArea
              size="large"
              placeholder={t("secondTitle")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.secondTitle || ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "secondTitle",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]
          ?.secondExcerpt && (
          <Space direction="vertical">
            <Text>{t("secondAdditionalInformation")}</Text>
            <TextArea
              size="large"
              placeholder={t("secondAdditionalInformation")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.secondExcerpt || ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "secondExcerpt",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]?.thirdTitle && (
          <Space direction="vertical">
            <Text>{t("thirdTitle")}</Text>
            <TextArea
              size="large"
              placeholder={t("thirdTitle")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.thirdTitle || ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "thirdTitle",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.translations?.[field_Language]
          ?.thirdExcerpt && (
          <Space direction="vertical">
            <Text>{t("thirdAdditionalInformation")}</Text>
            <TextArea
              size="large"
              placeholder={t("thirdAdditionalInformation")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.thirdExcerpt || ""
              }
              onChange={(event) =>
                setAddTplAddonTranslatedField(
                  "thirdExcerpt",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {updatedPageTextsState?.image && (
          <UploadFile
            label={t("uploadImage")}
            onChange={(props) => setTplFlowTexts("image", props.url)}
            defaultFileName={updatedPageTextsState?.image || ""}
          />
        )}

        <Flex justify="center">
          {itemID !== 0 ? (
            <Button
              style={{ width: "80%" }}
              size="large"
              type="primary"
              onClick={UpdateTplPageTexts}
            >
              {t("update")}
            </Button>
          ) : (
            <Button
              style={{ width: "80%" }}
              size="large"
              type="primary"
              onClick={UpdateMainTexts}
            >
              {t("update")}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default Admin_tpl_manage_pages_edit;
