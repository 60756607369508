import { DownloadOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Empty,
  Flex,
  Input,
  Pagination,
  Select,
  Table,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useDebounce } from "use-debounce";
import api from "../../../api";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";
import Loader from "../../../components/Loader";

type FilterFieldsType = {
  paymentDateFrom: string | null;
  paymentDateTo: string | null;
  privateNumber: string | null;
  orderId: string | null;
  productCategoryId: string | null;
};

const AdminCompanyPaymentList = () => {
  const { lang } = useAppSelector((root) => root.app);
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { t } = useTranslation();

  const [filterFieldsState, setFilterFieldsState] = useState<FilterFieldsType>({
    paymentDateFrom: null,
    paymentDateTo: null,
    privateNumber: null,
    orderId: null,
    productCategoryId: null,
  });

  const setFilterField = (
    fieldName: keyof FilterFieldsType,
    fieldValue: any
  ) => {
    setFilterFieldsState({
      ...filterFieldsState,
      [fieldName]: fieldValue,
    });
  };

  const {
    data: AdminProductCategories,
    isLoading: isAdminProductCategoriesLoading,
  } = api.useGetAdminProductCategoriesListQuery(undefined);

  const [pageForAllPayment, setPageForAllPayments] = useState<number>(1);

  // user current company
  const { data: companyList, isLoading: isCompanyListLoading } =
    api.useCompanyListQuery(undefined);

  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);

  const [privateNumberDebounce] = useDebounce(
    filterFieldsState?.privateNumber,
    1000
  );
  const [orderIdDebounce] = useDebounce(filterFieldsState?.orderId, 1000);

  // company payment list
  const {
    data: allCompanyPaymentList,
    isLoading: isAllCompanyPaymentListLoading,
    refetch: refetchPayments,
  } = api.useGetAllCompanyPaymentListQuery({
    paymentDateFrom: filterFieldsState?.paymentDateFrom,
    paymentDateTo: filterFieldsState?.paymentDateTo,
    privateNumber: privateNumberDebounce,
    orderId: orderIdDebounce,
    productCategoryId: filterFieldsState.productCategoryId,
    companyId: selectedCompanyId,
    page: pageForAllPayment,
    limit: 20,
  });

  const [
    sendAllPaymentsReport,
    {
      isLoading: isSendAllPaymentsReportLoading,
      data: filteredAllPaymentsReport,
    },
  ] = api.useGetDownloadAllCompanyPaymentListMutation();

  // watch and download excel
  useEffect(() => {
    if (filteredAllPaymentsReport) {
      document.location.href = filteredAllPaymentsReport?.url || "";
    }
    return () => {};
  }, [filteredAllPaymentsReport]);

  const downloadPdfReport = async () => {
    try {
      await sendAllPaymentsReport({
        paymentDateFrom: filterFieldsState.paymentDateFrom,
        paymentDateTo: filterFieldsState.paymentDateTo,
        companyId: selectedCompanyId,
        productCategoryId: filterFieldsState.productCategoryId,
        privateNumber: filterFieldsState.privateNumber,
        orderId: filterFieldsState.orderId,
      }).unwrap();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  useEffect(() => {
    if (auth.user?.companyUsers?.[0]?.companyId) {
      setSelectedCompanyId(auth.user?.companyUsers?.[0]?.companyId);
    }
    return () => {};
  }, [auth.user?.companyUsers]);

  const _clearProps = async () => {
    await setSelectedCompanyId(undefined);
    await setFilterFieldsState({
      paymentDateFrom: null,
      paymentDateTo: null,
      privateNumber: null,
      orderId: null,
      productCategoryId: null,
    });
    refetchPayments();
  };

  const columns = [
    {
      name: t("payer"),
      key: "fullName",
      dataIndex: "fullName",
    },
    {
      name: t("personalNum"),
      key: "personalNumber",
      dataIndex: "personalNumber",
    },
    {
      name: t("totalCommission"),
      key: "totalCommission",
      dataIndex: "totalCommission",
    },
    {
      name: t("franiCommission"),
      key: "currentCommission",
      dataIndex: "currentCommission",
    },
    {
      name: t("franiRate"),
      key: "franiPercent",
      dataIndex: "franiPercent",
    },
    {
      name: t("paymentDate"),
      key: "paymentDate",
      dataIndex: "paymentDate",
    },
    {
      name: t("scheduledPaymentDate"),
      key: "scheduledPaymentDate",
      dataIndex: "scheduledPaymentDate",
    },
    {
      name: t("scheduledPayment"),
      key: "scheduledPayment",
      dataIndex: "scheduledPayment",
    },
    {
      name: t("policyPrice"),
      key: "policyPrice",
      dataIndex: "policyPrice",
    },
    {
      name: t("product"),
      key: "product",
      dataIndex: "product",
    },
    {
      name: t("policyNumber"),
      key: "policyNumber",
      dataIndex: "policyNumber",
    },
    {
      name: t("orderId"),
      key: "orderId",
      dataIndex: "orderId",
    },
    {
      name: t("amount"),
      key: "paymentAmount",
      dataIndex: "paymentAmount",
    },
    {
      name: t("paymentStatus"),
      key: "paymentStatus",
      dataIndex: "paymentStatus",
    },
  ];

  return (
    <>
      <FilterBlock>
        <Select
          loading={isCompanyListLoading}
          size="large"
          showSearch
          value={selectedCompanyId || null}
          placeholder={t("chooseCompany")}
          optionFilterProp="label"
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          onChange={(value) => [
            setPageForAllPayments(1),
            setSelectedCompanyId(value),
          ]}
          options={
            companyList
              ? [
                  ...companyList.map((item) => ({
                    value: item.id,
                    label: item.brandName
                      ? item.brandName?.[lang]?.title
                      : item.slug,
                  })),
                ]
              : undefined
          }
        />

        <Select
          loading={isAdminProductCategoriesLoading}
          size="large"
          value={filterFieldsState.productCategoryId || null}
          placeholder={t("productCategory")}
          optionFilterProp="label"
          onChange={(value) => setFilterField("productCategoryId", value)}
          options={
            AdminProductCategories
              ? [
                  ...AdminProductCategories.map((item) => ({
                    value: item.id,
                    label: item.slug?.replace(/-/g, " "),
                  })),
                ]
              : undefined
          }
        />

        <DatePicker
          size="large"
          placeholder={t("fromPaymentsDate")}
          style={{ width: "100%" }}
          value={
            filterFieldsState?.paymentDateFrom
              ? dayjs(filterFieldsState?.paymentDateFrom)
              : null
          }
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              setFilterField(
                "paymentDateFrom",
                // @ts-ignore
                dayjs.utc(dateString).format("YYYY-MM-DD")
              );
            } else {
              setFilterField("paymentDateFrom", null);
            }
          }}
        />

        <DatePicker
          size="large"
          placeholder={t("uptoPaymentDate")}
          style={{ width: "100%" }}
          value={
            filterFieldsState?.paymentDateTo
              ? dayjs(filterFieldsState?.paymentDateTo)
              : null
          }
          onChange={(date) => {
            const dateString = dayjs(date).format("YYYY-MM-DD");
            if (dateString && date) {
              // @ts-ignore
              setFilterField(
                "paymentDateTo",
                // @ts-ignore
                dayjs.utc(dateString).format("YYYY-MM-DD")
              );
            } else {
              setFilterField("paymentDateTo", null);
            }
          }}
        />

        <Input
          value={filterFieldsState?.privateNumber || ""}
          size="large"
          placeholder={`${t("personalNumber")}`}
          onChange={(e) => setFilterField("privateNumber", e.target.value)}
        />

        <Input
          value={filterFieldsState?.orderId || ""}
          size="large"
          placeholder={`${t("orderId")}`}
          onChange={(e) => setFilterField("orderId", e.target.value)}
        />

        <Button size="large" type="dashed" danger onClick={_clearProps}>
          {t("clear")}
        </Button>

        <Button
          type="primary"
          icon={<DownloadOutlined />}
          size="large"
          onClick={downloadPdfReport}
          loading={isSendAllPaymentsReportLoading}
        >
          {t("downloadReports")}
        </Button>
      </FilterBlock>

      {isAllCompanyPaymentListLoading ? (
        <Loader />
      ) : (
        <>
          <Divider>
            {t("searched")} <b>{allCompanyPaymentList?.meta?.total || 0}</b>{" "}
            {t("transaction")}
          </Divider>

          <Table
            scroll={{ x: "max-content" }}
            locale={{
              emptyText: <Empty description={t("paymentsNotFound")} />,
            }}
            loading={isAllCompanyPaymentListLoading}
            pagination={false}
            dataSource={
              allCompanyPaymentList &&
              allCompanyPaymentList.data &&
              allCompanyPaymentList?.data?.length >= 1
                ? [
                    ...allCompanyPaymentList.data?.map((item, index) => ({
                      key: index,
                      fullName: item?.fullName,
                      personalNumber: item?.personalNumber,
                      totalCommission: item?.totalCommission,
                      currentCommission: item?.currentCommission
                        ? item?.currentCommission
                        : "",
                      franiPercent: item?.franiPercent,
                      paymentDate: dayjs(item?.paymentDate).format(
                        "DD / MM / YYYY / HH : MM"
                      ),
                      scheduledPaymentDate: dayjs(
                        item?.scheduledPaymentDate
                      ).format("DD / MM / YYYY / HH : MM"),
                      scheduledPayment: item?.scheduledPayment,
                      policyPrice: item?.policyPrice,
                      product: item?.productCategory?.replaceAll("-", " "),
                      policyNumber: item.policyNumber,
                      orderId: item.orderId,
                      paymentAmount: item?.paymentAmount,
                      paymentStatus: item?.paymentStatus,
                    })),
                  ]
                : []
            }
            columns={[
              ...columns.map((item) => ({
                title: item.name,
                dataIndex: item.dataIndex,
              })),
            ]}
          />

          {allCompanyPaymentList &&
          allCompanyPaymentList.meta &&
          allCompanyPaymentList.meta.total &&
          allCompanyPaymentList?.meta?.total >= 1 ? (
            <Flex justify="center" style={{ marginTop: "20px" }}>
              <Pagination
                responsive={true}
                current={allCompanyPaymentList?.meta?.current_page || 0}
                total={allCompanyPaymentList?.meta?.total || 0}
                pageSize={allCompanyPaymentList?.meta?.per_page || 0}
                showSizeChanger={false}
                onChange={(page) => setPageForAllPayments(page)}
              />
            </Flex>
          ) : null}
        </>
      )}
    </>
  );
};

const FilterBlock = styled.div`
  -webkit-box-shadow: 0px 0px 15px 3px #64646411;
  box-shadow: 0px 0px 15px 3px #64646411;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 10px;
  padding: 20px;
  margin-bottom: 30px;
  align-items: flex-end;
`;
//

export default AdminCompanyPaymentList;
