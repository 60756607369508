import { ClearOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Flex,
  Input,
  Select,
  Space,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDebounce } from "use-debounce";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { PetBreed } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
const { Text } = Typography;

type FilterFieldsType = {
  searchTerm: string;
  animalCategoryId: number;
  status: string;
};

const AdminPetBreed = () => {
  const { t } = useTranslation();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  const [petBreeds, setPetBreeds] = useState<Partial<PetBreed>[]>([]);
  const [updatedIds, setUpdatedIds] = useState<number[]>([]);
  const [filterFields, setFilterFields] =
    useState<Partial<FilterFieldsType> | null>({
      status: undefined,
    });

  const _setFilterFields = (field: keyof FilterFieldsType, value: any) => {
    setFilterFields({
      ...filterFields,
      [field]: value,
    });
  };
  //
  const [storeBreeds, { isLoading: isStoreBreedsLoading }] =
    api.useUpdatePetBreedsMutation();

  const setFields = (id: number, field: keyof PetBreed, value: any) => {
    // set updated data
    const edited = petBreeds.map((item) =>
      item.id === id ? { ...item, [field]: value } : item
    );

    setPetBreeds(edited);
    // set updated item ids in array
    if (updatedIds.includes(id)) {
    } else {
      setUpdatedIds([...updatedIds, id]);
    }
  };

  const addNewBreed = () => {
    setPetBreeds([...petBreeds, { id: -1 * petBreeds.length }]);
  };

  const { data: petCategories, isLoading: isPetCategoriesLoading } =
    api.useGetPetCategoriesQuery(undefined);

  const [debouncedSearchTerm] = useDebounce(filterFields?.searchTerm, 1000);

  const query = `${
    debouncedSearchTerm !== undefined && debouncedSearchTerm !== ""
      ? `SearchTerm=${debouncedSearchTerm}&`
      : ``
  }${
    filterFields?.animalCategoryId !== undefined &&
    filterFields?.animalCategoryId !== null
      ? `AnimalCategoryId=${filterFields?.animalCategoryId}&`
      : ``
  }${
    filterFields?.status !== undefined && filterFields?.status !== ""
      ? `Status=${filterFields?.status}`
      : ``
  }`;

  const {
    data: petBreedsData,
    isLoading: isPetBreedsLoading,
    refetch: refetchPetBreeds,
  } = api.useGetPetBreedsQuery(
    {
      query: query,
    },
    {
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  // set existing breeds
  useEffect(() => {
    if (petBreedsData) {
      setPetBreeds(petBreedsData);
    }
    return () => {};
  }, [petBreedsData]);

  const _StorePetBreeds = async () => {
    const filteredObjects = petBreeds.filter((obj1) =>
      updatedIds.some((obj2) => obj2 === obj1.id)
    );

    const formatedData =
      filteredObjects &&
      filteredObjects.map((item) => ({
        id: item.id && item.id > 0 ? item.id : null,
        categoryId: item.categoryId,
        translations: item.translations,
        status: item.status,
      }));

    try {
      await storeBreeds(formatedData as never).unwrap();
      // refetch
      refetchPetBreeds();
      toast.success(`${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const statusesOptions = [
    {
      translations: {
        en: {
          title: "Draft",
        },
        ge: {
          title: "დრაფტი",
        },
      },
      slug: "draft",
    },
    {
      translations: {
        en: {
          title: "Published",
        },
        ge: {
          title: "დადასტურებული",
        },
      },
      slug: "published",
    },
  ];

  const MainLoading =
    isPetCategoriesLoading || isPetBreedsLoading || isStoreBreedsLoading;

  return (
    <>
      <Flex gap={20} justify="space-between">
        <Title level={4}>{t("addAndUpdateBreeds")}</Title>
        <AdminLangSwicher />
      </Flex>

      <Divider />

      <GridMinMax>
        <Space direction="vertical">
          <Text>{t("searchByName")}</Text>
          <Input
            size="large"
            placeholder={t("searchByName")}
            value={filterFields?.searchTerm || ""}
            onChange={(event) =>
              _setFilterFields("searchTerm", event?.target?.value)
            }
          />
        </Space>

        <Space direction="vertical">
          <Text>{t("petCategory")}</Text>
          <Select
            placeholder={t("petCategory")}
            size="large"
            style={{
              width: "100%",
            }}
            value={filterFields?.animalCategoryId || null}
            onChange={(value) => _setFilterFields("animalCategoryId", value)}
            options={
              petCategories
                ? [
                    ...petCategories.map((item) => ({
                      value: item.id,
                      label: item?.translations?.[field_Language]?.name,
                    })),
                  ]
                : []
            }
          />
        </Space>

        {statusesOptions && (
          <Space direction="vertical">
            <Text>{t("status")}</Text>
            <Select
              placeholder={t("status")}
              size="large"
              style={{
                width: "100%",
              }}
              value={filterFields?.status || null}
              onChange={(value) => _setFilterFields("status", value)}
              options={
                statusesOptions
                  ? [
                      ...statusesOptions.map((item) => ({
                        value: item.slug,
                        label: item?.translations?.[field_Language]?.title,
                      })),
                    ]
                  : []
              }
            />
          </Space>
        )}

        <Button
          danger
          size="large"
          type="dashed"
          icon={<ClearOutlined />}
          onClick={() =>
            setFilterFields({
              status: undefined,
            })
          }
        >
          {t("clear")}
        </Button>
      </GridMinMax>

      <>
        {MainLoading ? (
          <Loader />
        ) : (
          <>
            {petBreeds && petBreeds.length >= 1 ? (
              <Divider>
                {t("searched")} <b>{petBreeds?.length}</b> {t("breed")}
              </Divider>
            ) : null}

            <Flex vertical gap={20}>
              {petBreeds.map((item, index) => (
                <Card size="small" key={index} hoverable>
                  <GridMinMax size={200}>
                    <Space direction="vertical">
                      <Text>{t("breedName")}</Text>
                      <Input
                        size="large"
                        placeholder={t("breedName")}
                        value={item?.translations?.[field_Language]?.name || ""}
                        onChange={(event) => {
                          const currentTranslationObj = petBreeds.find(
                            (n) => n.id === item.id
                          );

                          if (currentTranslationObj) {
                            const updated = {
                              ...currentTranslationObj?.translations,
                              [field_Language]: {
                                ...currentTranslationObj?.translations?.[
                                  field_Language
                                ],
                                name: event?.target?.value,
                              },
                            };
                            setFields(
                              item.id as number,
                              "translations",
                              updated
                            );
                          }
                        }}
                      />
                    </Space>

                    {petCategories && (
                      <Space direction="vertical">
                        <Text>{t("petCategory")}</Text>
                        <Select
                          placeholder={t("petCategory")}
                          size="large"
                          style={{
                            width: "100%",
                          }}
                          value={
                            petCategories.find((x) => x.id === item.categoryId)
                              ?.translations?.[field_Language]?.name
                          }
                          onChange={(value) =>
                            setFields(item.id as number, "categoryId", value)
                          }
                          options={
                            petCategories
                              ? [
                                  ...petCategories.map((category) => ({
                                    value: category.id,
                                    label:
                                      category?.translations?.[field_Language]
                                        ?.name,
                                  })),
                                ]
                              : []
                          }
                        />
                      </Space>
                    )}

                    <Space direction="vertical">
                      <Text>{t("petCategory")}</Text>
                      <Select
                        placeholder={t("petCategory")}
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        value={item?.status}
                        onChange={(value) =>
                          setFields(item.id as number, "status", value)
                        }
                        options={[
                          ...statusesOptions.map((item) => ({
                            value: item?.slug,
                            label: item?.translations?.[field_Language]?.title,
                          })),
                        ]}
                      />
                    </Space>
                  </GridMinMax>
                </Card>
              ))}
            </Flex>

            <Divider />

            <GridMinMax>
              <Button
                style={{
                  width: "100%",
                }}
                type="dashed"
                size="large"
                onClick={addNewBreed}
              >
                {t("add")}
              </Button>

              <Button
                style={{
                  width: "100%",
                }}
                type="primary"
                size="large"
                onClick={_StorePetBreeds}
              >
                {t("save")}
              </Button>
            </GridMinMax>
          </>
        )}
      </>
    </>
  );
};

export default AdminPetBreed;
