import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { EnabledLanguage, TravelAddon } from "../../../../domain/types";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

type AddTravelAddonTranslatedFields = {
  title: string;
  body: string;
};

const AdminTravelAddons = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { lang } = useAppSelector((root) => root.app);
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  // get admin product category list
  const { data: productCategoryList, isLoading: isProductCategoryListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);

  // selected addon id
  const [travelAddonId, setTravelAddonId] = useState<number | null>(null);

  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

  const productCategoryID =
    productCategoryList?.find((x) => x.slug === "travel-outside-georgia")?.id ||
    undefined;

  // add travel addon state
  const [addTravelAddonState, setAddTravelAddonState] = useState<
    Partial<TravelAddon>
  >({
    productCategoryId: productCategoryID,
  });

  // get travel addons
  const {
    data: travelAddons,
    isLoading: isTravelAddonsLoading,
    refetch: refetchTravelAddons,
  } = api.useGetAdminTravelAbroadAddonListQuery(undefined);

  // add travel addon
  const [saveTravelAddon, { isLoading: isAddTravelAddonLoading }] =
    api.useSaveTravelAddonMutation();

  // remvoe travel addon
  const [
    removeTravelAddon,
    {
      isLoading: isRemoveTravelAddonLoading,
      isSuccess: removeTravelAddonSucces,
    },
  ] = api.useRemoveTravelAddonMutation();

  //close add trave addon drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setTravelAddonId(null);
    setAddTravelAddonState({});
  };

  // add travel addon fields
  const setAddTravelAddon = (field: keyof TravelAddon, value: any) => {
    setAddTravelAddonState({
      ...addTravelAddonState,
      [field]: value,
    });
  };

  const { data: existingTravelAddon, isLoading: isExistingTravelAddonLoading } =
    api.useGetAdminTravelAddonByIdQuery(travelAddonId as number, {
      skip: !travelAddonId,
      refetchOnReconnect: true,
      refetchOnMountOrArgChange: true,
    });

  // add travel translation fields
  const setAddTravelAddonTranslatedField = (
    field: keyof AddTravelAddonTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddTravelAddonState({
      ...addTravelAddonState,
      translations: {
        ...addTravelAddonState.translations,
        [language]: {
          ...addTravelAddonState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add travel Addon function
  const AddTravelAddonFunction = async () => {
    //
    try {
      await saveTravelAddon(addTravelAddonState).unwrap();
      // refetch travel Addons
      refetchTravelAddons();
      // close drawer
      onCloseDrawer();
      toast.success(`${t("additionalCoverages")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // auto refetch travel addons when user remove addon
  useEffect(() => {
    if (removeTravelAddonSucces) {
      refetchTravelAddons();
      onCloseDrawer();
    }
  }, [removeTravelAddon, removeTravelAddonSucces, refetchTravelAddons]);

  useEffect(() => {
    if (travelAddonId && existingTravelAddon) {
      setAddTravelAddonState(existingTravelAddon);
    }
  }, [existingTravelAddon, travelAddonId]);

  useEffect(() => {
    if (
      productCategoryID &&
      addTravelAddonState.productCategoryId === undefined
    ) {
      setAddTravelAddonState({
        ...addTravelAddonState,
        productCategoryId: productCategoryID,
      });
    }

    return () => {};
  }, [addTravelAddonState, productCategoryID]);

  const drawerLoading =
    isExistingTravelAddonLoading ||
    isRemoveTravelAddonLoading ||
    isAddTravelAddonLoading;

  return (
    <>
      {isTravelAddonsLoading || isProductCategoryListLoading ? (
        <Loader />
      ) : (
        <>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => [
              setDrawerStatus(!drawerStatus),
              setAddTravelAddonState({}),
            ]}
          >
            {t("create")}
          </Button>

          <Divider />

          <GridBlock>
            {travelAddons &&
              travelAddons.map((item, index) => (
                <Card
                  key={index}
                  onClick={() => [
                    setTravelAddonId(item.id),
                    setDrawerStatus(!drawerStatus),
                  ]}
                  // style={{ width: 240 }}
                  hoverable
                  cover={
                    <img
                      alt="example"
                      style={{
                        marginInline: "auto",
                        paddingTop: "20px",
                        width: "50px",
                      }}
                      src={item?.image}
                    />
                  }
                >
                  <Meta
                    title={item.translations[lang]?.title}
                    description={item.translations[lang]?.body}
                  />
                </Card>
              ))}
          </GridBlock>
        </>
      )}

      <Drawer
        extra={
          <AdminLangSwicher
            onChange={(value) => dispatch(setEditedlanguage(value))}
          />
        }
        title={travelAddonId ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        {drawerLoading ? (
          <Flex justify="center" align="center">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </Flex>
        ) : (
          <>
            <Flex vertical gap={20}>
              <Input
                size="large"
                placeholder={t("slug")}
                value={addTravelAddonState.slug || ""}
                onChange={(event) =>
                  setAddTravelAddon("slug", event?.target?.value)
                }
                suffix={
                  <Tooltip title={t("slug")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />

              <Input
                size="large"
                placeholder={t("title")}
                value={
                  addTravelAddonState?.translations?.[field_Language]?.title ||
                  ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "title",
                    field_Language,
                    event?.target?.value
                  )
                }
                suffix={
                  <Tooltip title={t("title")}>
                    <InfoCircleOutlined />
                  </Tooltip>
                }
              />

              <TextArea
                size="large"
                value={
                  addTravelAddonState?.translations?.[field_Language]?.body ||
                  ""
                }
                onChange={(event) =>
                  setAddTravelAddonTranslatedField(
                    "body",
                    field_Language,
                    event?.target?.value
                  )
                }
                placeholder={t("description")}
                autoSize={{ minRows: 2 }}
              />

              <UploadFile
                label={t("icon")}
                onChange={(props) => setAddTravelAddon("image", props.url)}
                defaultFileName={addTravelAddonState?.image || ""}
              />
            </Flex>

            <Divider />

            <Flex align="center" justify="space-between" gap={20}>
              <Button
                size="large"
                onClick={AddTravelAddonFunction}
                disabled={drawerLoading}
                style={{
                  width: "100%",
                }}
              >
                {travelAddonId ? t("update") : t("create")}
              </Button>

              {travelAddonId && (
                <Popconfirm
                  disabled={drawerLoading}
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeTravelAddon(travelAddonId)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    disabled={drawerLoading}
                    danger
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          </>
        )}
      </Drawer>
    </>
  );
};
//
const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;
//
export default AdminTravelAddons;
