import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import React from "react";

const Loader: React.FC<{
  text?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
}> = ({ text = "", style, horizontal = false }) => {
  return (
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Spin indicator={<LoadingOutlined spin />} size="large" />
    </Flex>
  );
};

export default Loader;
