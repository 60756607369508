import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Segmented,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
import { EnabledLanguage, FaqTypes } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const Admin_manage_faq = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [selectedFaqId, setSelectedFaqId] = useState<number | null | undefined>(
    null
  );
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [selectedFaq, setSelectedFaq] = useState<Partial<FaqTypes>>({});
  const { data, isLoading, refetch } = api.useGetWebPageQuery("faq");
  const [_segmented, _setSegmented] = useState<string>("1");

  const FaqArray: FaqTypes = data as FaqTypes;
  const [updatedFaqMainTextsState, setUpdatedFaqMainTextsState] = useState<
    Partial<FaqTypes>
  >({});

  const {
    data: faqList,
    isLoading: isFaqListLoading,
    refetch: isFaqListRefetch,
  } = api.useGetFaqListQuery(undefined);

  const { data: faqById, isLoading: isFaqByIdLoading } = api.useGetFaqByIdQuery(
    selectedFaqId as number,
    {
      skip: !selectedFaqId,
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  // remvoe travel addon
  const [
    removeFaq,
    { isLoading: isRemoveFaqLoading, isSuccess: removeTravelFaqSucces },
  ] = api.useRemoveFaqByIdMutation();

  const [storeFaq, { isLoading: isStoreFaqLoading }] =
    api.useStoreFAQMutation();

  //  update faq main texts
  const [updateFaqMainText, { isLoading: isUpdateFaqMainTextLoading }] =
    api.useUpdateFaqMainTextMutation();

  // update faq main texts fun
  const updateFaqMainTextFun = async () => {
    try {
      await updateFaqMainText(updatedFaqMainTextsState).unwrap();
      refetch();
      toast.success(`${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // update Faq text
  const StoreFAQFnction = async () => {
    try {
      await storeFaq(selectedFaq).unwrap();
      // refetch
      isFaqListRefetch();
      // close drawer
      onCloseDrawer();
      toast.success(`FAQ ${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // add FAQ translation fields
  const setUpdateFaqMainTextTranslatedField = (
    field: keyof FaqTypes,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedFaqMainTextsState({
      ...updatedFaqMainTextsState,
      translations: {
        ...updatedFaqMainTextsState.translations,
        [language]: {
          ...updatedFaqMainTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add FAQ translation fields
  const setEditFaqTranslatedField = (
    field: keyof FaqTypes,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedFaq({
      ...selectedFaq,
      translations: {
        ...selectedFaq.translations,
        [language]: {
          ...selectedFaq?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  //close base drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setSelectedFaqId(null);
  };

  // auto refetch when user remove FAQ
  useEffect(() => {
    if (removeTravelFaqSucces) {
      isFaqListRefetch();
      onCloseDrawer();
    }
  }, [removeFaq, removeTravelFaqSucces, isFaqListRefetch]);

  // set Faq info in fields when user click Faq card
  useEffect(() => {
    if (faqById) {
      setSelectedFaq(faqById);
    }
  }, [faqById]);

  // updatedFaqMainTextsState
  useEffect(() => {
    if (FaqArray) {
      setUpdatedFaqMainTextsState(FaqArray);
    }
  }, [FaqArray]);

  // main loading
  const MainLoader =
    isFaqListLoading || isUpdateFaqMainTextLoading || isLoading;
  //
  const drawerLoading =
    isFaqByIdLoading || isStoreFaqLoading || isRemoveFaqLoading;
  //
  return MainLoader ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider>
        <Segmented<string>
          size="middle"
          style={{ width: "100%", minWidth: "200px" }}
          block
          options={[
            { label: t("questions"), value: "1" },
            { label: t("FAQ"), value: "2" },
          ]}
          value={_segmented}
          onChange={(value) => {
            _setSegmented(value);
          }}
        />
      </Divider>

      {_segmented === "1" && (
        <>
          <GridMinMax align="stretch">
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("pageName")}</Text>
              <TextArea
                size="large"
                placeholder={t("pageName")}
                autoSize={{ minRows: 2 }}
                value={
                  updatedFaqMainTextsState?.translations?.[field_Language]
                    ?.title || ""
                }
                onChange={(event) =>
                  setUpdateFaqMainTextTranslatedField(
                    "title",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("pageDescription")}</Text>
              <TextArea
                size="large"
                placeholder={t("pageDescription")}
                autoSize={{ minRows: 2 }}
                value={
                  updatedFaqMainTextsState?.translations?.[field_Language]
                    ?.body || ""
                }
                onChange={(event) =>
                  setUpdateFaqMainTextTranslatedField(
                    "body",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>
          </GridMinMax>

          <Divider variant="dashed" />

          <Flex justify="center">
            <Button
              type="primary"
              size="large"
              style={{ width: "60%" }}
              onClick={updateFaqMainTextFun}
            >
              {t("update")}
            </Button>
          </Flex>
        </>
      )}

      {_segmented === "2" && (
        <>
          <>
            <GridMinMax align="stretch">
              {faqList &&
                faqList.map((item, index) => (
                  <Card
                    loading={selectedFaqId === item?.id && isFaqByIdLoading}
                    hoverable
                    key={index}
                    onClick={() => [
                      setDrawerStatus(!drawerStatus),
                      setSelectedFaqId(item?.id),
                    ]}
                  >
                    <Flex vertical gap={10}>
                      <Text>{item?.translations?.[field_Language]?.title}</Text>
                      <Text>{item?.translations?.[field_Language]?.body}</Text>
                    </Flex>
                  </Card>
                ))}
            </GridMinMax>

            <Divider variant="dashed" />

            <Flex justify="center">
              <Button
                style={{ width: "60%" }}
                size="large"
                type="primary"
                onClick={() => [
                  setDrawerStatus(!drawerStatus),
                  setSelectedFaq({}),
                ]}
              >
                {t("create")}
              </Button>
            </Flex>
          </>

          <Drawer
            extra={<AdminLangSwicher />}
            title={selectedFaqId ? t("update") : t("create")}
            onClose={onCloseDrawer}
            open={drawerStatus}
            loading={drawerLoading}
          >
            <Flex vertical gap={20}>
              <Space direction="vertical">
                <Text>{t("title")}</Text>
                <Input
                  size="large"
                  placeholder={t("title")}
                  value={
                    selectedFaq?.translations?.[field_Language]?.title || ""
                  }
                  onChange={(event) =>
                    setEditFaqTranslatedField(
                      "title",
                      field_Language,
                      event?.target?.value
                    )
                  }
                />
              </Space>

              <Space direction="vertical">
                <Text>{t("description")}</Text>
                <Input
                  size="large"
                  placeholder={t("description")}
                  value={
                    selectedFaq?.translations?.[field_Language]?.body || ""
                  }
                  onChange={(event) =>
                    setEditFaqTranslatedField(
                      "body",
                      field_Language,
                      event?.target?.value
                    )
                  }
                />
              </Space>
            </Flex>

            <Divider />

            <Flex vertical gap={20}>
              <Button
                size="large"
                style={{
                  width: "100%",
                }}
                onClick={StoreFAQFnction}
              >
                {selectedFaqId ? t("update") : t("create")}
              </Button>

              {selectedFaqId && (
                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeFaq(selectedFaqId)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    danger
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Admin_manage_faq;
