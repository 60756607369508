import { PlusOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { EnabledLanguage, PetService } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

const { Text } = Typography;

const AdminPetServiceSecond = () => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [existingService, setExistingService] = useState<
    Partial<PetService> | undefined
  >(undefined);

  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const {
    data: petServices,
    isLoading: isPetServicesLoading,
    refetch: refetchServices,
  } = api.useGetPetServicesSecondQuery(undefined);

  const [createService, { isLoading: isCreateServiceLoading }] =
    api.useCreatePetServiceSecondMutation();

  const [updateService, { isLoading: isUpdateServiceLoading }] =
    api.useUpdatePetServiceSecondMutation();

  const [
    deletePetService,
    {
      isLoading: isDeletePetServiceLoading,
      isSuccess: isDeletePetServiceSuccess,
    },
  ] = api.useDeletePetServiceSecondMutation();

  const _createAndUpdateService = async () => {
    if (existingService) {
      try {
        if (existingService && existingService.id) {
          // update
          await updateService(existingService).unwrap();
        } else {
          // create
          await createService(existingService).unwrap();
        }
        refetchServices();
        setDrawerStatus(false);
        toast.success(
          existingService?.id
            ? `${t("otherService")} ${t("updatedSuccessfully")}`
            : `${t("otherService")} ${t("createdSuccessfully")}`
        );
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const onCloseDrawer = () => {
    setDrawerStatus(false);
  };

  const deleteService = (id: number) => {
    if (id) {
      return [deletePetService(id), refetchServices(), onCloseDrawer()];
    }
  };

  const setServiceFields = (field: keyof PetService, value: any) => {
    setExistingService({
      ...existingService,
      [field]: value,
    });
  };

  const setServiceTranslationFields = (
    field: keyof { name: string },
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingService({
      ...existingService,
      translations: {
        ...existingService?.translations,
        [language]: {
          ...existingService?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  useEffect(() => {
    if (isDeletePetServiceSuccess) {
      refetchServices();
      onCloseDrawer();
    }

    return () => {};
  }, [isDeletePetServiceSuccess, refetchServices]);

  const MainLoading = isPetServicesLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between">
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => [setExistingService(undefined), setDrawerStatus(true)]}
        >
          {t("create")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <GridMinMax>
        {petServices?.map((item, index) => (
          <Badge.Ribbon
            text={item.status === "active" ? t("active") : t("inActive")}
            key={index}
            color={item.status === "active" ? "green" : "red"}
          >
            <Card
              hoverable
              key={index}
              onClick={() => [setExistingService(item), setDrawerStatus(true)]}
            >
              <Flex vertical>
                <Text>{item.translations?.[field_Language]?.name}</Text>
                <Text>
                  {t("priority")}: {item.priority}
                </Text>
              </Flex>
            </Card>
          </Badge.Ribbon>
        ))}
      </GridMinMax>

      <Drawer
        extra={<AdminLangSwicher />}
        title={existingService?.id ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <Input
              size="large"
              placeholder={t("title")}
              value={
                existingService?.translations?.[field_Language]?.name || ""
              }
              onChange={(event) =>
                setServiceTranslationFields(
                  "name",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("status")}</Text>
            <Select
              size="large"
              style={{
                width: "100%",
              }}
              placeholder={t("status")}
              value={existingService?.status || undefined}
              onChange={(value) => setServiceFields("status", value)}
              options={[
                ...["active", "inactive"].map((item) => ({
                  value: item,
                  label: item === "active" ? t("active") : t("inActive"),
                })),
              ]}
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("priority")}</Text>
            <Input
              type="number"
              size="large"
              placeholder={t("priority")}
              value={existingService?.priority || ""}
              onChange={(event) =>
                setServiceFields("priority", parseInt(event?.target?.value))
              }
            />
          </Space>
        </Flex>

        <Divider />

        <Flex vertical gap={20}>
          <Button
            size="large"
            loading={isUpdateServiceLoading || isCreateServiceLoading}
            onClick={_createAndUpdateService}
            disabled={
              drawerStatus &&
              existingService?.priority &&
              existingService.status
                ? false
                : true
            }
            style={{
              width: "100%",
            }}
          >
            {existingService?.id ? t("update") : t("create")}
          </Button>

          {existingService && existingService.id && (
            <Popconfirm
              disabled={isDeletePetServiceLoading}
              title={t("certainToDeleteIt")}
              onConfirm={() => {
                if (existingService && existingService.id) {
                  deleteService(existingService.id);
                }
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
              placement="bottom"
            >
              <Button
                danger
                size="large"
                style={{
                  width: "100%",
                }}
              >
                {t("delete")}
              </Button>
            </Popconfirm>
          )}
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminPetServiceSecond;
