import { ArrowLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Colors from "../../../assets/styles/Colors";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import {
  DeactivatedPolicy,
  EnabledLanguage,
  currencySymbol,
  georgianFormatter,
} from "../../../domain/types";
import { RootState, useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import { AuthState } from "../../../store/authSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";

const { Text } = Typography;

const AdminTravelSoldPolicyDetail = () => {
  const { policyId: policyIdStr = "0" } = useParams();
  const policyId = parseInt(policyIdStr);
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { t } = useTranslation();

  const [activePolicyStatus, setActivePolicyStatus] = useState("");
  const [drawerToggle, setDrawerToggle] = useState<boolean>(false);

  // active policy mutation
  const [activePolicy, { isLoading: isActivePolicyLoading }] =
    api.useActivePolicyMutation();

  // deactive policy mutation
  const [deActivePolicy, { isLoading: isDeActivePolicyLoading }] =
    api.useDeactivePolicyMutation();

  const {
    data: adminPolicyDetail,
    isLoading: isAdminPolicyDetailLoading,
    refetch: refetchAdminPolicy,
  } = api.useGetAdminPolicyDetailQuery(policyId, {
    skip:
      auth.user?.userType === "Frani-Admin" ||
      auth.user?.userType === "Frani-Product-Owner"
        ? false
        : true,
  });

  // get travel policy deactive reason
  const { data: deactiveReasonArr, isLoading: isDeaactiveReasonArrLoading } =
    api.useGetAdminPolicyDeactiveReasonQuery(
      {
        categoryId: (adminPolicyDetail?.productCategoryId as number) || 0,
        isActive: true,
      },
      {
        skip: adminPolicyDetail?.productCategoryId ? false : true,
      }
    );

  const { data: companyPolicyDetail, isLoading: isCompanyPolicyDetailLoading } =
    api.useGetAdminPolicyByIdQuery(policyId);

  const [deactiveInfo, setDeactiveInfo] = useState<Partial<DeactivatedPolicy>>({
    policyId: policyId,
  });

  const setDeactiveFields = (field: keyof DeactivatedPolicy, value: any) => {
    setDeactiveInfo({
      ...deactiveInfo,
      [field]: value,
    });
  };

  const setDeactiveTranslatedField = (
    field: keyof DeactivatedPolicy,
    language: EnabledLanguage,
    value: any
  ) => {
    setDeactiveInfo({
      ...deactiveInfo,
      translations: {
        ...deactiveInfo.translations,
        [language]: {
          ...deactiveInfo?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const _activePolicyFuntion = async (
    policyId: number,
    policyDeactivationId: number,
    policyStatus: string
  ) => {
    if (policyId && policyDeactivationId && policyStatus) {
      try {
        await activePolicy({
          policyId,
          policyDeactivationId,
          policyStatus,
        }).unwrap();
        toast.success(`${t("policy")} ${t("activatedSuccessfully")}`);
        refetchAdminPolicy();
        setDrawerToggle(false);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const _deActivePolicyFuntion = async () => {
    if (deactiveInfo) {
      try {
        await deActivePolicy({
          cancellationPenalty: Number(deactiveInfo.cancellationPenalty),
          comment: deactiveInfo.comment,
          deactivatedDate: deactiveInfo.deactivatedDate,
          docUrl: deactiveInfo.docUrl,
          earnedPremium: Number(deactiveInfo.earnedPremium),
          policyId: policyId,
          policyDeactivationReasonId: deactiveInfo.policyDeactivationReasonId,
          refundAmount: deactiveInfo.refundAmount,
          translations: deactiveInfo.translations,
        }).unwrap();
        toast.success(`${t("policy")} ${t("deactivated")} !`);
        refetchAdminPolicy();
        setDrawerToggle(false);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  const policy = adminPolicyDetail || companyPolicyDetail;
  const discount = policy?.mainPolicy?.orderItems?.[0]?.order?.discount;
  const parsedWordingFileUrl = policy?.mainPolicy?.productWordingUrl?.includes(
    "translations"
  )
    ? JSON.parse(policy?.mainPolicy?.productWordingUrl)
    : "";

  // main loading
  const MainLoading =
    isAdminPolicyDetailLoading ||
    isCompanyPolicyDetailLoading ||
    isActivePolicyLoading ||
    isDeActivePolicyLoading ||
    isDeaactiveReasonArrLoading;

  return (
    <>
      {MainLoading ? (
        <Loader />
      ) : (
        <>
          <Card size="small">
            <Flex justify="space-between" align="center">
              <Button
                size="large"
                type="link"
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate(-1)}
              >
                {t("back")}
              </Button>

              {(auth.user?.userType === "Frani-Admin" ||
                auth.user?.userType === "Frani-Product-Owner") && (
                <Button
                  size="large"
                  type="dashed"
                  onClick={() => {
                    setDrawerToggle(!drawerToggle);
                    setDeactiveFields(
                      "refundAmount",
                      Number(policy?.refundAmount || 0)
                    );
                  }}
                >
                  {policy?.mainPolicy?.deactivatedPolicy
                    ? `${t("policyActivation")}`
                    : `${t("policyDeactivation")}`}
                </Button>
              )}
            </Flex>
          </Card>

          {/* POLICY DEACTIVE FORM TOP SIDE */}
          <Drawer
            extra={<AdminLangSwicher />}
            title={
              policy?.mainPolicy?.deactivatedPolicy
                ? `${t("policyActivation")}`
                : `${t("policyDeactivation")}`
            }
            onClose={() => setDrawerToggle(!drawerToggle)}
            open={drawerToggle}
          >
            {policy?.mainPolicy?.deactivatedPolicy ? (
              <>
                {/* IS ACTIVATION POLICY FORM */}
                <Flex gap={20} wrap="wrap">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("choosePolicyStatus")}</Text>
                    <Select
                      style={{ width: "100%" }}
                      placeholder={t("choosePolicyStatus")}
                      size="large"
                      value={activePolicyStatus}
                      onChange={(value) => setActivePolicyStatus(value)}
                      options={[
                        ...["ACTIVE", "CLOSED", "EXPIRED", "PENDING"].map(
                          (item) => ({
                            value: item,
                            label: item,
                          })
                        ),
                      ]}
                    />
                  </Space>
                </Flex>

                <Divider />

                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  disabled={activePolicyStatus ? false : true}
                  onClick={() => {
                    if (
                      activePolicyStatus &&
                      policyId &&
                      policy?.mainPolicy?.deactivatedPolicy?.id &&
                      window.confirm(`${t("certainToChangeStatus")}`)
                    )
                      _activePolicyFuntion(
                        policyId,
                        policy?.mainPolicy?.deactivatedPolicy?.id,
                        activePolicyStatus
                      );
                  }}
                >
                  {t("submit")}
                </Button>
                {/* IS ACTIVATION POLICY FORM */}
              </>
            ) : (
              <>
                {/* IS DEACTIVE POLICY FORM */}
                <Flex gap={20} wrap="wrap">
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("selectDeactivationDate")}</Text>
                    <Input
                      size="large"
                      type="date"
                      placeholder={t("selectDeactivationDate")}
                      value={deactiveInfo.deactivatedDate || ""}
                      onChange={(event) =>
                        setDeactiveFields(
                          "deactivatedDate",
                          dayjs(event?.target?.value).format("YYYY-MM-DD")
                        )
                      }
                    />
                  </Space>

                  {deactiveReasonArr && (
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Text>{t("deactivationReason")}</Text>
                      <Select
                        style={{ width: "100%" }}
                        placeholder={t("deactivationReason")}
                        size="large"
                        value={deactiveInfo.policyDeactivationReasonId}
                        onChange={(value) =>
                          setDeactiveFields("policyDeactivationReasonId", value)
                        }
                        options={[
                          ...deactiveReasonArr.map((item) => ({
                            value: item.id,
                            label: item?.translations?.[field_Language]?.title,
                          })),
                        ]}
                      />
                    </Space>
                  )}

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("earnedPremium")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("earnedPremium")}
                      value={deactiveInfo.earnedPremium || ""}
                      onChange={(event) =>
                        setDeactiveFields("earnedPremium", event?.target?.value)
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("deactivationPenalty")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("deactivationPenalty")}
                      value={deactiveInfo.cancellationPenalty || ""}
                      onChange={(event) =>
                        setDeactiveFields(
                          "cancellationPenalty",
                          event?.target?.value
                        )
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("refundableAmount")}</Text>
                    <Input
                      size="large"
                      type="number"
                      placeholder={t("refundableAmount")}
                      value={deactiveInfo.refundAmount || ""}
                      onChange={(event) =>
                        setDeactiveFields("refundAmount", event?.target?.value)
                      }
                    />
                  </Space>

                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Text>{t("comment")}</Text>
                    <TextArea
                      size="large"
                      placeholder={t("comment")}
                      autoSize={{ minRows: 2 }}
                      value={deactiveInfo.translations?.[field_Language]?.title}
                      onChange={(event) =>
                        setDeactiveTranslatedField(
                          "comment",
                          field_Language,
                          event.target.value
                        )
                      }
                    />
                  </Space>

                  <UploadFile
                    label={t("attachDocument")}
                    onChange={(props) => setDeactiveFields("docUrl", props.url)}
                    defaultFileName={deactiveInfo?.docUrl || ""}
                  />
                </Flex>

                <Divider />

                <Popconfirm
                  title={t("certainToDeactivate")}
                  onConfirm={_deActivePolicyFuntion}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="top"
                >
                  <Button
                    style={{ width: "100%" }}
                    size="large"
                    type="primary"
                    disabled={
                      deactiveInfo.policyDeactivationReasonId &&
                      deactiveInfo.deactivatedDate &&
                      deactiveInfo.cancellationPenalty &&
                      deactiveInfo.policyId &&
                      deactiveInfo.earnedPremium
                        ? false
                        : true
                    }
                  >
                    {t("policyDeactivate")}
                  </Button>
                </Popconfirm>

                {/* IS DEACTIVE POLICY FORM */}
              </>
            )}
          </Drawer>
          {/* POLICY DEACTIVE FORM BOTTOM SIDE */}

          <Flex gap={20} wrap="wrap" style={{ marginTop: "20px" }}>
            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "1",
                  label: t("insurerInformation"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("firstName")}</Text>
                        <Text>
                          {policy?.mainPolicy?.user?.firstNameGe ??
                            policy?.mainPolicy?.user?.firstName}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("lastName")}</Text>
                        <Text>
                          {policy?.mainPolicy?.user?.lastNameGe ??
                            policy?.mainPolicy?.user?.lastName}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("personalNumber")}</Text>
                        <Text>{policy?.mainPolicy?.user?.personalNumber}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("passportNumber")}</Text>
                        <Text>{policy?.mainPolicy?.user?.passportNumber}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("dateOfBirth")}</Text>
                        <Text>
                          {dayjs(policy?.mainPolicy?.user?.birthday).format(
                            "DD / MMM / YYYY"
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("phone")}</Text>
                        <Text>{policy?.mainPolicy?.user?.phone}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("email")}</Text>
                        <Text>{policy?.mainPolicy?.user?.email}</Text>
                      </Flex>
                      {/*  */}
                    </Flex>
                  ),
                },
              ]}
            />

            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "2",
                  label: t("policyInfo"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("insuranceCompany")}</Text>
                        <Text>
                          {
                            policy?.mainPolicy?.package?.product?.company
                              ?.translations[lang]?.title
                          }
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("policyType")}</Text>
                        <Text>
                          {(policy?.travelType?.toLowerCase() === "single" &&
                            lang === "ge" &&
                            "ერთჯერადი") ||
                            (policy?.travelType?.toLowerCase() === "single" &&
                              lang === "en" &&
                              "single") ||
                            (policy?.travelType?.toLowerCase() === "multi" &&
                              lang === "ge" &&
                              "მრავალჯერადი") ||
                            (policy?.travelType?.toLowerCase() === "multi" &&
                              lang === "en" &&
                              "multi")}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("category")}</Text>
                        <Text>
                          {String(
                            policy?.mainPolicy?.targetType?.match(
                              /[A-Z][a-z]+/g
                            )
                          )?.replace(",", " ")}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("policyNumber")}</Text>
                        <Text>{policy?.mainPolicy?.policyNumber}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("startDate")}</Text>
                        <Text>
                          {dayjs(policy?.mainPolicy?.startDate).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("endDate")}</Text>
                        <Text>
                          {dayjs(policy?.mainPolicy?.endDate).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("numberOfDays")}</Text>
                        <Text>
                          {policy?.insuranceDays} {t("day")}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("travelArea")}</Text>
                        <Text>{policy?.area?.toLowerCase()}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("limit")}</Text>
                        <Text>
                          {policy?.mainPolicy?.compensationLimit}{" "}
                          {currencySymbol(
                            policy?.mainPolicy?.compensationCurrency
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("franchise")}</Text>
                        <Text>
                          {policy?.mainPolicy?.franchise}{" "}
                          {currencySymbol(
                            policy?.mainPolicy?.compensationCurrency
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("policePrice")}</Text>
                        <Text>
                          {discount === null
                            ? `${georgianFormatter.format(
                                (policy?.eachPrice || 0) as number as never
                              )} ₾`
                            : `${georgianFormatter.format(
                                discount?.promoCode?.applyingDiscount
                                  ?.actualAmount || 0
                              )} ₾`}{" "}
                        </Text>
                      </Flex>

                      {discount !== null && (
                        <>
                          <Flex justify="space-between">
                            <Text>{t("promoCode")}</Text>
                            <Text>{discount?.promoCode?.code}</Text>
                          </Flex>
                          <Flex justify="space-between">
                            <Text>{t("promoCodeName")}</Text>
                            <Text>{discount?.promoCode?.name}</Text>
                          </Flex>
                          {discount?.promoCode?.applyingDiscount?.days && (
                            <Flex justify="space-between">
                              <Text>{t("discountByDays")}</Text>
                              <Text>
                                {`${
                                  discount?.promoCode?.applyingDiscount?.days
                                } ${t("day")}`}{" "}
                              </Text>
                            </Flex>
                          )}
                          {discount?.promoCode?.applyingDiscount?.amount && (
                            <Flex justify="space-between">
                              <Text>{t("discountByAmount")}</Text>
                              <Text>
                                {discount?.promoCode?.applyingDiscount?.amount}{" "}
                              </Text>
                            </Flex>
                          )}
                          {discount?.promoCode?.applyingDiscount
                            ?.percentageDiscount && (
                            <>
                              <Flex justify="space-between">
                                <Text>{t("discountByPercent")}</Text>
                                <Text>
                                  {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.percentage} %`}{" "}
                                </Text>
                              </Flex>
                              <Flex justify="space-between">
                                <Text>{t("maximumAmount")}</Text>
                                <Text>
                                  {`${discount?.promoCode?.applyingDiscount?.percentageDiscount?.maximumAmount} `}{" "}
                                </Text>
                              </Flex>
                            </>
                          )}
                        </>
                      )}

                      <Flex justify="space-between">
                        <Text>{t("orderId")}</Text>
                        <Text>
                          {policy?.mainPolicy?.orderItems[0]?.orderId}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("pan")}</Text>
                        <Text>
                          {policy?.mainPolicy?.orderItems[0]?.order.payments.map(
                            (p) => {
                              return p.status === "paid"
                                ? p.detailJson?.pan
                                : "";
                            }
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("accountId")}</Text>
                        <Text>
                          {policy?.mainPolicy?.orderItems[0]?.order.payments.map(
                            (p) => {
                              return p.status === "paid"
                                ? p.detailJson?.accountId
                                : "";
                            }
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("releaseDate")}</Text>
                        <Text>
                          {dayjs(policy?.mainPolicy?.createdAt).format(
                            "DD MMMM YYYY | HH:mm"
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("status")}</Text>
                        <Text>{policy?.mainPolicy?.status?.toUpperCase()}</Text>
                      </Flex>

                      {policy?.mainPolicy?.deactivatedPolicy && (
                        <div>
                          <hr
                            style={{
                              color: Colors.primaryBlack,
                            }}
                          />

                          <Flex justify="space-between">
                            <Text>{t("dateOfDeactivation")}</Text>
                            <Text>
                              {dayjs(
                                policy?.mainPolicy?.deactivatedPolicy
                                  ?.deactivatedDate
                              ).format("DD / MMMM / YYYY")}
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("deactivationRequestDate")}</Text>
                            <Text>
                              {dayjs(
                                policy?.mainPolicy?.deactivatedPolicy?.createdAt
                              ).format("DD / MMMM / YYYY | HH:mm")}
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("refundableAmount")}</Text>
                            <Text>
                              {
                                policy?.mainPolicy?.deactivatedPolicy
                                  ?.refundAmount
                              }
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("earnedPremium")}</Text>
                            <Text>
                              {
                                policy?.mainPolicy?.deactivatedPolicy
                                  ?.earnedPremium
                              }
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("deactivationPenalty")}</Text>
                            <Text>
                              {
                                policy?.mainPolicy?.deactivatedPolicy
                                  ?.cancellationPenalty
                              }
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("document")}</Text>
                            <Text>
                              <a
                                href={
                                  policy?.mainPolicy?.deactivatedPolicy?.docUrl
                                }
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {policy?.mainPolicy?.deactivatedPolicy?.docUrl}
                              </a>
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("decativationReason")}</Text>
                            <Text>
                              {
                                deactiveReasonArr?.find(
                                  (x) =>
                                    x?.id ===
                                    policy?.mainPolicy?.deactivatedPolicy
                                      ?.policyDeactivationReasonId
                                )?.translations?.[lang]?.title
                              }
                            </Text>
                          </Flex>

                          <Flex justify="space-between">
                            <Text>{t("comment")}</Text>
                            <Text>
                              {policy?.mainPolicy?.deactivatedPolicy?.comment}
                            </Text>
                          </Flex>
                        </div>
                      )}
                    </Flex>
                  ),
                },
              ]}
            />

            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "3",
                  label: t("travelerInformation"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("firstName")}</Text>
                        <Text>
                          {policy?.mainPolicy?.firstName ||
                            policy?.mainPolicy?.translations?.en?.firstName}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("lastName")}</Text>
                        <Text>
                          {policy?.mainPolicy?.lastName ||
                            policy?.mainPolicy?.translations?.en?.lastName}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("birthDay")}</Text>
                        <Text>
                          {dayjs(policy?.mainPolicy?.birthday).format(
                            "DD MMMM YYYY"
                          )}
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("passportNumber")}</Text>
                        <Text>{policy?.passportNumber}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("personalNumber")}</Text>
                        <Text>{policy?.personalNumber}</Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("gender")}</Text>
                        <Text>
                          {policy?.gender === "female" && lang === "ge"
                            ? "ქალი"
                            : policy?.gender === "male" && lang === "ge"
                            ? "კაცი"
                            : policy?.gender}
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />

            {(policy?.attachedAddons && policy?.attachedAddons.length >= 1) ||
            (policy?.grouppedAddons && policy?.grouppedAddons.length >= 1) ? (
              <Collapse
                style={{ width: "100%" }}
                items={[
                  {
                    key: "4",
                    label: t("additionalCoverages"),
                    children: (
                      <Flex vertical gap={10}>
                        {policy?.attachedAddons?.map((item, index) => (
                          <Flex justify="space-between" key={index}>
                            <Text>{item?.title}</Text>
                            <Text>{item?.price} ₾</Text>
                          </Flex>
                        ))}
                        {/*  */}
                        {policy?.grouppedAddons?.map((item, index) => (
                          <Flex justify="space-between" key={index}>
                            <Text>{item?.title}</Text>
                            <Text>{item?.price} ₾</Text>
                          </Flex>
                        ))}
                      </Flex>
                    ),
                  },
                ]}
              />
            ) : null}

            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "5",
                  label: t("whatIsCovered"),
                  children: (
                    <Flex vertical gap={10}>
                      {policy?.mainPolicy?.package?.travelAttachedRisks?.map(
                        (item, index) => (
                          <Flex justify="space-between" key={index}>
                            <Text>
                              {item?.risk?.translations[lang]?.riskName}
                            </Text>
                            <Text>
                              {item?.translations[lang]?.riskDescription}
                            </Text>
                          </Flex>
                        )
                      )}
                    </Flex>
                  ),
                },
              ]}
            />

            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "6",
                  label: t("importantInformation"),
                  children: (
                    <Flex vertical gap={10}>
                      {policy?.mainPolicy?.package?.insuranceExceptions?.map(
                        (item, index) => (
                          <Flex gap={20} key={index}>
                            <CaretRightOutlined />
                            <Text>{item?.translations?.[lang]?.title}</Text>
                          </Flex>
                        )
                      )}
                    </Flex>
                  ),
                },
              ]}
            />

            <Collapse
              style={{ width: "100%" }}
              items={[
                {
                  key: "7",
                  label: t("documents"),
                  children: (
                    <Flex vertical gap={10}>
                      <Flex justify="space-between">
                        <Text>{t("insuranceContract")}</Text>
                        <Text>
                          <a
                            href={
                              policy?.mainPolicy?.productWordingUrl?.includes(
                                "translations"
                              )
                                ? parsedWordingFileUrl?.translations?.[lang]
                                    ?.url
                                : policy?.mainPolicy?.productWordingUrl
                            }
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {policy?.mainPolicy?.productWordingUrl?.includes(
                              "translations"
                            )
                              ? parsedWordingFileUrl?.translations?.[lang]?.url
                              : policy?.mainPolicy?.productWordingUrl}
                          </a>
                        </Text>
                      </Flex>

                      <Flex justify="space-between">
                        <Text>{t("policyAgreement")}</Text>
                        <Text>
                          <a
                            href={policy?.mainPolicy?.policyPdfUrl}
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            {policy?.mainPolicy?.policyPdfUrl}
                          </a>
                        </Text>
                      </Flex>
                    </Flex>
                  ),
                },
              ]}
            />
          </Flex>
        </>
      )}
    </>
  );
};

export default AdminTravelSoldPolicyDetail;
