import { Button, Divider, Flex } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import { AdminInformationSheet, EnabledLanguage } from "../../../domain/types";
import DocumentFieldUploader from "../../admin_components/DocumentFieldUploader";

const AdminInformationSheets = () => {
  //
  const { slug } = useParams();
  //
  const { t } = useTranslation();
  //
  const [existingAttachment, setExistingAttachment] = useState<
    Partial<AdminInformationSheet>
  >({});
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  //
  const categoryId = productCategories?.find((x) => x.slug === slug)?.id || 0;
  //
  const {
    data: informationsheetlist,
    isLoading: isInformationSheetListLoading,
  } = api.useGetAdminInformationSheetQuery({
    categoryId: categoryId,
    attachmentSlug: "information-sheet",
  });
  //
  const itemID =
    informationsheetlist?.find((x) => x.productCategoryId === categoryId)?.id ||
    0;
  //
  const {
    data: currentInformationSheet,
    isLoading: isCurrentInformationSheetLoading,
    refetch: refetchCurrentSheet,
  } = api.useGetAdminInformationSheetDetailQuery(itemID, {
    skip: !itemID && true,
  });
  //
  const [storeAttachment, { isLoading: isStoreAttachmentLoading }] =
    api.useStoreInformationSheetMutation();
  //
  useEffect(() => {
    if (
      informationsheetlist &&
      informationsheetlist?.length >= 1 &&
      currentInformationSheet
    ) {
      setExistingAttachment(currentInformationSheet);
    }
    //
    if (informationsheetlist && informationsheetlist?.length < 1) {
      setExistingAttachment({});
    }
  }, [currentInformationSheet, informationsheetlist]);
  //
  useEffect(() => {
    if (itemID !== 0) {
      refetchCurrentSheet();
    }
    //
    return () => {};
  }, [refetchCurrentSheet, itemID]);
  //
  const setEditAttachmentTranslationField = (
    field: string,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingAttachment({
      ...existingAttachment,
      attachment: {
        ...existingAttachment.attachment,
        [language]: {
          ...existingAttachment?.attachment?.[language],
          [field]: value,
        },
      },
    });
  };
  // store sheet
  const storeSheetFun = async () => {
    //
    try {
      if (itemID === 0) {
        await storeAttachment({
          productCategoryId: categoryId,
          attachmentSlug: "information-sheet",
          attachment: existingAttachment.attachment,
        }).unwrap();
      } else {
        await storeAttachment({
          ...existingAttachment,
        }).unwrap();
      }
      //
      toast.success(
        itemID === 0
          ? `${t("createdSuccessfully")}`
          : `${t("updatedSuccessfully")}`
      );
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const mainLoading =
    isProductCategoryLoading ||
    isInformationSheetListLoading ||
    isCurrentInformationSheetLoading ||
    isStoreAttachmentLoading;
  //
  return (
    <>
      {mainLoading ? (
        <Loader />
      ) : (
        <>
          <Divider>{t("informationSheet")}</Divider>
          <Flex gap={40} wrap="wrap" justify="space-evenly" align="center">
            <DocumentFieldUploader
              language="EN"
              url={existingAttachment?.attachment?.en?.url || ""}
              onChange={(newUrl) =>
                setEditAttachmentTranslationField("url", "en", newUrl)
              }
            />

            <DocumentFieldUploader
              language="GE"
              url={existingAttachment?.attachment?.ge?.url || ""}
              onChange={(newUrl) =>
                setEditAttachmentTranslationField("url", "ge", newUrl)
              }
            />
          </Flex>
          <Divider />
          <Flex>
            <Button
              size="large"
              type="primary"
              onClick={storeSheetFun}
              style={{
                width: "100%",
                marginInline: "20%",
              }}
            >
              {itemID ? t("update") : t("create")}
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default AdminInformationSheets;
