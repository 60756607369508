import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import { TplCurrencies } from "../../../../domain/types";

const { Text } = Typography;

const AdminTplCurrencies = () => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

  const [editedCurrency, setEditedCurrency] =
    useState<Partial<TplCurrencies> | null>(null);

  // set currency fields
  const setCurrencyFields = (field: keyof TplCurrencies, value: any) => {
    setEditedCurrency({
      ...editedCurrency,
      [field]: value,
    });
  };

  const {
    data: tplCurrencies,
    isLoading: isTplCurrenciesLoading,
    refetch: refetchTplCurrencies,
  } = api.useGetTplCurrenciesQuery(undefined);

  const [saveTplCurrency, { isLoading: isSaveCurrencyLoading }] =
    api.useAddTplCurrencyMutation();

  const [
    removeTplCurrency,
    {
      isLoading: isRemoveTplCurrencyLoading,
      isSuccess: removeTplCurrencySucces,
    },
  ] = api.useRemoveTplCurrencyMutation();

  const [
    updateTplCurrencyStatus,
    {
      isLoading: isUpdateTplCurrencyStatusLoading,
      isSuccess: isUpdateTplCurrencySuccess,
    },
  ] = api.useUpdateTplCurrencyStatusMutation();

  const updateTplCurrencyStatusFun = async (id: number, isActive: boolean) => {
    if (id && isActive !== undefined) {
      try {
        await updateTplCurrencyStatus({ id, isActive }).unwrap();
        // refetch franchise list
        refetchTplCurrencies();
        toast.success(`${t("status")} ${t("updatedSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  //close add trave franchise drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setEditedCurrency(null);
  };

  const saveLimitFunction = async () => {
    if (editedCurrency) {
      try {
        await saveTplCurrency(editedCurrency).unwrap();
        // refetch franchise list
        refetchTplCurrencies();
        // message for user
        toast.success(`${t("currency")} ${t("addedSuccessfully")}`);
        // close modal
        onCloseDrawer();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error(`${t("enterCurrency")} ...`);
    }
  };

  useEffect(() => {
    if (removeTplCurrencySucces || isUpdateTplCurrencySuccess) {
      refetchTplCurrencies();
    }
  }, [
    removeTplCurrencySucces,
    isUpdateTplCurrencySuccess,
    refetchTplCurrencies,
  ]);

  const MainLoading =
    isTplCurrenciesLoading ||
    isSaveCurrencyLoading ||
    isRemoveTplCurrencyLoading ||
    isUpdateTplCurrencyStatusLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setDrawerStatus(!drawerStatus)}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax>
        {tplCurrencies &&
          tplCurrencies.length >= 1 &&
          tplCurrencies.map((item, index) => (
            <Card key={index} hoverable>
              <Flex vertical gap={10}>
                <Text>
                  {t("currency")}: <b>{item.currencyCode}</b>
                </Text>
                <Text>
                  {t("symbol")}: <b>{item.symbolOrName}</b>
                </Text>
                <Text>
                  {t("status")}:
                  <b> {item.isActive === true ? t("active") : t("inActive")}</b>
                </Text>
              </Flex>

              <Divider />

              <Flex gap={20}>
                <Button
                  size="large"
                  onClick={() => {
                    const newStatus = item.isActive;
                    return updateTplCurrencyStatusFun(item.id, !newStatus);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  {item.isActive === true ? t("deactivate") : t("activate")}
                </Button>

                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeTplCurrency(item.id)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button size="large" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Flex>
            </Card>
          ))}
      </GridMinMax>

      <Drawer title={t("create")} onClose={onCloseDrawer} open={drawerStatus}>
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("currency")}</Text>
            <Input
              size="large"
              placeholder={t("currency")}
              value={editedCurrency?.currencyCode || ""}
              onChange={(evenet) =>
                setCurrencyFields("currencyCode", evenet?.target?.value)
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("currencySymbol")}</Text>
            <Input
              size="large"
              placeholder={t("currencySymbol")}
              value={editedCurrency?.symbolOrName || ""}
              onChange={(event) =>
                setCurrencyFields("symbolOrName", event?.target?.value)
              }
            />
          </Space>

          <Space direction="vertical">
            <Divider />
            <Button
              size="large"
              disabled={
                editedCurrency?.currencyCode && editedCurrency.symbolOrName
                  ? false
                  : true
              }
              onClick={saveLimitFunction}
              style={{
                width: "100%",
              }}
            >
              {t("create")}
            </Button>
          </Space>
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminTplCurrencies;
