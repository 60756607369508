import {
  InfoCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Spin,
  Tooltip,
} from "antd";
import Meta from "antd/es/card/Meta";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { AddWelcomerRisks, EnabledLanguage } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

type AddWelcomerRiskTranslatedFields = {
  riskName: string;
  icon: string;
};

const AdminWelcomerRisks = () => {
  const { t } = useTranslation();
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const onCloseDrawer = () => {
    setDrawerStatus(false);
  };

  // select risk id
  const [welcomerRiskId, setWelcomerRiskId] = useState<number | null>(null);

  // add welcomer risk state
  const [addWelcomerRiskState, setAddWelcomerRiskState] = useState<
    Partial<AddWelcomerRisks>
  >({});

  // get all welcomer risks list
  const {
    data: welcomerRisks,
    isLoading: isWelcomerRisksLoading,
    refetch: refetchWelcomerRisks,
  } = api.useGetAdminWelcomerRiskListQuery(undefined);

  //   add welcomer risk
  const [saveWelcomerRisk, { isLoading: isAddWelcomerRiskLoading }] =
    api.useSaveWelcomerRiskMutation();

  // remove welcomer risk
  const [
    removeWelcomerRisk,
    {
      isLoading: isRemoveWelcomerRiskLoading,
      isSuccess: removeWelcomerRiskSuccess,
    },
  ] = api.useRemoveWelcomerRiskMutation();

  // add welcomer risk fields
  const setAddWelcomerRisk = (field: keyof AddWelcomerRisks, value: any) => {
    setAddWelcomerRiskState({
      ...addWelcomerRiskState,
      [field]: value,
    });
  };

  // get welcomer risk by id
  const {
    data: existingWelcomerRisk,
    isLoading: isExistingWelcomerRiskLoading,
  } = api.useGetAdminWelcomerRiskByIdQuery(welcomerRiskId || 0);

  // add welcomer translations fields
  const setAddWelcomerRiskTranslatedFields = (
    field: keyof AddWelcomerRiskTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddWelcomerRiskState({
      ...addWelcomerRiskState,
      translations: {
        ...addWelcomerRiskState.translations,
        [language]: {
          ...addWelcomerRiskState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add welcomer risk function
  const AddWelcomerRiskFunction = async () => {
    try {
      await saveWelcomerRisk(addWelcomerRiskState).unwrap();
      // refetch welcomer risks
      refetchWelcomerRisks();
      // close modal
      onWelcomerAddModalClose();
      toast.success(`${t("risk")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // auto refetch welcomer risks when user remove risk
  useEffect(() => {
    if (removeWelcomerRiskSuccess) {
      refetchWelcomerRisks();
      onWelcomerAddModalClose();
    }
  }, [removeWelcomerRisk, removeWelcomerRiskSuccess, refetchWelcomerRisks]);
  //
  //
  useEffect(() => {
    if (existingWelcomerRisk) {
      setAddWelcomerRiskState(existingWelcomerRisk);
    }
  }, [existingWelcomerRisk]);

  // close add welcomer risk drawer
  const onWelcomerAddModalClose = () => {
    return [
      setWelcomerRiskId(null),
      setDrawerStatus(false),
      setAddWelcomerRiskState({}),
    ];
  };

  const drawerLoading =
    isAddWelcomerRiskLoading ||
    isExistingWelcomerRiskLoading ||
    isRemoveWelcomerRiskLoading;

  return (
    <>
      {isWelcomerRisksLoading ? (
        <Loader />
      ) : (
        <>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => [
              setDrawerStatus(!drawerStatus),
              setAddWelcomerRiskState({}),
              setWelcomerRiskId(null),
              {},
            ]}
          >
            {t("create")}
          </Button>

          <Divider />

          <GridBlock>
            {welcomerRisks &&
              welcomerRisks.map((item, index) => (
                <Card
                  key={index}
                  onClick={() => [
                    setWelcomerRiskId(item.id),
                    setDrawerStatus(!drawerStatus),
                  ]}
                  hoverable
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                  cover={
                    <img
                      alt="example"
                      style={{
                        width: "50px",
                      }}
                      src={item.icon}
                    />
                  }
                >
                  <Meta title={item.translations?.[lang]?.riskName} />
                </Card>
              ))}
          </GridBlock>
        </>
      )}

      <Drawer
        extra={<AdminLangSwicher />}
        title={welcomerRiskId ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        {drawerLoading ? (
          <Flex justify="center" align="center">
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </Flex>
        ) : (
          <Flex vertical gap={20}>
            <Input
              size="large"
              placeholder={t("riskTitle")}
              value={
                addWelcomerRiskState?.translations?.[field_Language]
                  ?.riskName || ""
              }
              onChange={(event) =>
                setAddWelcomerRiskTranslatedFields(
                  "riskName",
                  field_Language,
                  event?.target?.value
                )
              }
              suffix={
                <Tooltip title={t("riskTitle")}>
                  <InfoCircleOutlined />
                </Tooltip>
              }
            />

            <UploadFile
              label={t("riskIcon")}
              onChange={(props) => setAddWelcomerRisk("icon", props.url)}
              defaultFileName={addWelcomerRiskState?.icon || ""}
            />

            <Divider />

            <Flex align="center" justify="space-between" gap={20}>
              <Button
                size="large"
                onClick={AddWelcomerRiskFunction}
                disabled={drawerLoading}
                style={{
                  width: "100%",
                }}
              >
                {welcomerRiskId ? t("update") : t("create")}
              </Button>

              {welcomerRiskId && (
                <Popconfirm
                  disabled={drawerLoading}
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeWelcomerRisk(welcomerRiskId)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    disabled={drawerLoading}
                    danger
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("removeRisk")}
                  </Button>
                </Popconfirm>
              )}
            </Flex>
          </Flex>
        )}
      </Drawer>
    </>
  );
};

const GridBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
`;
export default AdminWelcomerRisks;
