import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from 'react-router-dom'
import styled from "styled-components";
import Accordion from "../../../../../components/Accordion";
import Button from "../../../../admin_components/AdminButton";
import Input from "../../../../admin_components/AdminInput";
// import Loader from '../../../../../components/Loader'
// import NotFound404 from '../../../../../components/NotFound404'
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-toastify";
import Logo from "../../../../../assets/icons/JSX/Logo";
import {
  Country,
  CountryExceptionInfo,
  Currency,
  // EnabledCurrencies,
  EnabledLanguage,
  InsuranceException,
  Package,
  Product,
  TravelAbroadArea,
  TravelAbroadAreaOptions,
  TravelAddon,
  TravelEnabledRisk,
  TravelFranchise,
  TravelMultiDayOption,
  TravelPackage,
  TravelProduct,
  TravelRisk,
  numberWithSpaces,
  supportedLocales,
} from "../../../../../domain/types";
// import BaseModal from '../../../../../components/modals/BaseModal'
import dayjs from "dayjs";
import api, { uploadUrl } from "../../../../../api";
import Responsive from "../../../../../assets/config/Responsive";
import Colors from "../../../../../assets/styles/Colors";
import CustomDropdown from "../../../../../components/CustomDropdown";
import { TravelAbroadEnabledCurrencyOptions } from "../../../../../domain/travelAbroad";
import { useAppSelector } from "../../../../../store";
import DocumentFieldUploader from "../../../../admin_components/DocumentFieldUploader";
import { AppState } from "../../../../../store/appSlice";
import { useTranslation } from "react-i18next";
dayjs.locale("ka");

// const Dropdown: React.FC<{
//   id: string;
//   label: string;
//   options: Array<{ label: string; value: any }>;
//   selectedValue?: any;
//   minWidth?: number;
//   onChange: (_) => void;
// }> = ({ id, label, options, selectedValue, onChange, minWidth = 100 }) => {
//   return (
//     <FormControl fullWidth>
//       <InputLabel id={`${id}-label`}>{label}</InputLabel>
//       <Select
//         labelId={`${id}-label`}
//         id={id}
//         value={selectedValue}
//         label={"label"}
//         onChange={onChange}
//         autoWidth={true}
//         style={{ minWidth: minWidth }}
//       >
//         <MenuItem value={""}>აირჩიეთ</MenuItem>
//         {options.map((option) => (
//           <MenuItem key={option.value} value={option.value}>
//             {option.label}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

type Props = {
  travelPackageId: TravelPackage["id"];
  countries: Country[];
  travelFranchises: TravelFranchise[];
  travelRisks: TravelRisk[];
  travelMultiDays: TravelMultiDayOption[];
  travelAbroadAddons: TravelAddon[];
  refetchTravelProduct: () => void;
  travelProduct: TravelProduct;
  /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
  setTravelProductField: (
    fieldName: keyof TravelProduct,
    fieldValue: any
  ) => void;
  setUsedCurrencies: (travelPackageId: number, _: Currency[]) => void;
  setUsedAreas: (travelPackageId: number, _: TravelAbroadArea[]) => void;
  setUsedFranchises: (travelPackageId: number, _: number[]) => void;
};

const TravelPackageEditor = ({
  travelPackageId: pkgId,
  countries,
  travelFranchises,
  travelRisks,
  travelMultiDays,
  travelAbroadAddons,
  refetchTravelProduct,
  travelProduct,
  setTravelProductField,
  setUsedCurrencies,
  setUsedAreas,
  setUsedFranchises,
}: Props) => {
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { t } = useTranslation();
  //
  const auth = useAppSelector((data) => data.auth);
  const isAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";
  /** small context:
   * when the package fields change here, we have to propagate them back to the parent.
   * this means that we shouldn't receive `travelPackage` from the parent, or it will
   * become a two-way binding and we'll receive a "Maximum update depth exceeded" error.
   */
  const {
    //
    data: initialTravelPackage,
    isLoading: isInitialTravelPackageLoading,
    // refetch: refetchInitialTravelPackage,
  } = api.useGetTravelPackageByIdQuery(pkgId);
  //
  const [m_updateTravelPackage, { isLoading: isUpdateTravelPackageLoading }] =
    api.useUpdateTravelPackageMutation();
  //
  const [_deleteTravelPackage, { isLoading: isDeleteTravelPackageLoading }] =
    api.useDeleteTravelPackageMutation();
  // [admin]: approve package changes
  const [m_activatePackage, { isLoading: isActivatePackageLoading }] =
    api.useAdminActivatePackageMutation();
  // [admin]: reject package changes
  const [m_deactivatePackage, { isLoading: isDeactivatePackageLoading }] =
    api.useAdminDeactivatePackageMutation();
  //
  //
  const [addonsType, setAddonsType] = useState<"group" | "default" | string>(
    "group"
  );
  //
  //
  //
  //
  const [editedPackage, only_setEditedPackage] = useState<
    TravelPackage | undefined
  >(undefined);
  //
  //
  //
  /** also update parent TravelProduct state so that price combinations are updated */
  const setEditedPackage = useCallback(
    (newState: TravelPackage) => {
      only_setEditedPackage(newState);
      if (newState) {
        const mainProductWithUpdatedPackage: Product = {
          ...travelProduct.mainProduct,
          packages: travelProduct.mainProduct.packages.map((mainPkg) => {
            if (mainPkg.id === newState.mainPackage.id) {
              return {
                ...newState.mainPackage,
                target: { ...newState },
              };
            } else {
              return mainPkg;
            }
          }),
        };
        /** this will cause price combinations to regenerate with updated data */
        setTravelProductField("mainProduct", mainProductWithUpdatedPackage);
      }
    },
    [only_setEditedPackage, setTravelProductField, travelProduct]
  );
  useEffect(() => {
    if (initialTravelPackage) {
      setEditedPackage(initialTravelPackage);
      // console.log(
      //   'initialTravelPackage changed. franchises before change:',
      //   editedPackage?.mainPackage.franchises,
      //   'franchises after change:',
      //   initialTravelPackage.mainPackage.franchises,
      // )
    }
  }, [initialTravelPackage, setEditedPackage]);
  //
  const setTravelPackageField = (
    fieldName: keyof TravelPackage,
    fieldValue: any
  ) => {
    if (!editedPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      [fieldName]: fieldValue,
    });
  };
  //
  const setMainPackageField = (
    fieldName: keyof TravelPackage["mainPackage"],
    fieldValue: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setEditedPackage({
      ...editedPackage,
      mainPackage: {
        ...editedPackage.mainPackage,
        [fieldName]: fieldValue,
      },
    });
  };
  //
  //
  const setTranslatedFieldOnMainPackage = (
    field: keyof Package["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!editedPackage || !editedPackage.mainPackage) {
      return;
    }
    setMainPackageField("translations", {
      ...editedPackage.mainPackage.translations,
      [language]: {
        ...editedPackage.mainPackage.translations?.[language],
        [field]: value,
      },
    });
  };
  //
  const [countrySearchText, setCountrySearchText] = useState("");
  //
  const saveTravelPackage = async () => {
    if (editedPackage) {
      try {
        /** `franchise` and `description` should always be strings, but some old data (including from older seeders) is still numbers and fails backend validation. this can be removed after a while (e.g. 1 month) */
        let fixedUpEditedPackage: TravelPackage = { ...editedPackage };
        fixedUpEditedPackage.groupedTravelAttachedAddonInfos =
          fixedUpEditedPackage.groupedTravelAttachedAddonInfos.map(
            (groupAddon) => ({
              groupedPackageCurrency: groupAddon.packageCurrency,
              groupedPremiumCurrency: "GEL",
              groupedPaymentType: groupAddon.paymentType,
              groupedPremium: groupAddon.premium,
              groupedTranslations: groupAddon.translations,
              addonsInformation: groupAddon.addons.map((groupAddonInfo) => ({
                addonId: groupAddonInfo.addon.id,
                translations: groupAddonInfo.insuranceTranslations,
              })),
            })
          );

        // fixedUpEditedPackage.travelAttachedAddons =
        //   fixedUpEditedPackage.travelAttachedAddons.map((add) => ({
        //     ...add,
        //     addonInfos: add.addonInfos.map((inf) => ({
        //       ...inf,
        //       franchise: inf.franchise.toString(),
        //       description: inf.description.toString(),
        //     })),
        //   }));

        const result = await m_updateTravelPackage(
          fixedUpEditedPackage
        ).unwrap();
        // COMMENTED to avoid resetting product fields
        // refetchTravelProduct()
        setEditedPackage(result);
        console.log("save travel package", result);
        toast.success(`${t("package")} ${t("savedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToSave")}`;
        toast.error(message);
      }
    }
  };
  const deleteTravelPackage = async () => {
    if (editedPackage) {
      if (!window.confirm(`${t("certainToDeleteIt")}`)) {
        return;
      }
      try {
        const result = await _deleteTravelPackage(editedPackage.id).unwrap();
        refetchTravelProduct();
        console.log("delete travel package", result);
        toast.success(`${t("package")} ${t("deletedSuccessfully")}`);
      } catch (error) {
        const message =
          error?.data?.errors?.[0]?.message ||
          error?.message ||
          `${t("failedToDelete")}`;
        toast.error(message);
      }
    }
  };
  // approve package changes [admin does this]
  const approvePackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save package fields
      await saveTravelPackage();
      // second, activate the package
      const result = await m_activatePackage(
        editedPackage.mainPackage.id
      ).unwrap();
      toast.success(`${t("package")} ${t("publishedSuccessfully")}`);
      //
      // refetch travel products
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      // refetchExistingTravelProduct()
      setEditedPackage({
        ...editedPackage,
        mainPackage: result,
      });
    } catch (error) {
      console.log("approve package tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  // reject package changes [admin does this]
  const rejectPackageChanges = async () => {
    try {
      if (!editedPackage) {
        throw new Error("Missing package data");
      }
      if (editedPackage.mainPackage.status === "published") {
        // in this case, admin is converting a published package to draft (probably just auditing existing published packages)
      }
      //const result =
      await m_deactivatePackage(editedPackage.mainPackage.id).unwrap();
      toast.success(`${t("package")} ${t("deactivated")}`);
      //
      // refetch travel products
      // WARNING: this resets edited package inputs. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      //  refetchExistingTravelPackage()
      // WARNING: this also resets edited package inputs, so only update the status field
      // setEditedPackage({
      //   ...editedPackage,
      //   mainPackage: result,
      // })
      setMainPackageField("status", "draft");
    } catch (error) {
      console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  //
  // Generate a random color in hexadecimal format
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  // Create a color mapping based on object titles
  const colorMapping = {};
  editedPackage?.groupedTravelAttachedAddonInfos?.forEach((object) => {
    if (!colorMapping[object.title]) {
      colorMapping[object.title] = getRandomColor();
    }
  });

  //
  //
  const isLoading =
    isInitialTravelPackageLoading ||
    isUpdateTravelPackageLoading ||
    isActivatePackageLoading ||
    isDeactivatePackageLoading ||
    isDeleteTravelPackageLoading;
  //
  if (!editedPackage) {
    return null;
  }
  //
  //
  const FranoHorizontalLine: React.FC<{ title?: string }> = ({ title }) => {
    return (
      <CustomHorizontalLine>
        <div></div>
        {title ? <span>{title}</span> : <Logo />}
        <div></div>
      </CustomHorizontalLine>
    );
  };
  //
  //
  //
  // console.log(
  //   "editedPackage.mainPackage.travelAttachedRisks",
  //   editedPackage.mainPackage.travelAttachedRisks
  // );
  //
  //
  //
  return (
    <Wrapper>
      <Accordion title={editedPackage.mainPackage.title} key={editedPackage.id}>
        <Row>
          <Column $widthPercent={70} style={{ paddingLeft: "0px !important" }}>
            {/* <Block>
            <h4>ჯერადობის მიხედვით მარკეტინგული ფასი</h4>
            <Text>პაკეტი &quot;{pkg.title}&quot;</Text>
          </Block> */}

            <FranoHorizontalLine title={t("packageTitle")} />
            <Block
              style={{
                width: "100%",
              }}
            >
              <Input
                id=""
                label=""
                type="text"
                placeholder=""
                value={
                  editedPackage.mainPackage.translations[field_Language]
                    ?.title || ""
                }
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  // @ts-ignore
                  setTranslatedFieldOnMainPackage(
                    "title",
                    field_Language,
                    value
                  )
                }
                width={"fullWidth"}
              />
            </Block>

            <FranoHorizontalLine title={t("travelArea")} />
            <Block>
              {/* <Text>მოგზაურობის არეალი</Text> */}
              <RegionDiv>
                {TravelAbroadAreaOptions.map((area) => (
                  <Block key={area}>
                    <InputLabel
                      htmlFor={`package-${editedPackage.id}-area-ckb-${area}`}
                    >
                      {area}
                    </InputLabel>
                    <Checkbox
                      id={`package-${editedPackage.id}-area-ckb-${area}`}
                      checked={editedPackage?.areas?.some(
                        (a) => a.area === area
                      )}
                      onChange={(event) => {
                        const checked = event?.target.checked;
                        const newAreaList = [
                          ...(editedPackage?.areas || []).filter(
                            (a) => a.area !== area
                          ),
                          ...(checked ? [{ area }] : []),
                        ];
                        setTravelPackageField("areas", newAreaList);
                        setUsedAreas(
                          editedPackage.id,
                          newAreaList.map((v) => v.area as TravelAbroadArea)
                        );
                      }}
                    />
                  </Block>
                ))}
              </RegionDiv>
            </Block>

            <FranoHorizontalLine title={t("limit")} />
            <Block>
              {/* <Text>ლიმიტი</Text> */}
              <Input
                id=""
                label={`${t("limit")}: ${numberWithSpaces(
                  editedPackage.mainPackage.compensationLimit
                )}`}
                type="text"
                placeholder=""
                value={editedPackage.mainPackage.compensationLimit}
                // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                onChange={(value) =>
                  setMainPackageField("compensationLimit", value)
                }
                width={"fullWidth"}
              />
            </Block>

            <FranoHorizontalLine title={t("currencyForLimit")} />
            <Block>
              {/* <Text>ლიმიტის ვალუტა</Text> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {TravelAbroadEnabledCurrencyOptions.map((currency) => (
                  <Block key={currency}>
                    <InputLabel
                      htmlFor={`package-${editedPackage.id}-limit-currency-ckb-${currency}`}
                    >
                      {currency}
                    </InputLabel>
                    <Checkbox
                      id={`package-${editedPackage.id}-limit-currency-ckb-${currency}`}
                      checked={editedPackage?.mainPackage?.compensationCurrencies?.some(
                        (a) => a.currency === currency
                      )}
                      onChange={(event) => {
                        const checked = event?.target.checked;
                        const newCurrencyList = [
                          ...(
                            editedPackage?.mainPackage.compensationCurrencies ||
                            []
                          ).filter((a) => a.currency !== currency),
                          ...(checked ? [{ currency: currency }] : []),
                        ];
                        setMainPackageField(
                          "compensationCurrencies",
                          newCurrencyList
                        );
                        setUsedCurrencies(
                          editedPackage.id,
                          newCurrencyList.map((v) => v.currency as Currency)
                        );
                      }}
                    />
                  </Block>
                ))}
              </div>
            </Block>

            <FranoHorizontalLine title={t("deductible")} />

            <Block>
              {/* <Text>ფრანშიზა</Text> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {travelFranchises.map((franchiseOption) => (
                  <Block key={franchiseOption.value}>
                    <InputLabel
                      htmlFor={`package-${editedPackage.id}-franchise-ckb-${franchiseOption}`}
                    >
                      {franchiseOption.value}
                    </InputLabel>
                    <Checkbox
                      id={`package-${editedPackage.id}-franchise-ckb-${franchiseOption}`}
                      checked={editedPackage?.mainPackage.franchises?.some(
                        (fValue) => fValue === franchiseOption.value
                      )}
                      onChange={(event) => {
                        const checked = event?.target.checked;
                        const newFranchiseList = [
                          ...(
                            editedPackage?.mainPackage.franchises ?? []
                          ).filter(
                            (fValue) => fValue !== franchiseOption.value
                          ),
                          ...(checked ? [franchiseOption.value] : []),
                        ];
                        setMainPackageField("franchises", newFranchiseList);
                        setUsedFranchises(editedPackage.id, newFranchiseList);
                      }}
                    />
                  </Block>
                ))}
              </div>
            </Block>

            <FranoHorizontalLine />
            <Block style={{ flexDirection: "column" }}>
              {/* <Title>დაფარვები</Title> */}
              <Accordion
                title={t("coverages")}
                className="admin-addons-accoridon"
              >
                <RiskContent>
                  {[...travelRisks]
                    // .sort((risk) =>
                    //   editedPackage.mainPackage.travelRisks?.some((enabledRisk: TravelRisk) => enabledRisk.id === risk.id)
                    //     ? -1
                    //     : 1,
                    // )
                    .map((risk) => (
                      <RiskDiv key={risk.id}>
                        <>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={editedPackage.mainPackage.travelAttachedRisks?.some(
                                  (enabledRisk: TravelEnabledRisk) =>
                                    enabledRisk.travelRiskId === risk.id
                                )}
                                onChange={(event) => {
                                  const listWithoutThisRisk: TravelEnabledRisk[] =
                                    (
                                      editedPackage.mainPackage
                                        .travelAttachedRisks ?? []
                                    ).filter(
                                      (enabledRisk) =>
                                        enabledRisk.travelRiskId !== risk.id
                                    );
                                  if (event.target.checked) {
                                    const thisRiskEnabled: TravelEnabledRisk = {
                                      id:
                                        -1 *
                                        ((
                                          editedPackage.mainPackage
                                            .travelAttachedRisks ?? []
                                        ).length +
                                          1000),
                                      travelRiskId: risk.id,
                                      packageId: editedPackage.mainPackage.id,
                                      risk,
                                      translations: {
                                        [field_Language]: {
                                          ...risk.translations?.[
                                            field_Language
                                          ],
                                          riskDescription: "",
                                        },
                                      },
                                    };
                                    const listIncludingThisRisk: TravelEnabledRisk[] =
                                      [...listWithoutThisRisk, thisRiskEnabled];
                                    setMainPackageField(
                                      "travelAttachedRisks",
                                      listIncludingThisRisk
                                    );
                                  } else {
                                    setMainPackageField(
                                      "travelAttachedRisks",
                                      listWithoutThisRisk
                                    );
                                  }
                                }}
                              />
                            }
                            label={
                              <>
                                <img src={uploadUrl(risk.icon)} alt="" />
                                <span>
                                  {risk.translations?.[field_Language]
                                    ?.riskName ?? risk.riskName}
                                </span>
                              </>
                            }
                          />

                          <TextBlock>
                            <TextAreaTitle>{t("comment")}</TextAreaTitle>
                            <TextAreaStyle
                              id={`travelRisks-${risk.id}-comment`}
                              placeholder=""
                              value={
                                (
                                  editedPackage.mainPackage
                                    .travelAttachedRisks ?? []
                                ).find(
                                  (enabledRisk: TravelEnabledRisk) =>
                                    enabledRisk.travelRiskId === risk.id
                                )?.translations?.[field_Language]
                                  ?.riskDescription || ""
                              }
                              onChange={(event) => {
                                const updatedRisks = (
                                  editedPackage.mainPackage
                                    .travelAttachedRisks ?? []
                                )?.map((item) =>
                                  item.travelRiskId === risk.id
                                    ? {
                                        ...item,
                                        translations: {
                                          ...item.translations,
                                          [field_Language]: {
                                            ...item.translations?.[
                                              field_Language
                                            ],
                                            riskDescription: event.target.value,
                                          },
                                        },
                                      }
                                    : item
                                );
                                //
                                setMainPackageField(
                                  "travelAttachedRisks",
                                  updatedRisks
                                );
                                //
                              }}

                              // onChange={(event) => {
                              //   const listWithoutThisRisk: TravelEnabledRisk[] =
                              //     (
                              //       editedPackage.mainPackage
                              //         .travelAttachedRisks ?? []
                              //     ).filter(
                              //       (enabledRisk) =>
                              //         enabledRisk.travelRiskId !== risk.id
                              //     );
                              //   //
                              //   const updatedRisk: TravelEnabledRisk = {
                              //     id:
                              //       -1 *
                              //       ((
                              //         editedPackage.mainPackage
                              //           .travelAttachedRisks ?? []
                              //       ).length +
                              //         1),
                              //     travelRiskId: risk.id,
                              //     packageId: editedPackage.mainPackage.id,
                              //     risk,

                              //     translations: {
                              //       [field_Language]: {
                              //         ...risk.translations?.[field_Language],
                              //         riskDescription: event.target.value,
                              //       },
                              //     },
                              //   };

                              //   const listIncludingThisRisk: TravelEnabledRisk[] =
                              //     [...listWithoutThisRisk, updatedRisk];
                              //   setMainPackageField(
                              //     "travelAttachedRisks",
                              //     listIncludingThisRisk
                              //   );
                              // }}
                            ></TextAreaStyle>
                          </TextBlock>

                          {/* <Input
                            id={`travelRisks-${risk.id}-comment`}
                            label="კომენტარი"
                            type="text"
                            placeholder=""
                            value={
                              (
                                editedPackage.mainPackage.travelAttachedRisks ??
                                []
                              ).find(
                                (enabledRisk: TravelEnabledRisk) =>
                                  enabledRisk.travelRiskId === risk.id
                              )?.translations?.[field_Language]
                                ?.riskDescription || ""
                            }
                            // @ts-ignore Partial EnabledLanguage cannot reliably detect translated field because 'en' is also Partial
                            onChange={(value) => {
                              const listWithoutThisRisk: TravelEnabledRisk[] = (
                                editedPackage.mainPackage.travelAttachedRisks ??
                                []
                              ).filter(
                                (enabledRisk) =>
                                  enabledRisk.travelRiskId !== risk.id
                              );
                              const updatedRisk: TravelEnabledRisk = {
                                id:
                                  -1 *
                                  ((
                                    editedPackage.mainPackage
                                      .travelAttachedRisks ?? []
                                  ).length +
                                    1),
                                travelRiskId: risk.id,
                                packageId: editedPackage.mainPackage.id,
                                risk,
                                translations: {
                                  [field_Language]: {
                                    ...risk.translations?.[field_Language],
                                    riskDescription: value,
                                  },
                                },
                              };
                              const listIncludingThisRisk: TravelEnabledRisk[] =
                                [...listWithoutThisRisk, updatedRisk];
                              setMainPackageField(
                                "travelAttachedRisks",
                                listIncludingThisRisk
                              );
                            }}
                            width={"fullWidth"}
                          /> */}
                        </>
                      </RiskDiv>
                    ))}
                </RiskContent>
              </Accordion>
            </Block>

            <FranoHorizontalLine title={t("functionalityPackageType")} />
            <MultiOrSingleDivBlock>
              {/* <Title>ერთჯერადი / მულტიპაკეტის ფუნქციონალი</Title> */}
              <MultiOrSingleDiv>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedPackage.enableSingle}
                      onChange={(event) => {
                        setTravelPackageField(
                          "enableSingle",
                          event.target.checked
                        );
                      }}
                    />
                  }
                  label={
                    <>
                      <span>{t("singlePolicyActivate")}</span>
                    </>
                  }
                />
              </MultiOrSingleDiv>
              <MultiOrSingleDiv>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedPackage.enableMulti}
                      onChange={(event) => {
                        setTravelPackageField(
                          "enableMulti",
                          event.target.checked
                        );
                      }}
                    />
                  }
                  label={
                    <>
                      <span>{t("multiPolicyActivate")}</span>
                    </>
                  }
                />
              </MultiOrSingleDiv>
            </MultiOrSingleDivBlock>

            <FranoHorizontalLine title={t("multiDays")} />
            {editedPackage.enableMulti ? (
              <MultiDayDiv>
                {/* <Title>მულტი დღეები</Title> */}
                <MultiDaysContent>
                  {[...travelMultiDays]
                    // .sort((risk) =>
                    //   editedPackage.travelRisks?.some((enabledRisk: TravelRisk) => enabledRisk.id === risk.id)
                    //     ? -1
                    //     : 1,
                    // )
                    .map((multiDayOption) => (
                      <div key={multiDayOption.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedPackage.travelMultiDays?.some(
                                (enabledOption: TravelMultiDayOption) =>
                                  enabledOption.id === multiDayOption.id
                              )}
                              onChange={(event) => {
                                const listWithoutOption: TravelMultiDayOption[] =
                                  (editedPackage.travelMultiDays ?? []).filter(
                                    (enabledOption) =>
                                      enabledOption.id !== multiDayOption.id
                                  );
                                if (event.target.checked) {
                                  const listIncludingThisOption: TravelMultiDayOption[] =
                                    [...listWithoutOption, multiDayOption];
                                  setTravelPackageField(
                                    "travelMultiDays",
                                    listIncludingThisOption
                                  );
                                } else {
                                  setTravelPackageField(
                                    "travelMultiDays",
                                    listWithoutOption
                                  );
                                }
                              }}
                            />
                          }
                          label={
                            <>
                              <span>{multiDayOption.days}</span>
                            </>
                          }
                        />
                      </div>
                    ))}
                </MultiDaysContent>
              </MultiDayDiv>
            ) : null}

            <FranoHorizontalLine title={t("additionalCoveragesActivate")} />
            <AddonsHeadBlock>
              <FormGroup
                style={{
                  flexDirection: "unset",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                {/* <Title>დამატებითი დაფარვების შეძენის გააქტიურება</Title> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={editedPackage.enableAddonsOnSingle}
                      onChange={(event) =>
                        setTravelPackageField(
                          "enableAddonsOnSingle",
                          event.target.checked
                        )
                      }
                    />
                  }
                  label={t("onSinglePolicy")}
                />
                {editedPackage.enableMulti ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={editedPackage.enableAddonsOnMulti}
                        onChange={(event) =>
                          setTravelPackageField(
                            "enableAddonsOnMulti",
                            event.target.checked
                          )
                        }
                      />
                    }
                    label={t("onMultiPolicy")}
                  />
                ) : null}
              </FormGroup>
            </AddonsHeadBlock>

            <FranoHorizontalLine />

            <Accordion
              openAccordion={true}
              title={t("newAditionalCoverages")}
              className="admin-addons-accoridon"
            >
              <div>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={addonsType || ""}
                  onChange={(event) => {
                    if (
                      event.target.value === "default" ||
                      event.target.value === "group"
                    ) {
                      return [setAddonsType(event.target.value)];
                    }
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "unset",
                  }}
                >
                  <FormControlLabel
                    value="group"
                    control={<Radio />}
                    label={t("grouped")}
                  />
                  <FormControlLabel
                    value="default"
                    control={<Radio />}
                    label={t("standard")}
                  />
                </RadioGroup>
                {/*  */}
                {/*  */}
                {/* BEGIN group */}
                {addonsType === "group" && (
                  <AddonBlock>
                    {editedPackage?.groupedTravelAttachedAddonInfos?.map(
                      (groupAddonItem, groupAddonIndex) => {
                        return (
                          <div
                            key={groupAddonIndex}
                            className="new-addon-item"
                            style={{
                              boxShadow: "unset",
                              border: `2px dashed ${
                                colorMapping[groupAddonItem?.title]
                              }`,
                            }}
                          >
                            <h6
                              style={{
                                textAlign: "center",
                                color: Colors.primaryRed,
                                borderBottom: `1px solid ${Colors.primaryRed}`,
                                paddingBottom: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              {/* <b>ვალუტა: {groupAddonItem?.packageCurrency}</b> */}
                              <b>
                                {groupAddonItem?.title ||
                                  t("groupedNameNotFound")}{" "}
                                {`(${groupAddonItem?.packageCurrency})`}
                              </b>

                              {/* editedPackage?.groupedTravelAttachedAddonInfos */}
                            </h6>

                            <div className="new-addon-head"></div>
                            <div className="new-addon-body">
                              <div className="new-addon-fields">
                                <CustomDropdown
                                  title={t("valueType")}
                                  placeholder={t("valueType")}
                                  isMulti={false}
                                  isClearable={false}
                                  value={groupAddonItem.paymentType}
                                  onChange={(obj) => {
                                    if (
                                      editedPackage &&
                                      editedPackage.groupedTravelAttachedAddonInfos
                                    ) {
                                      setTravelPackageField(
                                        "groupedTravelAttachedAddonInfos",
                                        editedPackage?.groupedTravelAttachedAddonInfos.map(
                                          (grAd) =>
                                            grAd.id === groupAddonItem.id ||
                                            grAd.translations.ge?.title ===
                                              groupAddonItem.translations.ge
                                                ?.title
                                              ? {
                                                  ...grAd,
                                                  paymentType: obj.value,
                                                }
                                              : grAd
                                        )
                                      );
                                    }
                                  }}
                                  options={[
                                    ...[
                                      { label: t("single"), value: "ONCE" },
                                      { label: t("daily"), value: "DAILY" },
                                    ].map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    })),
                                  ]}
                                />

                                <Input
                                  id="gel-addon-premium"
                                  name="gel-addon-premium"
                                  label={"GEL"}
                                  type="text"
                                  placeholder=""
                                  value={groupAddonItem.premium || ""}
                                  onChange={(value) => {
                                    if (
                                      editedPackage &&
                                      editedPackage.groupedTravelAttachedAddonInfos
                                    ) {
                                      setTravelPackageField(
                                        "groupedTravelAttachedAddonInfos",
                                        editedPackage?.groupedTravelAttachedAddonInfos.map(
                                          (grAd) =>
                                            grAd.id === groupAddonItem.id ||
                                            grAd.translations.ge?.title ===
                                              groupAddonItem.translations.ge
                                                ?.title
                                              ? {
                                                  ...grAd,
                                                  premium: parseInt(value, 10),
                                                }
                                              : grAd
                                        )
                                      );
                                    }
                                  }}
                                  width={"fullWidth"}
                                />
                              </div>

                              <Input
                                id="grouped-translation-title"
                                name="grouped-translation-title"
                                label={t("groupTitle")}
                                type="text"
                                placeholder=""
                                value={
                                  groupAddonItem?.translations?.[field_Language]
                                    ?.title || ""
                                }
                                onChange={(value) => {
                                  if (
                                    editedPackage &&
                                    editedPackage.groupedTravelAttachedAddonInfos
                                  ) {
                                    setTravelPackageField(
                                      "groupedTravelAttachedAddonInfos",
                                      editedPackage?.groupedTravelAttachedAddonInfos.map(
                                        (grAd) =>
                                          grAd.id === groupAddonItem.id ||
                                          grAd.translations.ge?.title ===
                                            groupAddonItem.translations.ge
                                              ?.title
                                            ? {
                                                ...grAd,
                                                translations: {
                                                  ...grAd.translations,
                                                  [field_Language]: {
                                                    ...grAd.translations[
                                                      field_Language
                                                    ],
                                                    title: value,
                                                  },
                                                },
                                              }
                                            : grAd
                                      )
                                    );
                                  }
                                }}
                                width={"fullWidth"}
                                style={{
                                  marginBlock: "20px",
                                }}
                              />

                              {groupAddonItem?.translations?.[field_Language]
                                ?.title &&
                              groupAddonItem.premium &&
                              groupAddonItem.paymentType ? (
                                <div className="defult-group-addon-block">
                                  <h6>{t("selectAdditonalCoverages")}</h6>
                                  {travelAbroadAddons?.map(
                                    (defaultAddonItem, defaultAddonIndex) => {
                                      return (
                                        <div
                                          key={defaultAddonIndex}
                                          className="defult-group-addon-item"
                                        >
                                          <div className="defult-group-addon-inner-item">
                                            <img
                                              src={defaultAddonItem.image}
                                              alt={`default-addon-img-${defaultAddonItem.id}`}
                                            />

                                            {
                                              defaultAddonItem?.translations[
                                                field_Language
                                              ]?.title
                                            }
                                            <Checkbox
                                              checked={
                                                editedPackage?.groupedTravelAttachedAddonInfos
                                                  ?.find(
                                                    (x) =>
                                                      x.id === groupAddonItem.id
                                                  )
                                                  ?.addons?.find(
                                                    (x) =>
                                                      x.addon.id ===
                                                      defaultAddonItem.id
                                                  )
                                                  ? true
                                                  : false
                                              }
                                              onChange={(event) => {
                                                if (
                                                  editedPackage &&
                                                  editedPackage.groupedTravelAttachedAddonInfos &&
                                                  event.target.checked
                                                ) {
                                                  setTravelPackageField(
                                                    "groupedTravelAttachedAddonInfos",
                                                    editedPackage?.groupedTravelAttachedAddonInfos.map(
                                                      (grAd) =>
                                                        grAd.title ===
                                                        groupAddonItem?.title
                                                          ? {
                                                              ...grAd,
                                                              addons:
                                                                grAd.addons
                                                                  ? [
                                                                      ...grAd.addons,

                                                                      {
                                                                        addon: {
                                                                          id: defaultAddonItem.id,
                                                                          insuranceTranslations:
                                                                            {
                                                                              ge: {
                                                                                description:
                                                                                  "",
                                                                                franchiseDescription:
                                                                                  "",
                                                                              },
                                                                            },
                                                                        },
                                                                      },
                                                                    ]
                                                                  : [
                                                                      {
                                                                        addon: {
                                                                          id: defaultAddonItem.id,
                                                                          insuranceTranslations:
                                                                            {
                                                                              ge: {
                                                                                description:
                                                                                  "",
                                                                                franchiseDescription:
                                                                                  "",
                                                                              },
                                                                            },
                                                                        },
                                                                      },
                                                                    ],
                                                            }
                                                          : grAd
                                                    )
                                                  );
                                                }
                                                //
                                                //
                                                if (
                                                  event.target.checked ===
                                                    false &&
                                                  editedPackage &&
                                                  editedPackage.groupedTravelAttachedAddonInfos
                                                ) {
                                                  //
                                                  setTravelPackageField(
                                                    "groupedTravelAttachedAddonInfos",
                                                    editedPackage.groupedTravelAttachedAddonInfos.map(
                                                      (inerITem) => {
                                                        const updatedItems =
                                                          inerITem.addons.filter(
                                                            (x) =>
                                                              x.addon.id !==
                                                              defaultAddonItem.id
                                                          );
                                                        return {
                                                          ...inerITem,
                                                          addons: updatedItems,
                                                        };
                                                      }
                                                    )
                                                  );
                                                  //
                                                }
                                              }}
                                            />
                                          </div>

                                          {/* levani */}

                                          {editedPackage?.groupedTravelAttachedAddonInfos
                                            ?.find(
                                              (x) => x.id === groupAddonItem.id
                                            )
                                            ?.addons?.find(
                                              (x) =>
                                                x.addon.id ===
                                                defaultAddonItem.id
                                            ) ? (
                                            <div className="defult-group-addon-inner-item-description">
                                              {editedPackage?.mainPackage?.compensationCurrencies
                                                .filter(
                                                  (x) =>
                                                    x.currency ===
                                                    groupAddonItem.packageCurrency
                                                )
                                                ?.map(
                                                  (
                                                    currencyItem,
                                                    currencyindex
                                                  ) => {
                                                    //
                                                    //
                                                    return (
                                                      <div key={currencyindex}>
                                                        <Input
                                                          id={`1-addon-premium`}
                                                          name={`1-addon-premium`}
                                                          label={`${t(
                                                            "deductibleDescription"
                                                          )} ${
                                                            currencyItem.currency
                                                          }`}
                                                          type="text"
                                                          placeholder=""
                                                          value={
                                                            editedPackage?.groupedTravelAttachedAddonInfos
                                                              ?.find(
                                                                (x) =>
                                                                  x.id ===
                                                                    groupAddonItem.id &&
                                                                  x.packageCurrency ===
                                                                    currencyItem.currency
                                                              )
                                                              ?.addons?.find(
                                                                (x) =>
                                                                  x.addon.id ===
                                                                  defaultAddonItem.id
                                                              )
                                                              ?.insuranceTranslations?.[
                                                              field_Language
                                                            ]
                                                              ?.franchiseDescription ||
                                                            ""
                                                          }
                                                          onChange={(value) => {
                                                            //
                                                            const updateFranchiseDescription1 =
                                                              (
                                                                groupedPackageCurrency,
                                                                addonId,
                                                                newDescription
                                                              ) => {
                                                                const updatedTravelAttachedAddonInfos =
                                                                  editedPackage.groupedTravelAttachedAddonInfos.map(
                                                                    (info) => {
                                                                      if (
                                                                        info.packageCurrency ===
                                                                        groupedPackageCurrency
                                                                      ) {
                                                                        const updatedAddonsInformation =
                                                                          info.addons.map(
                                                                            (
                                                                              addonInfo
                                                                            ) => {
                                                                              if (
                                                                                addonInfo
                                                                                  .addon
                                                                                  .id ===
                                                                                addonId
                                                                              ) {
                                                                                const updatedTranslations =
                                                                                  {
                                                                                    ...addonInfo.insuranceTranslations,
                                                                                    [field_Language]:
                                                                                      {
                                                                                        ...addonInfo
                                                                                          .insuranceTranslations?.[
                                                                                          field_Language
                                                                                        ],
                                                                                        franchiseDescription:
                                                                                          newDescription,
                                                                                      },
                                                                                  };

                                                                                return {
                                                                                  ...addonInfo,
                                                                                  insuranceTranslations:
                                                                                    updatedTranslations,
                                                                                };
                                                                              }
                                                                              return addonInfo;
                                                                            }
                                                                          );

                                                                        return {
                                                                          ...info,
                                                                          addons:
                                                                            updatedAddonsInformation,
                                                                        };
                                                                      }
                                                                      return info;
                                                                    }
                                                                  );

                                                                setTravelPackageField(
                                                                  "groupedTravelAttachedAddonInfos",
                                                                  updatedTravelAttachedAddonInfos
                                                                );
                                                              };
                                                            //
                                                            updateFranchiseDescription1(
                                                              groupAddonItem?.packageCurrency,
                                                              defaultAddonItem?.id,
                                                              value
                                                            );
                                                            //
                                                          }}
                                                          width={"fullWidth"}
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        />

                                                        <Input
                                                          id={`2-addon-description`}
                                                          name={`2-addon-description`}
                                                          label={`${t(
                                                            "comment"
                                                          )} ${
                                                            currencyItem.currency
                                                          }`}
                                                          type="text"
                                                          placeholder=""
                                                          value={
                                                            editedPackage?.groupedTravelAttachedAddonInfos
                                                              ?.find(
                                                                (x) =>
                                                                  x.id ===
                                                                    groupAddonItem.id &&
                                                                  x.packageCurrency ===
                                                                    currencyItem.currency
                                                              )
                                                              ?.addons?.find(
                                                                (x) =>
                                                                  x.addon.id ===
                                                                  defaultAddonItem.id
                                                              )
                                                              ?.insuranceTranslations?.[
                                                              field_Language
                                                            ]?.description || ""
                                                          }
                                                          onChange={(value) => {
                                                            //
                                                            const updateFranchiseDescription1 =
                                                              (
                                                                groupedPackageCurrency,
                                                                addonId,
                                                                newDescription2
                                                              ) => {
                                                                const updatedTravelAttachedAddonInfos =
                                                                  editedPackage.groupedTravelAttachedAddonInfos.map(
                                                                    (info) => {
                                                                      if (
                                                                        info.packageCurrency ===
                                                                        groupedPackageCurrency
                                                                      ) {
                                                                        const updatedAddonsInformation =
                                                                          info.addons.map(
                                                                            (
                                                                              addonInfo
                                                                            ) => {
                                                                              if (
                                                                                addonInfo
                                                                                  .addon
                                                                                  .id ===
                                                                                addonId
                                                                              ) {
                                                                                const updatedTranslations =
                                                                                  {
                                                                                    ...addonInfo.insuranceTranslations,
                                                                                    [field_Language]:
                                                                                      {
                                                                                        ...addonInfo
                                                                                          .insuranceTranslations?.[
                                                                                          field_Language
                                                                                        ],
                                                                                        description:
                                                                                          newDescription2,
                                                                                      },
                                                                                  };

                                                                                return {
                                                                                  ...addonInfo,
                                                                                  insuranceTranslations:
                                                                                    updatedTranslations,
                                                                                };
                                                                              }
                                                                              return addonInfo;
                                                                            }
                                                                          );

                                                                        return {
                                                                          ...info,
                                                                          addons:
                                                                            updatedAddonsInformation,
                                                                        };
                                                                      }
                                                                      return info;
                                                                    }
                                                                  );

                                                                setTravelPackageField(
                                                                  "groupedTravelAttachedAddonInfos",
                                                                  updatedTravelAttachedAddonInfos
                                                                );
                                                              };
                                                            //
                                                            updateFranchiseDescription1(
                                                              groupAddonItem?.packageCurrency,
                                                              defaultAddonItem?.id,
                                                              value
                                                            );
                                                            //
                                                          }}
                                                          width={"fullWidth"}
                                                          style={{
                                                            marginBottom:
                                                              "20px",
                                                          }}
                                                        />
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              ) : (
                                <div>{t("additionalCoveragesMandatiory")}</div>
                              )}

                              <hr />

                              <Button
                                btnStyleType="primary"
                                border={false}
                                size="medium"
                                icon={false}
                                text={t("delete")}
                                fullWidth={true}
                                disabled={false}
                                onClick={() => {
                                  if (
                                    editedPackage?.groupedTravelAttachedAddonInfos
                                  ) {
                                    const editedGrAddon =
                                      editedPackage?.groupedTravelAttachedAddonInfos.filter(
                                        (x) => x.title !== groupAddonItem.title
                                      );
                                    //
                                    setTravelPackageField(
                                      "groupedTravelAttachedAddonInfos",
                                      editedGrAddon
                                    );
                                  }
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}

                    <Button
                      btnStyleType="transparent"
                      border={true}
                      size="medium"
                      icon={false}
                      fullWidth={true}
                      disabled={false}
                      onClick={() => {
                        const initialGroupAddon =
                          editedPackage?.mainPackage?.compensationCurrencies.map(
                            (currencyItem) => ({
                              id:
                                1 *
                                  editedPackage.groupedTravelAttachedAddonInfos
                                    ?.length +
                                  1 || 1,
                              packageCurrency: currencyItem.currency,
                              premiumCurrency: "GEL",
                              paymentType: "",
                              premium: 0,
                              translations: {
                                ge: {
                                  title: "",
                                },
                              },
                              addons: [],
                            })
                          );

                        const updatedGroupArray = [
                          ...(editedPackage?.groupedTravelAttachedAddonInfos ||
                            []),

                          ...initialGroupAddon,
                        ];

                        //
                        return setTravelPackageField(
                          "groupedTravelAttachedAddonInfos",
                          updatedGroupArray
                        );
                      }}
                      text={t("add")}
                    />
                  </AddonBlock>
                )}

                {/* END group */}
                {/*  */}
                {/* BEGIN default addon */}
                {addonsType === "default" && (
                  <div>
                    <AddonBlock>
                      {travelAbroadAddons?.map((addonItem, addonIndex) => {
                        //
                        //
                        return (
                          <div key={addonIndex} className="new-addon-item">
                            <div className="new-addon-head">
                              <img src={addonItem.image} alt="addon-img" />
                              <div>
                                {addonItem.translations[field_Language]?.title}
                              </div>
                              <Checkbox
                                id={`package-${addonIndex}-id`}
                                checked={
                                  editedPackage?.travelAttachedAddons.find(
                                    (x) => x.addonId === addonItem.id
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(event) => {
                                  //
                                  const newObj =
                                    TravelAbroadEnabledCurrencyOptions
                                      ? TravelAbroadEnabledCurrencyOptions.map(
                                          (currency) => {
                                            //
                                            return {
                                              addonId: addonItem.id,
                                              paymentType: "",
                                              packageCurrency: currency,
                                              premiumCurrency: "GEL",
                                              premium: null,
                                              translations:
                                                supportedLocales.reduce(
                                                  (result, field_Language) => {
                                                    result[field_Language] = {
                                                      description: "",
                                                      franchiseDescription: "",
                                                    };
                                                    return result;
                                                  },
                                                  {}
                                                ),
                                            };
                                          }
                                        )
                                      : [];
                                  //
                                  const updatedArry = [
                                    ...(editedPackage?.travelAttachedAddons ||
                                      []),
                                    ...newObj,
                                  ];
                                  //
                                  if (event.target.checked) {
                                    setTravelPackageField(
                                      "travelAttachedAddons",
                                      updatedArry
                                    );
                                  } else {
                                    const filteredArray =
                                      editedPackage?.travelAttachedAddons?.filter(
                                        (item) => item.addonId !== addonItem.id
                                      );
                                    //
                                    setTravelPackageField(
                                      "travelAttachedAddons",
                                      filteredArray
                                    );
                                  }
                                }}
                              />
                            </div>

                            <hr />

                            <div className="new-addon-body">
                              <div className="new-addon-fields">
                                <CustomDropdown
                                  title={t("valueType")}
                                  placeholder={t("valueType")}
                                  isMulti={false}
                                  value={
                                    editedPackage?.travelAttachedAddons.find(
                                      (x) => x.addonId === addonItem.id
                                    )?.paymentType || undefined
                                  }
                                  isClearable={false}
                                  onChange={(obj) => {
                                    setTravelPackageField(
                                      "travelAttachedAddons",
                                      editedPackage.travelAttachedAddons.map(
                                        (atAd) =>
                                          atAd.addonId === addonItem.id
                                            ? {
                                                ...atAd,
                                                paymentType: obj.value,
                                              }
                                            : atAd
                                      )
                                    );
                                  }}
                                  options={[
                                    ...[
                                      { label: t("single"), value: "ONCE" },
                                      { label: t("daily"), value: "DAILY" },
                                    ].map((item) => ({
                                      value: item.value,
                                      label: item.label,
                                    })),
                                  ]}
                                />

                                <Input
                                  id="gel-addon-premium"
                                  name="gel-addon-premium"
                                  label={"GEL"}
                                  type="text"
                                  placeholder=""
                                  value={
                                    editedPackage?.travelAttachedAddons?.find(
                                      (x) => x.addonId === addonItem.id
                                    )?.premium || ""
                                  }
                                  onChange={(value) => {
                                    setTravelPackageField(
                                      "travelAttachedAddons",
                                      editedPackage.travelAttachedAddons.map(
                                        (atAd) =>
                                          atAd.addonId === addonItem.id
                                            ? {
                                                ...atAd,
                                                premium: parseInt(value, 10),
                                              }
                                            : atAd
                                      )
                                    );
                                  }}
                                  width={"fullWidth"}
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {TravelAbroadEnabledCurrencyOptions?.map(
                                (currency, currencyIndex) => {
                                  //
                                  //
                                  return (
                                    <div key={currencyIndex}>
                                      <hr />
                                      <Input
                                        id={`${currency}-addon-premium`}
                                        name={`${currency}-addon-premium`}
                                        label={`${t(
                                          "deductibleDescription"
                                        )} (${currency})`}
                                        type="text"
                                        placeholder=""
                                        value={
                                          editedPackage?.travelAttachedAddons.find(
                                            (x) =>
                                              x.addonId === addonItem.id &&
                                              x.packageCurrency === currency
                                          )?.translations?.[field_Language]
                                            ?.franchiseDescription
                                        }
                                        //
                                        onChange={(value) => {
                                          setTravelPackageField(
                                            "travelAttachedAddons",
                                            editedPackage.travelAttachedAddons.map(
                                              (atAd) =>
                                                atAd.addonId === addonItem.id &&
                                                atAd.packageCurrency ===
                                                  currency
                                                  ? {
                                                      ...atAd,
                                                      translations: {
                                                        ...atAd.translations,
                                                        [field_Language]: {
                                                          ...atAd.translations[
                                                            field_Language
                                                          ],
                                                          franchiseDescription:
                                                            value,
                                                        },
                                                      },
                                                    }
                                                  : atAd
                                            )
                                          );
                                        }}
                                        //
                                        //
                                        width={"fullWidth"}
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                      />

                                      <Input
                                        id={`${currency}-addon-description`}
                                        name={`${currency}-addon-description`}
                                        label={`${t("comment")} (${currency})`}
                                        type="text"
                                        placeholder=""
                                        value={
                                          editedPackage?.travelAttachedAddons.find(
                                            (x) =>
                                              x.addonId === addonItem.id &&
                                              x.packageCurrency === currency
                                          )?.translations?.[field_Language]
                                            ?.description
                                        }
                                        onChange={(value) => {
                                          setTravelPackageField(
                                            "travelAttachedAddons",
                                            editedPackage.travelAttachedAddons.map(
                                              (atAd) =>
                                                atAd.addonId === addonItem.id &&
                                                atAd.packageCurrency ===
                                                  currency
                                                  ? {
                                                      ...atAd,
                                                      translations: {
                                                        ...atAd.translations,
                                                        [field_Language]: {
                                                          ...atAd.translations[
                                                            field_Language
                                                          ],
                                                          description: value,
                                                        },
                                                      },
                                                    }
                                                  : atAd
                                            )
                                          );
                                        }}
                                        width={"fullWidth"}
                                        style={{
                                          marginBottom: "20px",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </AddonBlock>
                  </div>
                )}
                {/* END default */}
              </div>
            </Accordion>
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/*  */}
            {/* <div style={{ justifyContent: "flex-start", width: "100%" }}>
              <Accordion
                title="დამატებითი დაფარვები"
                className="admin-addons-accoridon"
              >
                <div style={{ width: "100%" }}>
                  {[...travelAbroadAddons].map((addonOption) => (
                    <AddonDiv key={addonOption.id}>
                      <div style={{ width: "100%" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={editedPackage.travelAttachedAddons?.some(
                                (attachedOption: TravelAttachedAddon) =>
                                  attachedOption.addonId === addonOption.id
                              )}
                              onChange={(event) => {
                                const listWithoutOption: TravelAttachedAddon[] =
                                  (
                                    editedPackage.travelAttachedAddons ?? []
                                  ).filter(
                                    (attachedOption) =>
                                      attachedOption.addonId !== addonOption.id
                                  );
                                const thisOption: TravelAttachedAddon = (
                                  editedPackage.travelAttachedAddons ?? []
                                ).find(
                                  (attachedOption) =>
                                    attachedOption.addonId === addonOption.id
                                ) || {
                                  id:
                                    -1 *
                                    (editedPackage.travelAttachedAddons.length +
                                      1),
                                  addonId: addonOption.id,
                                  travelPackageId: editedPackage.id,
                                  paymentType: "ONCE",
                                  premiumCurrency: "GEL",
                                  premium: 0,
                                  createdAt: new Date().toISOString(),
                                  updatedAt: new Date().toISOString(),
                                  addonInfos:
                                    TravelAbroadEnabledCurrencyOptions.map(
                                      (currency) => ({
                                        currency,
                                        translations: {
                                          ge: {
                                            description: "",
                                          },
                                          en: {
                                            description: "",
                                          },
                                          ru: {
                                            description: "",
                                          },
                                        },
                                        franchise: "",
                                        description: "",
                                      })
                                    ),
                                };
                                if (event.target.checked) {
                                  const listIncludingThisOption: TravelAttachedAddon[] =
                                    [...listWithoutOption, thisOption!];
                                  setTravelPackageField(
                                    "travelAttachedAddons",
                                    listIncludingThisOption
                                  );
                                } else {
                                  setTravelPackageField(
                                    "travelAttachedAddons",
                                    listWithoutOption
                                  );
                                }
                              }}
                            />
                          }
                          label={
                            <>
                              {addonOption.image ? (
                                <img
                                  src={uploadUrl(addonOption.image)}
                                  alt=""
                                />
                              ) : null}
                              <span>
                                {addonOption.translations?.[language]?.title ||
                                  `#${addonOption.id}`}
                              </span>
                            </>
                          }
                        />
                        <Row
                          style={{
                            alignItems: "flex-end",
                            justifyContent: "space-between",
                          }}
                        >
                          <Column
                            $widthPercent={50}
                            style={{
                              paddingBlock: "5px",
                            }}
                          >
                            <Dropdown
                              id={`travelAttachedAddons-${addonOption.id}-paymentType`}
                              label="ღირებულების ტიპი"
                              options={[
                                { label: "ერთჯერადი", value: "ONCE" },
                                { label: "ყოველდღიური", value: "DAILY" },
                              ]}
                              selectedValue={
                                editedPackage.travelAttachedAddons.find(
                                  (at) => at.addonId === addonOption.id
                                )?.paymentType ?? "ONCE"
                              }
                              onChange={(event) => {
                                setTravelPackageField(
                                  "travelAttachedAddons",
                                  editedPackage.travelAttachedAddons.map(
                                    (atAd) =>
                                      atAd.addonId === addonOption.id
                                        ? {
                                            ...atAd,
                                            paymentType: event.target.value,
                                          }
                                        : atAd
                                  )
                                );
                              }}
                            />
                          </Column>

                          <Column
                            $widthPercent={50}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              paddingBlock: "5px",
                            }}
                          >
                            <Input
                              id={`travelAttachedAddons-${addonOption.id}-premium`}
                              label="GEL"
                              value={
                                editedPackage.travelAttachedAddons.find(
                                  (at) => at.addonId === addonOption.id
                                )?.premium
                              }
                              width="fullWidth"
                              placeholder="ქვეყნების ფილტრი.."
                              onChange={(value) => {
                                setTravelPackageField(
                                  "travelAttachedAddons",
                                  editedPackage.travelAttachedAddons.map(
                                    (atAd) =>
                                      atAd.addonId === addonOption.id
                                        ? { ...atAd, premium: value }
                                        : atAd
                                  )
                                );
                              }}
                            />
                          </Column>
                        </Row>
                        <div>
                          {TravelAbroadEnabledCurrencyOptions?.map(
                            (currency) => (
                              <Row
                                key={currency}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <Column
                                  $widthPercent={100}
                                  style={{
                                    paddingBlock: "5px",
                                    width: "100%",
                                  }}
                                >
                                  <Input
                                    id={`travelAttachedAddons-${addonOption.id}-addonInfos-${currency}-franchiseText`}
                                    label={`ფრანშიზა (${currency})`}
                                    value={
                                      editedPackage.travelAttachedAddons
                                        ?.find(
                                          (at) => at.addonId === addonOption.id
                                        )
                                        ?.addonInfos?.find(
                                          (inf) => inf.currency === currency
                                        )?.franchise
                                    }
                                    placeholder=""
                                    onChange={(value) => {
                                      setTravelPackageField(
                                        "travelAttachedAddons",
                                        editedPackage.travelAttachedAddons.map(
                                          (atAd) =>
                                            atAd.addonId === addonOption.id
                                              ? {
                                                  ...atAd,
                                                  addonInfos:
                                                    atAd.addonInfos.map((inf) =>
                                                      inf.currency === currency
                                                        ? {
                                                            ...inf,
                                                            franchise: value,
                                                          }
                                                        : inf
                                                    ),
                                                }
                                              : atAd
                                        )
                                      );
                                    }}
                                    width="fullWidth"
                                  />
                                  <br />
                                  <TextBlock>
                                    <TextAreaTitle>
                                      კომენტარი ({currency})
                                    </TextAreaTitle>
                                    <TextAreaStyle
                                      id={`travelAttachedAddons-${addonOption.id}-addonInfos-${currency}-description`}
                                      value={
                                        editedPackage.travelAttachedAddons
                                          ?.find(
                                            (at) =>
                                              at.addonId === addonOption.id
                                          )
                                          ?.addonInfos?.find(
                                            (inf) => inf.currency === currency
                                          )?.translations?.[language]
                                          ?.description
                                      }
                                      onChange={(event) => {
                                        setTravelPackageField(
                                          "travelAttachedAddons",
                                          editedPackage.travelAttachedAddons.map(
                                            (atAd) =>
                                              atAd.addonId === addonOption.id
                                                ? {
                                                    ...atAd,
                                                    addonInfos:
                                                      atAd.addonInfos.map(
                                                        (inf) =>
                                                          inf.currency ===
                                                          currency
                                                            ? {
                                                                ...inf,
                                                                translations: {
                                                                  ...inf.translations,
                                                                  [language]: {
                                                                    ...inf
                                                                      .translations[
                                                                      language
                                                                    ],
                                                                    description:
                                                                      event
                                                                        .target
                                                                        .value,
                                                                  },
                                                                },
                                                              }
                                                            : inf
                                                      ),
                                                  }
                                                : atAd
                                          )
                                        );
                                      }}
                                    ></TextAreaStyle>
                                  </TextBlock>
                                </Column>
                              </Row>
                            )
                          )}
                        </div>
                      </div>
                    </AddonDiv>
                  ))}
                </div>
              </Accordion>
            </div> */}
            {/* END addonInfos */}

            <FranoHorizontalLine title={t("exceptionCountries")} />
            <FormGroup>
              {/* <Text>გამონაკლისი ქვეყნები</Text> */}
              <Input
                id="exception-countries-search-input"
                label=""
                value={countrySearchText}
                placeholder={t("filterCountries")}
                onChange={(value) => {
                  setCountrySearchText(value);
                }}
                width="fullWidth"
              />
              <div
                style={{
                  height: 300,
                  overflowY: "scroll",
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
                  border: "1px solid #ccc",
                  padding: 10,
                }}
              >
                {[
                  ...countries.filter((c) =>
                    (c.translations[field_Language]?.name || c.name).includes(
                      countrySearchText
                    )
                  ),
                ]
                  .sort((country) =>
                    editedPackage.countryExceptionInfos?.some(
                      (ex: CountryExceptionInfo) =>
                        ex.countryISOCode === country.isoCode
                    )
                      ? -1
                      : 1
                  )
                  .map((country) => (
                    <div key={country.isoCode}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={editedPackage.countryExceptionInfos?.some(
                              (ex: CountryExceptionInfo) =>
                                ex.countryISOCode === country.isoCode
                            )}
                            onChange={(event) => {
                              const listWithoutThisCountry: CountryExceptionInfo[] =
                                editedPackage.countryExceptionInfos?.filter(
                                  (ex) => ex.countryISOCode !== country.isoCode
                                ) ?? [];
                              if (event.target.checked) {
                                const listIncludingThisCountry: CountryExceptionInfo[] =
                                  [
                                    ...listWithoutThisCountry,
                                    { countryISOCode: country.isoCode },
                                  ];
                                setTravelPackageField(
                                  "countryExceptionInfos",
                                  listIncludingThisCountry
                                );
                              } else {
                                setTravelPackageField(
                                  "countryExceptionInfos",
                                  listWithoutThisCountry
                                );
                              }
                            }}
                          />
                        }
                        label={
                          country.translations[field_Language]?.name ||
                          country.name
                        }
                      />
                    </div>
                  ))}
              </div>
            </FormGroup>

            <FranoHorizontalLine />
            <Accordion
              title={t("importantTerms")}
              className="admin-addons-accoridon"
            >
              <div style={{ marginTop: "20px" }}>
                {/* <Text>გამონაკლისი შემთხვევები</Text> */}
                <div>
                  {(editedPackage.mainPackage.insuranceExceptions ?? []).map(
                    (insuranceException, insuranceExceptionIndex) => (
                      <div
                        // key={`${ageGroup.minAge}-${ageGroup.maxAge}`}
                        key={insuranceExceptionIndex}
                        style={{
                          // display: 'flex',
                          // flexDirection: 'row',
                          // alignItems: 'top',
                          // padding: 10,
                          // borderRadius: 6,
                          // border: '1px solid #cacaca',
                          marginBottom: 10,
                        }}
                      >
                        <TextAreaTitle>{t("comment")}</TextAreaTitle>
                        <TextAreaStyle
                          id={`insurance-exception-${insuranceExceptionIndex}`}
                          value={
                            insuranceException?.translations?.[field_Language]
                              ?.title
                          }
                          style={{
                            display: "inline-flex",
                            flexDirection: "row-reverse",
                            alignItems: "center",
                            // marginBottom: 8,
                            marginRight: 8,
                          }}
                          onChange={(event) => {
                            const listWithUpdatedTitle = (
                              editedPackage.mainPackage.insuranceExceptions ??
                              []
                            ).map((g, i) =>
                              i === insuranceExceptionIndex
                                ? {
                                    ...g,
                                    translations: {
                                      ...g.translations,
                                      [field_Language]: {
                                        ...g.translations?.[field_Language],
                                        title: event.target.value,
                                      },
                                    },
                                  }
                                : g
                            );
                            setMainPackageField(
                              "insuranceExceptions",
                              listWithUpdatedTitle
                            );
                          }}
                        ></TextAreaStyle>

                        {/* <Input
                        id={`insurance-exception-${insuranceExceptionIndex}`}
                        width="fullWidth"
                        label=""
                        value={insuranceException?.translations?.[language]?.title}
                        style={{
                          display: 'inline-flex',
                          flexDirection: 'row-reverse',
                          alignItems: 'center',
                          // marginBottom: 8,
                          marginRight: 8,
                        }}
                        onChange={(value) => {
                          const listWithUpdatedTitle = (editedPackage.mainPackage.insuranceExceptions ?? []).map(
                            (g, i) =>
                              i === insuranceExceptionIndex
                                ? {
                                    ...g,
                                    translations: {
                                      ...g.translations,
                                      [language]: {
                                        ...g.translations?.[language],
                                        title: value,
                                      },
                                    },
                                  }
                                : g,
                          )
                          setMainPackageField('insuranceExceptions', listWithUpdatedTitle)
                        }}
                      /> */}
                        <Button
                          style={{
                            marginLeft: "auto",
                          }}
                          btnStyleType="secondary"
                          size="medium"
                          icon={false}
                          fullWidth={false}
                          disabled={false}
                          onClick={() => {
                            setMainPackageField(
                              "insuranceExceptions",
                              editedPackage.mainPackage.insuranceExceptions!.filter(
                                (iEx, i) => i !== insuranceExceptionIndex
                              )
                            );
                          }}
                          text={t("delete")}
                        />
                      </div>
                    )
                  )}
                  <br />
                  <Button
                    btnStyleType="primary"
                    size="medium"
                    icon={false}
                    fullWidth={true}
                    disabled={false}
                    onClick={() => {
                      const listWithAddedItem: InsuranceException[] = [
                        ...(editedPackage.mainPackage.insuranceExceptions ||
                          []),
                        {
                          translations: {
                            ge: { title: "" },
                            en: { title: "" },
                          },
                        },
                      ];
                      setMainPackageField(
                        "insuranceExceptions",
                        listWithAddedItem
                      );
                    }}
                    text={t("addExceptionalCase")}
                  />
                </div>
              </div>
            </Accordion>
            {/* END insuranceExceptions */}

            <FranoHorizontalLine title={t("packageDocuments")} />
            <DocumentsWrapper
              style={{
                paddingTop: "0px",
              }}
            >
              {/* <MediumScreenTitle>დოკუმენტების მართვა</MediumScreenTitle> */}
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (GE)
                  </DocumentBlockTitle>
                  <DocumentFieldUploader
                    url={
                      editedPackage?.mainPackage?.wordingFiles?.translations?.ge
                        ?.url || ""
                    }
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...editedPackage?.mainPackage?.wordingFiles,
                        translations: {
                          ...editedPackage?.mainPackage?.wordingFiles
                            ?.translations,
                          ge: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>

                <Column $widthPercent={50}>
                  <DocumentBlockTitle>
                    {t("wordingFile")} (EN)
                  </DocumentBlockTitle>
                  <DocumentFieldUploader
                    url={
                      editedPackage?.mainPackage?.wordingFiles?.translations?.en
                        ?.url || ""
                    }
                    onChange={(newUrl) =>
                      setMainPackageField("wordingFiles", {
                        ...editedPackage.mainPackage.wordingFiles,
                        translations: {
                          ...editedPackage.mainPackage.wordingFiles
                            ?.translations,
                          en: {
                            url: newUrl,
                          },
                        },
                      })
                    }
                    width={275}
                  />
                </Column>

                <Column $widthPercent={50}>
                  <div>
                    <DocumentBlockTitle>
                      {t("policyFormTemplate")}
                    </DocumentBlockTitle>
                    <DocumentFieldUploader
                      url={editedPackage.mainPackage.policyFileUrl}
                      onChange={(newUrl) =>
                        setMainPackageField("policyFileUrl", newUrl)
                      }
                    />
                  </div>
                </Column>
              </Row>
              {/* <Button
              btnStyleType="primary"
              icon={false}
              fullWidth={true}
              disabled={false}
              onClick={saveTab}
              text={'შენახვა'}
            /> */}
            </DocumentsWrapper>
          </Column>
          <Column $widthPercent={30}>
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="primary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => saveTravelPackage()}
                  text={t("save")}
                />
              )}
            </Block>
            <br />
            <Block>
              {isLoading ? (
                `${t("loading")}`
              ) : (
                <Button
                  btnStyleType="secondary"
                  fullWidth={true}
                  icon={false}
                  onClick={() => deleteTravelPackage()}
                  text={t("deletePackage")}
                />
              )}
            </Block>
            {editedPackage.mainPackage.createdAt ? (
              <Block>
                <Text>{t("createdDate")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.createdAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {editedPackage.mainPackage.updatedAt ? (
              <Block>
                <Text>{t("lastUpdatedAt")}:</Text>
                <Text>
                  {dayjs(editedPackage.mainPackage.updatedAt).format(
                    "DD MMMM YYYY HH:mm"
                  )}
                </Text>
              </Block>
            ) : null}
            {/* if approved */}
            {/* if approved, activationDate MUST be present */}
            {editedPackage.mainPackage.approvedByAdmin ? (
              <Block>
                <Text>{t("lastPublishedAt")}:</Text>
                <Text>
                  {editedPackage.mainPackage.activatedDate
                    ? dayjs(editedPackage.mainPackage.activatedDate).format(
                        "DD MMMM YYYY HH:mm"
                      )
                    : `${t("notFound")}`}
                </Text>
              </Block>
            ) : null}
            {/* if NOT approved */}
            {/* if NOT approved, two cases: 1) never rejected before, we have no deactivation date */}{" "}
            {/* 2) rejected recently, and we have deactiation date */}
            {/* that's why we don't */}
            {!editedPackage.mainPackage.approvedByAdmin ? (
              editedPackage.mainPackage.deactivatedDate ? (
                <Block>
                  <Text>{t("lastRejectedAt")}:</Text>
                  <Text>
                    {dayjs(editedPackage.mainPackage.deactivatedDate).format(
                      "DD MMMM YYYY HH:mm"
                    )}
                  </Text>
                </Block>
              ) : (
                <Block>
                  <Text style={{ flexBasis: "100%" }}>
                    {t("waitingAdminReview")}
                  </Text>
                </Block>
              )
            ) : null}
            <Block>
              <Text>{t("status")}:</Text>
              <Text>{editedPackage.mainPackage.status}</Text>
            </Block>
            {isAdmin && editedPackage.mainPackage.status === "draft" ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={approvePackageChanges}
                text={t("publish")}
              />
            ) : null}
            {isAdmin ? (
              <Button
                btnStyleType="primary"
                icon={false}
                fullWidth={true}
                style={{ marginBottom: 16 }}
                disabled={false}
                onClick={rejectPackageChanges}
                text={
                  editedPackage.mainPackage.status === "draft"
                    ? `${t("rejectChanges")}`
                    : `${t("moveToDraft")}`
                }
              />
            ) : null}
            {/* {editedPackage.mainPackage.activatedDate ? (
            <Block>
              <Text>გააქტიურდა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.activatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null}
          {editedPackage.mainPackage.deactivatedDate ? (
            <Block>
              <Text>დასადასტურებლად გაიგზავნა:</Text>
              <Text>{dayjs(editedPackage.mainPackage.deactivatedDate).format('DD MMMM YYYY HH:mm')}</Text>
            </Block>
          ) : null} */}
          </Column>
        </Row>
      </Accordion>
    </Wrapper>
  );
};
//
//
//
// styles
const AddonBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 20px;
  margin-top: 20px;

  /*  */
  .defult-group-addon-block {
    display: flex;
    align-items: center;
    flex-direction: column;

    img {
      height: 50px;
    }
  }
  /*  */
  .defult-group-addon-item {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    border: 1px dashed ${Colors.primaryRed};
    border-radius: 8px;
    padding: 5px;

    .defult-group-addon-inner-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .defult-group-addon-inner-item-description {
      width: 100%;
      border-top: 1px solid ${Colors.primaryRed};
      padding-top: 10px;
      margin-top: 10px;
    }
  }
  /*  */
  .new-addon-fields {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  /*  */
  .new-addon-body {
  }
  /*  */
  .new-addon-item {
    box-shadow: ${Colors.primaryGrey} 0px 0px 0px 1px;
    border-radius: 8px;
    font-size: 14px;
    padding: 15px;
  }
  /*  */
  .new-addon-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    row-gap: 10px;

    img {
      height: 50px;
    }
  }
`;
const RegionDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;
const CustomHorizontalLine = styled.div`
  margin-block: 20px;
  padding-block: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: all 200ms ease;
    height: 25px;
    width: 55px;
    min-width: 55px;
  }
  span {
    white-space: nowrap;
    font-weight: bold;
    color: ${Colors.primaryBlack};
  }
  div {
    height: 2px;
    width: 100%;
    background-color: ${Colors.primaryRed};
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
  /* hover */
  &:hover {
    svg {
      transition: all 200ms ease;
      transform: scale(1.05);
    }
  }
`;

const AddonsHeadBlock = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const MultiDaysContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const MultiDayDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const MultiOrSingleDivBlock = styled.div`
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid black;
  margin-bottom: 20px; */
  div {
    &:first-child {
      text-align: center;
    }
  }
`;
const MultiOrSingleDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const TextBlock = styled.div`
  width: 100%;
`;
const TextAreaTitle = styled.div`
  font-size: 16px;
  margin-bottom: 5px;
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  min-height: 100px;
  border-radius: 4px;
  border: 1px solid ${Colors.primaryRed};
  padding: 10px;
  &:focus {
    outline-color: ${Colors.primaryRed};
  }
`;
const RiskDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin-block: 20px;
  border-radius: 4px;
  box-shadow: ${Colors.lightRed} 0px 2px 5px -1px,
    ${Colors.lightRed} 0px 1px 3px -1px;
  /*  */
  .MuiFormControlLabel-labelPlacementEnd {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0px 0px 10px 0px;
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }

  /*  */
  &:hover {
    box-shadow: ${Colors.lightRed} 0px 10px 20px, ${Colors.lightRed} 0px 6px 6px;
  }
`;
// const Title = styled.div`
//   margin-bottom: 10px;
//   color: ${Colors.primaryRed};
//   font-size: 16px;
//   font-weight: bold;
//   text-align: center;
// `
const RiskContent = styled.div`
  width: 100%;
`;
const Wrapper = styled.div`
  .MuiSvgIcon-fontSizeMedium {
    color: ${Colors.primaryRed};
  }
  .MuiFormControlLabel-label {
    font-family: inherit;
  }
  .MuiFormLabel-root {
    font-family: inherit;
    font-weight: 600;
  }
  .admin-addons-accoridon {
    .accordion-head-title {
      color: ${Colors.primaryRed};
      font-weight: bold;
    }
    .accordion-head-title ~ svg {
      color: ${Colors.primaryRed};
    }
  }
`;
const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid ${Colors.primaryGrey};
  padding-bottom: 10px;
  margin-bottom: 10px; */
  /*  */
  /* > div {
    flex-basis: 50%;
  } */
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  ${Responsive.tablet} {
    flex-direction: column;
  }
`;

const Column = styled.div<{ $widthPercent?: number }>`
  padding: 20px 15px;
  ${({ $widthPercent = 50 }) =>
    $widthPercent ? `width: calc(${$widthPercent}% - 15px);` : ""}
  ${Responsive.tablet} {
    width: 100%;
  }
`;
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
  color: ${Colors.primaryBlack};
`;
const DocumentsWrapper = styled.div`
  padding: 20px 15px;
  margin-bottom: 50px;
`;
const DocumentBlockTitle = styled.h5`
  /* min-height: 55px; */
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 18px;
  /* border-left: 1px solid ${Colors.primaryGrey}; */
  /* padding-left: 5px; */
`;

const areEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps.travelPackageId === nextProps.travelPackageId) {
    return true; // do NOT re-render
  } else {
    return false; // will re-render
  }
};

export default React.memo(TravelPackageEditor, areEqual);
