import {
  ClearOutlined,
  ContainerOutlined,
  FileSearchOutlined,
  FileSyncOutlined,
  FilterOutlined,
  LoadingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Input,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import { AdminSoldPolicyFilterFields } from "../../../domain/types";
import { RootState } from "../../../store";
import { AuthState } from "../../../store/authSlice";
const { Text } = Typography;

// filter field type
type FilterFieldsType = {
  reportDownload: boolean;
  page: number | string;
  productCategory: "TRAVEL" | "TPL" | string;
  firstName: string;
  lastName: string;
  personalNumber: string;
  companyIds: { value: any; label: any }[] | number[] | [];
  registerDateFrom: string;
  registerDateTo: string;
  paymentDateFrom: string;
  paymentDateTo: string;
  policyStatus: string[] | [];
  // | "ACTIVE"
  // | "PENDING"
  // | "CLOSED"
  // | "DEACTIVATED"
  // | "PENDING-DELETED"
  // | string;
  paymentStatus: "PAID" | "UNPAID" | string;
  policyNumber: string;
  tplRegistrationNumber: string;
  policyDateFrom: string;
  accountId: string;
  orderId: string;
};

const AdminSoldPolicies = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const insuranceUserCompanyId = auth?.user?.companyUsers?.[0]?.companyId;

  const { data: authorizedCompanies, isLoading: isAuthorizedCompaniesLoading } =
    api.useGetAuthorizedCompaniesQuery(undefined);

  const [reportType, setReportType] = useState<
    "TRAVEL" | "TPL" | "WELCOMER" | "PET" | "COMBINED" | "BORDERO" | string
  >("COMBINED");

  const [toggleSearchFields, setToggleSearchFields] = useState<boolean>(false);

  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";

  const [selectedCompanyCheckbox, setSelectedCompanyCheckbox] = useState<
    number[]
  >([]);

  const [showGeneratePolicyFiles, setShowGeneratePolicyFiles] =
    useState<boolean>(false);

  const [targetTypeState, setTargetTypeState] = useState<string | undefined>(
    undefined
  );

  const companyIdArr = authorizedCompanies?.map((c) => ({
    value: c.id,
    label: c.title,
  }));

  // current page filter fields
  const [filterFieldsState, setFilterFieldsState] = useState<FilterFieldsType>({
    reportDownload: false,
    page: 1,
    productCategory: "COMBINED",
    firstName: "",
    lastName: "",
    personalNumber: "",
    companyIds: [],
    registerDateFrom: "",
    registerDateTo: "",
    paymentDateFrom: "",
    paymentDateTo: "",
    policyStatus: [],
    paymentStatus: "",
    policyNumber: "",
    tplRegistrationNumber: "",
    policyDateFrom: "",
    orderId: "",
    accountId: "",
  });

  const setFilterField = (
    fieldName: keyof AdminSoldPolicyFilterFields,
    fieldValue: any
  ) => {
    setFilterFieldsState({
      ...filterFieldsState,
      [fieldName]: fieldValue,
    });
  };

  // set company ids firs load
  useEffect(() => {
    // frani user
    if (authorizedCompanies) {
      const companyArr = authorizedCompanies?.map((c) => ({
        value: c.id,
        label: c.title,
      }));

      if (companyArr) {
        setFilterFieldsState({
          ...filterFieldsState,
          companyIds: companyArr,
        });
      }
    }

    // company user
    if (insuranceUserCompanyId && auth.user?.userType === "Insurance") {
      setFilterFieldsState({
        ...filterFieldsState,
        companyIds: [insuranceUserCompanyId],
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedCompanies, insuranceUserCompanyId]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  // post filter fields
  const [
    sendFilterFields,
    { isLoading: isFilterLoading, data: filteredPolicies },
  ] = api.useGetSoldSearchedPoliciesMutation();

  // post filter report
  const [
    sendFilterReport,
    { isLoading: isFilterReportLoading, data: filteredPolicyReport },
  ] = api.useGetSoldSearchedPoliciesMutation();

  // download report
  useEffect(() => {
    if (filteredPolicyReport) {
      document.location.href = filteredPolicyReport?.url || "";
    }
    return () => {};
  }, [filteredPolicyReport]);

  // send filter fields fun
  const sendFilterData = async () => {
    try {
      await sendFilterFields({
        companyIds: filterFieldsState.companyIds?.map((x) => x.value) as any,
        firstName: filterFieldsState.firstName,
        lastName: filterFieldsState.lastName,
        page: filterFieldsState.page,
        paymentDateFrom: filterFieldsState.paymentDateFrom,
        paymentDateTo: filterFieldsState.paymentDateTo,
        paymentStatus: filterFieldsState.paymentStatus,
        personalNumber: filterFieldsState.personalNumber,
        policyNumber: filterFieldsState.policyNumber,
        policyStatus: filterFieldsState.policyStatus,
        productCategory: filterFieldsState.productCategory,
        registerDateFrom: filterFieldsState.registerDateFrom,
        registerDateTo: filterFieldsState.registerDateTo,
        reportDownload: filterFieldsState.reportDownload,
        tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
        policyDateFrom: filterFieldsState.policyDateFrom,
        accountId: filterFieldsState.accountId,
        orderId: filterFieldsState.orderId,
      }).unwrap();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // send filter report fun
  const sendFilterReportFun = async (currentReportType?: string) => {
    if (currentReportType || reportType) {
      try {
        await sendFilterReport({
          reportType: currentReportType ?? reportType,
          companyIds: filterFieldsState.companyIds?.map((x) => x.value) as any,
          firstName: filterFieldsState.firstName,
          lastName: filterFieldsState.lastName,
          page: filterFieldsState.page,
          paymentDateFrom: filterFieldsState.paymentDateFrom,
          paymentDateTo: filterFieldsState.paymentDateTo,
          paymentStatus: filterFieldsState.paymentStatus,
          personalNumber: filterFieldsState.personalNumber,
          policyNumber: filterFieldsState.policyNumber,
          policyStatus: filterFieldsState.policyStatus,
          productCategory: filterFieldsState.productCategory,
          registerDateFrom: filterFieldsState.registerDateFrom,
          registerDateTo: filterFieldsState.registerDateTo,
          reportDownload: true,
          tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
          policyDateFrom: filterFieldsState.policyDateFrom,
          accountId: filterFieldsState.accountId,
          orderId: filterFieldsState.orderId,
        }).unwrap();

        // set same product category
        if (currentReportType) {
          setReportType(filterFieldsState.productCategory);
        }
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.info(`${t("chooseReportType")}`);
    }
  };

  // add company id
  const addCompanyId = (companyID: number) => {
    setSelectedCompanyCheckbox([...selectedCompanyCheckbox, companyID]);
  };

  // remove company id
  const removeCompanyId = (companyID: number) => {
    setSelectedCompanyCheckbox([
      ...selectedCompanyCheckbox.filter((x) => x !== companyID),
    ]);
  };

  // pagination page count
  const pageCount =
    filteredPolicies && Math.ceil(filteredPolicies?.meta?.total / 20);

  // generate policy file
  const [generatePolicyFile, { isLoading: IsGeneratePolicyFileLoading }] =
    api.useGeneratePolicyFilesByPolicyIdsMutation();

  // Send Email/Sms To Policy Holder mutatuon
  const [
    sendEmailAndSmsToPolicyHolder,
    { isLoading: IsSendEmailAndSmsToPolicyHolderLoading },
  ] = api.useSendEmailAndSmsToPolicyHolderMutation();

  // generate policy files fun
  const GeneratePolicyFilesFun = async () => {
    if (targetTypeState && selectedCompanyCheckbox) {
      try {
        await generatePolicyFile({
          policyType: targetTypeState,
          policyIds: selectedCompanyCheckbox,
        }).unwrap();
        toast.success(`${t("generatedSuccessfully")}`);
        window.location.reload();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  // Send Email/Sms To Policy Holder
  const SendEmailAndSmsToPolicyHolderFun = async () => {
    if (targetTypeState && selectedCompanyCheckbox) {
      try {
        await sendEmailAndSmsToPolicyHolder({
          policyType: targetTypeState,
          policyIds: selectedCompanyCheckbox,
        }).unwrap();
        toast.success(`${t("sendedSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  useEffect(() => {
    const sendFilterData = async () => {
      try {
        await sendFilterFields({
          companyIds: filterFieldsState.companyIds?.map((x) => x.value) as any,
          firstName: filterFieldsState.firstName,
          lastName: filterFieldsState.lastName,
          page: filterFieldsState.page,
          paymentDateFrom: filterFieldsState.paymentDateFrom,
          paymentDateTo: filterFieldsState.paymentDateTo,
          paymentStatus: filterFieldsState.paymentStatus,
          personalNumber: filterFieldsState.personalNumber,
          policyNumber: filterFieldsState.policyNumber,
          policyStatus: filterFieldsState.policyStatus,
          productCategory: filterFieldsState.productCategory,
          registerDateFrom: filterFieldsState.registerDateFrom,
          registerDateTo: filterFieldsState.registerDateTo,
          reportDownload: filterFieldsState.reportDownload,
          tplRegistrationNumber: filterFieldsState.tplRegistrationNumber,
          policyDateFrom: filterFieldsState.policyDateFrom,
          accountId: filterFieldsState.accountId,
          orderId: filterFieldsState.orderId,
        }).unwrap();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    };
    sendFilterData();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageClick = (page) => {
    setCurrentPage(page);
    setFilterField("page", page);
  };

  const policyStatusArr =
    insuranceUserCompanyId && auth.user?.userType === "Insurance"
      ? ["ACTIVE", "PENDING", "CLOSED"]
      : ["ACTIVE", "PENDING", "CLOSED", "DEACTIVATED", "PENDING-DELETED"];

  const mainLoading = isFilterLoading;

  return (
    <>
      <GridMinMax>
        <Button
          size="large"
          type="default"
          icon={<FileSyncOutlined />}
          onClick={() => [
            setShowGeneratePolicyFiles(!showGeneratePolicyFiles),
            setToggleSearchFields(false),
          ]}
        >
          {t("generateFilesBtn")}
        </Button>

        <Button
          loading={
            reportType !== "BORDERO" && isFilterReportLoading ? true : false
          }
          size="large"
          type="dashed"
          icon={<ContainerOutlined />}
          onClick={() => sendFilterReportFun()}
        >
          {t("downloadReports")}
        </Button>

        <Button
          loading={
            reportType === "BORDERO" && isFilterReportLoading ? true : false
          }
          size="large"
          type="dashed"
          icon={<ContainerOutlined />}
          onClick={async () => {
            const newReportType = "BORDERO";
            setReportType(newReportType);
            await sendFilterReportFun(newReportType);
          }}
        >
          {t("BORDERO")}
        </Button>

        <Button
          size="large"
          type="primary"
          icon={<FilterOutlined />}
          onClick={() => [
            setToggleSearchFields(!toggleSearchFields),
            setShowGeneratePolicyFiles(false),
          ]}
        >
          {t("filter_")}
        </Button>
      </GridMinMax>

      {/* ***** GENERATE SMS | FILES | EMAILS | TOP SIDE ***** */}
      {showGeneratePolicyFiles && isFraniAdmin && (
        <Card style={{ marginTop: "20px" }}>
          <Flex vertical gap={20}>
            <Alert message={t("selectUsersForGenerateFiles")} type="info" />

            <Flex gap={20} justify="space-around" wrap="wrap">
              <Alert
                message={`${t("selectedUserNumber")} ${
                  selectedCompanyCheckbox?.length
                }`}
                type="warning"
              />

              <Popconfirm
                title={t("certainToGenerateFiles")}
                onConfirm={GeneratePolicyFilesFun}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                placement="bottom"
              >
                <Button
                  loading={IsGeneratePolicyFileLoading}
                  disabled={selectedCompanyCheckbox.length < 1}
                  size="large"
                  type="primary"
                  icon={<ContainerOutlined />}
                >
                  {t("generateFilesBtn")}
                </Button>
              </Popconfirm>

              <Popconfirm
                title={t("certainToSendSMS")}
                onConfirm={SendEmailAndSmsToPolicyHolderFun}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                placement="bottom"
              >
                <Button
                  loading={IsSendEmailAndSmsToPolicyHolderLoading}
                  disabled={selectedCompanyCheckbox.length < 1}
                  size="large"
                  type="primary"
                  icon={<MailOutlined />}
                >
                  {t("sendSmsAndEmails")}
                </Button>
              </Popconfirm>
            </Flex>
          </Flex>
        </Card>
      )}
      {/* ***** GENERATE SMS | FILES | EMAILS | BOTTOM SIDE ***** */}

      {/* ***** FITER FIELDS TOP SIDE ***** */}
      {toggleSearchFields ? (
        <>
          <Divider />

          <GridMinMax>
            <Space direction="vertical">
              <Text>{t("product")}</Text>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                placeholder={t("product")}
                size="large"
                value={filterFieldsState?.productCategory || ""}
                onChange={(value) => [
                  setFilterField("productCategory", value),
                  setReportType(value),
                ]}
                options={[
                  ...["TRAVEL", "TPL", "WELCOMER", "PET", "COMBINED"].map(
                    (item) => ({
                      value: item,
                      label: item,
                    })
                  ),
                ]}
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("fromRegistrationDate")}</Text>
              <Input
                size="large"
                type="date"
                placeholder={t("fromRegistrationDate")}
                value={filterFieldsState?.registerDateFrom || ""}
                onChange={(event) =>
                  setFilterField("registerDateFrom", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("upToRegistrationDate")}</Text>
              <Input
                type="date"
                size="large"
                placeholder={t("upToRegistrationDate")}
                value={filterFieldsState?.registerDateTo || ""}
                onChange={(event) =>
                  setFilterField("registerDateTo", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("fromPolicyStartDate")}</Text>
              <Input
                type="date"
                size="large"
                placeholder={t("fromPolicyStartDate")}
                value={filterFieldsState?.policyDateFrom || ""}
                onChange={(event) =>
                  setFilterField("policyDateFrom", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("policyStatus")}</Text>
              <Select
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                placeholder={`${t("policyStatus")}`}
                value={filterFieldsState?.policyStatus?.map((x) => x)}
                onChange={(value: string[]) => {
                  setFilterField(
                    "policyStatus",
                    value?.map((status: string) => status)
                  );
                }}
                options={[
                  ...(policyStatusArr || []).map((item) => ({
                    label: item,
                    value: item,
                  })),
                ]}
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("Account ID")}</Text>
              <Input
                size="large"
                placeholder={t("Account ID")}
                value={filterFieldsState?.accountId || ""}
                onChange={(event) =>
                  setFilterField("accountId", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("Order ID")}</Text>
              <Input
                size="large"
                placeholder={t("Order ID")}
                value={filterFieldsState?.orderId || ""}
                onChange={(event) =>
                  setFilterField("orderId", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("paymentStatus")}</Text>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                placeholder={t("paymentStatus")}
                size="large"
                value={filterFieldsState?.paymentStatus || undefined}
                onChange={(value) => setFilterField("paymentStatus", value)}
                options={[
                  ...["PAID", "UNPAID"].map((item) => ({
                    value: item,
                    label: item,
                  })),
                ]}
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("policyNumber")}</Text>
              <Input
                size="large"
                placeholder={t("policyNumber")}
                value={filterFieldsState?.policyNumber || ""}
                onChange={(event) =>
                  setFilterField("policyNumber", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("insurerIdNumber")}</Text>
              <Input
                size="large"
                placeholder={t("insurerIdNumber")}
                value={filterFieldsState?.personalNumber || ""}
                onChange={(event) =>
                  setFilterField("personalNumber", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("isurerName")}</Text>
              <Input
                size="large"
                placeholder={t("isurerName")}
                value={filterFieldsState?.firstName || ""}
                onChange={(event) =>
                  setFilterField("firstName", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("insurerSurname")}</Text>
              <Input
                size="large"
                placeholder={t("insurerSurname")}
                value={filterFieldsState?.lastName || ""}
                onChange={(event) =>
                  setFilterField("lastName", event?.target?.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("fromPaymentsDate")}</Text>
              <Input
                type="date"
                size="large"
                placeholder={t("fromPaymentsDate")}
                value={filterFieldsState?.paymentDateFrom || ""}
                onChange={(event) =>
                  setFilterField("paymentDateFrom", event.target.value)
                }
              />
            </Space>

            <Space direction="vertical">
              <Text>{t("uptoPaymentDate")}</Text>
              <Input
                type="date"
                size="large"
                placeholder={t("uptoPaymentDate")}
                value={filterFieldsState?.paymentDateTo || ""}
                onChange={(event) =>
                  setFilterField("paymentDateTo", event?.target?.value)
                }
              />
            </Space>

            {filterFieldsState.productCategory === "TPL" && (
              <Space direction="vertical">
                <Text>{t("carRegistrationNumber")}</Text>
                <Input
                  size="large"
                  placeholder={t("carRegistrationNumber")}
                  value={filterFieldsState?.tplRegistrationNumber || ""}
                  onChange={(event) =>
                    setFilterField(
                      "tplRegistrationNumber",
                      event?.target?.value
                    )
                  }
                />
              </Space>
            )}

            <Button
              danger
              size="large"
              type="dashed"
              icon={<ClearOutlined />}
              onClick={() =>
                setFilterFieldsState({
                  reportDownload: false,
                  page: 1,
                  productCategory: "",
                  firstName: "",
                  lastName: "",
                  personalNumber: "",
                  companyIds: [],
                  registerDateFrom: "",
                  registerDateTo: "",
                  paymentDateFrom: "",
                  paymentDateTo: "",
                  policyStatus: [],
                  paymentStatus: "",
                  policyNumber: "",
                  tplRegistrationNumber: "",
                  policyDateFrom: "",
                  accountId: "",
                  orderId: "",
                })
              }
            >
              {t("clear")}
            </Button>

            <Button
              loading={isFilterLoading}
              size="large"
              type="default"
              icon={<FileSearchOutlined />}
              onClick={sendFilterData} // false send filter data info & true send report url
            >
              {t("filter")}
            </Button>
          </GridMinMax>

          {authorizedCompanies && isFraniAdmin && (
            <>
              <Divider />

              <Select
                loading={isAuthorizedCompaniesLoading}
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                placeholder={`${t("insuranceCompanies")}`}
                value={filterFieldsState.companyIds?.map((x) => x)}
                onChange={(value: number[]) => {
                  setFilterField(
                    "companyIds",
                    value?.map((id: number) => id)
                  );
                }}
                options={companyIdArr}
              />
            </>
          )}
        </>
      ) : null}
      {/* ***** FITER FIELDS TOP SIDE ***** */}

      <>
        {mainLoading ? (
          <Flex justify="center" align="center" style={{ height: "80%" }}>
            <Spin indicator={<LoadingOutlined spin />} size="large" />
          </Flex>
        ) : (
          <>
            <Divider>
              {t("searched")} <b>{filteredPolicies?.meta?.total || 0}</b>{" "}
              {t("policy")}
            </Divider>

            {filteredPolicies &&
            filteredPolicies.data &&
            filteredPolicies.data.length >= 1 ? (
              <Flex vertical gap={20}>
                {filteredPolicies.data.map((item, index) => {
                  //
                  const paymentsWithoutInit =
                    item.orderItems?.[0]?.order?.payments?.filter(
                      (x) => x.status !== "init"
                    );
                  //
                  const lastPayment =
                    paymentsWithoutInit && paymentsWithoutInit.length > 0
                      ? paymentsWithoutInit[paymentsWithoutInit.length - 1]
                      : null;
                  //
                  return (
                    <Flex key={index} gap={20}>
                      {showGeneratePolicyFiles && isFraniAdmin && (
                        <Checkbox
                          defaultChecked={
                            selectedCompanyCheckbox.find((x) => x === item.id)
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (e.target.checked === true) {
                              addCompanyId(item.id);
                              setTargetTypeState(item.targetType);
                            } else {
                              removeCompanyId(item.id);
                              setTargetTypeState(item.targetType);
                            }
                          }}
                        />
                      )}

                      <Card
                        hoverable
                        style={{
                          width: "100%",
                          borderWidth: selectedCompanyCheckbox.find(
                            (x) => x === item.id
                          )
                            ? "3px"
                            : "1px",
                        }}
                        onClick={() => {
                          if (item?.targetType === "TravelPolicy") {
                            // navigate travel detail page
                            navigate(`/travel-sold-policy-detail/${item.id}`);
                            //
                          } else if (item?.targetType === "TplPolicy") {
                            // navigate tpl detail page
                            navigate(`/tpl-sold-policy-detail/${item.id}`);
                          } else if (item?.targetType === "WelcomerPolicy") {
                            navigate(`/welcomer-sold-policy-detail/${item.id}`);
                          } else if (item?.targetType === "PetPolicy") {
                            navigate(`/pet-sold-policy-detail/${item.id}`);
                          }
                        }}
                      >
                        <Row gutter={[16, 16]} justify="space-between">
                          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Flex
                              vertical
                              style={{ width: "20%", whiteSpace: "nowrap" }}
                            >
                              <Text>
                                {String(item?.targetType?.match(/[A-Z][a-z]+/g))
                                  .split(",")?.[0]
                                  ?.toUpperCase()}
                              </Text>
                              <Text>
                                {item?.package?.product?.company?.slug?.replace(
                                  "-",
                                  " "
                                )}
                              </Text>
                            </Flex>
                          </Col>

                          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Flex
                              vertical
                              style={{ width: "20%", whiteSpace: "nowrap" }}
                            >
                              <Text>
                                {item?.translations?.en?.firstName}{" "}
                                {item?.translations?.en?.lastName}
                              </Text>
                              <Text>
                                {"N: "}
                                {item.policyNumber}
                              </Text>
                            </Flex>
                          </Col>

                          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Flex
                              vertical
                              style={{ width: "20%", whiteSpace: "nowrap" }}
                            >
                              <Text>
                                {t("from")}
                                {": "}
                                {dayjs(item?.startDate).format("DD  MMM YYYY")}
                              </Text>
                              <Text>
                                {t("to")}
                                {": "}
                                {dayjs(item?.endDate).format("DD MMM YYYY")}
                              </Text>
                            </Flex>
                          </Col>

                          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Flex
                              vertical
                              style={{ width: "20%", whiteSpace: "nowrap" }}
                            >
                              <Text>
                                {t("policy")}: {item?.status?.toUpperCase()}
                              </Text>
                              <Text>
                                {t("payment")}:{" "}
                                {lastPayment?.status?.toUpperCase() || "UNPAID"}
                              </Text>
                            </Flex>
                          </Col>

                          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Flex
                              vertical
                              style={{ width: "20%", whiteSpace: "nowrap" }}
                            >
                              <Text>{t("registrationDate")}:</Text>
                              <Text>
                                {dayjs(item.createdAt).format(
                                  "DD  MMM YYYY | HH:mm"
                                )}
                              </Text>
                            </Flex>
                          </Col>
                        </Row>
                      </Card>
                    </Flex>
                  );
                })}
              </Flex>
            ) : null}

            {filteredPolicies &&
            filteredPolicies.data &&
            filteredPolicies.data.length >= 1 ? (
              <Flex justify="center" style={{ marginTop: "20px" }}>
                <Pagination
                  responsive={true}
                  current={currentPage}
                  total={pageCount || 0}
                  showSizeChanger={false}
                  onChange={handlePageClick}
                />
              </Flex>
            ) : null}
          </>
        )}
      </>
    </>
  );
};

const GridMinMax = styled.div`
  display: grid;
  align-items: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

export default AdminSoldPolicies;
