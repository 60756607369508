import { AdminMenu } from "../../../domain/types";

export const MenuList: AdminMenu[] = [
  {
    id: 111,
    url: "/",
    hide: true,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "მთავარი",
      },
      en: {
        title: "Dashboard",
      },
    },
    sub_menu: [],
  },
  {
    id: 1,
    url: null,
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "სამოგზაურო",
      },
      en: {
        title: "Travel",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/risks",
        hide: true,
        permissions: ["Frani-Admin", "Frani-Product-Owner"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/travel-addons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "დაფარვები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/travel-franchise",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ფრანშიზები",
          },
          en: {
            title: "Deductible",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/manage-travel-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      // {
      //   sub_menu_id: 5,
      //   url: '/travel-reports',
      //   hide: true,
      //   permissions: ['Frani-Admin', 'Frani-Product-Owner', 'Insurance-Admin', 'Insurance-Financial-Viewer'],
      //   translations: {
      //     ge: {
      //       title: 'რეპორტები',
      //     },
      //     en: {
      //       title: 'Reports',
      //     },
      //   },
      // },
      {
        sub_menu_id: 6,
        url: "/travel-multi-days",
        hide: false,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "მრავალჯერადი (დღეები)",
          },
          en: {
            title: "Multi (days)",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/travel-outside-georgia/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 8,
        url: "/travel-outside-georgia/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 12,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "შიდა ტური",
      },
      en: {
        title: "Local Tours",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/welcomer-risks",
        hide: true,
        permissions: ["Frani-Admin", "Frani-Product-Owner"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/welcomer-addons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "დაფარვები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/welcomer-franchise",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ფრანშიზები",
          },
          en: {
            title: "Deductible",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/manage-welcomer-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/welcomer-to-georgia/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/welcomer-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 8,
        url: "/welcomer-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 9,
        url: "/welcomer-to-georgia/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 9,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "ტპლ",
      },
      en: {
        title: "TPL",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 5,
        url: "/tpl-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 1,
        url: "/tpl-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/tpl-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/tpl-exception-cars",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გამონაკლისი მანქანები",
          },
          en: {
            title: "Exceptional cars",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/tpl/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/tpl-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "სერვისები",
          },
          en: {
            title: "Services",
          },
        },
      },

      {
        sub_menu_id: 9,
        url: "/tpl/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 13,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "შინაური ცხოველები",
      },
      en: {
        title: "Pet",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/pet-categories",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ცხოველის კატეგორია",
          },
          en: {
            title: "Pet category",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/pet-breed",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ცხოველის ჯიში",
          },
          en: {
            title: "Pet breed",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/pet-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "სერვისები",
          },
          en: {
            title: "Services",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/pet-package-services",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "მომსახურებები",
          },
          en: {
            title: "Coverages",
          },
        },
      },
      {
        sub_menu_id: 5,
        url: "/pet-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/pet/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 7,
        url: "/pet/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 14,
    url: null,
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "კრიტიკული დაავადებები",
      },
      en: {
        title: "Critical Illness",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/critical-limits",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ლიმიტები",
          },
          en: {
            title: "Limit",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/critical-currencies",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვალუტები",
          },
          en: {
            title: "Currency",
          },
        },
      },
      {
        sub_menu_id: 3,
        url: "/critical-manage-risks",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "რისკები",
          },
          en: {
            title: "Risks",
          },
        },
      },
      {
        sub_menu_id: 4,
        url: "/critical-manage-pages",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გვერდების მართვა",
          },
          en: {
            title: "Manage pages",
          },
        },
      },
      {
        sub_menu_id: 5,
        url: "/critical/policy-deactivation-reasons",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "გაუქმების მიზეზები",
          },
          en: {
            title: "Deactivation reason",
          },
        },
      },
      {
        sub_menu_id: 6,
        url: "/critical/information-sheet",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "საინფორმაციო ფურცელი",
          },
          en: {
            title: "Information Sheet",
          },
        },
      },
    ],
  },
  {
    id: 16,
    url: "/payment-schedule-types",
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "გადახდის ჯერადობები",
      },
      en: {
        title: "Payment multiples",
      },
    },
    sub_menu: [],
  },
  {
    id: 2,
    url: "/authorized-companies",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Company-Editor",
    ],
    translations: {
      ge: {
        title: "კომპანიები",
      },
      en: {
        title: "Companies",
      },
    },
    sub_menu: [],
  },
  {
    id: 3,
    url: "/products",
    hide: false,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "პროდუქტების მართვა",
      },
      en: {
        title: "Manage Products",
      },
    },
    sub_menu: [],
  },
  {
    id: 4,
    url: "/sold-policies",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Insurance-Product-Editor",
      "Frani-Product-Owner",
      "Insurance-Admin",
      "Insurance-Financial-Viewer",
    ],
    translations: {
      ge: {
        title: "გაყიდული პოლისები",
      },
      en: {
        title: "Purchased policies",
      },
    },
    sub_menu: [],
  },
  {
    id: 5,
    url: "/company-payment-history",
    hide: false,
    permissions: [
      "Frani-Admin",
      "Frani-Product-Owner",
      "Insurance-Financial-Viewer",
      "Insurance-Admin",
    ],
    translations: {
      ge: {
        title: "გადახდების ისტორია",
      },
      en: {
        title: "Payment history",
      },
    },
    sub_menu: [],
  },
  {
    id: 6,
    url: "/web-documents",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "დოკუმენტაციები",
      },
      en: {
        title: "Documentations",
      },
    },
    sub_menu: [],
  },
  {
    id: 7,
    url: "null",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "მომხმარებლების მართვა",
      },
      en: {
        title: "Manage users",
      },
    },
    sub_menu: [
      {
        sub_menu_id: 1,
        url: "/frani-users",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ადმინის მომხმარებლები",
          },
          en: {
            title: "Admin Users",
          },
        },
      },
      {
        sub_menu_id: 2,
        url: "/web-users",
        hide: true,
        permissions: ["Frani-Admin"],
        translations: {
          ge: {
            title: "ვების მომხმარებლები",
          },
          en: {
            title: "Web Users",
          },
        },
      },
    ],
  },
  {
    id: 8,
    url: "/manage-pages",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "გვერდების მართვა",
      },
      en: {
        title: "Manage pages",
      },
    },
    sub_menu: [],
  },
  {
    id: 10,
    url: "/manage-product-emails",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "მეილების მართვა",
      },
      en: {
        title: "Manage emails",
      },
    },
    sub_menu: [],
  },
  {
    id: 11,
    url: "/business-module",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "კორპორატიული მოდული",
      },
      en: {
        title: "Corporate model",
      },
    },
    sub_menu: [],
  },
  {
    id: 15,
    url: "/promo-codes",
    hide: true,
    permissions: ["Frani-Admin"],
    translations: {
      ge: {
        title: "პრომოკოდები",
      },
      en: {
        title: "Promo codes",
      },
    },
    sub_menu: [],
  },
];
