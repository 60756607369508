import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
import { EnabledLanguage, MainPageText } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const Admin_landing_manage_texts = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [selectedPageId, setSelectedPageId] = useState<number | null>(null);
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [selectedPage, setSelectedPage] = useState<Partial<MainPageText>>({});

  // main page text list query
  const {
    data: mainpageTextList,
    isLoading: isMainPageTextLoading,
    refetch: refetchMainPageText,
  } = api.useGetMainPageTextListQuery(undefined);

  const { data: pageById, isLoading: isPageIdLoading } =
    api.useGetMainPageTextByIdQuery(selectedPageId as number, {
      skip: !selectedPageId,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    });

  const [updatePage, { isLoading: isUpdatePageLoading }] =
    api.useUpdateMainPageTextMutation();

  // update page text
  const updatePageText = async () => {
    try {
      await updatePage(selectedPage).unwrap();
      // refetch
      refetchMainPageText();
      // close drawer
      onCloseDrawer();
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // add page translation fields
  const setEditPageTranslatedField = (
    field: keyof MainPageText,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedPage({
      ...selectedPage,
      translations: {
        ...selectedPage.translations,
        [language]: {
          ...selectedPage?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  //close base drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setSelectedPageId(null);
    setSelectedPage({});
  };

  // set page info in fields when user click page card
  useEffect(() => {
    if (pageById && selectedPageId) {
      setSelectedPage(pageById);
    }
  }, [pageById, selectedPageId]);

  // main loading
  const MainLoading = isMainPageTextLoading || isUpdatePageLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <GridMinMax align="stretch">
        {mainpageTextList &&
          mainpageTextList.map((item, index) => (
            <Card
              loading={item.id === selectedPageId && isPageIdLoading}
              hoverable
              key={index}
              onClick={() => [
                setDrawerStatus(!drawerStatus),
                setSelectedPageId(item.id),
              ]}
            >
              {item.translations?.[field_Language]?.title && (
                <>
                  <Text>{item.translations?.[field_Language]?.title}</Text>
                  <Divider variant="dashed" />
                </>
              )}
              {item.translations?.[field_Language]?.body && (
                <>
                  <Text>{item.translations?.[field_Language]?.body}</Text>
                  <Divider variant="dashed" />
                </>
              )}

              <Text>{item.translations?.[field_Language]?.excerpt}</Text>
            </Card>
          ))}
      </GridMinMax>

      <Drawer
        extra={<AdminLangSwicher />}
        title={t("update")}
        onClose={onCloseDrawer}
        open={drawerStatus}
        loading={isPageIdLoading}
      >
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <Input
              size="large"
              placeholder={t("title")}
              value={selectedPage?.translations?.[field_Language]?.title || ""}
              onChange={(event) =>
                setEditPageTranslatedField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("description")}</Text>
            <Input
              size="large"
              placeholder={t("description")}
              value={selectedPage?.translations?.[field_Language]?.body || ""}
              onChange={(event) =>
                setEditPageTranslatedField(
                  "body",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("description") + " 2"}</Text>
            <Input
              size="large"
              placeholder={t("description") + " 2"}
              value={
                selectedPage?.translations?.[field_Language]?.excerpt || ""
              }
              onChange={(event) =>
                setEditPageTranslatedField(
                  "excerpt",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Flex vertical>
            <Divider />

            <Button
              size="large"
              onClick={updatePageText}
              style={{
                width: "100%",
              }}
            >
              {t("save")}
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default Admin_landing_manage_texts;
