import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import { EnabledLanguage, WelcomerAddon } from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
const { Text } = Typography;

type AddWelcomerAddonTranslatedFields = {
  title: string;
  body: string;
};

const AdminWelcomerAddons = () => {
  const { t } = useTranslation();
  const { lang } = useAppSelector((root) => root.app);
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // selected addon id
  const [welcomerAddonId, setWelcomerAddonId] = useState<number | null>(null);
  // welcomer addon drawer
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);

  // welcomer addon state
  const [addWelcomerAddonState, setAddWelcomerAddonState] = useState<
    Partial<WelcomerAddon>
  >({});

  const [defaultSelectedCategory, setDefaultSelectedCategory] = useState<
    string | null
  >(null);

  //get welcomer addons list
  const {
    data: welcomerAddons,
    isLoading: isWelcomerAddonsLoading,
    refetch: refetchWelcomerAddons,
  } = api.useGetAdminWelcomerAddonListQuery(undefined);

  // get admin product category list
  const { data: productCategoryList, isLoading: isProductCategoryListLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);

  // add welcomer addon
  const [saveWelcomerAddon, { isLoading: isAddWelcomerAddonLoading }] =
    api.useSaveWelcomerAddonMutation();

  // remove welcomer addon
  const [
    removeWelcomerAddon,
    {
      isLoading: isRemoveWelcomerAddonLoading,
      isSuccess: removeWelcomerAddonSuccess,
    },
  ] = api.useRemoveWelcomerAddonMutation();

  // close add welcomer addon drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setWelcomerAddonId(null);
    setDefaultSelectedCategory(null);
  };

  // add welcomer addon fields
  const setAddWelcomerAddon = (field: keyof WelcomerAddon, value: any) => {
    setAddWelcomerAddonState({
      ...addWelcomerAddonState,
      [field]: value,
    });
  };

  // get welcomer addon by id
  const {
    data: existingWelcomerAddon,
    isLoading: isExistingWelcomerAddonLoading,
  } = api.useGetAdminWelcomerAddonByIdQuery(welcomerAddonId as number, {
    skip: true && !welcomerAddonId,
  });

  // add welcomer addons translation fields
  const setAddWelcomerAddonTranslatedField = (
    field: keyof AddWelcomerAddonTranslatedFields,
    language: EnabledLanguage,
    value: any
  ) => {
    setAddWelcomerAddonState({
      ...addWelcomerAddonState,
      translations: {
        ...addWelcomerAddonState.translations,
        [language]: {
          ...addWelcomerAddonState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add welcomer addon function
  const AddWelcomerAddonFunction = async () => {
    try {
      await saveWelcomerAddon(addWelcomerAddonState).unwrap();
      // refetch welcomer addons
      refetchWelcomerAddons();
      onCloseDrawer();
      toast.success(`${t("additionalCoverages")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // auto refetch welcomer addons when user remove addon
  useEffect(() => {
    if (removeWelcomerAddonSuccess) {
      refetchWelcomerAddons();
      onCloseDrawer();
    }
  }, [removeWelcomerAddon, removeWelcomerAddonSuccess, refetchWelcomerAddons]);

  useEffect(() => {
    if (existingWelcomerAddon) {
      setAddWelcomerAddonState(existingWelcomerAddon);
    }
  }, [existingWelcomerAddon]);

  // default category
  useEffect(() => {
    if (productCategoryList && addWelcomerAddonState) {
      const category = productCategoryList?.find(
        (x) => x.id === addWelcomerAddonState.productCategoryId
      )?.title;
      //
      if (category) {
        setDefaultSelectedCategory(category);
      }
    }
  }, [productCategoryList, addWelcomerAddonState]);

  const MainLoading =
    isAddWelcomerAddonLoading ||
    isExistingWelcomerAddonLoading ||
    isProductCategoryListLoading ||
    isRemoveWelcomerAddonLoading ||
    isWelcomerAddonsLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => [
          setDrawerStatus(!drawerStatus),
          setAddWelcomerAddonState({}),
        ]}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax align="stretch">
        {welcomerAddons &&
          welcomerAddons.map((item, index) => (
            <Card
              hoverable
              key={index}
              onClick={() => [
                setWelcomerAddonId(item.id),
                setDrawerStatus(!drawerStatus),
              ]}
            >
              <Flex vertical gap={10}>
                <img
                  style={{
                    height: "50px",
                    objectFit: "contain",
                  }}
                  alt={`img_${item.image}_alt`}
                  src={item.image}
                />
                <Divider variant="dashed" />
                <Text>{item?.translations?.[lang]?.title}</Text>
                <Text>{item?.translations?.[lang]?.body}</Text>
              </Flex>
            </Card>
          ))}
      </GridMinMax>

      <Drawer
        title={welcomerAddonId ? t("update") : t("create")}
        onClose={onCloseDrawer}
        open={drawerStatus}
      >
        <Flex vertical gap={20}>
          {((productCategoryList &&
            defaultSelectedCategory &&
            welcomerAddonId) ||
            (productCategoryList && !welcomerAddonId)) && (
            <Space direction="vertical">
              <Text>{t("productCategory")}</Text>
              <Select
                style={{
                  width: "100%",
                }}
                allowClear
                placeholder={t("productCategory")}
                size="large"
                value={defaultSelectedCategory || undefined}
                onChange={(value) =>
                  setAddWelcomerAddon("productCategoryId", value)
                }
                options={[
                  ...productCategoryList.map((item) => ({
                    value: item.id,
                    label: item.title,
                    isDisabled:
                      item.slug === "welcomer-to-georgia" ? false : true,
                  })),
                ]}
              />
            </Space>
          )}

          <Space direction="vertical">
            <Text>{t("slug")}</Text>
            <Input
              size="large"
              placeholder={t("slug")}
              value={addWelcomerAddonState.slug || ""}
              onChange={(event) =>
                setAddWelcomerAddon("slug", event?.target?.value)
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <Input
              size="large"
              placeholder={t("title")}
              value={
                addWelcomerAddonState?.translations?.[field_Language]?.title ||
                ""
              }
              onChange={(event) =>
                setAddWelcomerAddonTranslatedField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <Space direction="vertical">
            <Text>{t("description")}</Text>
            <Input
              size="large"
              placeholder={t("description")}
              value={
                addWelcomerAddonState?.translations?.[field_Language]?.body ||
                ""
              }
              onChange={(event) =>
                setAddWelcomerAddonTranslatedField(
                  "body",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>

          <UploadFile
            label={t("icon")}
            onChange={(props) => setAddWelcomerAddon("image", props.url)}
            defaultFileName={addWelcomerAddonState?.image || ""}
          />

          <Flex vertical>
            <Divider />

            <Button
              style={{ marginBottom: "20px" }}
              size="large"
              onClick={AddWelcomerAddonFunction}
            >
              {welcomerAddonId ? t("update") : t("create")}
            </Button>

            {welcomerAddonId && (
              <Popconfirm
                title={t("certainToDeleteIt")}
                onConfirm={() => removeWelcomerAddon(welcomerAddonId)}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
                placement="bottom"
              >
                <Button
                  danger
                  size="large"
                  style={{
                    width: "100%",
                  }}
                >
                  {t("delete")}
                </Button>
              </Popconfirm>
            )}
          </Flex>
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminWelcomerAddons;
