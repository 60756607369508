import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
const { Text } = Typography;

const AdminWelcomerLimits = () => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [editedLimit, setEditedLimit] = useState<string | null>(null);

  // limits array
  const {
    data: welcomerLimits,
    isLoading: isWelcomerLimitsLoading,
    refetch: refetchWelcomerLimits,
  } = api.useGetWelcomerLimitsQuery({});

  // add welcomer limits
  const [saveWelcomerLimit, { isLoading: isSaveLimitLoading }] =
    api.useAddWelcomerLimitMutation();

  const [
    removeLimit,
    { isLoading: isRemoveLimitLoading, isSuccess: removeLimitSucces },
  ] = api.useRemoveWelcomerLimitMutation();

  const [
    updateWelcomerLimitsStatus,
    {
      isLoading: isUpdateWelcomerLimitStatusLoading,
      isSuccess: isUpdateWelcomerLimitSuccess,
    },
  ] = api.useUpdateWelcomerLimitStatusMutation();

  const updateWelcomerLimitStatusFun = async (
    id: number,
    isActive: boolean
  ) => {
    if (id && isActive !== undefined) {
      try {
        await updateWelcomerLimitsStatus({ id, isActive }).unwrap();
        // refetch franchise list
        refetchWelcomerLimits();
        toast.success(`${t("status")} ${t("updatedSuccessfully")}`);
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  //close add trave franchise drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setEditedLimit(null);
  };

  const saveLimitFunction = async () => {
    if (editedLimit) {
      try {
        await saveWelcomerLimit({ value: parseInt(editedLimit) }).unwrap();
        // refetch franchise list
        refetchWelcomerLimits();
        // message for user
        toast.success(`${t("limit")} ${t("addedSuccessfully")}`);
        // close drawer
        onCloseDrawer();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error(`${t("enterLimit")} ...`);
    }
  };

  useEffect(() => {
    if (removeLimitSucces || isUpdateWelcomerLimitSuccess) {
      refetchWelcomerLimits();
    }
  }, [removeLimitSucces, isUpdateWelcomerLimitSuccess, refetchWelcomerLimits]);

  const MainLoading =
    isWelcomerLimitsLoading ||
    isSaveLimitLoading ||
    isRemoveLimitLoading ||
    isUpdateWelcomerLimitStatusLoading;
  //

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setDrawerStatus(!drawerStatus)}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax>
        {welcomerLimits &&
          welcomerLimits.length >= 1 &&
          welcomerLimits.map((item, index) => (
            <Card key={index} hoverable>
              <Flex vertical gap={10}>
                <Text>
                  {t("limit")}: <b>{item.value}</b>
                </Text>
                <Text>
                  {t("status")}:
                  <b> {item.isActive === true ? t("active") : t("inActive")}</b>
                </Text>
              </Flex>

              <Divider />

              <Flex gap={20}>
                <Button
                  size="large"
                  onClick={() => {
                    const newStatus = item.isActive;
                    return updateWelcomerLimitStatusFun(item.id, !newStatus);
                  }}
                  style={{
                    width: "100%",
                  }}
                >
                  {item.isActive === true ? t("deactivate") : t("activate")}
                </Button>

                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeLimit(item.id)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button size="large" danger>
                    <DeleteOutlined />
                  </Button>
                </Popconfirm>
              </Flex>
            </Card>
          ))}
      </GridMinMax>

      <Drawer title={t("create")} onClose={onCloseDrawer} open={drawerStatus}>
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("limit")}</Text>
            <Input
              size="large"
              placeholder={t("limit")}
              value={editedLimit || ""}
              onChange={(event) => setEditedLimit(event?.target?.value)}
            />
          </Space>

          <Space direction="vertical">
            <Divider />
            <Button
              size="large"
              onClick={saveLimitFunction}
              style={{
                width: "100%",
              }}
            >
              {t("create")}
            </Button>
          </Space>
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminWelcomerLimits;
