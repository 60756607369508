import styled, { createGlobalStyle } from "styled-components";
import { EnabledLanguage } from "../../domain/types";
import Responsive from "../config/Responsive";
import Fonts from "../Fonts";
import Colors from "./Colors";

const GlobalStyle = createGlobalStyle`

`;

// Set the font according to the language
export const SelectFont = (language: EnabledLanguage) => {
  const FontsArray = Object.keys(Fonts);
  const GetSelectedLang = FontsArray.find((element) => element === language);

  if (GetSelectedLang) {
    document.body.style.fontFamily = Fonts?.[GetSelectedLang];
  } else {
    alert("We don't have a similar font !!!");
  }
};

// hide scroll bar all screen size
export const Scrollbar = createGlobalStyle<{ hideScroll: boolean }>`
  body {
      overflow: ${(props) => (props.hideScroll === true ? "hidden" : "unset")};
      padding-right: ${(props) => (props.hideScroll === true ? "5px" : "0px")};
  }
`;
// hide scroll bar in mobile
export const ScrollbarInMobile = createGlobalStyle<{ hideScroll: boolean }>`
  body {
    ${Responsive.mobile}{
      overflow: ${(props) => (props.hideScroll === true ? "hidden" : "unset")};
      padding-right: ${(props) => (props.hideScroll === true ? "5px" : "0px")};
    }
  }
`;

// full screen
export const FullScreen = styled.div`
  min-height: calc(100vh - 56px);
  padding: 50px 0px;
`;

// large page title
export const MediumScreenTitle = styled.h5`
  border-left: 2px solid ${Colors.primaryGrey};
  padding: 0px 5px 5px 5px;
  width: fit-content;
  margin-bottom: 50px;
  color: ${Colors.primaryGrey};
`;

export const GridMinMax = styled.div<{
  size?: number;
  align?: "top" | "center" | "end" | "stretch";
  gap?: number;
}>`
  display: grid;
  align-items: ${(props) => props.align || "end"};
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.size || 300}px, 1fr)
  );
  gap: ${(props) => props.gap || 20}px;
`;

// container
export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;
  /* container size  | desktop*/
  ${Responsive.desktop} {
    /* background-color: green; */
    max-width: 1600px;
  }
  ${Responsive.laptop} {
    /* background-color: aqua; */
    max-width: 1180px;
  }
  ${Responsive.tablet} {
    /* background-color: yellow; */
    max-width: 760px;
  }
  ${Responsive.mobile} {
    /* background-color: yellow; */
    max-width: 600px;
    padding-right: 25px;
    padding-left: 25px;
  }
`;

export default GlobalStyle;
