import { UploadOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { Button, Flex, Space, Typography, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import api from "../api";
const { Text } = Typography;

interface UploadFileProps {
  label?: string;
  onChange: (props: { url: string; key?: string }) => void;
  defaultFileName?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const Upload_File: React.FC<UploadFileProps> = ({
  label = "please enter label text",
  defaultFileName,
  onChange,
  disabled = false,
  style,
}) => {
  const [uploadFileMutation, { isLoading: isUploadFileLoading }] =
    api.useUploadFileMutation();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isUploaded, setIsUploaded] = useState(false);

  const extractFileName = (url: string): string => {
    if (!url) return "";

    // Try extracting the name based on common URL patterns
    return (
      url.split("/images/")[1]?.split("-").slice(1).join("-") || // Extract after "/images/"
      url.split("---")[1] || // Extract after "---"
      url // Return the full URL as fallback
    );
  };

  useEffect(() => {
    if (defaultFileName) {
      setFileList([
        {
          uid: String(Date.now()),
          name: extractFileName(defaultFileName) || "",
          status: "done",
          url: defaultFileName,
        },
      ]);
      setIsUploaded(true);
    } else {
      setFileList([]);
      setIsUploaded(false);
    }
  }, [defaultFileName]);

  const uploadProps: UploadProps = {
    listType: "picture",
    maxCount: 1,
    fileList,
    beforeUpload: async (file) => {
      try {
        const result = await uploadFileMutation({ file }).unwrap();
        // message.success(t("filePploadedSuccessfully"));

        const uploadedFile: UploadFile<any> = {
          uid: String(result.id || Date.now()),
          name: extractFileName(result?.url),
          status: "done",
          url: result?.url,
        };

        setFileList([uploadedFile]);
        onChange({ url: result?.url, key: result?.key });
        setIsUploaded(true);
      } catch (error) {
        message.error("File upload failed | " + error);
        console.error(error);
      }

      return false;
    },
    onRemove: () => {
      setFileList([]);
      onChange({ url: "" });
      setIsUploaded(false);
    },
  };

  //
  return (
    <Block>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Text>{label}</Text>
        <Flex style={{ width: "100%" }}>
          <Upload
            disabled={disabled}
            style={{
              ...style,
              width: "100%",
            }}
            {...uploadProps}
            showUploadList={{ showRemoveIcon: true }}
          >
            {!isUploaded && (
              <Button
                size="large"
                style={{
                  width: "100%",
                }}
                disabled={disabled}
                type="default"
                icon={<UploadOutlined />}
                loading={isUploadFileLoading}
              >
                {label}
              </Button>
            )}
          </Upload>
        </Flex>
      </Space>
    </Block>
  );
};

const Block = styled.div`
  .ant-upload-wrapper {
    width: 100%;

    .ant-upload {
      width: 100%;
    }
  }
`;

export default Upload_File;
