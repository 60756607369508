import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Popconfirm,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "../../../../api";
import { GridMinMax } from "../../../../assets/styles/GlobalStyle";
import Loader from "../../../../components/Loader";
const { Text } = Typography;

const AdminWelcomerFranchise = () => {
  const { t } = useTranslation();
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [editedFranchise, setEditedFranchise] = useState<number | null>(null);

  // franchise array
  const {
    data: franchiseList,
    isLoading: isFranchiseListLoading,
    refetch: refetchFranchiseList,
  } = api.useGetAdminWelcomerFranchiseListQuery("welcomer-franchise");

  // update and add welcomer franchise
  const [saveWelcomerFranchise, { isLoading: isSaveWelcomerFranchise }] =
    api.useAddAdminWelcomerFranchiseMutation();

  // remove welcomer franchise
  const [
    removeFranchise,
    { isLoading: isRemoveFranchiseLoading, isSuccess: removeFranchiseSuccess },
  ] = api.useRemoveWelcomerFranchiseMutation();

  // close welcomer add drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setEditedFranchise(null);
  };

  const saveFranchiseFunction = async () => {
    if (editedFranchise) {
      try {
        await saveWelcomerFranchise({ franchise: editedFranchise }).unwrap();
        // refetch franchise list
        refetchFranchiseList();
        //message for user
        toast.success(`${t("deductible")} ${t("addedSuccessfully")}`);
        // close drawer
        onCloseDrawer();
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    } else {
      toast.error(`${t("enterDeductible")}`);
    }
  };

  useEffect(() => {
    if (removeFranchiseSuccess) {
      refetchFranchiseList();
    }
  }, [removeFranchiseSuccess, refetchFranchiseList]);

  const MainLoading =
    isFranchiseListLoading ||
    isSaveWelcomerFranchise ||
    isRemoveFranchiseLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Button
        size="large"
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setDrawerStatus(!drawerStatus)}
      >
        {t("create")}
      </Button>

      <Divider />

      <GridMinMax>
        {franchiseList &&
          franchiseList.map((item, index) => (
            <Card key={index} hoverable>
              <Flex vertical align="center">
                <Text>
                  <b>{item.value}</b>
                </Text>
                <Divider variant="dashed" />

                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={() => removeFranchise(item.id)}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button
                    danger
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  >
                    {t("delete")}
                  </Button>
                </Popconfirm>
              </Flex>
            </Card>
          ))}
      </GridMinMax>

      <Drawer title={t("create")} onClose={onCloseDrawer} open={drawerStatus}>
        <Flex vertical gap={20}>
          <Space direction="vertical">
            <Text>{t("deductible")}</Text>
            <Input
              type="number"
              size="large"
              placeholder={t("deductible")}
              value={editedFranchise || ""}
              onChange={(event) =>
                setEditedFranchise(Number(event?.target?.value))
              }
            />
          </Space>

          <Space direction="vertical">
            <Divider variant="dashed" />
            <Button
              style={{ width: "100%" }}
              size="large"
              type="primary"
              icon={<PlusOutlined />}
              onClick={saveFranchiseFunction}
            >
              {t("create")}
            </Button>
          </Space>
        </Flex>
      </Drawer>
    </>
  );
};

export default AdminWelcomerFranchise;
