import React, { useEffect, useState } from "react";
// MUI
// import TabContext from '@mui/lab/TabContext'
// import TabList from '@mui/lab/TabList'
// import TabPanel from '@mui/lab/TabPanel'
import Box from "@mui/material/Box";
// import Tab from '@mui/material/Tab'
import lodash from "lodash";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Responsive from "../../../../assets/config/Responsive";
import Colors from "../../../../assets/styles/Colors";
import { Container } from "../../../../assets/styles/GlobalStyle";
import ScreenHeader from "../../../../components/ScreenHeader";
import {
  allAreasOfTravelProduct,
  allCurrenciesOfTravelProduct,
  allFranchisesOfTravelProduct,
  decorateTravelProductWithDefaultPackageCoefficients,
  regeneratePriceCombinations,
} from "../../../../domain/travelAbroad";
import {
  Currency,
  EnabledLanguage,
  Product,
  TravelAbroadArea,
  TravelProduct,
  WordingFileType,
} from "../../../../domain/types";
// import { ProductCategorySlugs } from '../../../../domain/productCategory'
import { toast } from "react-toastify";
import api from "../../../../api";
import EditAbroadDocumentsTab from "./travel_product_store/EditAbroadDocumentsTab";
import EditAbroadPackagesTab from "./travel_product_store/EditAbroadPackagesTab";
import EditAbroadPricesTab from "./travel_product_store/EditAbroadPricesTab";
import EditAbroadProductFieldsTab from "./travel_product_store/EditAbroadProductFieldsTab";
// import _ from 'lodash'
import { useTranslation } from "react-i18next";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { AppState, setEditedlanguage } from "../../../../store/appSlice";
//
//
type ProductTabData = {
  travelProduct: TravelProduct;
};
//
const Admin_product_edit = () => {
  const { travelProductId: str = "0" } = useParams();
  const travelProductId = parseInt(str);
  //
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  //
  // translation
  const { t } = useTranslation();
  //
  //
  const dispatch = useAppDispatch();
  //
  // For mui tab
  const [activeTab, setActiveTab] = React.useState("1");
  // mui tab onChange
  // const handleChange = (event: React.SyntheticEvent, newValue: string) => {
  //   setActiveTab(newValue)
  // }
  // const [language, setLanguage] = useState<EnabledLanguage>("ge");
  //
  // const [ageGroups, _only_setAgeGroups] = useState<AgeGroup[]>([])
  // const [ageGroupsWithCoefficient, setAgeGroupsWithCoefficient] =
  //   useState<MinTotalPremiumAgeGroupWithCoefficient[]>(ageGroups)
  /** set both ageGroups and ageGroupsWithCoefficient */
  // const setAgeGroups = (incomingAgeGroups: AgeGroup[]) => {
  //   // set normal age groups state
  //   _only_setAgeGroups(incomingAgeGroups)
  //   setAgeGroupsWithCoefficient(
  //     incomingAgeGroups.map((incomingGroup) => {
  //       const matchingCoefficient = ageGroupsWithCoefficient.find(
  //         (g) => g.minAge === incomingGroup.minAge && g.maxAge === incomingGroup.maxAge,
  //       )?.coefficient
  //       return {
  //         ...incomingGroup,
  //         coefficient: matchingCoefficient,
  //       }
  //     }),
  //   )
  // }
  //
  // const [dayGroupsWithCoefficient, setDayGroupsWithCoefficient] = useState<TravelDayGroupWithCoefficient[]>([])
  ///

  //
  //
  //
  // product categories list
  const {
    data: existingTravelProduct,
    isLoading: isExistingTravelProductLoading,
    refetch: refetchExistingTravelProduct,
  } = api.useGetTravelProductByIdQuery(travelProductId);
  /** could not reliably figure out how to update packages/travelPackages here from TravelPackageEditor */
  /** that was needed for detecting used areas/franchises/currencies, so doing that manually here */
  const [perTravelPackage_usedCurrencies, only_setUsedCurrencies] = useState<
    Array<{ travelPackageId: number; values: Currency[] }>
  >([]);

  const [perTravelPackage_usedAreas, only_setUsedAreas] = useState<
    Array<{ travelPackageId: number; values: TravelAbroadArea[] }>
  >([]);

  const [perTravelPackage_usedFranchises, only_setUsedFranchises] = useState<
    Array<{ travelPackageId: number; values: number[] }>
  >([]);
  const setUsedCurrencies = (travelPackageId: number, values: Currency[]) => {
    const listWithout = perTravelPackage_usedCurrencies.filter(
      (x) => x.travelPackageId !== travelPackageId
    );
    const listWith = [...listWithout, { travelPackageId, values: values }];
    only_setUsedCurrencies(listWith);
  };
  const setUsedAreas = (
    travelPackageId: number,
    values: TravelAbroadArea[]
  ) => {
    const listWithout = perTravelPackage_usedAreas.filter(
      (x) => x.travelPackageId !== travelPackageId
    );
    const listWith = [...listWithout, { travelPackageId, values: values }];
    only_setUsedAreas(listWith);
  };
  const setUsedFranchises = (travelPackageId: number, values: number[]) => {
    const listWithout = perTravelPackage_usedFranchises.filter(
      (x) => x.travelPackageId !== travelPackageId
    );
    const listWith = [...listWithout, { travelPackageId, values: values }];
    only_setUsedFranchises(listWith);
  };
  const usedCurrencies = lodash.uniq(
    perTravelPackage_usedCurrencies.reduce(
      (carry, next) => carry.concat(next.values),
      [] as Currency[]
    )
  );
  const usedAreas = lodash.uniq(
    perTravelPackage_usedAreas.reduce(
      (carry, next) => carry.concat(next.values),
      [] as TravelAbroadArea[]
    )
  );
  const usedFranchises = lodash.uniq(
    perTravelPackage_usedFranchises.reduce(
      (carry, next) => carry.concat(next.values),
      [] as number[]
    )
  );
  //
  // countries list
  const { data: countries = [], isLoading: isCountriesLoading } =
    api.useGetCountriesQuery(undefined);
  // travel franchises
  const { data: travelFranchises = [], isLoading: isTravelFranchisesLoading } =
    api.useGetTravelFranchisesQuery("travel-abroad-franchise");
  // travel limits
  const { data: travelLimits = [], isLoading: isTravelLimitsLoading } =
    api.useGetTravelLimitsQuery(undefined);
  console.log("travelLimits", travelLimits ? "" : "");
  // get all travel risks list
  const {
    data: travelRisks = [],
    isLoading: isTravelRisksLoading,
    // refetch: refetchTravelRisks,
  } = api.useGetAdminTravelRiskListQuery(undefined);
  //
  const {
    data: travelMultiDays = [],
    isLoading: isTravelMultiDaysLoading,
    // refetch: refetchTravelRisks,
  } = api.useGetTravelMultiDaysQuery(undefined);
  //
  const {
    data: travelAbroadAddons = [],
    isLoading: isTravelAbroadAddonsLoading,
    // refetch: refetchTravelAbroadAddons,
  } = api.useGetAdminTravelAbroadAddonListQuery(undefined);
  //
  const [productFieldsState, setProductFieldsState] = useState<
    ProductTabData | undefined
  >();
  //
  //
  const setMainProductField = (
    fieldName: keyof ProductTabData["travelProduct"]["mainProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setProductFieldsState({
      ...productFieldsState,
      travelProduct: {
        ...productFieldsState.travelProduct,
        mainProduct: {
          ...productFieldsState.travelProduct.mainProduct,
          [fieldName]: fieldValue,
        },
      },
    });
  };

  const setTravelProductField = (
    fieldName: keyof ProductTabData["travelProduct"],
    fieldValue: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    const updatedProductFields: TravelProduct = {
      ...productFieldsState.travelProduct,
      [fieldName]: fieldValue,
    };
    // TODO if this becomes slow, check if these coefficient fields have changed:
    // minTotalPremiumInfos, dayGroupInfos, priceCombinations, priceCoefficients
    let productWithRegeneratedPriceCombinations: TravelProduct;
    try {
      productWithRegeneratedPriceCombinations =
        regeneratePriceCombinations(updatedProductFields);
    } catch (e) {
      // console.log(e);
      // revert to product without regenerated price combinations
      productWithRegeneratedPriceCombinations = updatedProductFields;
    }
    setProductFieldsState({
      ...productFieldsState,
      travelProduct: productWithRegeneratedPriceCombinations,
    });
  };

  const setTranslatedFieldOnProduct = (
    field: keyof Product["translations"]["en"],
    language: EnabledLanguage,
    value: any
  ) => {
    if (!productFieldsState) {
      return;
    }
    setTravelProductField("mainProduct", {
      ...productFieldsState.travelProduct.mainProduct,
      translations: {
        ...productFieldsState.travelProduct.mainProduct.translations,
        [language]: {
          ...productFieldsState.travelProduct.mainProduct?.translations?.[
            language
          ],
          [field]: value,
        },
      },
    });
  };
  //
  //
  //
  //
  // travel product tab one fields store | save
  const [
    m_travelProductTabOneProductFields,
    { isLoading: isSaveTravelProductFieldsLoading },
  ] = api.useStoreTravelProductTabOneProductFieldsMutation();
  // product tab three documents store | save
  const [
    m_saveProductDocumentFields,
    { isLoading: isSaveProductDocumentFieldsLoading },
  ] = api.useUpdateProductDocumentsMutation();
  // save prices tab
  const [
    m_updateTravelProductPriceCombinations,
    { isLoading: isSavePricesTabLoading },
  ] = api.useUpdateTravelProductPriceCombinationsMutation();
  // [admin]: approve product changes
  const [m_activateProduct, { isLoading: isActivateProductLoading }] =
    api.useAdminActivateProductMutation();
  // [admin]: reject product changes
  const [m_deactivateProduct, { isLoading: isDeactivateProductLoading }] =
    api.useAdminDeactivateProductMutation();

  const storeTavelProductGeneralFields = async () => {
    try {
      if (!productFieldsState?.travelProduct?.id) {
        throw new Error("Missing product data");
      }

      let policyRanges =
        productFieldsState?.travelProduct.mainProduct.policyNumberRanges || [];

      if (
        policyRanges.some(
          (p) =>
            hasNonNumericCharacters(p.minPolicyNumber) ||
            hasNonNumericCharacters(p.maxPolicyNumber)
        )
      ) {
        toast.error(`${t("incorrectPolicyFormat")}`);
        return;
      }

      const travelProductToSave = productFieldsState.travelProduct;

      await m_travelProductTabOneProductFields(travelProductToSave).unwrap();
      toast.success(`${t("product")} ${t("savedSuccessfully")}`);

      refetchExistingTravelProduct();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };

  const hasNonNumericCharacters = (input: string) => /[^0-9]/.test(input);

  //
  // save uploaded documents
  const storeProductDocumentFields = async (wordingFiles: WordingFileType) => {
    try {
      if (!productFieldsState?.travelProduct?.id) {
        throw new Error("Missing product data");
      }
      await m_saveProductDocumentFields({
        ...productFieldsState.travelProduct.mainProduct,
        wordingFiles: wordingFiles,
      }).unwrap();

      toast.success(`${t("uploadedSuccessfully")}`);

      // refetch travel products
      refetchExistingTravelProduct();

      // clean old fields
      window.location.reload();
    } catch (error) {
      // console.log("save documents tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  // save uploaded documents
  const updateTavelProductPricesTab = async () => {
    try {
      if (!productFieldsState?.travelProduct?.id) {
        throw new Error("Missing product data");
      }
      await m_updateTravelProductPriceCombinations({
        id: productFieldsState.travelProduct.id,
        priceCoefficients: productFieldsState.travelProduct.priceCoefficients,
        minTotalPremiumInfos:
          productFieldsState.travelProduct.minTotalPremiumInfos,
        dayGroupInfos: productFieldsState.travelProduct.dayGroupInfos,
        priceCombinations: productFieldsState.travelProduct.priceCombinations,
      }).unwrap();
      toast.success(`${t("prices")} ${t("savedSuccessfully")}`);
      //
      // refetch travel products
      // DISABLED, because it resets product fields. if you enable this, also save product fields tab here.
      // refetchExistingTravelProduct()
    } catch (error) {
      // console.log("save prices tab error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  // approve product changes [admin does this]
  const approveProductChanges = async () => {
    try {
      if (!productFieldsState?.travelProduct?.id) {
        throw new Error("Missing product data");
      }
      if (productFieldsState.travelProduct.mainProduct.status === "published") {
        throw new Error("დაუდასტურებელი ცვლილებები არ მოიძებნა");
      }
      // first, save main fields
      await storeTavelProductGeneralFields();
      // second, save prices
      await updateTavelProductPricesTab();
      // third, activate the product
      await m_activateProduct(
        productFieldsState.travelProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t("product")} ${t("publishedSuccessfully")}`);
      //
      // refetch travel products
      // WARNING: this resets product fields.
      // if this is enabled, also save product fields above.
      refetchExistingTravelProduct();
    } catch (error) {
      // console.log("approve product error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  // reject product changes [admin does this]
  const rejectProductChanges = async () => {
    try {
      if (!productFieldsState?.travelProduct?.id) {
        throw new Error("Missing product data");
      }
      if (productFieldsState.travelProduct.mainProduct.status === "published") {
        // in this case, admin is converting a published product to draft (probably just auditing existing published products)
      }
      await m_deactivateProduct(
        productFieldsState.travelProduct.mainProduct.id
      ).unwrap();
      toast.success(`${t("product")} ${t("deactivated")}`);
      //
      // refetch travel products
      // WARNING: this resets product fields. if you enable this, also save product fields tab here.
      // 09.02.2022: deactivation without saving modified fields. hoping for no problems in UX.
      refetchExistingTravelProduct();
    } catch (error) {
      // console.log("reject product changes error:", error);
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.warning(error?.message || `${t("unknownError")}`);
      }
    }
  };
  //
  const isFullPageLoading =
    isExistingTravelProductLoading || !productFieldsState;
  //
  const isProductFieldsTabLoading =
    isFullPageLoading ||
    isActivateProductLoading ||
    isDeactivateProductLoading ||
    isSaveTravelProductFieldsLoading;
  //
  const isPackagesTabLoading =
    isFullPageLoading ||
    isTravelLimitsLoading ||
    isCountriesLoading ||
    isTravelFranchisesLoading ||
    isTravelRisksLoading ||
    isTravelMultiDaysLoading ||
    isTravelAbroadAddonsLoading;
  // || 'TODO add/edit/remove packages loading' === false
  //
  const isPDFTabLoading =
    isFullPageLoading || isSaveProductDocumentFieldsLoading; // || 'TODO save product PDF loading' === false
  //
  const isPricesTabLoading =
    isFullPageLoading ||
    isTravelFranchisesLoading ||
    isTravelLimitsLoading ||
    isSavePricesTabLoading; // 'TODO save prices loading' === false
  //
  // onload existing travel product
  useEffect(() => {
    if (existingTravelProduct) {
      // fill missing package coefficients, set default 1 (missing package coefficient was a frequent occurence)
      const travelProductWithDefaultPackageCoefficients =
        decorateTravelProductWithDefaultPackageCoefficients(
          existingTravelProduct
        );
      setProductFieldsState({
        travelProduct: travelProductWithDefaultPackageCoefficients,
      });
      //
      for (let travelPackageId of existingTravelProduct.mainProduct.packages.map(
        (pkg) => pkg.targetId
      )) {
        setUsedCurrencies(
          travelPackageId,
          allCurrenciesOfTravelProduct(existingTravelProduct)
        );
        setUsedAreas(
          travelPackageId,
          allAreasOfTravelProduct(existingTravelProduct)
        );
        setUsedFranchises(
          travelPackageId,
          allFranchisesOfTravelProduct(existingTravelProduct)
        );
      }
      // const existingAgeGroups = existingTravelProduct.minTotalPremiumInfos.map((mtpi) => ({
      //   minAge: mtpi.minAge,
      //   maxAge: mtpi.maxAge,
      // }))
      // setAgeGroups(existingAgeGroups)
      // setAgeGroupsWithCoefficient(existingAgeGroups)
      // day groups are not stored, so detect them from price combinations
      // TODO read from `existingTravelProduct`
      // const detectedDayGroupsFromPriceCombinations = _.uniqBy(
      //   existingTravelProduct.mainProduct.packages
      //     .map((p) => p.target)
      //     .map((p) => p?.travelAbroadPriceCombinations || [])
      //     .flat()
      //     .map(({ minDay, maxDay }) => ({ minDay, maxDay })),
      //   (v) => `${v.minDay}-${v.maxDay}`,
      // )
      // setDayGroupsWithCoefficient(detectedDayGroupsFromPriceCombinations)
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingTravelProduct]);

  //
  return (
    <div style={{ paddingBottom: "50px" }}>
      <ScreenHeader
        text={t("products")}
        url="/products"
        // TEMPORARY fix going back and forward resulting in package accordion having stale cached data from RTK Query.
        // correct fix is using RTK Invalidation Tags: https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
        onClick={() => {
          document.location.href = "/products";
        }}
        toolbar={
          <AdminLangSwicher
            onChange={(value) => dispatch(setEditedlanguage(value))}
          />
        }
      />
      <Container>
        <MuiBox>
          {/* <TabContext value={activeTab}> */}
          <Box>
            {/* onChange={handleChange} aria-label="lab API tabs example" */}
            <MuiTabList>
              {/* <MuiTab label={t('productFeatures')} value="1" />
                <MuiTab label={t('packages')} value="2" />
                <MuiTab label={t('documents')} value="3" />
                <MuiTab label={t('prices')} value="4" /> */}
              {/* <MuiTab label="ბენეფიტები" value="5" /> */}
              <MuiTab
                onClick={() => setActiveTab("1")}
                $active={activeTab === "1"}
              >
                {t("productFeatures")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("2")}
                $active={activeTab === "2"}
              >
                {t("packages")}
              </MuiTab>
              <MuiTab
                onClick={() => setActiveTab("3")}
                $active={activeTab === "3"}
              >
                {t("documents")}
              </MuiTab>
              {productFieldsState &&
              productFieldsState.travelProduct &&
              productFieldsState.travelProduct.mainProduct &&
              productFieldsState.travelProduct.mainProduct.integrated &&
              productFieldsState.travelProduct.mainProduct.integrated ===
                true ? null : (
                <MuiTab
                  onClick={() => setActiveTab("4")}
                  $active={activeTab === "4"}
                >
                  {t("prices")}
                </MuiTab>
              )}
            </MuiTabList>
          </Box>

          {/* value="1" */}
          <MuiTabPanel style={activeTab === "1" ? {} : { display: "none" }}>
            {/*პროდუქტის მახასიათებლები */}
            <EditAbroadProductFieldsTab
              travelProduct={productFieldsState?.travelProduct}
              language={field_Language}
              isLoading={isProductFieldsTabLoading}
              setTravelProductField={setTravelProductField}
              setTranslatedFieldOnProduct={setTranslatedFieldOnProduct}
              setMainProductField={setMainProductField}
              // ageGroups={ageGroups}
              // setAgeGroups={setAgeGroups}
              // dayGroups={dayGroupsWithCoefficient}
              // setDayGroups={setDayGroupsWithCoefficient}
              saveTab={storeTavelProductGeneralFields}
              approveProductChanges={approveProductChanges}
              rejectProductChanges={rejectProductChanges}
            />
          </MuiTabPanel>

          {/* value="2" */}
          <MuiTabPanel style={activeTab === "2" ? {} : { display: "none" }}>
            {/* პაკეტები */}
            <EditAbroadPackagesTab
              travelProduct={productFieldsState?.travelProduct}
              countries={countries}
              travelFranchises={travelFranchises}
              travelRisks={travelRisks}
              travelMultiDays={travelMultiDays}
              travelAbroadAddons={travelAbroadAddons}
              isLoading={isPackagesTabLoading}
              refetchExistingTravelProduct={refetchExistingTravelProduct}
              /** setTravelProductField is not used for saving packages, but only to receive price combination-related updates */
              setTravelProductField={setTravelProductField}
              setUsedCurrencies={setUsedCurrencies}
              setUsedAreas={setUsedAreas}
              setUsedFranchises={setUsedFranchises}
            />
          </MuiTabPanel>

          {/* value="3" */}
          <MuiTabPanel style={activeTab === "3" ? {} : { display: "none" }}>
            {/* დოკუმენტები */}
            <EditAbroadDocumentsTab
              language={field_Language}
              travelProduct={productFieldsState?.travelProduct}
              isLoading={isPDFTabLoading}
              setTravelProductField={setTravelProductField}
              setMainProductField={setMainProductField}
              saveTab={storeProductDocumentFields}
            />
          </MuiTabPanel>

          {productFieldsState &&
          productFieldsState.travelProduct &&
          productFieldsState.travelProduct.mainProduct &&
          productFieldsState.travelProduct.mainProduct.integrated &&
          productFieldsState.travelProduct.mainProduct.integrated ===
            true ? null : (
            <MuiTabPanel style={activeTab === "4" ? {} : { display: "none" }}>
              {/* ფასები */}
              <EditAbroadPricesTab
                language={field_Language}
                travelProductId={travelProductId}
                travelProduct={productFieldsState?.travelProduct}
                setTravelProductField={setTravelProductField}
                travelFranchises={travelFranchises}
                // ageGroupsWithCoefficient={ageGroupsWithCoefficient}
                // setAgeGroupsWithCoefficient={setAgeGroupsWithCoefficient}
                // dayGroupsWithCoefficient={dayGroupsWithCoefficient}
                // setDayGroupsWithCoefficient={setDayGroupsWithCoefficient}
                saveTab={updateTavelProductPricesTab}
                isLoading={isPricesTabLoading}
                // isLoading={isPricesTabLoading}
                // saveTab={() => alert('TODO save prices')}
                usedCurrencies={usedCurrencies}
                usedAreas={usedAreas}
                usedFranchises={usedFranchises}
              />
            </MuiTabPanel>
          )}

          {/* <MuiTabPanel value="5">
              {/* ბენეფიტები * /}
              {/* <Tab /> * /}
            </MuiTabPanel> */}
          {/* </TabContext> */}
        </MuiBox>
      </Container>
    </div>
  );
};
//
//
// styles
// const MuiTabList = styled(TabList)`
const MuiTabList = styled.div`
  /* MUI tab bottom line */
  .MuiTabs-indicator {
    background-color: ${Colors.primaryRed} !important;
  }
  ${Responsive.mobile} {
    /* mui button */
    button {
      margin-right: auto;
      font-size: 17px;
    }
  }
`;
// const MuiTab = styled(Tab)`
const MuiTab = styled.button<{ $active?: boolean }>`
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0px;
  border: 0px none;
  margin: 0px;
  margin-right: 0px;
  border-radius: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  color: rgba(0, 0, 0, 0.6);
  color: ${Colors.primaryBlack} !important;
  padding: 0px;
  margin-right: 30px;
  font-size: 18px;
  text-transform: none;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  ${({ $active = false }) =>
    $active ? `border-bottom-color: rgb(254, 95, 85);` : ""}

  &:last-child {
    margin-right: 0px;
  }
`;
// const MuiTabPanel = styled(TabPanel)`
const MuiTabPanel = styled.div`
  background-color: ${Colors.white};
  padding: 0px;
  padding-block: 20px;
  border-radius: 8px;
  box-shadow: ${Colors.white} 0px 30px 60px -12px inset,
    ${Colors.white} 0px 18px 36px -18px inset;
  ${Responsive.mobile} {
    margin-top: 20px;
  }
`;
// const MuiBox = styled(Box)`
const MuiBox = styled.div`
  margin-top: 30px;
  button {
    font-family: inherit;
  }
`;
//
export default Admin_product_edit;
