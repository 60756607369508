import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Responsive from "../assets/config/Responsive";
import LineArrow from "../assets/icons/JSX/LineArrow";
import Colors from "../assets/styles/Colors";
import { Container } from "../assets/styles/GlobalStyle";

const ScreenHeader: React.FC<{
  text: string;
  url: string;
  toolbar?: React.ReactNode;
  stateProps?: any;
  onClick?: () => void;
}> = ({ text, url, toolbar, stateProps, onClick }) => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Container>
        <HeaderRow>
          <Btn
            onClick={() => {
              if (onClick) {
                onClick();
              } else {
                navigate(`${url}`, { state: stateProps });
              }
            }}
          >
            <LineArrow /> <span>{text}</span>
          </Btn>
          {toolbar ? <ToolbarWrapper>{toolbar}</ToolbarWrapper> : null}
        </HeaderRow>
      </Container>
    </Wrapper>
  );
};
//
// styles luka ramishvili
const ToolbarWrapper = styled.div``;
const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
// styles
const Btn = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 5px 20px 5px 0px;
  color: ${Colors.primaryBlack};
  display: flex;
  align-items: center;

  /* inner svg / icon */
  svg {
    transform: rotate(180deg);
    width: 22px;
    height: 10px;
    /* margin-top: -2px; */
  }

  /* inner text span */
  span {
    font-size: 18px;
    padding-left: 11px;
  }

  /* responsive */
  ${Responsive.tablet} {
    /* inner svg / icon */
    svg {
      width: 17px;
      height: 15px;
    }

    /* inner text span */
    span {
      font-size: 14px;
      padding-left: 10px;
    }
  }

  ${Responsive.mobile} {
    margin-left: -25px;
    margin-right: -25px;
    /* inner svg / icon */
    svg {
      width: 17px;
      height: 15px;
      margin-top: -3px;
    }

    /* inner text span */
    span {
      font-size: 14px;
      padding-left: 10px;
    }
  }
`;
const Wrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  background-color: ${Colors.white};
  display: flex;
  align-items: center;
  user-select: none;
  z-index: 2;
  -webkit-box-shadow: 0px 0px 15px 3px #88888811;
  box-shadow: 0px 0px 15px 3px #88888811;
  padding-left: 100px;
  padding-right: 100px;
  ${Responsive.tablet} {
    padding-left: 50px;
    padding-right: 50px;
    height: 64px;
  }
  ${Responsive.mobile} {
    padding-left: 25px;
    padding-right: 25px;
    height: 64px;
  }
`;

export default ScreenHeader;
