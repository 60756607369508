import { ArrowLeftOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Flex, Popconfirm } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import Loader from "../../../components/Loader";
import {
  AdminDeactivePolicyReason,
  EnabledLanguage,
} from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
//
const AdminPolicyDeactivationReasonsDetails = () => {
  //
  const { id: idStr = "0" } = useParams();
  const itemId = parseInt(idStr);
  //
  const { slug } = useParams();
  //
  const navigate = useNavigate();
  //
  const { t } = useTranslation();
  // existing reason
  const [existingReason, setExistingReason] = useState<
    Partial<AdminDeactivePolicyReason>
  >({});
  //
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  //
  const { data: productCategories, isLoading: isProductCategoryLoading } =
    api.useGetAdminProductCategoriesListQuery(undefined);
  // get policy deactive reason
  const {
    data: deactiveReasonDetail,
    isLoading: isDeaactiveReasonDetailLoading,
    refetch: refetchPolicyReason,
  } = api.useGetAdminPolicyDeactiveReasonDetailQuery(itemId, {
    skip: !itemId && true,
  });
  //
  const [storePolicyReason, { isLoading: isStorePolicyReason }] =
    api.useStorePolicyReasonMutation();
  //  remove reason
  const [removeReason, { isLoading: isRemoveReasonLoading }] =
    api.useRemoveReasonMutation();
  //
  const setEditReasonTranslationField = (
    field: keyof AdminDeactivePolicyReason,
    language: EnabledLanguage,
    value: any
  ) => {
    setExistingReason({
      ...existingReason,
      translations: {
        ...existingReason.translations,
        [language]: {
          ...existingReason?.translations?.[language],
          [field]: value,
        },
      },
    });
  };
  //
  useEffect(() => {
    if (deactiveReasonDetail) {
      setExistingReason(deactiveReasonDetail);
    }
  }, [deactiveReasonDetail]);
  //
  useEffect(() => {
    if (itemId !== 0) {
      refetchPolicyReason();
    }
    //
    return () => {};
  }, [refetchPolicyReason, itemId]);
  // store reason
  const storeReasonFun = async (isActive?: boolean) => {
    //
    try {
      if (itemId === 0) {
        await storePolicyReason({
          productCategoryId:
            productCategories?.find((x) => x.slug === slug)?.id || 0,
          translations: existingReason.translations,
        }).unwrap();
      } else {
        await storePolicyReason({
          ...existingReason,
          isActive: isActive,
        }).unwrap();
      }
      //
      toast.success(
        itemId === 0
          ? `${t("createdSuccessfully")}`
          : `${t("updatedSuccessfully")}`
      );
      //
      navigate(`/${slug}/policy-deactivation-reasons`);
      //
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  //
  const RemoveReasonFunction = async () => {
    try {
      if (itemId !== 0 && itemId) {
        await removeReason(itemId).unwrap();
        //
        navigate(`/${slug}/policy-deactivation-reasons`);
      }
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(error);
      }
    }
  };
  //
  const MainLoader =
    isDeaactiveReasonDetailLoading ||
    isProductCategoryLoading ||
    isStorePolicyReason ||
    isRemoveReasonLoading;
  //
  return (
    <>
      {MainLoader ? (
        <Loader />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <Button
              size="large"
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => navigate(-1)}
            >
              {t("back")}
            </Button>

            <Title level={3}>{itemId ? t("update") : t("create")}</Title>

            <AdminLangSwicher />
          </Flex>

          <Divider />

          <Badge.Ribbon
            text={t("deactivationReason")}
            color="blue"
            style={{
              marginTop: "-20px",
            }}
          >
            <TextArea
              size="large"
              placeholder={t("deactivationReason")}
              autoSize={{ minRows: 2 }}
              value={existingReason.translations?.[field_Language]?.title || ""}
              onChange={(event) =>
                setEditReasonTranslationField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Badge.Ribbon>

          <Divider />

          <Flex gap={20} justify="center" align="center">
            <Button
              size="large"
              type="primary"
              onClick={() => storeReasonFun()}
            >
              {itemId ? t("update") : t("create")}
            </Button>

            {itemId !== 0 && (
              <>
                <Button
                  size="large"
                  danger
                  onClick={() => {
                    if (existingReason.isActive === true) {
                      storeReasonFun(false);
                    } else {
                      storeReasonFun(true);
                    }
                  }}
                >
                  {existingReason.isActive === true
                    ? t("deactivate")
                    : t("activate")}
                </Button>

                <Popconfirm
                  title={t("certainToDeleteIt")}
                  onConfirm={RemoveReasonFunction}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                  placement="bottom"
                >
                  <Button size="large" type="primary" danger>
                    {t("delete")}
                  </Button>
                </Popconfirm>
              </>
            )}
          </Flex>
        </>
      )}
    </>
  );
};

export default AdminPolicyDeactivationReasonsDetails;
