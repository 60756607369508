import { LoadingOutlined } from "@ant-design/icons";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import GlobalFonts from "./assets/fonts/fonts";
import { useAppDispatch, useAppSelector } from "./store";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
// admin side

// some pages & components

import api from "./api";

import { ConfigProvider, Flex, Spin, theme } from "antd";
import { useTranslation } from "react-i18next";
import Colors from "./assets/styles/Colors";
import ComingSoon from "./components/ComingSoon";
import i18n from "./i18n";
import AppRoutes from "./routes";
import { AppState } from "./store/appSlice";
import { getAppThemeLocalStorage } from "./store/appThemeStorage";
import { clearLoginData } from "./store/authSlice";

dayjs.extend(utc);

//@ts-ignore
require("dayjs/locale/ka");
//@ts-ignore
require("dayjs/locale/en");

// admin private
export const AdminPrivateRoute = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element | null => {
  const auth = useAppSelector((data) => data.auth);

  if (auth.userLoading) {
    return (
      <Flex
        justify="center"
        align="center"
        style={{
          height: "100svh",
          width: "100vw",
          backgroundColor: Colors.primaryGrey,
          overflow: "hidden",
        }}
      >
        <Spin indicator={<LoadingOutlined spin />} size="large" />
      </Flex>
    );
  }

  if (
    auth.token &&
    auth.user &&
    auth.user.userType !== "User" &&
    (auth.user.userType === "Frani-Admin" ||
      auth.user.userType === "Frani-Product-Owner" ||
      auth.user.companyUsers.some((value) => {
        const hasAdminRole = [
          "Frani-Admin",
          "Frani-Product-Owner",
          "Insurance-Admin",
          "Insurance-Company-Editor",
          "Insurance-Financial-Viewer",
          "Insurance-Product-Editor",
        ].includes(value.role);

        return hasAdminRole;
      }))
  ) {
    return <>{children}</>;
  }

  return <Navigate replace to="/" state={{ from: "routeProps.location" }} />;
};

const App: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((data) => data.auth);
  const { lang } = useAppSelector((data: { app: AppState }) => data.app);

  // update html lang att
  document.documentElement.lang = i18n?.language;
  // update tab title
  document.title = auth.user
    ? `${auth?.user?.firstName}  | ` + t("administrator")
    : t("administrator");
  const hasRunOnce = useRef(false);

  dayjs.extend(weekday);
  dayjs.extend(localeData);

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const PathName = window.location.hostname;

  dayjs.locale(lang === "ge" ? "ka" : lang);

  useEffect(() => {
    if (hasRunOnce.current) return;

    if (auth.token) {
      const loadProfile = async () => {
        const { error: loadProfileError } = await dispatch(
          api.endpoints.profile.initiate({ token: auth.token })
        );
        if (loadProfileError) {
          dispatch(clearLoginData());
        }
      };
      loadProfile();
    }

    hasRunOnce.current = true;
  }, [auth.token, dispatch]);

  // pin code for main domain
  const [pinCode] = useState<string | null>(localStorage.getItem("pin"));

  return (
    <ConfigProvider
      theme={{
        algorithm:
          getAppThemeLocalStorage() === "dark"
            ? darkAlgorithm
            : defaultAlgorithm,
        token: {
          fontFamily: "inherit",
        },
      }}
    >
      <GlobalFonts />
      {(pinCode === "78651" || pinCode === null) &&
      PathName === "admin-dev.frani.com" ? (
        <Router>
          <GlobalFonts />
          <Routes>
            <Route
              path="*"
              element={
                <ComingSoon
                  title={t("pinTitle")}
                  message={t("pinDescription")}
                />
              }
            />
          </Routes>
        </Router>
      ) : (
        <AppRoutes />
      )}
    </ConfigProvider>
  );
};

export default App;
