import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Drawer,
  Flex,
  Input,
  Segmented,
  Space,
  Typography,
} from "antd";
import Meta from "antd/es/card/Meta";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { GridMinMax } from "../../../assets/styles/GlobalStyle";
import Loader from "../../../components/Loader";
import UploadFile from "../../../components/UploadFile";
import { AboutUsType, EnabledLanguage, OurTeam } from "../../../domain/types";
import { useAppSelector } from "../../../store";
import { AppState } from "../../../store/appSlice";
import AdminLangSwicher from "../../admin_components/AdminLangSwicher";
const { Text } = Typography;

const Admin_about_us = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field_Language, lang } = useAppSelector(
    (data: { app: AppState }) => data.app
  );

  const [selectedMemberID, setSelectedMemberID] = useState<
    number | null | undefined
  >(null);
  const [_segmented, _setSegmented] = useState<string>("1");
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<Partial<AboutUsType>>(
    {}
  );

  const [editedTexts, setEditedTexts] = useState<Partial<AboutUsType>>({});

  const {
    data: aboutUsArray,
    isLoading: isOurTeamLoading,
    refetch: refetchMember,
  } = api.useGetWebPageQuery("about-us");
  const ourTeam: AboutUsType = aboutUsArray as AboutUsType;

  const {
    data: aboutUsTexts,
    isLoading: isAboutUsTextsLoading,
    refetch: isAbouSTextsRefetch,
  } = api.useGetAdminAboutUsTextsQuery(undefined);

  //close base drawer
  const onCloseDrawer = () => {
    setDrawerStatus(false);
    setSelectedMemberID(null);
    setSelectedMember({});
  };

  const setMemberFields = (field: keyof OurTeam, value: any) => {
    setSelectedMember({
      ...selectedMember,
      [field]: value,
    });
  };

  const setEditAboutTextsField = (
    field: keyof AboutUsType,
    language: EnabledLanguage,
    value: any
  ) => {
    setEditedTexts({
      ...editedTexts,
      translations: {
        ...editedTexts.translations,
        [language]: {
          ...editedTexts?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const setEditMemberTranslatedField = (
    field: keyof OurTeam,
    language: EnabledLanguage,
    value: any
  ) => {
    setSelectedMember({
      ...selectedMember,
      translations: {
        ...selectedMember.translations,
        [language]: {
          ...selectedMember?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  const { data: currentMemberData, isLoading: isCurrentMemberDataLoading } =
    api.useGetCurrentTeamMemberQuery(selectedMemberID as number, {
      skip: !selectedMemberID && true,
    });

  const [updateAboutUsTexts, { isLoading: isUpdateAboutUsTextsLoading }] =
    api.useUpdateAboutUsTextsMutation();

  // update about us texts mutation
  const [storeTeamMember, { isLoading: isStoreTeamMemberLoading }] =
    api.useStoreTeamMemberMutation();

  const UpdateAbouUsTexts = async () => {
    try {
      await updateAboutUsTexts(editedTexts).unwrap();
      // refetch
      isAbouSTextsRefetch();
      toast.success(`${"information"} ${t("updatedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  // update & store
  const StoreMember = async () => {
    try {
      await storeTeamMember(selectedMember).unwrap();
      // refetch
      refetchMember();
      // close drawer
      onCloseDrawer();
      toast.success(
        selectedMemberID
          ? `${t("user")} ${"updatedSuccessfully"}`
          : `${t("createdSuccessfully")}`
      );
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  useEffect(() => {
    if (currentMemberData) {
      setSelectedMember(currentMemberData);
    }
  }, [currentMemberData]);

  useEffect(() => {
    if (aboutUsTexts) {
      setEditedTexts(aboutUsTexts);
    }
  }, [aboutUsTexts]);

  const MainLoader =
    isOurTeamLoading || isUpdateAboutUsTextsLoading || isAboutUsTextsLoading;

  const drawerLoading = isCurrentMemberDataLoading || isStoreTeamMemberLoading;

  return MainLoader ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <AdminLangSwicher />
      </Flex>

      <Divider>
        <Segmented<string>
          size="middle"
          style={{ width: "100%", minWidth: "350px" }}
          block
          options={[
            { label: t("manageTexts"), value: "1" },
            { label: t("manageMembers"), value: "2" },
          ]}
          value={_segmented}
          onChange={(value) => {
            _setSegmented(value);
          }}
        />
      </Divider>

      {_segmented === "1" && (
        <>
          <Flex vertical gap={20}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("title")}</Text>
              <TextArea
                size="large"
                placeholder={t("title")}
                autoSize={{ minRows: 2 }}
                value={editedTexts.translations?.[field_Language]?.title || ""}
                onChange={(event) =>
                  setEditAboutTextsField(
                    "title",
                    field_Language,
                    event.target.value
                  )
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("description")}</Text>
              <TextArea
                size="large"
                placeholder={t("description")}
                autoSize={{ minRows: 2 }}
                value={
                  editedTexts.translations?.[field_Language]?.excerpt || ""
                }
                onChange={(event) =>
                  setEditAboutTextsField(
                    "excerpt",
                    field_Language,
                    event.target.value
                  )
                }
              />
            </Space>

            <Space direction="vertical" style={{ width: "100%" }}>
              <Text>{t("sliderTitle")}</Text>
              <TextArea
                size="large"
                placeholder={t("sliderTitle")}
                autoSize={{ minRows: 2 }}
                value={editedTexts.translations?.[field_Language]?.body || ""}
                onChange={(event) =>
                  setEditAboutTextsField(
                    "body",
                    field_Language,
                    event.target.value
                  )
                }
              />
            </Space>

            <Flex justify="center">
              <Button
                size="large"
                style={{ width: "60%" }}
                onClick={UpdateAbouUsTexts}
              >
                {t("update")}
              </Button>
            </Flex>
          </Flex>
        </>
      )}

      {_segmented === "2" && (
        <>
          <Button
            size="large"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => [
              setDrawerStatus(!drawerStatus),
              setSelectedMember({}),
            ]}
          >
            {t("create")}
          </Button>

          <Divider />

          <GridMinMax>
            {ourTeam &&
              ourTeam?.children?.map((item, index) => (
                <Card
                  hoverable
                  key={index}
                  onClick={() => [
                    setDrawerStatus(!drawerStatus),
                    setSelectedMemberID(item?.id),
                  ]}
                  cover={
                    <img
                      style={{ height: "200px", objectFit: "contain" }}
                      alt={item?.image}
                      src={item?.image}
                    />
                  }
                >
                  <Meta
                    title={item?.translations?.[lang]?.title}
                    description={
                      <Flex vertical gap={5}>
                        <Text>{item?.translations?.[lang]?.excerpt}</Text>
                        <Text>{item.url}</Text>
                      </Flex>
                    }
                  />
                </Card>
              ))}
          </GridMinMax>

          <Drawer
            extra={<AdminLangSwicher />}
            title={selectedMemberID ? t("update") : t("create")}
            onClose={onCloseDrawer}
            open={drawerStatus}
            loading={drawerLoading}
          >
            <Flex vertical gap={20}>
              <Space direction="vertical">
                <Text>{t("title")}</Text>
                <Input
                  size="large"
                  placeholder={t("title")}
                  value={
                    selectedMember?.translations?.[field_Language]?.title || ""
                  }
                  onChange={(event) =>
                    setEditMemberTranslatedField(
                      "title",
                      field_Language,
                      event?.target?.value
                    )
                  }
                />
              </Space>

              <Space direction="vertical">
                <Text>{t("description")}</Text>
                <Input
                  size="large"
                  placeholder={t("description")}
                  value={
                    selectedMember?.translations?.[field_Language]?.excerpt ||
                    ""
                  }
                  onChange={(event) =>
                    setEditMemberTranslatedField(
                      "excerpt",
                      field_Language,
                      event?.target?.value
                    )
                  }
                />
              </Space>

              <Space direction="vertical">
                <Text>{t("linkedinLink")}</Text>
                <Input
                  size="large"
                  placeholder={t("linkedinLink")}
                  value={selectedMember?.url || ""}
                  onChange={(event) =>
                    setMemberFields("url", event?.target?.value)
                  }
                />
              </Space>

              <UploadFile
                label={t("image")}
                onChange={(props) => setMemberFields("image", props.url)}
                defaultFileName={selectedMember?.image || ""}
              />
            </Flex>

            <Divider />

            <Flex>
              <Button
                size="large"
                style={{
                  width: "100%",
                }}
                onClick={StoreMember}
              >
                {selectedMemberID ? t("update") : t("create")}
              </Button>
            </Flex>
          </Drawer>
        </>
      )}
    </>
  );
};

export default Admin_about_us;
