import {
  DeleteOutlined,
  FullscreenOutlined,
  LoadingOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Divider,
  Flex,
  Popconfirm,
  Result,
  Spin,
} from "antd";
import Meta from "antd/es/card/Meta";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api";
import { RootState, useAppSelector } from "../../../store";
import { AuthState } from "../../../store/authSlice";

const AdminUsers = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { lang } = useAppSelector((root) => root.app);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // authorized company users list
  const {
    data: authorizedCompanies,
    isLoading: isAuthorizedUsersLoading,
    refetch: refetchAuthorizedCompanies,
  } = api.useGetAdminAuthorizedCompaniesQuery(undefined);

  // remove company
  const [removeCompany, { isLoading: isRemoveCompanyLoading }] =
    api.useRemoveCompanyMutation();

  // remove user in admin functon
  const removeCompanyFun = async (companyId: number) => {
    if (companyId) {
      try {
        await removeCompany(companyId).unwrap();
        // refetch authorized users
        refetchAuthorizedCompanies();
        //
        toast.success(`${t("company")} ${t("deletedSuccessfully")}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  // main loading
  const MainLoading = isAuthorizedUsersLoading || isRemoveCompanyLoading;

  const refetchCompanies = useCallback(() => {
    refetchAuthorizedCompanies();
  }, [refetchAuthorizedCompanies]);

  // refetch companies
  useEffect(() => {
    refetchCompanies();
    return () => {};
  }, [refetchCompanies]);

  return (
    <>
      {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) !==
      "Frani-Admin" ? (
        <Flex justify="center" align="center" style={{ height: "100%" }}>
          <Result
            title="თქვენ არ გაქვთ წვდომა მიმდინარე გვერდზე !!!"
            extra={
              <Button type="primary" size="large" onClick={() => navigate("/")}>
                {t("homePage")}
              </Button>
            }
          />
        </Flex>
      ) : (
        <>
          {MainLoading ? (
            <Flex justify="center" align="center" style={{ height: "100%" }}>
              <Spin indicator={<LoadingOutlined spin />} size="large" />
            </Flex>
          ) : (
            <>
              <Button
                size="large"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate(`/company-detail/0`)}
              >
                {t("create")}
              </Button>

              {authorizedCompanies && (
                <>
                  <Divider />

                  <Flex
                    wrap="wrap"
                    gap={30}
                    justify="space-around"
                    align="center"
                    style={{ marginTop: 20 }}
                  >
                    {authorizedCompanies &&
                      authorizedCompanies.map((item, index) => (
                        <Card
                          key={index}
                          style={{
                            maxWidth: 350,
                            width: "100%",
                            height: "",
                            cursor: "default",
                          }}
                          hoverable
                          cover={
                            <img
                              style={{
                                paddingTop: 10,
                                width: 150,
                                height: 150,
                                objectFit: "contain",
                                marginInline: "auto",
                              }}
                              alt={`company-cover---${item?.cover}`}
                              src={item?.cover}
                            />
                          }
                          actions={[
                            <Button
                              // detail screen
                              type="link"
                              onClick={() =>
                                navigate(`/company-detail/${item.id}`)
                              }
                              style={{
                                display: "grid",
                                placeItems: "center",
                                width: "100%",
                              }}
                            >
                              <FullscreenOutlined
                                style={{
                                  fontSize: 16,
                                  height: 16,
                                  width: 16,
                                }}
                              />
                            </Button>,

                            <Button
                              // company users
                              type="link"
                              onClick={() =>
                                navigate(`/authorized-company-users/${item.id}`)
                              }
                              style={{
                                display: "grid",
                                placeItems: "center",
                                width: "100%",
                              }}
                            >
                              <UsergroupAddOutlined
                                style={{
                                  fontSize: 16,
                                }}
                              />
                            </Button>,

                            <Popconfirm
                              // delete company
                              title={t("certainToDeleteIt")}
                              onConfirm={() => removeCompanyFun(item.id)}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                type="link"
                                style={{
                                  display: "grid",
                                  placeItems: "center",
                                  width: "100%",
                                }}
                              >
                                <DeleteOutlined
                                  style={{
                                    fontSize: 16,
                                  }}
                                />
                              </Button>
                            </Popconfirm>,
                          ]}
                        >
                          <Meta
                            style={{ minHeight: 80 }}
                            avatar={<Avatar src={item?.logo} />}
                            title={item?.translations?.[lang]?.title}
                            description={item?.translations?.[lang]?.excerpt}
                          />
                        </Card>
                      ))}
                  </Flex>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdminUsers;
