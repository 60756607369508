import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import api from "../../../api";
import Responsive from "../../../assets/config/Responsive";
import Colors from "../../../assets/styles/Colors";
import { MediumScreenTitle } from "../../../assets/styles/GlobalStyle";
import Button from "../../admin_components/AdminButton";
import Checkbox from "../../../components/Checkbox";
import Input from "../../../components/Input";
import Loader from "../../../components/Loader";
import BaseModal from "../../../components/modals/BaseModal";
import NotFound404 from "../../../components/NotFound404";
import {
  AddUser,
  AdminCompanyUser,
  AdminRole,
  User,
} from "../../../domain/types";
import { AuthState } from "../../../store/authSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { useTranslation } from "react-i18next";

const AdminCompanyUsersEdit = () => {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { t } = useTranslation();
  const { companyId: companyIdStr = "0" } = useParams();
  const companyId = parseInt(companyIdStr);
  // add user modal status
  const [addUserModalStatus, setAddUserModalStatus] = useState<boolean>(false);
  // add user collected roles
  const [collectedRoles, setCollectedRoles] = useState<string[]>([]);
  // update user collted roles
  const [updateUserColletedRole, setupdateUserColletedRole] = useState<
    { id: number | null; companyId: number; role: string }[]
  >([]);
  // add user state
  const [addUserState, setAddUserState] = useState<Partial<AddUser>>({});
  // update user state
  const [updateUserState, setUpdateUserState] = useState<
    Partial<AdminCompanyUser>
  >({});
  // update user state
  const [updateUserModalStatus, setupdateUserModalStatus] =
    useState<boolean>(false);
  // update user id
  const [updateUserId, setupdateUserId] = useState<number | null>(null);
  // get admin authorized company users
  const {
    data: authorizedCompanyUsers,
    isLoading: isAuthorizedCompanyUsersLoading,
    refetch: refetchUsers,
  } = api.useGetAdminCompanyUsersQuery(companyId as number, {
    skip: true && !companyId,
  });
  // existing user
  const existingUser: User | undefined =
    authorizedCompanyUsers &&
    authorizedCompanyUsers.find((x) => x.id === updateUserId);
  // add user fields
  const setAddUser = (field: keyof AddUser, value: any) => {
    setAddUserState({
      ...addUserState,
      companyId: companyId,
      [field]: value,
    });
  };
  // update user fields
  const setUpdateUser = (
    field: keyof Partial<AdminCompanyUser>,
    value: any
  ) => {
    setUpdateUserState({
      ...updateUserState,
      [field]: value,
    });
  };
  useEffect(() => {
    setAddUser("companyRoles", collectedRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectedRoles]);

  useEffect(() => {
    setUpdateUser("companyUsers", updateUserColletedRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserColletedRole]);
  //close add user modal
  const _addUserCloseModal = () => {
    setAddUserModalStatus(false);
  };
  //close update user modal
  const _updateUserCloseModal = () => {
    setupdateUserModalStatus(false);
    // setUpdateUserState({})
    setupdateUserColletedRole([]);
  };
  // store user
  const [storeUserInAdmin, { isLoading: isStoreUserInADminLoading }] =
    api.useStoreUserInAdminMutation();
  //
  const storeUserFun = async () => {
    try {
      await storeUserInAdmin(addUserState as AddUser).unwrap();
      // refetch authorized users
      refetchUsers();
      // updated user state
      setAddUserState({});
      // close modal
      _addUserCloseModal();
      //
      toast.success(`${t("user")} ${t("addedSuccessfully")}`);
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };
  // remove user
  const [removeUserInAdmin, { isLoading: isRemoveUserInAdmin }] =
    api.useRemoveUserInAdminMutation();
  // remove user in admin functon
  const removeUserInAdminFun = async () => {
    if (updateUserId) {
      try {
        await removeUserInAdmin(updateUserId).unwrap();
        // refetch authorized users
        refetchUsers();
        // close update user modal
        _updateUserCloseModal();
        //
        toast.success(`${t("user")} ${t("deletedSuccessfully")}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };
  // update user
  const [updateUserInAdmin, { isLoading: isUpdateUserInADminLoading }] =
    api.useUpdateUserInAdminMutation();
  // update user in admin functon
  const updateUserInAdminFun = async () => {
    if (updateUserState && updateUserId) {
      try {
        await updateUserInAdmin({
          company: {
            firstName: updateUserState.firstName,
            lastName: updateUserState.lastName,
            email: updateUserState.email,
            password: updateUserState.password,
            companyUsers: updateUserState.companyUsers,
          },
          userId: updateUserId,
        }).unwrap();
        // refetch authorized users
        refetchUsers();
        // close update user modal
        _updateUserCloseModal();
        //
        toast.success(`${t("user")} ${t("deletedSuccessfully")}`);
        //
      } catch (error) {
        if (error?.data?.errors?.constructor === Array) {
          const firstError = error.data.errors[0];
          const errorMessage = firstError.message;
          toast.error(errorMessage);
        } else {
          toast.error(`${t("errorOccurred")}`);
        }
      }
    }
  };

  useEffect(() => {
    if (existingUser) {
      setUpdateUserState(existingUser);
      setupdateUserColletedRole([...existingUser.companyUsers]);
    }
  }, [existingUser]);

  const MainLoading =
    isAuthorizedCompanyUsersLoading ||
    isStoreUserInADminLoading ||
    isRemoveUserInAdmin ||
    isUpdateUserInADminLoading;

  return MainLoading ? (
    <Loader />
  ) : !authorizedCompanyUsers ? (
    <NotFound404 />
  ) : (
    <Wrapper>
      <MediumScreenTitle>{t("companyUsers")}</MediumScreenTitle>
      {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) ===
        "Insurance-Admin" || "Frani-Admin" ? (
        <Button
          btnStyleType="primary"
          text={t("createUser")}
          onClick={() => setAddUserModalStatus(!addUserModalStatus)}
        />
      ) : null}
      <br />
      <br />
      <Block>
        {authorizedCompanyUsers &&
          authorizedCompanyUsers.map((item, index) => (
            <UsersCard
              key={index}
              onClick={() => [
                setupdateUserId(item.id),
                setupdateUserModalStatus(!updateUserModalStatus),
              ]}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "500",
                  borderBottom: `1px solid ${Colors.primaryRed}`,
                }}
              >
                {item.firstName}
              </span>
              {/*  */}
              {item.companyUsers.map((user, userIndex) => (
                <UserRoleStyle key={userIndex}>
                  <span>{user.role.toLowerCase().replace(/-/g, " ")}</span>
                </UserRoleStyle>
              ))}
              {/*  */}
            </UsersCard>
          ))}
      </Block>
      {/* add user */}
      <BaseModal onClose={_addUserCloseModal} isOpen={addUserModalStatus}>
        <h4>{t("createUser")}</h4>

        <Input
          id="first-name"
          label={t("firstName")}
          type="text"
          placeholder=""
          value={addUserState.firstName || ""}
          name="first-name"
          onChange={(value) => setAddUser("firstName", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />

        <Input
          id="last-name"
          label={t("lastName")}
          type="text"
          placeholder=""
          value={addUserState.lastName || ""}
          name="last-name"
          onChange={(value) => setAddUser("lastName", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />

        <Input
          id="იმეილი"
          label={t("email")}
          type="text"
          placeholder=""
          value={addUserState.email || ""}
          name="add-email"
          onChange={(value) => setAddUser("email", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />

        <br />

        {Object.keys(AdminRole).map((role, index) => {
          const test = collectedRoles.find((x) => x === role);
          return (
            <CheckboxBox key={index}>
              <Checkbox
                id={`role-${role}`}
                defaultChecked={test ? true : false}
                label={role.toLowerCase().replace(/_/g, " ")}
                onChange={(checked) => {
                  if (checked === true) {
                    setCollectedRoles([
                      ...collectedRoles,
                      `${role.replace(/_/g, "-")}`,
                    ]);
                    setAddUser("companyRoles", collectedRoles);
                  } else {
                    setCollectedRoles([
                      ...collectedRoles.filter((x) => x !== role),
                    ]);
                    setAddUser("companyRoles", collectedRoles);
                  }
                }}
              />
            </CheckboxBox>
          );
        })}
        <br />
        <Button
          disabled={
            addUserState.companyRoles &&
            addUserState.companyRoles.length >= 1 &&
            addUserState.email &&
            addUserState.firstName &&
            addUserState.lastName
              ? false
              : true
          }
          fullWidth={true}
          btnStyleType="primary"
          text={t("create")}
          onClick={storeUserFun}
        />
      </BaseModal>
      {/* add user */}
      {/* update user */}
      <BaseModal onClose={_updateUserCloseModal} isOpen={updateUserModalStatus}>
        <h4>{t("update")}</h4>
        <Input
          id="update-first-name"
          label={t("firstName")}
          type="text"
          placeholder=""
          value={updateUserState.firstName || ""}
          name="update-first-name"
          onChange={(value) => setUpdateUser("firstName", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />
        <Input
          id="update-last-name"
          label={t("lastName")}
          type="text"
          placeholder=""
          value={updateUserState.lastName || ""}
          name="update-last-name"
          onChange={(value) => setUpdateUser("lastName", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />
        <Input
          id="update-add-email"
          label={t("email")}
          type="text"
          placeholder=""
          value={updateUserState.email || ""}
          name="update-add-email"
          onChange={(value) => setUpdateUser("email", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
        />

        <Input
          id="password"
          label={t("password")}
          type="password"
          placeholder=""
          value={updateUserState.password || ""}
          name="password"
          onChange={(value) => setUpdateUser("password", value)}
          width={"fullWidth"}
          style={{ marginBottom: "24px" }}
          icon={true}
        />

        <br />

        {Object.keys(AdminRole).map((role, index) => {
          const test = existingUser?.companyUsers.find(
            (x) => x.role === `${role.replace(/_/g, "-")}`
          );
          return (
            <CheckboxBox key={index}>
              <Checkbox
                id={`role-${role}`}
                defaultChecked={test ? true : false}
                label={role.toLowerCase().replace(/_/g, " ")}
                onChange={(checked) => {
                  if (checked === true) {
                    setupdateUserColletedRole([
                      ...updateUserColletedRole,
                      {
                        companyId: companyId,
                        id: updateUserId && -updateUserId,
                        role: `${role.replace(/_/g, "-")}`,
                      },
                    ]);
                    setUpdateUser("companyUsers", updateUserColletedRole);
                  } else {
                    setupdateUserColletedRole([
                      ...updateUserColletedRole.filter(
                        (x) => x.role !== `${role.replace(/_/g, "-")}`
                      ),
                    ]);
                    setUpdateUser("companyUsers", updateUserColletedRole);
                  }
                }}
              />
            </CheckboxBox>
          );
        })}

        <ButtonsBlock>
          {/* update user */}
          {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) ===
            "Insurance-Admin" || "Frani-Admin" ? (
            <Button
              fullWidth={true}
              disabled={
                updateUserState &&
                updateUserState.companyUsers &&
                updateUserState.companyUsers.length >= 1 &&
                updateUserState.firstName &&
                updateUserState.lastName &&
                updateUserState.email
                  ? false
                  : true
              }
              text={t("update")}
              btnStyleType="primary"
              onClick={updateUserInAdminFun}
            />
          ) : null}

          {/* update user */}

          <br />

          {/* remove user */}
          {(auth?.user?.companyUsers[0]?.role || auth?.user?.userType) ===
          "Frani-Admin" ? (
            <Button
              fullWidth={true}
              btnStyleType="transparent"
              border={true}
              text={t("delete")}
              onClick={() =>
                window.confirm(`${t("certainToDeleteIt")}`) &&
                removeUserInAdminFun()
              }
            />
          ) : null}

          {/* remove user */}
        </ButtonsBlock>
      </BaseModal>
      {/* update user */}
    </Wrapper>
  );
};
//
// styles
const ButtonsBlock = styled.div`
  display: flex;
  margin-top: 40px;
  border-top: 1px solid ${Colors.primaryGrey};
  padding-top: 40px;
  /*  */
  button {
    margin-right: 20px;
    &:last-child {
      margin-right: 0px;
    }
  }
`;
const CheckboxBox = styled.div`
  margin-bottom: 10px;
`;
const UserRoleStyle = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: ${Colors.primaryGrey};
  }
`;
const Block = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  /*  */
  ${Responsive.mobile} {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
`;
const UsersCard = styled.div`
  border: 1px solid ${Colors.primaryGrey};
  border-radius: 8px;
  padding: 10px;
  min-height: 200px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  /*  */
  span {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  /*  */
  &:hover {
    transform: scale(1.05);
    transition: all 200ms ease-in-out;
  }
`;
const Wrapper = styled.div``;
//
export default AdminCompanyUsersEdit;
